
import { Box, Flex, Grid, GridItem, Heading, useColorModeValue, useToast, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Mentee } from 'views/trader/trades/variables/types';
import MenteeBar from '../components/MenteeBar';
import Card from 'components/card/Card';
import api from 'services/api';
import MenteeInvite from '../components/MenteeInvite';
import MentorProfileModal from '../components/MentorProfileModal';
import MentorHelpModal from '../components/help/MentorHelpModal';
import { useData } from 'services/data/DataProvider';
import MentorStudentsTable from '../components/MentorStudentsTable';

const Mentorpage = () => {

  const { mentees } = useData();

  const [menteesactive, setMenteesActive] = useState<Mentee[]>([]);
  const [menteespending, setMenteesPending] = useState<Mentee[]>([]);
  const bgcolor = useColorModeValue('white', 'navy.900');

  const toast = useToast();

  useEffect(() => {
    let ma = mentees.filter((mt: Mentee) => mt.status !== 'pending' && mt.status !== 'requested')
    setMenteesActive(ma);
    let mp = mentees.filter((mt: Mentee) => mt.status === 'pending' || mt.status === 'requested')
    setMenteesPending(mp);

    api.post('/mentor/reviewspending').then(async (res) => {
      if (res.data === false) {
        alert('FALSE')
      }
      else {
        for (let m of ma) {
          let me = res.data.filter((mr: { traderid: number, pending: number }) => mr.traderid === m.menteeid);
          if (me.length) {
            m.pending = me[0].pending;
          }
          else {
            m.pending = 0;
          }
        }
        setMenteesActive(ma);
      }
    }).catch((e) => {
      alert('ERROR')
    });

  }, [mentees])

  const updatePending = (userid: number, reviewed:boolean) => {
    let ma = menteesactive.slice();
    let me = ma.filter((m) => m.menteeid === userid);
    if (me.length && !reviewed) {
      me[0].pending--;
    }
    setMenteesActive(ma);
  }

  return (
    <>
      <Flex direction={'column'} gap={5} w={'100%'}>
        <Flex justify={'end'} mb='-60px' me='20px' >
          <MentorProfileModal />
          <MentorHelpModal />
        </Flex>

        {(menteespending.length < 1 && menteesactive.length < 1) &&
          <>
            <Card borderWidth={'1px'} bg={'transparent'} w='100%' mt='20px'>
              <Flex>
                <Heading size={'sm'} mt='-30px' bg={bgcolor} px='10px'>Students</Heading>
              </Flex>
              <Flex direction={'column'} justify={'space-evenly'}>
                <Text mt='10px'>Mentor friends and family, or paying students.</Text>
                <Flex mt='20px' justify={'start'}>
                  <MenteeInvite />
                </Flex>
                <Text mt='20px'>You will need the user's email address that they have registered with TradeJorno.</Text>
              </Flex>
            </Card>
          </>
        }

        {menteespending.length > 0 &&
          <Card borderWidth={'1px'} bg={'transparent'} w='100%' mt='20px'>
            <Flex>
              <Heading size={'md'} mt='-35px' bg={bgcolor} px='10px'>Pending</Heading>
            </Flex>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }} gap={5} mt='30px'>
              {menteespending.map((m, index) => (
                <GridItem w='100%'>
                  <Flex>
                    <MenteeBar mentee={m} />
                  </Flex>
                </GridItem>
              ))}
            </Grid>
          </Card>
        }

        {menteesactive.length > 0 &&
          <Flex hidden={!menteesactive.length} mt='20px'>
            <Card borderWidth={'1px'} bg='transparent' overflowX={'auto'}>
              <Flex zIndex={0}>
                <Heading size={'sm'} bg={bgcolor} px='10px'>Students</Heading>
              </Flex>
              <Flex direction='column' w='100%' overflowX={'auto'}>
                <Box>
                  <MentorStudentsTable mentees={menteesactive} updatepending={updatePending} />
                </Box>
              </Flex>
            </Card>
          </Flex>
        }
      </Flex>
    </>

  )
}

export default Mentorpage