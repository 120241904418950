import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Flex, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'

const AccountInputModal = forwardRef((props: { updateValue: any }, ref) => {

    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [balance, setBalance] = useState(null);
    const cancelRef = useRef();

    useImperativeHandle(ref, () => ({
        openInput() {
            onOpen();
        }
    }));
    const closeInput = () => {
        /*if (balance) {
            props.updateValue(balance);
            onClose();
        }*/
        props.updateValue(balance);
        onClose();
    }
    

    return <>
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} motionPreset='slideInBottom' closeOnOverlayClick={false}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Enter your opening balance ..
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Flex justify='center'>
                            <NumberInput min={1} allowMouseWheel={true} onChange={(valueString) => setBalance(valueString ? Number(valueString) : '')} >
                                <NumberInputField color={textColor} autoFocus={true}/>
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </Flex>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={closeInput}>
                            Cancel
                        </Button>
                        <Button colorScheme='red' onClick={closeInput} ml={3}>
                            Update
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </>
});

export default AccountInputModal