import { StatStrat, Trade } from "views/trader/trades/variables/types";

export async function StartegyStats(strategyid: number, trades: Trade[], currency: string): Promise<any> {

    return new Promise(async (resolve, reject) => {

        let winners = 0;
        let losers = 0;
        let wintotal = 0;
        let losstotal = 0;
        let maxwin = 0;
        let maxloss = 0;

        let Lscoretotal = 0;
        let Wscoretotal = 0;

        let tradecount = 0;

        for (let t of trades) {

            tradecount++;

            let ex = (t.currency !== currency ? t.exchange : 1);
            let nett = (t.nett / ex);

            winners += (nett > 0 ? 1 : 0);
            losers += (nett < 0 ? 1 : 0);

            wintotal += (nett > 0 ? nett : 0);
            losstotal += (nett < 0 ? nett : 0);

            maxwin = nett > maxwin ? nett : maxwin;
            maxloss = nett < maxloss ? nett : maxloss;

            let s = t.strategies.filter((s) => s.id === strategyid);
            if (s.length) { // never going to be ZERO but best to check anyway
                let used = 0;
                for (let r of s[0].rules) {
                    used += r.used === true ? 1 : 0;
                }

                if (nett > 0) {
                    Wscoretotal += (s[0].rules.length > 0 ? used / s[0].rules.length : 0)
                }
                else {
                    Lscoretotal += (s[0].rules.length > 0 ? used / s[0].rules.length : 0)
                }
            }
        }


        let wperc = tradecount > 0 ? (winners / tradecount) * 100 : 0;
        let lperc = tradecount > 0 ? (losers / tradecount) * 100 : 0;

        let wavg = winners > 0 ? wintotal / winners : 0;
        let lavg = losers > 0 ? losstotal / losers : 0;

        let wavgscore = winners > 0 ? Wscoretotal / winners : 0;
        let lavgscore = losers > 0 ? Lscoretotal / losers : 0;


        let val: StatStrat = {
            wcount: winners,
            wperc: wperc,
            wavg: wavg,
            wmax: maxwin,
            wavgscore: wavgscore * 100,
            lcount: losers,
            lperc: lperc,
            lavg: lavg,
            lmax: maxloss,
            lavgscore: lavgscore * 100
        }

        resolve(val);
    });
}

export async function MistakeStats(mistakeid: number, trades: Trade[], currency: string): Promise<any> {

    return new Promise(async (resolve, reject) => {
        
    });
}
