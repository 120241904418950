import { Flex, Icon, IconButton } from "@chakra-ui/react";
import { MdClear } from "react-icons/md";

export const TradeEditCell = (props: { row: any, table: any }) => {
    const meta = props.table.options.meta;

    const removeRow = () => {
        meta?.removeRow(props.row.index);
    };

    return (
        <>
            <Flex justifyContent='start'>
                <IconButton
                    variant='solid'
                    colorScheme='red'
                    size='sm'
                    aria-label='remove'
                    name='remove'
                    icon={<Icon as={MdClear} w="16px" h="16px" />}
                    onClick={removeRow}
                    ms='5px' me='5px'
                />
            </Flex>
        </>
    );
};
