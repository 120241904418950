import dayjs, { Dayjs } from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { PeriodDates } from "./types";

export function PeriodDateRange(period: string) {

    dayjs.extend(weekOfYear);
    dayjs.extend(quarterOfYear);

    let s_this: Dayjs;
    let e_this: Dayjs;
    let s_last: Dayjs;
    let e_last: Dayjs;

    let per = period.replace('this', '').replace('last', '');

    if (per === 'week') {
        s_this = (period === 'weekthis' ? dayjs().startOf("week") : dayjs().startOf("week").subtract(1, 'week'));
        e_this = s_this.add(1, 'week')
        s_last = s_this.subtract(1, 'week');
        e_last = s_last.add(1, 'week');
    }
    if (per === 'month') {
        s_this = (period === 'monththis' ? dayjs().startOf("month") : dayjs().startOf("month").subtract(1, 'month'));
        e_this = s_this.add(1, 'month');
        s_last = s_this.subtract(1, 'month');
        e_last = s_last.add(1, 'month');
    }
    if (per === 'quarter') {
        s_this = (period === 'quarterthis' ? dayjs().startOf("quarter") : dayjs().startOf("quarter").subtract(1, 'quarter'));
        e_this = s_this.add(1, 'quarter');
        s_last = s_this.subtract(1, 'quarter');
        e_last = s_last.add(1, 'quarter');
    }
    if (per === 'year') {
        s_this = (period === 'yearthis' ? dayjs().startOf('year') : dayjs().startOf('year').subtract(1, 'year'));
        e_this = s_this.add(1, 'year');
        s_last = s_this.subtract(1, 'year');
        e_last = s_last.add(1, 'year');
    }

    let range: PeriodDates = {
        period: per,
        startthis: s_this,
        endthis: e_this,
        startlast: s_last,
        endlast: e_last,
    }

    return range;
}