import { Box, Flex, Heading, Icon, Spinner, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import Card from 'components/card/Card'
import IconBox from 'components/icons/IconBox';
import { currencyformat, getDuration } from 'views/trader/trades/variables/FormatData';
import { MdOutlineHorizontalRule, MdOutlineTimer, MdOutlineTrendingDown, MdNumbers } from 'react-icons/md';
import { LuCalendarMinus, LuCalendarX, LuCalendarX2 } from "react-icons/lu";
import { PiMathOperationsBold } from "react-icons/pi";
import { StatLose } from 'views/trader/trades/variables/types';

const LoseStats = (props: { loading: boolean, stats: StatLose, currency: string }) => {

    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const iconColor = "gray.400";
    const boxBg = useColorModeValue('white', 'navy.800');
    const statColor = useColorModeValue('navy.700', 'gray.200');
    const spinnerColor = useColorModeValue('gray.300', 'brand.300');
    const bgcolor = useColorModeValue('white', 'navy.900');
    const headercolor = "red.500";
    const mbottom = '10px'

    return (
        <Card borderColor={bdColor} borderWidth='1px' h={'100%'} bg='transparent' mt='10px'>
            <Flex>
                <Heading size={'xs'} mt='-30px' bg={bgcolor} px='10px'>Losers</Heading>
            </Flex>

            <Box h={'100%'} justifyContent={'center'} alignContent={'center'} hidden={!props.loading}>
                <Flex justify={'center'}>
                    <Spinner color={spinnerColor}></Spinner>
                </Flex>

            </Box>
            <Flex direction={'column'} hidden={props.loading}>
                <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                    <Flex direction={'column'}>
                        <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Total</Text>
                        <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.losstotal}{'  ('}{props.stats.lossperc.toFixed()}%{')'}</Text>
                    </Flex>
                    <Flex>
                        <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
                            icon={<Icon color={iconColor} as={MdNumbers} w="20px" h="20px" />}
                        />
                    </Flex>
                </Flex>

                <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                    <Flex direction={'column'}>
                        <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>AVG loss</Text>
                        <Text color={statColor} fontSize="sm" fontWeight="500" >{currencyformat(props.stats.avgloss, props.currency)}</Text>
                    </Flex>
                    <Flex>
                        <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
                            icon={<Icon color={iconColor} as={PiMathOperationsBold} w="20px" h="20px" />}
                        />
                    </Flex>
                </Flex>

                <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                    <Flex direction={'column'}>
                        <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Biggest loss</Text>
                        <Text color={statColor} fontSize="sm" fontWeight="500" >{currencyformat(props.stats.maxloss, props.currency)}</Text>
                    </Flex>
                    <Flex>
                        <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
                            icon={<Icon color={iconColor} as={MdOutlineHorizontalRule} w="20px" h="20px" />}
                        />
                    </Flex>
                </Flex>

                <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                    <Flex direction={'column'}>
                        <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Worst day</Text>
                        <Text color={statColor} fontSize="sm" fontWeight="500" >{currencyformat(props.stats.worstday, props.currency)}</Text>
                    </Flex>
                    <Flex>
                        <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
                            icon={<Icon color={iconColor} as={LuCalendarX2} w="20px" h="20px" />}
                        />
                    </Flex>
                </Flex>

                <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                    <Flex direction={'column'}>
                        <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Losing days</Text>
                        <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.lossdays}</Text>
                    </Flex>
                    <Flex>
                        <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
                            icon={<Icon color={iconColor} as={LuCalendarMinus} w="20px" h="20px" />}
                        />
                    </Flex>
                </Flex>

                <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                    <Flex direction={'column'}>
                        <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Max loss streak</Text>
                        <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.losstreak}</Text>
                    </Flex>
                    <Flex>
                        <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
                            icon={<Icon color={iconColor} as={MdOutlineTrendingDown} w="20px" h="20px" />}
                        />
                    </Flex>
                </Flex>

                <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                    <Flex direction={'column'}>
                        <Text color={headercolor} fontSize="sm" fontWeight="600" >AVG lose duration</Text>
                        <Text color={statColor} fontSize="sm" fontWeight="500" >{getDuration(props.stats.avg_duration)}</Text>
                    </Flex>
                    <Flex>
                        <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
                            icon={<Icon color={iconColor} as={MdOutlineTimer} w="20px" h="20px" />}
                        />
                    </Flex>
                </Flex></Flex>
        </Card>
    )
}

export default LoseStats