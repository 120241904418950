
import {
    useDisclosure,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useToast,
    Flex,
    Icon,
    Text
} from '@chakra-ui/react'

import { useRef, useState } from "react";
import { MdClose, MdOutlineWarningAmber } from 'react-icons/md';
import api from 'services/api';
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';

const DeleteAccountConfirm = (props: { accountid: number, resetEdit: any }) => {

    const {muuid} = useAuth();
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    const deleteAccount = async () => {

        setLoading(true);

        await api.post('/accounts/deleteaccount', { accountid: props.accountid, last_uuid: muuid })
            .then(async (res) => {
                if (res.status === 200) {
                    if (res.data) {
                        await db.accounts.where({ accountid: props.accountid }).delete().then(async () => { // delete account first ..
                            await db.trades.where({ accountid: props.accountid }).delete().catch(() => { }); // then if successful, delete account trades
                        }).catch(() => { });
                    }
                }
                else {
                    toast({ title: 'Unable to delete', description: "Please try again ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                }
                setLoading(false);
                onClose();
                props.resetEdit();
            }).catch(function (error) {
                onClose();
                toast({ title: 'Unable to delete', description: "Verify your network connection ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                setLoading(false);
                props.resetEdit();
            });

        //db.trades.delete(props.id);
    }

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    return (
        <>
            <Button variant='outline' onClick={onOpen} borderLeftRadius='0px' borderRightRadius='10px' hidden={props.accountid === 0}>
                <Icon as={MdClose} boxSize={5} color={'red.500'}/>
            </Button>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} motionPreset='slideInBottom'>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader >
                            <Flex direction={'row'}>
                                <Icon as={MdOutlineWarningAmber} boxSize={8} color={'red.500'} />
                                <Text fontSize='lg' fontWeight='bold' color={'red.500'} ms='20px' mt='5px'>
                                    Delete Account
                                </Text>
                            </Flex>
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Text>
                                This action will delete the account and ALL trades associated with it and cannot be undone ...
                            </Text>
                            <Text fontWeight={'700'} mt='20px' fontSize='lg'>
                                Are you sure?
                            </Text>

                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={deleteAccount} ml={3} isLoading={loading} loadingText="Deleting ...">
                                Yes, Delete!
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default DeleteAccountConfirm