import { useColorModeValue } from '@chakra-ui/system';
import dashlight from 'assets/img/jorno/dash_light.png';
import dashdark from 'assets/img/jorno/dash_dark.png';
import tradelight from 'assets/img/jorno/trcomp_light.png';
import tradedark from 'assets/img/jorno/trcomp_dark.png';
import stratlight from 'assets/img/jorno/strat_light.png';
import stratdark from 'assets/img/jorno/strat_dark.png';
import journallight from 'assets/img/jorno/jorno_light.png';
import journaldark from 'assets/img/jorno/jorno_dark.png';
import acclight from 'assets/img/jorno/acc_light.png';
import accdark from 'assets/img/jorno/acc_dark.png';
import mentordark from 'assets/img/jorno/mentor_dark.png';
import mentorlight from 'assets/img/jorno/mentor_light.png';

import LandingOtherLayout from 'layouts/landing/variants/LandingOther';
import { Flex, Heading, Spacer, Text, useMediaQuery } from '@chakra-ui/react';
import { TJLogo } from "components/icons/Icons";
import HeroList from './components/HeroList';

export default function Features() {

    const dashimage = useColorModeValue(dashlight, dashdark);
    const tradeimage = useColorModeValue(tradelight, tradedark);
    const stratimage = useColorModeValue(stratlight, stratdark);
    const journalimage = useColorModeValue(journallight, journaldark);
    const accimage = useColorModeValue(acclight, accdark);
    const mentorimage = useColorModeValue(mentorlight, mentordark);

    const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

    const textColor = useColorModeValue('white', 'white');
    let logoColor = useColorModeValue('white', 'white');

    return (
        <LandingOtherLayout parent='features'>

            <Flex direction={'column'} position={'relative'} mb='80px' bgGradient="linear(to-b, brand.600, brand.600)" w='100%' p='70px'>
                <Heading as="h1" size="lg" fontWeight="bold" color={textColor} p='20px' mt='10px' textAlign={"center"}>
                    Here's a sampling of the features in {<TJLogo h="25px" w="210px" mb={'10px'} color={textColor} />}
                </Heading>
                <Flex justify="center" mt='20px'>
                    <Text color={'gray.300'}>Sign up for FREE to explore in-depth</Text>
                </Flex>
            </Flex>
            <HeroList
                left={true}
                title="Interactive Dashboard"
                features={[
                    'Key performance analytics',
                    'Daily trades calendar and note taking',
                    'Flagged trade grouping and viewing',
                    'Filtered trading activity by period',
                    'Interactive charting'
                ]}
                image={dashimage}
                minH='30vh'
                mt={'40px'}
                mb={{ base: '30px', lg: '0px' }}
            />
            <HeroList
                left={isLargerThanMD ? false : true}
                title="Detailed trade recording"
                features={[
                    'Detailed trade entry, imaging and editing',
                    'Tag trades with strategies and/or mistakes',
                    'Flag trades for easy access and viewing',
                    'Trade signal and rulescore calculation',
                    'Note taking and templating'
                ]}
                image={tradeimage}
                minH='30vh'
                mt={'40px'}
                mb={{ base: '30px', lg: '0px' }}
            />
            <HeroList
                left={true}
                title="Account grouping"
                features={[
                    'Ideal for prop traders with multiple accounts',
                    'Ideal for traders using multiple brokers',
                    'Grouping of accounts for analysis',
                    'Filter according to accounts or whole groups',
                    'Edit accounts or groups on the fly'
                ]}
                image={accimage}
                minH='30vh'
                mt={'40px'}
                mb={{ base: '30px', lg: '0px' }}
            />

            <HeroList
                left={isLargerThanMD ? false : true}
                title="Strategy management"
                features={[
                    'Strategy and entry rule recordng',
                    'Update, add or remove strategies as needed',
                    'Strategy and mistake analytics',
                    'Tagged trades grouping and viewing',
                    'Interactive performance charting'
                ]}
                image={stratimage}
                minH='30vh'
                mt={'40px'}
                mb={{ base: '30px', md: '0px' }}
            />
            <HeroList
                left={true}
                title="Calendar journaling"
                features={[
                    'Detailed journaling for future reference',
                    'Interactive calendar management',
                    'Arrange entries with easy drag and drop',
                    'Categorise and filter entries',
                    'Flag important entries for easy access'
                ]}
                image={journalimage}
                minH='30vh'
                mt={'40px'}
                mb={{ base: '30px', lg: '0px' }}
            />
            <HeroList
                left={isLargerThanMD ? false : true}
                title="Mentoring"
                features={[
                    'Review and comment on student trades',
                    'Track student performance',
                    'Share strategies with students',
                    'Share calendar journal entries with students',
                    'Share trade examples with students',
                ]}
                image={mentorimage}
                minH='30vh'
                mt={'40px'}
                mb={{ base: '30px', md: '50px' }}
            />
            <Spacer />
        </LandingOtherLayout>
    );
}