import { useToast } from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./jornoquill.css"

const JornoQuill = (props: { content: string, updatecontent: any, placeHolder: string, allowimages: boolean }) => {

    const { content, updatecontent, placeHolder, allowimages } = props;

    const toast = useToast();
    const quillref = useRef<ReactQuill | null>(null);
    const [quillcontent, setquillcontent] = useState("");

    useEffect(() => {
        setquillcontent(content);
    }, [content])

    const imageHandler = async () => {
        // Create an input element of type 'file'
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        // When a file is selected
        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();
            if (file.size > 256000) {
                toast({ title: 'Not accepted ...', description: "Image maximum 256kb size", status: 'error', position: 'top', duration: 3000, isClosable: false });
            } else {
                // Read the selected file as a data URL
                reader.onload = () => {
                    const imageUrl = reader.result;
                    const quillEditor = quillref.current.getEditor();
                    const range = quillEditor.getSelection(true);
                    quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
                };
                reader.readAsDataURL(file);
            }
        };
    }

    //const myColors = ["white", "#8F9BBA", "#707EAE", "black", "#422AFB", "#422AFB", "#3965FF", "#01B574", "#FFB547", "#E31A1A"];
    const formats = ["font", "header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "link", "color", "image", "background", "align"];

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ align: ["right", "center", "justify"] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [(allowimages?"image":"")],
                    //["link","image"],
                    //[{ color: myColors }],
                    //[{ background: myColors }]
                ],
                handlers: {
                    image: imageHandler,
                }
            },
        }),
        []
    );

    const handleProcedureContentChange = (content: any) => {
        setquillcontent(content);
        updatecontent(content);
    };

    return (
        <>
            <ReactQuill
                ref={quillref}
                theme="snow"
                modules={modules}
                formats={formats}
                value={quillcontent}
                onChange={handleProcedureContentChange}
                placeholder={placeHolder}
         
                
            />
        </>
    );
}
export default JornoQuill;