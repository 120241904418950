import { Flex, Icon, IconButton } from "@chakra-ui/react";
import { MouseEvent } from "react";
import { MdEdit, MdClear, MdDone } from "react-icons/md";

export const EditCell = (props: { row: any, table: any }) => {
    const meta = props.table.options.meta;
    const validRow = meta?.validRows[props.row.id];
    const disableSubmit = validRow ? Object.values(validRow)?.some(item => !item) : false;


    const setEditedRows = (e: MouseEvent<HTMLButtonElement>) => {
        const elName = e.currentTarget.name;
        //alert(elName);
        meta?.setEditedRows((old: []) => ({
            ...old,
            [props.row.id]: !old[props.row.id],
        }));
        if (elName !== "edit") {
            e.currentTarget.name === "cancel" ? meta?.revertData(props.row.index) : meta?.updateRow(props.row.index);
        }
    };

    const removeRow = () => {
        meta?.removeRow(props.row.index);
    };

    return (
        <>
            <Flex justifyContent='start'>
                {meta?.editedRows[props.row.id] ? (
                    <>
                        {/*<IconButton
                            variant='outline'
                            colorScheme='gray'
                            size='sm'
                            aria-label='cancel'
                            name='cancel'
                            icon={<Icon as={MdMoreHoriz} w="16px" h="16px" />}
                            onClick={setEditedRows}
                            ms='5px' me='5px'
                        />*/}
                        <IconButton
                            variant='outline'
                            colorScheme='blue'
                            size='sm'
                            aria-label='done'
                            name='done'
                            icon={<Icon as={MdDone} w="16px" h="16px" />}
                            onClick={setEditedRows}
                            disabled={disableSubmit}
                            ms='5px' me='5px'
                        />
                    </>
                ) : (
                    <>
                        <IconButton
                            variant='outline'
                            colorScheme='green'
                            size='sm'
                            aria-label='edit'
                            name='edit'
                            icon={<Icon as={MdEdit} w="16px" h="16px" />}
                            onClick={setEditedRows}
                            ms='5px' me='5px'
                        />
                    </>
                )}
                <IconButton
                    variant='outline'
                    colorScheme='red'
                    size='sm'
                    aria-label='remove'
                    name='remove'
                    icon={<Icon as={MdClear} w="16px" h="16px" />}
                    onClick={removeRow}
                    ms='5px' me='5px'
                />
            </Flex>
        </>
    );
};
