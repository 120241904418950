
import { PostAPIupdates } from "views/trader/components/syncapi/SyncApi";
import { ProcessStats } from "views/trader/trades/variables/StatProcessor";
import { Strategy, Trade } from "views/trader/trades/variables/types";
import { db } from "./db";

export async function getTrade(tradeid: number) {
    return await db.trades.get(tradeid).then((tr) => {
        return tr;
    })
}
export async function getDefaultAccountID(groupid: number) {
    return await db.accounts.where({ groupid: groupid }).toArray().then((accs) => {
        let ac = accs.filter((a) => a.defaultacc);
        return ac[0].accountid;
    })
}
export async function getDefaultAccount(groupid: number) {
    return await db.accounts.where({ groupid: groupid }).toArray().then((accs) => {
        let a = accs.filter((ac) => ac.defaultacc);
        return a[0];
    });
}
export async function getAccount(accountid: number) {
    return await db.accounts.get(accountid).then((acc) => {
        return acc;
    })
}

export async function getDefaultGroupID(userid: number) {
    return await db.accountgroups.where({ userid: userid }).toArray().then((grps) => {
        let g = grps.filter((g) => g.defaultgrp);
        return g[0].groupid;
    })
}
export async function getDefaultGroup(userid: number) {
    return await db.accountgroups.where({ userid: userid }).toArray().then((grps) => {
        let g = grps.filter((gr) => gr.defaultgrp)
        return g[0];
    });
}
export async function getAccountGroup(groupid: number) {
    return await db.accountgroups.get(groupid).then((g) => {
        return g;
    });
}
export function setGroupAccountsSyncDue(groupid: number, sync: boolean) {
    return new Promise(async function (resolve) {
        await db.accounts.toCollection().modify(acc => {
            if (acc.groupid === groupid) {
                acc.sync_due = sync;
            }
        }).catch((e) => { })
        resolve('');
    });
}
export function setGroupsSyncDue(sync: boolean) {
    return new Promise(async function (resolve) {
        await db.accountgroups.toCollection().modify(grp => {
            grp.sync_due = sync;
        }).catch((e) => { })
        resolve('');
    });
}
export function setDefaultAccount(accountid: number, groupid: number, userid: number) {
    return new Promise(async function (resolve) {
        await db.accounts.toCollection().modify(acc => {
            if (acc.userid === userid && acc.groupid === groupid) {
                acc.defaultacc = (acc.accountid !== accountid ? false : true);
            }
        }).then(() => {
            resolve(accountid);
        }).catch(() => {
            resolve(0);
        })
    });
}
export function setDefaultGroup(groupid: number, userid: number) {
    return new Promise(async function (resolve) {
        await db.accountgroups.toCollection().modify(grp => {
            if (grp.userid === userid) {
                grp.defaultgrp = (grp.groupid !== groupid ? false : true);
            }
        }).then(() => {
            resolve(groupid);
        }).catch(() => {
            resolve(null);
        })
    });
}
export function updateStratsAndMistakes(stratupdates?: Strategy[], mistupdates?: any[]) {
    return new Promise(async (resolve, reject) => {
        if (stratupdates) {
            for (let su of stratupdates) {
                await db.trades.toCollection().modify(trade => {
                    trade.strategies.forEach((s) => {
                        if (s.id === su.id) {
                            if (s.name.trim() !== su.name.trim()) {
                                s.name = su.name;
                                //trade.sync_due = su.sync_due; -> udpate is done on server now
                            }
                        }
                    })
                }).catch((err) => { });
            }
        }
        if (mistupdates) {
            for (let mu of mistupdates) {
                await db.mistakes.get(mu.id).then(async (mis) => { // check if mistake sync_due
                    await db.trades.toCollection().modify(trade => {
                        const index = trade.mistakes.findIndex((m) => m.id === mu.id);
                        if (index > -1) {
                            let m: { name: string, id: number } = { name: mu.name, id: mu.id }
                            let mist = trade.mistakes.slice();
                            trade.mistakes = [...mist.slice(0, index), m, ...mist.slice(index + 1)];
                            //trade.sync_due = mis.sync_due; -> udpate is done on server now
                        }
                    }).catch((err) => { });
                })

            }
        }
        setTimeout(async () => {
            await PostAPIupdates();
            resolve('');
        }, 1000);
    });
}

/*export function reprocessStats(groupid: number) {
    return new Promise(async (resolve, reject) => {

        //let user = JSON.parse(localStorage.getItem('userProfile'));
        await db.users.get({ current_user: 1 }).then((u) => {
            getDefaultAccount(groupid).then(async (acc) => {
                if (acc) {
                    await db.trades.where({ groupid: groupid, accountid: acc.accountid }).toArray().then(async (trades) => {
                        await ProcessStats(trades, localStorage.getItem("period"), localStorage.getItem("currency"));
                    })
                }
                else {
                    getDefaultGroup(u.id).then(async (grp) => {
                        if (grp) {
                            await db.trades.where({ groupid: groupid }).toArray().then(async (trades) => {
                                await ProcessStats(trades, localStorage.getItem("period"), localStorage.getItem("currency"));
                            })
                        }
                    })
                }
            })
        });
        resolve('');
    })
}*/
export function saveTradesToDb(trades: Trade[], sync_due: boolean) {
    return new Promise(async (resolve, reject) => {
        for (let t of trades) {
            delete t.last_uuid;
            t.sync_due = sync_due;
            await db.trades.put(t, t.tradeid).then(async () => { }).catch(error => { reject('') });
        }
        resolve('');
    })
}
