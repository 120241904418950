import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Text, Button, useColorModeValue, Icon } from "@chakra-ui/react";
import HeaderLogo from "layouts/landing/ui/Logo";
import { IoMenuOutline } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import LandingMenu from "layouts/landing/navbar/LandingMenu";

const MenuItem = (props: { children: any, isLast?: any, to: string, color: string, hidden: boolean }) => {

  return (
    <Text
      mb={{ base: props.isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: props.isLast ? 0 : 8 }}
      display="block"
      hidden={props.hidden}
    >
      <Link to={props.to}>{props.children}</Link>
    </Text>
  );
};

const AuthHeader = (props: { parent: string }) => {

  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);
  const textColor = useColorModeValue('white', 'white');
  const menuColor = useColorModeValue('gray.400', 'white');

  return (
    <Flex as="nav" align="center" justify={{ base: 'space-between', lg: 'space-evenly' }} wrap="wrap" w="100%" p={8} top="0" color={textColor} bgGradient="linear(to-b, brand.400, brand.600)" position="sticky" zIndex="sticky">
      <Flex align="center" position={'sticky'}>
        <HeaderLogo />
      </Flex>
      <Box display={{ base: "block", md: "none" }} onClick={toggleMenu} position={'sticky'}>
        {show ? <Icon as={MdClose} color={menuColor} boxSize={8} _hover={{ cursor: 'pointer' }} /> : <Icon as={IoMenuOutline} color={menuColor} boxSize={8} _hover={{ cursor: 'pointer' }} />}
      </Box>
      <Box display={{ base: show ? "block" : "none", md: "block" }} flexBasis={{ base: "100%", md: "auto" }} position={'sticky'}>
        <LandingMenu parent={props.parent}/>
        {/*<Flex align="center" justify={["center", "space-between", "flex-end", "flex-end"]} direction={["column", "row", "row", "row"]} pt={[4, 4, 0, 0]} mt={{ base: "30px", md: "0px" }}>
          <MenuItem to="/home" color={textColor} hidden={props.parent === 'home'}>Home </MenuItem>
          <MenuItem to="/home/features" color={textColor} hidden={props.parent === 'features'}>Features </MenuItem>
          <MenuItem to="/home/pricing" color={textColor} hidden={props.parent === 'pricing'}>Pricing </MenuItem>
          <MenuItem to="/auth/signin" color={textColor} isLast hidden={props.parent === 'signin'}>
            <Button variant={'outline'} colorScheme={'brand'} size="sm" rounded="md" color={'white'} me='10px'>
              Login
            </Button>
          </MenuItem>
          <MenuItem to="/auth/signup" color={textColor} hidden={props.parent === 'signup'}>
            <Button variant={'solid'} bg={'navy.900'} color={'white'} size="sm" rounded="md" borderWidth={'1px'} borderColor={'white'}>
              FREE Sign Up
            </Button>
          </MenuItem>
        </Flex>*/}
      </Box>
    </Flex>
  );
};

export default AuthHeader;