/* eslint-disable */

import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Button,
} from '@chakra-ui/react';
import * as React from 'react';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { FaCheck } from "react-icons/fa";
import { IoInfiniteOutline } from "react-icons/io5";

// Custom components
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

type RowObj = {
  feature: string;
  free: boolean | string;
  novice: boolean | string;
  pro: boolean | string;
  mentor: boolean | string;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function FeaturesTable(props: { tableData: any }) {

  const { tableData } = props;
  const navigate = useNavigate();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  let defaultData = tableData;

  function getCellValue(value: boolean | string) {
    if (typeof value === 'boolean') {
      if (value === true) {
        return <Icon
          as={FaCheck}
          boxSize={5}
          color={'green'}
        />
      }
      else {
        return <Icon
          as={MdClose}
          boxSize={7}
          color={'red.500'}
        />
      }
    }
    else {
      if (value === 'Unlimited') { //IoInfiniteSharp
        return <Icon
          as={IoInfiniteOutline}
          boxSize={5}
        />
      }
      else {
        return <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {value}
          </Text>
        </Flex>
      }
    }
  }

  const columns = [
    columnHelper.accessor('feature', {
      id: 'feature',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        ></Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('free', {
      id: 'free',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          FREE
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          {getCellValue(info.getValue())}
        </Flex>
      ),
    }),
    columnHelper.accessor('novice', {
      id: 'novice',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          NOVICE
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          {getCellValue(info.getValue())}
        </Flex>
      ),
    }),
    columnHelper.accessor('pro', {
      id: 'pro',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          PRO
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          {getCellValue(info.getValue())}
        </Flex>
      ),
    }),
    columnHelper.accessor('mentor', {
      id: 'mentor',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          MENTOR
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          {getCellValue(info.getValue())}
        </Flex>
      ),
    }),


  ];
  const [data, setData] = React.useState(() => [...defaultData]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0, //custom initial page index
        pageSize: 100, //custom default page size
      },
    },

  });
  return (

    <Box
      flexDirection="column"
      w={{ sm: '375px', md: '780px' }}
      p="30px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      bg={null}
      borderWidth={'1px'}
      borderRadius={'20px'}
    >
      <Flex px="25px" mb="8px" mt="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          mb="4px"
          fontWeight="700"
          lineHeight="100%"
        >
          Features
        </Text>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 100)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
        <Flex justify={'center'}>
          
          <Button variant={'outline'} colorScheme={'cyan'} size="sm" rounded="md" mt='15px' onClick={()=>navigate('/auth/signup')}>
            Sign Up FREE to explore!
          </Button>
        </Flex>

      </Box>
    </Box>

  );
}
