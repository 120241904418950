
import { ProcessExecutions } from "views/trader/trades/variables/ExecutionsProcessor";
import { AccountExecutions, Trade } from "views/trader/trades/variables/types";
import { getNinjaTrades } from "./Ninjatrader";
import { getTradingviewTrades } from "./Tradingview";
import { getTradovateTrades } from "./Tradovate";
import { getCSVImportTrades } from "./ImportCsv";


export async function BrokerInput(platform: string, file: File, brokerid?: string): Promise<{ trades: Trade[], unfound: AccountExecutions[] }> {

    //let retarr: Trade[] = [];

    let retobj: { trades: Trade[], unfound: AccountExecutions[] } = { trades: [], unfound: [] };

    if (platform === "ninja") {
        await getNinjaTrades(file).then(async (a_execs: AccountExecutions[][]) => {
            //alert(JSON.stringify(a_execs[1]));
            // a_execs[0] are the found tickers
            // a_execs[1] are the UNFOUND tickers
            retobj.unfound = a_execs[1];

            for (let a of a_execs[0]) {
                await ProcessExecutions(a).then((a_tr: Trade[]) => {
                    for (let tr of a_tr) {
                        tr.brokerid = a.account; // add brokerid temporarily
                        //retarr.push(tr);
                        retobj.trades.push(tr);
                    }
                }).catch((err) => { })
            }
        }).catch((err) => { })
    }
    if (platform === "tvate") {
        await getTradovateTrades(file).then(async (a_execs: AccountExecutions[][]) => {
            // a_execs[0] are the found tickers
            // a_execs[1] are the UNFOUND tickers
            retobj.unfound = a_execs[1];

            for (let a of a_execs[0]) {
                await ProcessExecutions(a).then((a_tr: Trade[]) => {
                    for (let tr of a_tr) {
                        tr.brokerid = a.account; // add brokerid temporarily
                        //retarr.push(tr);
                        retobj.trades.push(tr);
                    }
                }).catch((err) => { })
            }
        }).catch((err) => { })
    }
    if (platform === "tview") {
        await getTradingviewTrades(file).then(async (a_execs: AccountExecutions[][]) => {
            // a_execs[0] are the found tickers
            // a_execs[1] are the UNFOUND tickers
            retobj.unfound = a_execs[1];

            for (let a of a_execs[0]) {
                await ProcessExecutions(a).then((a_tr: Trade[]) => {
                    for (let tr of a_tr) {
                        tr.brokerid = a.account; // add brokerid temporarily
                        //retarr.push(tr);
                        retobj.trades.push(tr);
                    }
                }).catch((err) => { })
            }
        }).catch((err) => { })
    }

    if (platform === "paper") {
        await getCSVImportTrades(file).then(async (a_execs: AccountExecutions[][]) => {
            // a_execs[0] are the found tickers
            // a_execs[1] are the UNFOUND tickers
            retobj.unfound = a_execs[1];

            for (let a of a_execs[0]) {
                await ProcessExecutions(a).then((a_tr: Trade[]) => {
                    for (let tr of a_tr) {
                        tr.brokerid = brokerid; // add brokerid temporarily
                        //retarr.push(tr);
                        retobj.trades.push(tr);
                    }
                }).catch((err) => { })
            }
        }).catch((err) => { })
    }

    return retobj;
}



