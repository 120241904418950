import { Box, Flex, FormLabel, ScaleFade, useColorModeValue, Text } from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react'
import { StatIntraday, StatMonthly, StatWeekday } from 'views/trader/trades/variables/types';
import dayjs from 'dayjs';
import { currencyformat } from 'views/trader/trades/variables/FormatData';

const MonthlyChart = (props: { stats: StatMonthly[], currency: string, ticker: string, hidden:boolean }) => {

  const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bdColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    updateVariables();
  }, [props.stats, props.ticker, textColor])

  const updateVariables = () => {
    //setnochartdata(!props.stats.data.length);
    let data = [];
    let axis = [];

    for (let d of props.stats) {

      let nett = 0;
      
      if (props.ticker === "") {
        for (let t of d.ticker) {
          nett += t.nett;
        }
      }
      else {
        for (let t of d.ticker) {
          if (t.symbol === props.ticker) {
            nett += t.nett;
          }
        }
      }

      data.push(nett.toFixed(2))
      axis.push(dayjs().month(d.month).format("MMM"));
    }

    options.xaxis.categories = axis;

    setVariables({ options: options, series: [{ data: data }] });
  }

  const options: ApexOptions = {
    chart: {
      toolbar: { show: false }
    },
    annotations: {
      xaxis: [{
        x: 0,
        strokeDashArray: 4,
        borderColor: '#848287',
        fillColor: '#848287',
        opacity: 0.3,
        offsetX: 0,
        offsetY: 0,

      }]
    },
    plotOptions: {
      bar: {
        barHeight: '80%',
        distributed: false,
        horizontal: true,
        borderRadius: 8,
        dataLabels: {
          position: 'bottom'
        },
        colors: { ranges: [{ from: 0, to: 1000000, color: '#01B574' }, { from: -1000000, to: 0, color: '#EE5D50' }] }
      }
    },
    colors: ['#dc748b', '#d8637c', '#d4526e', '#be4963'],
    grid: { show: false },
    legend: { show: false },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.15,
        opacityFrom: 0.9,
        opacityTo: 1
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          colors: ['#848287'],
          fontSize: '12px',
          fontFamily: 'DM Sans',
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      }
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: ['#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287'],
          fontSize: '12px',
          fontWeight: 400,
        },
      }
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: true
      },
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          return currencyformat(value, props.currency)
        },
        title: {
          formatter: () => "Nett: ",
        },
      }
    }
  }
  const [variables, setVariables] = useState({ options: options, series: [] });


  return (
    <>
<Box minH={'550px'}>
<Flex hidden={!props.hidden} justify={'center'} align={'center'} >
        <ScaleFade initialScale={0.5} in={true} transition={{ exit: { delay: 0.5 }, enter: { duration: 1 } }}>
          <Box p='10px' color={textColorSecondary} >
            <Text color={textColorSecondary} fontSize='sm' mt='20px' mb='20px' ms='20px'>No data to display</Text>
          </Box>
        </ScaleFade>
      </Flex>
      <Chart
        type='bar'
        hidden={props.hidden}
        options={variables.options}
        width="100%"
        height="100%"
        series={variables.series}
      />
</Box>
      

    </>
  )
}

export default MonthlyChart