import dayjs from "dayjs";
import { dayModalChartData, Trade } from "views/trader/trades/variables/types";

export async function DayChartValues(trades: Trade[], currency: string): Promise<any> {

    return new Promise(async (resolve, reject) => {

        let axis = [];
        let data = [];

        for (let tr of trades) {

            let ex = (tr.currency !== currency ? tr.exchange : 1);
            let nett = (tr.nett / ex);

            axis.push(dayjs(tr.datetime).format('HH:mm'));
            data.push(nett);

        }

        let val: dayModalChartData = {
            axis: axis,
            data: data
        }

        resolve(val);
    });
}