import { useRef, useState, useEffect } from 'react';

import {
    Flex,
    Icon,
    useColorModeValue,
    IconButton,
    Show,
    Center
} from '@chakra-ui/react';

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/pagination';


// Custom components
import StatisticBox from 'views/trader/components/display/AccountStatistics';
import IconBox from 'components/icons/IconBox';

// Assets
import { MdArrowUpward, MdOutlineBarChart, MdInsights, MdPlayArrow, MdPause, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdArrowDownward, MdHorizontalRule } from 'react-icons/md';
import { FaPercent } from "react-icons/fa6";
import { RiDashboard3Line } from "react-icons/ri";
import { MinimalStatistics } from '../../trades/variables/types';
import { currencyformat } from '../../trades/variables/FormatData';


const MinimalStatBar = (props: { loading: boolean, stats: MinimalStatistics }) => {

    const [paused, setPaused] = useState(false);
    //const [stats, setStats] = useState<Statistics[]>([newStats()]);

    const textColor = useColorModeValue('navy.700', 'white');
    const iconBg = useColorModeValue('secondaryGray.300', 'navy.700');
    const iconColor = useColorModeValue('brand.500', 'white');

    const autoplay = { delay: 3000, disableOnInteraction: false }
    const statSwiper = useRef(null);

    const [pfactorcolor, setPFactorColor] = useState('');
    const [nettcolor, setNettcolor] = useState('');
    const [avgcolor, setAvgcolor] = useState('');
    const [plcolor, setPlcolor] = useState('');
    const [wlcolor, setWlcolor] = useState('');
    const [rvaluecolor, setRvaluecolor] = useState('');

    const updatePause = (pause: boolean) => {
        setPaused(pause);
        if (pause) {
            statSwiper.current.swiper.autoplay.stop();
            localStorage.setItem('statpause', JSON.stringify(true));
        }
        else {
            statSwiper.current.swiper.autoplay.start();
            localStorage.setItem('statpause', JSON.stringify(false));
        }
    }
    const nextSlide = () => {
        statSwiper.current.swiper.slideNext(500, false);
    };
    const prevSlide = () => {
        statSwiper.current.swiper.slidePrev(500, false);
    };


    useEffect(() => {
        if (localStorage.getItem('statpause')) {
            updatePause(JSON.parse(localStorage.getItem('statpause')));
        }
        setPFactorColor((props.stats.profit_factor > 1 ? 'green.500' : (props.stats.profit_factor > 0.5 ? 'orange.500' : 'red.500')));//: 'secondaryGray.600')
        setNettcolor(props.stats.nett_gainloss > 0 ? 'green.500' : 'red.500');
        setAvgcolor(props.stats.avg_pl > 0 ? 'green.500' : 'red.500');
        setPlcolor(props.stats.pl_ratio >= 1 ? 'green.500' : (props.stats.pl_ratio >= 0.5 ? 'orange.500' : 'red.500'));
        setWlcolor(props.stats.wl_ratio >= 1 ? 'green.500' : (props.stats.wl_ratio >= 0.5 ? 'orange.500' : 'red.500'));
        setRvaluecolor(props.stats.avg_rvalue >= 1 ? 'green.500' : (props.stats.avg_rvalue > 0 ? 'orange.500' : 'red.500'));
    }, [props.stats]);

    return (
        <>
            <Flex direction='row' >
                <Show breakpoint='(max-width: 2500px)'>
                    <Flex>
                        <Center>
                            <IconButton
                                variant='unstyled'
                                color='gray.300'
                                isRound={true}
                                size='sm'
                                colorScheme='blue'
                                aria-label='lastslide'
                                icon={<Icon as={MdOutlineKeyboardArrowLeft} w="30px" h="30px" />}
                                onClick={() => prevSlide()}
                            />
                        </Center>
                    </Flex>
                </Show>

                <Swiper ref={statSwiper} spaceBetween={20} slidesPerView={1} pagination={{ dynamicBullets: true, }} autoplay={autoplay} modules={[Autoplay, Pagination]}
                    breakpoints={{
                        640: { slidesPerView: 1 }, 680: { slidesPerView: 2 }, 1000: { slidesPerView: 3 }, 1200: { slidesPerView: 4 }, 1500: { slidesPerView: 5 }, 2000: { slidesPerView: 6 },
                    }}
                >
                    <SwiperSlide>
                        <StatisticBox title={'Trades total'} value={props.stats.trade_count.toFixed()} loading={props.loading} me='20px'
                            detail={
                                <></>
                            }
                            illustration={<IconBox w="50px" h="50px" bg={iconBg}
                                icon={
                                    <Icon color={iconColor} as={MdInsights} w="28px" h="28px" />
                                }
                            />}
                        />
                    </SwiperSlide>

                    <SwiperSlide>
                        <StatisticBox title={'Nett gain/loss'} value={currencyformat(props.stats.nett_gainloss, props.stats.currency)} statcolor={nettcolor} loading={props.loading} me='20px'
                            detail={
                                <></>
                            }
                            illustration={
                                <IconBox w="50px" h="50px" bg={iconBg}
                                    icon={
                                        <Icon color={nettcolor} as={props.stats.nett_gainloss !== 0 ? (props.stats.nett_gainloss > 0 ? MdArrowUpward : MdArrowDownward) : MdHorizontalRule} w="30px" h="30px" />
                                    }
                                />
                            }
                        />
                    </SwiperSlide>

                    <SwiperSlide>
                        <StatisticBox title={'Average P/L'} value={currencyformat(props.stats.avg_pl, props.stats.currency)} statcolor={avgcolor} loading={props.loading} me='20px'
                            detail={
                                <></>
                            }
                            illustration={
                                <IconBox w="50px" h="50px" bg={iconBg}
                                    icon={
                                        <Icon color={avgcolor} as={MdOutlineBarChart} w="30px" h="30px" />
                                    }
                                />
                            }
                        />
                    </SwiperSlide>

                    <SwiperSlide>
                        <StatisticBox title={'Average R-Value'} value={props.stats.avg_rvalue.toFixed(2)} loading={props.loading} statcolor={rvaluecolor} me='20px'
                            detail={
                                <></>
                            }
                            illustration={
                                <IconBox w="50px" h="50px" bg={iconBg}
                                    icon={
                                        <Icon color={avgcolor} as={RiDashboard3Line} w="30px" h="30px" />
                                    }
                                />
                            }
                        />
                    </SwiperSlide>

                    <SwiperSlide>
                        <StatisticBox title={'P/L Ratio'} value={props.stats.pl_ratio.toFixed(2)} statcolor={plcolor} loading={props.loading} me='20px'
                            detail={
                                <></>
                            }
                            illustration={<IconBox w="50px" h="50px" bg={iconBg}
                                icon={
                                    <Icon color={plcolor} as={FaPercent} w="25px" h="25px" />
                                }
                            />}
                        />
                    </SwiperSlide>

                    <SwiperSlide>
                        <StatisticBox title={'W/L Ratio'} value={props.stats.wl_ratio.toFixed(2)} statcolor={wlcolor} loading={props.loading} me='20px'
                            detail={
                                <></>
                            }
                            illustration={<IconBox w="50px" h="50px" bg={iconBg}
                                icon={
                                    <Icon color={wlcolor} as={FaPercent} w="25px" h="25px" />
                                }
                            />}
                        />
                    </SwiperSlide>

                    {/*<SwiperSlide>
                        <StatisticBox title={'Profit Factor'} value={props.stats.profit_factor.toFixed(2)} loading={props.loading} statcolor={pfactorcolor}
                            detail={
                                <></>
                            }
                            illustration={
                                <IconBox w="50px" h="50px" bg={iconBg}
                                    icon={
                                        <Icon color={pfactorcolor} as={RiDashboard3Line} w="30px" h="30px" />
                                    }
                                />
                            }
                        />
                    </SwiperSlide>*/}
                </Swiper>

                <Show breakpoint='(max-width: 2500px)'>
                    <Flex>
                        <Center>
                            <IconButton
                                variant='unstyled'
                                color='gray.300'
                                isRound={true}
                                size='sm'
                                colorScheme='blue'
                                aria-label='nextslide'
                                icon={<Icon as={MdOutlineKeyboardArrowRight} w="30px" h="30px" />}
                                onClick={() => nextSlide()}
                            />
                        </Center>
                    </Flex>
                </Show>
            </Flex>

            <Show breakpoint='(max-width: 2500px)'>
                <Flex justifyContent='center' mt='5px'>
                    <IconButton
                        variant='outline'
                        colorScheme='blue'
                        isRound={true}
                        size='sm'
                        aria-label='playslide'
                        icon={<Icon as={MdPlayArrow} w="20px" h="20px" />}
                        hidden={!paused}
                        onClick={() => updatePause(false)}
                    />
                    <IconButton
                        variant='outline'
                        colorScheme='red'
                        isRound={true}
                        size='sm'
                        aria-label='pauseslide'
                        icon={<Icon as={MdPause} w="20px" h="20px" />}
                        hidden={paused}
                        onClick={() => updatePause(true)}
                    />
                </Flex>
            </Show>

        </>
    )
}

export default MinimalStatBar


