import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Icon, Text, useDisclosure } from "@chakra-ui/react"
import dayjs from "dayjs"
import { useRef } from "react"
import { TbUserX } from "react-icons/tb"
import { Subscription } from "views/trader/trades/variables/types"
import PasswordEntry from "../inputs/PasswordEntry"
import { useData } from "services/data/DataProvider"

export const DeleteSubscriptionConfirm = (props: { subscription: Subscription, role: string, confirmdelete: any, loadingbtn: boolean }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const{dateformat} = useData();
    const expiry = dayjs(props.subscription.renews_at).format(dateformat);
    const pwRef = useRef(null);

    const confirmDelete = () => {
        onClose();
        props.confirmdelete();
    }
    const verifyCancel = () =>{
        pwRef.current.openInput();
    }


    return (
        <>
            <Button variant={'outline'} colorScheme={'red'} onClick={onOpen} mt='20px' isLoading={props.loadingbtn} loadingText="Processing">
                Cancel subscription <Icon as={TbUserX} ms='20px' boxSize={5}></Icon>
            </Button>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Cancel subscription
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text fontWeight={'700'} mb='20px' color={'red.500'}>
                                READ CAREFULLY:
                            </Text>
                            <Text fontWeight={'700'} mb='20px' color={'orange.500'}>
                                Your '{props.role.toUpperCase()}' subscription is paid up to '{expiry}' and will remain ACTIVE until then.
                            </Text>
                            <Text fontWeight={'700'} mb='20px' color={'orange.500'}>
                                You can resume the subscription before it expires if you wish to continue the current plan.
                            </Text>
                            <Text fontWeight={'700'} mb='20px' color={'orange.500'}>
                                Any mentorship data in your profile will be deleted when the subscription expires.
                            </Text>
                            <Text mb='20px'>
                                Once expired you will be placed back on a FREE subscription. You can renew at anytime.
                            </Text>
                            <Text>
                                You can also upgrade or downgrade instead.
                            </Text>
                            <Text fontWeight={'700'} mt='20px'>
                                Are you sure?
                            </Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button colorScheme='red' variant={'outline'} onClick={verifyCancel} mr={3}>
                                YES, cancel
                            </Button>
                            <Button ref={cancelRef} colorScheme='blue' onClick={onClose}>
                                NO, don't cancel
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
                <PasswordEntry ref={pwRef} ifVerifiedAction={confirmDelete}/>
            </AlertDialog>
        </>
    )
}