import { Trade } from "views/trader/trades/variables/types";
import api from "./api";

export async function updateTrade(trade: Trade): Promise<any> {
    return new Promise(async (resolve, reject) => {
        await api.post('/trade/updatetrade', trade)
            .then(async (res) => {
                resolve(res.data);
            }).catch(async (err) => {
                resolve(true); // ie sync_due = true;
            })
    })
}

export async function updatesubscription(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        await api.post("/subs/update", payload)
            .then(async (res) => {
                resolve(res.data);
            }).catch(async (err) => {
                resolve(false);
            });
    })
}
