import { Button, Flex } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";

export const FooterCell = (props: { table: any }) => {
    const meta = props.table.options.meta;

    return (
        <Flex>
            <Button size='sm' borderRadius='5px' mt='10px' mb='10px' rightIcon={<MdAdd />} colorScheme='blue' variant='outline' onClick={meta?.addRow}>
                ADD Execution
            </Button>
        </Flex>
        /*<div className="footer-buttons">
          {selectedRows.length > 0 ? (
            <button className="remove-button" onClick={removeRows}>
              Remove Selected x
            </button>
          ) : null}
          <button className="add-button" onClick={meta?.addRow}>
            Add New +
          </button>
        </div>*/
    );
};
