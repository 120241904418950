import { forwardRef, useImperativeHandle, useState } from 'react'
import { AccountExecutions, Instrument, Trade } from '../../variables/types'
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Text, useToast, Flex } from '@chakra-ui/react';
import TickerInput from '../fields/TickerInput';
import { ProcessExecutions } from '../../variables/ExecutionsProcessor';
import UserInstrumentModal from 'views/trader/components/UserInstrumentModal';

const TickerModal = forwardRef((props: { postTrades: any }, ref) => {

    const [data, setData] = useState<AccountExecutions[]>([]);
    const [tickers, setTickers] = useState<{ ticker: string, instrument: Instrument }[]>([]);
    const [processing, setProcessing] = useState(false);
    const toast = useToast();


    useImperativeHandle(ref, () => ({
        openModal(data: AccountExecutions[]) {
            setData(data);
            getTickers(data);
            onOpen();
        }
    }));

    const { isOpen, onOpen, onClose } = useDisclosure();

    const getTickers = (data: AccountExecutions[]) => {
        let t: { ticker: string, instrument: Instrument }[] = [];
        for (let a of data) {
            for (let ex of a.executions) {
                let tick = t.filter((ti) => ti.ticker === ex.ticker);
                if (!tick.length) {
                    t.push({ ticker: ex.ticker, instrument: null });
                }
            }
        }
        setTickers(t);
    }

    const updateExecutions = async (orig_ticker: string, instrument: Instrument) => {

        data.forEach((val) => {
            val.executions.forEach((ex) => {
                if (ex.ticker === orig_ticker) {
                    ex.ticker = instrument.ticker;
                    ex.instrument = instrument.category;
                    ex.currency = instrument.currency;
                }
            })
        })

        let utick = tickers.slice();
        let i = utick.findIndex(x => x.ticker === orig_ticker);
        if (i > -1) {
            utick[i].instrument = instrument;
        }
        setTickers(utick);
    }

    const processupdatedexecutions = async () => {

        let i = tickers.findIndex(t => t.instrument === null);

        if (i < 0) {
            let retarr: Trade[] = [];
            for (let a of data) {
                await ProcessExecutions(a).then((a_tr: Trade[]) => {
                    for (let tr of a_tr) {
                        tr.brokerid = a.account; // add brokerid temporarily
                        retarr.push(tr);
                    }
                }).catch((err) => { return [] })
            }
            onClose();
            props.postTrades(retarr);
        }
        else {
            toast({ title: 'Not completed ...', description: "Update all symbols or remove them", status: 'error', position: 'top', duration: 3000, isClosable: false })
        }
    }

    const removeticker = (ticker: string) => {

        let udata = data.slice();

        for (let d = 0; d < udata.length; d++) {
            for (let i = 0; i < udata[d].executions.length; i++) {
                if (udata[d].executions[i].ticker === ticker) {
                    udata[d].executions.splice(i, 1);
                    i--;
                }
            }
        }
        setData(udata);

        let utick = tickers.slice();
        let i = utick.findIndex(x => x.ticker === ticker);
        if (i > -1) {
            utick.splice(i, 1);
        }
        setTickers(utick);

        if (!utick.length) {
            onClose();
        }

    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', md: 'xl' }} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Some symbols not found ...</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex ms={{base:'0px', md:'-15px'}} mt={{ base: '20px', md: '0px' }}  mb='30px' direction={'row'} align={'center'}>
                        <UserInstrumentModal islarge={false} />
                        <Text ms='20px'> ... add your own instrument</Text>
                    </Flex>
                    <Text>Please select the correct symbols below</Text>
                    {tickers.map((tick, index) => (
                        <TickerInput key={tick.ticker} ticker={tick.ticker} instrument={tick.instrument} update={updateExecutions} remove={removeticker} />
                    ))}
                </ModalBody>

                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={onClose}>Cancel</Button>
                    <Button colorScheme='blue' onClick={processupdatedexecutions} isLoading={processing}>
                        Process
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
})

export default TickerModal