
import {
    useDisclosure,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Icon
} from '@chakra-ui/react'

import { useContext, useRef, useState } from "react";
import { MdOutlineLogout } from 'react-icons/md';
import AuthContext from 'services/auth/AuthContext';
import { useAuth } from 'services/auth/AuthProvider';

const SignOut = () => {

    const { signout } = useAuth();//useContext(AuthContext);

    const [loading, setLoading] = useState(false);

    const handleSignout = async () => {
        signout();
        /*setLoading(true);
        await signout(true).catch(async (err: any) => {
            await signout(false);
            setLoading(false);
            onClose();
        });*/
    };

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()

    return (
        <>
            <Button colorScheme='red' onClick={onOpen}>
            <Icon as={MdOutlineLogout} h='20px' w='20px' me='10px'></Icon>
                Sign Out
            </Button>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Sign Out
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button  onClick={onClose}>
                                Cancel
                            </Button>
                            <Button ref={cancelRef} colorScheme='red' onClick={handleSignout} ml={3} isLoading={loading} loadingText="Signing out">
                                Sign Out
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default SignOut