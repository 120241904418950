import { Flex, Button, Card, FormControl, FormLabel, Input, Text, Image, Icon, useColorModeValue, Box, ListItem, UnorderedList, Badge } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import JornoQuillReadOnly from 'views/trader/components/inputs/JornoQuillReadOnly';
import ImageDrawer from 'views/trader/trades/components/modals/ImageDrawer';
import { Strategy } from 'views/trader/trades/variables/types';

const StrategyViewMentor = (props: { strategy: Strategy }) => {

    const { strategy } = props;

    const textColor = useColorModeValue('gray.700', 'white');
    const imageColor = useColorModeValue('gray.300', 'gray.500');
    const bgcolor = useColorModeValue('white', 'navy.900');
    const bdColor = useColorModeValue('gray.200', 'gray.600');

    const [stratname, setstratname] = useState(".");
    const [stratdesc, setstratdesc] = useState("");
    const [rules, setRules] = useState([]);
    const [image, setImage] = useState("");

    const [edited, setEdited] = useState(false);
    const [processingstrat, setProcessingStrat] = useState(false);

    const imageRef = useRef(null);

    useEffect(() => {
        if (strategy) {
            setstratname(strategy.name);
            setstratdesc(strategy.description);
            setRules(strategy.rules);
            setImage(strategy.base64Image);
        }
    }, [strategy])

    const imageclick = () => {
        imageRef.current.openImage();
    }

    return (
        <>
            <Flex direction={'column'} w={'100%'}>

                <Flex direction={'column'} >
                    <FormControl mb={'20px'}>
                        <Flex justify={'space-between'}>
                            <FormLabel fontWeight='bold' fontSize='sm' >Name</FormLabel>
                            <Flex align={'center'} mt='-10px'>
                                <Badge size={'sm'} variant='outline' borderRadius={'5px'} colorScheme='red' fontSize={'sm'}>
                                    Mentor Strategy
                                </Badge>
                            </Flex>
                        </Flex>
                        <Flex>
                            <Input placeholder='Enter name ...' color={textColor} value={stratname} isReadOnly={true} />
                        </Flex>

                    </FormControl>
                    <FormControl>
                        <Flex justify={'space-between'}>
                            <FormLabel fontWeight='bold' fontSize='sm' mt='10px'>Description</FormLabel>
                        </Flex>
                        <JornoQuillReadOnly content={stratdesc} placeHolder={'No mentor description provided ...'} />
                    </FormControl>
                </Flex>

                <Card bg='transparent' borderWidth={'1px'} p='30px' mt={'30px'} borderRadius={'20px'} borderColor={bdColor}>
                    <Flex direction={'column'} >
                        <Flex direction={'row'} justify={'start'} align={'center'}>
                            <FormLabel fontWeight='bold' fontSize='sm' mt='-55px' bg={bgcolor} px='10px'>Rules</FormLabel>
                        </Flex>
                        <Flex direction={'column'} >
                            <UnorderedList>
                                {rules.map((srule) => (
                                    <ListItem>{srule.text}</ListItem>
                                ))}
                            </UnorderedList>
                        </Flex>
                    </Flex>
                </Card>

                <Box mt={'20px'} mb={'50px'} bg={'transparent'} >
                    <Flex direction={'row'} justify={'space-between'}>
                        <FormLabel fontWeight='bold' fontSize='sm'>Example image</FormLabel>
                    </Flex>
                    {image.length ? (
                        <>
                            <Flex w="100%" h="100%" justify="center" >
                                <Flex onClick={() => imageclick()}>
                                    <Image src={image} w="100%" maxH={'700px'} cursor="pointer" />
                                </Flex>
                            </Flex>
                            <ImageDrawer ref={imageRef} imageurl={image}></ImageDrawer>
                        </>
                    ) : (
                        <Flex w="100%" h="100%" direction={'column'} justify="center" align={'center'}>
                            <Icon as={MdOutlineImageNotSupported} w='80px' h='80px' mb='20px' color={imageColor} />
                            <Text
                                mb='12px'
                                fontSize='md'
                                fontWeight='700'
                                color={imageColor}>
                                No image saved by mentor
                            </Text>
                        </Flex>
                    )}
                </Box>

                <Flex justify={'end'} mt='20px'>
                    <Button colorScheme="red" variant={'outline'} isLoading={processingstrat} loadingText="Saving ..." hidden={!edited} borderRadius={'5px'} minW='140px' >
                        {strategy && strategy.id > 0 ? 'Save changes' : 'Save'}
                    </Button>
                </Flex>
            </Flex>
        </>
    )
}

export default StrategyViewMentor