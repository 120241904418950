import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Icon, useDisclosure, useToast, Text, OrderedList, ListItem } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdCheck } from 'react-icons/md';
import api from 'services/api';
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';

const AcceptMentorConfirm = (props: { mentorshipid: number, label: string, owner: string }) => {

    const {muuid} = useAuth();
    const { mentorshipid, label } = props;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const goaccept = async () => {

        setLoading(true);

        // db.mentors.get(mentorshipid).then(async (mentor) => {
        //mentor.status = "active"
        //mentor.last_uuid = localStorage.getItem("jornomid");
        //delete mentor.sync_due;

        await api.post('/mentor/update', { id: mentorshipid, status: 'active', last_uuid: muuid }).then(async (res) => {
            if (res.data === true) {
                setLoading(false);
                onClose();

                switch (props.owner) {
                    case 'mentor': //ie mentor accepting request
                        await db.mentees.toCollection().modify(m => {
                            if (m.id === mentorshipid) {
                                m.status = 'active';
                            }
                        }).catch(() => { })
                        toast({ title: 'Mentorship request accepted ...', description: '', status: 'success', position: 'top', duration: 2000, isClosable: false })
                        break;
                    case 'mentee': // mentee accepting invite
                        await db.mentors.toCollection().modify(m => {
                            if (m.id === mentorshipid) {
                                m.status = 'active';
                            }
                        }).catch(() => { })
                        toast({ title: 'Mentorship invite accepted ...', description: '', status: 'success', position: 'top', duration: 2000, isClosable: false })
                        break;
                }
            }
            else {
                toast({ title: 'Unable to accept ...', description: 'Please try again in a few minutes ...', status: 'error', position: 'top', duration: 3000, isClosable: false })
                setLoading(false);
                onClose();
            }
        }).catch((e) => {
            toast({ title: 'Unable to accept ...', description: 'Please try again in a few minutes ...', status: 'error', position: 'top', duration: 3000, isClosable: false })
            setLoading(false);
            onClose();

        })
    }

    return (
        <>
            <Button colorScheme='blue' onClick={onOpen} size='sm' borderRadius={'5px'} me='10px'>
                {label} <Icon as={MdCheck} size={5} ms='5px'></Icon>
            </Button>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Accept Mentorship {props.owner === 'mentee' ?'invite':'request'}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            {props.owner === 'mentee' ? (
                                <>
                                    <Text mb='20px'>Your mentor will be able to do the following:</Text>
                                    <OrderedList mb='20px'>
                                        <ListItem>View and comment on your trades</ListItem>
                                        <ListItem>View your trading stats</ListItem>
                                        <ListItem>Send you messages via TradeJorno</ListItem>
                                    </OrderedList>
                                    <Text fontWeight={'700'} mb='20px'>Accept invite?</Text>
                                </>
                            ) : (
                                <>
                                    <Text mb='20px'>You will be able to do the following:</Text>
                                    <OrderedList mb='20px'>
                                        <ListItem>View and comment on the student's trades</ListItem>
                                        <ListItem>Share trades and strategies with the trader</ListItem>
                                        <ListItem>Share journal entries with the trader</ListItem>
                                        <ListItem>Send messages via TradeJorno</ListItem>
                                    </OrderedList>
                                    <Text fontWeight={'700'} mb='20px'>Accept request?</Text>
                                </>
                            )}
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='blue' onClick={goaccept} ml={3} isLoading={loading} loadingText='Accepting'>
                                Accept
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default AcceptMentorConfirm

