type RowObj = {
    feature: string;
    free: boolean | string;
    novice: boolean | string;
    pro: boolean | string;
    mentor: boolean | string;
};

const FeaturesTableData: RowObj[] = [
    {
        feature: "Max strategies",
        free: "Unlimited",
        novice: "Unlimited",
        pro: "Unlimited",
        mentor: "Unlimited"
    },
    {
        feature: "Max mistakes",
        free: "Unlimited",
        novice: "Unlimited",
        pro: "Unlimited",
        mentor: "Unlimited"
    },
    {
        feature: "Max weekly trades",
        free: "25",
        novice: "250",
        pro: "1500",
        mentor: "Unlimited"
    },
    {
        feature: "Trading accounts",
        free: "1",
        novice: "5",
        pro: "20",
        mentor: "Unlimited"
    },
    {
        feature: "Trading groups",
        free: "1",
        novice: "2",
        pro: "5",
        mentor: "Unlimited"
    },
    {
        feature: "Weekly journals",
        free: "1",
        novice: "2",
        pro: "7",
        mentor: "Unlimited"
    },
    {
        feature: "Daily note taking",
        free: true,
        novice: true,
        pro: true,
        mentor: true
    },
    {
        feature: "Trade rule scores",
        free: true,
        novice: true,
        pro: true,
        mentor: true
    },
    {
        feature: "Multicurrency",
        free: true,
        novice: true,
        pro: true,
        mentor: true
    },
    {
        feature: "Performance analytics",
        free: true,
        novice: true,
        pro: true,
        mentor: true
    },
    {
        feature: "Interactive charting",
        free: true,
        novice: true,
        pro: true,
        mentor: true
    },
    {
        feature: "Statement import",
        free: true,
        novice: true,
        pro: true,
        mentor: true
    },
    {
        feature: "Manual import",
        free: true,
        novice: true,
        pro: true,
        mentor: true
    },
    {
        feature: "Mentoring",
        free: false,
        novice: true,
        pro: true,
        mentor: true
    },
    {
        feature: "Mentors allowed",
        free: false,
        novice: "1",
        pro: "10",
        mentor: false
    },

];

export default FeaturesTableData;
