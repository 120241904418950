import dayjs from 'dayjs';
import { db } from 'services/db';
import { AccountExecutions, Instrument, newExecution } from 'views/trader/trades/variables/types';
import Papa from 'papaparse';
import uuid from 'react-uuid'

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { formatPrice } from 'views/trader/trades/variables/FormatData';

export function getTradingviewTrades(f: File): Promise<AccountExecutions[][]> {

    dayjs.extend(utc)
    dayjs.extend(timezone);

    return new Promise(async (resolve, reject) => {

        try {

            let instruments = await db.instruments.toArray();

            const accountarr: AccountExecutions[][] = [[], []];

            Papa.parse(f, {
                header: true,
                download: true,
                skipEmptyLines: true,
                delimiter: ",",
                complete: async (result: any) => {
                    try {
                        let acc = uuid();
                        let af: AccountExecutions = { account: acc, executions: [] };
                        let anf: AccountExecutions = { account: acc, executions: [] };

                        for (let e of result.data) {

                            const ex = newExecution();



                            let p = e.Symbol;

                            let parr = p.split(':');
                            let inst = instruments.filter((i: Instrument) => parr[1].startsWith(i.ticker));

                            if (inst.length) {
                                let contract = parr[1].substring(inst[0].ticker.length, parr[1].length);

                                let d = new Date(e['Closing Time']);
                                let djs = dayjs(d).tz(inst[0].timezone);
                                ex.datetime = djs.format('YYYY-MM-DD HH:mm:ss');
                                ex.ticker = inst[0].ticker
                                ex.instrument = inst[0].category;
                                ex.currency = inst[0].currency;
                                ex.side = e.Side.toLowerCase().trim();
                                ex.size = Number(e.Qty);
                                ex.price = await formatPrice(inst[0].ticker, e.Price, "'");
                                ex.fee = 0;
                                ex.contract = contract.toString().trim();
                                ex.orderid = e['Order ID'].toString().trim();

                                af.executions.push(ex);
                            }
                            else {
                                let d = new Date(e['Closing Time']);
                                let djs = dayjs(d).tz("America/New_York");
                                ex.datetime = djs.format('YYYY-MM-DD HH:mm:ss');
                                ex.ticker = parr[1]
                                ex.instrument = '';
                                ex.currency = 'USD';
                                ex.side = e.Side.toLowerCase().trim();
                                ex.size = Number(e.Qty);
                                ex.price = Number(e.Price);
                                ex.fee = 0;
                                ex.contract = ''
                                ex.orderid = e['Order ID'].toString().trim();

                                anf.executions.push(ex);
                            }
                        }

                        accountarr[0].push(af);

                        if (anf.executions.length) {
                            accountarr[1].push(anf);
                        }

                        resolve(accountarr);
                    }
                    catch (err) {
                        reject(err);
                    }
                }
            })
        }
        catch (err) {
            reject(err);
        }
    });
}


