import { Flex, Textarea, Text, InputGroup, InputLeftAddon, Select, InputRightAddon, Button, Icon, useToast, Badge, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { TbSend } from 'react-icons/tb'
import api from 'services/api'
import { useAuth } from 'services/auth/AuthProvider'
import { db } from 'services/db'
import TextMaxLength from 'views/trader/trades/components/fields/TextMaxLength'
import useAutosizeTextArea from 'views/trader/trades/components/fields/useAutoSizeTextArea'
import { Mentee, MentorMessage } from 'views/trader/trades/variables/types'
import MentorMessageBlock from './MentorMessageBlock'
import dayjs from 'dayjs'
import { useData } from 'services/data/DataProvider'

const MentorMessages = () => { // for use for mentor messages


  const { user, muuid } = useAuth();
  const { dateformat, mentormessages, mentees } = useData();

  const [newmessage, setnewmessage] = useState('');
  const [pvtmessage, setpvtmessage] = useState('');
  const [grpmessage, setgrpmessage] = useState('');
  const [activementees, setActiveMentees] = useState([]);
  const [sending, setSending] = useState(false);
  const [selectedmentorship, setselectedmentorship] = useState(0);
  const [messages, setMessages] = useState<MentorMessage[]>([]);
  const toast = useToast();
  const [pvtmessages, setPvtMessages] = useState<MentorMessage[]>([]);
  const [pvtnewcount, setpvtnewcount] = useState(0);
  const [grpmessages, setGrpMessages] = useState<MentorMessage[]>([]);
  const [grpnewcount, setgrpnewcount] = useState(0);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(textAreaRef.current, newmessage);

  const [isGroup, setIsGroup] = useState(true);

  const pvttextAreaRef = useRef<HTMLTextAreaElement>(null);
  const grptextAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(pvttextAreaRef.current, pvtmessage);
  useAutosizeTextArea(grptextAreaRef.current, grpmessage);

  useEffect(() => {
    let me = mentees.filter((m: Mentee) => m.status === 'active');
    setActiveMentees(me);
    setMessages(mentormessages);
  }, [mentormessages, mentees])

  useEffect(() => {
    displaymessages(selectedmentorship, messages);
  }, [messages])


  const setnewmessagetxt = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setnewmessage(e.target.value);
  }

  const setpvtmessagetxt = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setpvtmessage(e.target.value);
  }
  const setgrpmessagetxt = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setgrpmessage(e.target.value);
  }

  const sendpvtmessage = () => {
    if (!pvtmessage.trim().length) {
      toast({ title: 'Enter message text...', description: '', status: 'info', position: 'top', duration: 2000, isClosable: false })
    }
    else if (selectedmentorship < 1) {
      toast({ title: 'Select a recipient ...', description: '', status: 'info', position: 'top', duration: 2000, isClosable: false })
    }
    else {
      //alert("PVT" + pvtmessage);
      sendmessage(pvtmessage);
    }
  }

  const sendgrpmessage = () => {
    if (!grpmessage.trim().length) {
      toast({ title: 'Enter message text...', description: '', status: 'info', position: 'top', duration: 2000, isClosable: false })
    }
    else {
      //alert("GRP: " + grpmessage);
      sendmessage(grpmessage);
    }

  }

  const changeRecipient = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== '') {
      setselectedmentorship(Number(e.target.value));
      displaymessages(Number(e.target.value), messages);
    }
    else {
      setPvtMessages([]);
    }

  }

  const displaymessages = (mentorshipid: number, messages: MentorMessage[]) => {
    if (mentorshipid > 0) {
      let msa = messages.filter((m) => m.mentorshipid === mentorshipid);
      setPvtMessages(msa);
      //setSelectedMessages(msa);
    }
    else {
      let msa = messages.filter((m) => m.mentorshipid === 0);
      setGrpMessages(msa);
      //setSelectedMessages(msa);
    }
  }

  const sendmessage = async (message: string) => {
    //if (!newmessage.trim().length) {
    //  toast({ title: 'Enter message text...', description: '', status: 'info', position: 'top', duration: 2000, isClosable: false })
    // }
    // else {
    setSending(true);

    await api.post('/mentor/postmessage', { mentorshipid: selectedmentorship, content: message.trim(), last_uuid: muuid }).then(async (res) => {
      if (res.data !== false) {
        let mess: MentorMessage = res.data;

        mess.seen = false;

        await db.mentormessages.put(mess, mess.id).catch((m) => { })
        setnewmessage('');
        toast({ title: 'Message sent ...', description: '', status: 'info', position: 'top', duration: 2000, isClosable: false })
      }
      else {
        toast({ title: 'Error sending message ...', description: 'Please check you internet connection or try again in a few minutes', status: 'error', position: 'top', duration: 3000, isClosable: false })
      }
    }).catch((e) => {
      toast({ title: 'Error sending message ...', description: 'Please check you internet connection or try again in a few minutes', status: 'error', position: 'top', duration: 3000, isClosable: false })
    });

    setSending(false);
    //}
  }

  const setMessageType = (mtype: string) => {
    switch (mtype) {
      case 'grp': setIsGroup(true); setpvtmessage(''); setselectedmentorship(0); setPvtMessages([]); displaymessages(0, messages); break;
      case 'pvt': setIsGroup(false); setgrpmessage(''); break;
    }
  }


  return (
    <>

      <Tabs variant='solid-rounded' isFitted colorScheme='blue' size={'sm'} >
        <TabList>
          <Tab onClick={() => setMessageType('grp')}><Text fontWeight={'700'}>GROUP</Text>{pvtnewcount > 0 && <Badge colorScheme='red' variant='solid' ms='20px'>{pvtnewcount}</Badge>}</Tab>
          <Tab onClick={() => setMessageType('pvt')}><Text fontWeight={'700'}>PRIVATE</Text>{grpnewcount > 0 && <Badge colorScheme='red' variant='solid' ms='20px'>{grpnewcount}</Badge>}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {isGroup &&
              <Flex direction={'column'} w='100%'>
                <Textarea
                  ref={grptextAreaRef}
                  value={grpmessage}
                  onChange={setgrpmessagetxt}
                  placeholder='Enter message here ...'
                  borderRadius={'10px'}
                  rows={1}
                  maxLength={250}
                />
                <Flex justify={'space-between'}>
                  <Flex ms='10px'>
                    <TextMaxLength text={grpmessage} maxlength={250} justify={'end'} />
                  </Flex>
                  <Button bg='transparent' variant={'outline'} colorScheme={'blue'} borderRadius={'5px'} onClick={sendgrpmessage} isLoading={sending} mt='10px' size={'sm'}>SEND<Icon as={TbSend} boxSize={4} ms='10px'></Icon></Button>
                </Flex>
              </Flex>}
            <Flex mt='30px' direction={'column'}>
              {grpmessages.map((m, index) => (
                <MentorMessageBlock
                  content={m.content}
                  time={dayjs(m.sent).format(dateformat)}
                  side={m.seen ? 'left' : 'right'}
                  seen={m.seen}
                  allowclose={user.role === 'MENTOR'}
                  mid={m.id}
                />
              ))}
            </Flex>

          </TabPanel>

          <TabPanel>

            {!isGroup &&
              <Flex direction={'column'} w='100%'>
                <Textarea
                  ref={pvttextAreaRef}
                  value={pvtmessage}
                  onChange={setpvtmessagetxt}
                  placeholder='Enter message here ...'
                  borderRadius={'10px'}
                  rows={1}
                  maxLength={250}
                />
                <Flex direction={'row'} justify={'space-between'}>
                  <Flex ms='10px'>
                    <TextMaxLength text={pvtmessage} maxlength={250} justify={'end'} />
                  </Flex>
                  <Flex>
                    <InputGroup size={'sm'} mt='10px'>
                      <InputLeftAddon borderLeftRadius={'5px'}><Text fontWeight={'700'}>STUDENT</Text></InputLeftAddon>
                      <Select onChange={changeRecipient}>
                        <option value=''>Select ...</option>
                        {activementees.map((m, index) => (
                          <option value={m.id} key={m.id}>{m.menteename}</option>
                        ))}

                      </Select>
                      <InputRightAddon as={Button} bg='transparent' variant={'outline'} colorScheme={'blue'} borderWidth={'1px'} onClick={sendpvtmessage} borderRightRadius={'5px'} isLoading={sending}>
                        SEND<Icon as={TbSend} boxSize={4} ms='10px'></Icon>
                      </InputRightAddon>
                    </InputGroup>
                  </Flex>
                </Flex>
              </Flex>}
            <Flex mt='30px' direction={'column'}>
              {pvtmessages.map((m, index) => (
                <MentorMessageBlock
                  content={m.content}
                  time={dayjs(m.sent).format(dateformat)}
                  side={m.seen ? 'left' : 'right'}
                  seen={m.seen}
                  allowclose={user.role === 'MENTOR'}
                  mid={m.id}
                />
              ))}
            </Flex>

          </TabPanel>
        </TabPanels>
      </Tabs>


      {/*<Flex direction={'row'} align={'center'} mb='20px' mt='50px'>
        <Flex direction={'column'} w='100%'>
          <Textarea
            ref={textAreaRef}
            value={newmessage}
            onChange={setnewmessagetxt}
            placeholder='Enter message here ...'
            borderRadius={'10px'}
            rows={1}
            maxLength={250}
          />
          <Flex direction={'row'} justify={'space-between'}>
            <Flex ms='10px'>
              <TextMaxLength text={newmessage} maxlength={250} justify={'end'} />
            </Flex>
            <Flex>
              <InputGroup size={'sm'} mt='10px'>
                <InputLeftAddon borderLeftRadius={'5px'}><Text fontWeight={'700'}>RECIPIENT</Text></InputLeftAddon>
                <Select onChange={changeRecipient}>
                  <optgroup label='All students'>
                    <option value='0'>GROUP</option>
                  </optgroup>
                  {mentees.length && <optgroup label='Trader'>
                    {mentees.map((m, index) => (
                      <option value={m.id} key={m.id}>{m.menteename}</option>
                    ))}
                  </optgroup>}

                </Select>
                <InputRightAddon as={Button} bg='transparent' variant={'outline'} colorScheme={'blue'} onClick={sendmessage} borderRightRadius={'5px'} isLoading={sending}>
                  SEND<Icon as={TbSend} boxSize={4} ms='10px'></Icon>
                </InputRightAddon>
              </InputGroup>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {selectedmessages.map((m, index) => (
        <MentorMessageBlock
          content={m.content}
          time={dayjs(m.sent).format(dateformat)}
          side={m.seen ? 'left' : 'right'}
          seen={m.seen}
          allowclose={user.role === 'MENTOR'}
          mid={m.id}
        />
      ))}*/}
    </>
  )
}

export default MentorMessages