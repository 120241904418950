/* eslint-disable */

import { Badge, Button, Flex, HStack, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
	ExpandedState,
	getExpandedRowModel
} from '@tanstack/react-table';
// Custom components  
import * as React from 'react';
import { ExecutionsTable } from '../executionstable';
import { Trade } from 'views/trader/trades/variables/types';
import { TradeEditCell } from './components/TradeEditCell';
import TableCard from '../TableCard';
import { useImperativeHandle, useRef, useState } from 'react';
import dayjs from 'dayjs';
import useNewTrade from 'views/trader/trades/hooks/useNewTrade';
import { useData } from 'services/data/DataProvider';

const columnHelper = createColumnHelper<Trade>();

const currformat = (val: number, currcode: string) => {
	let curr = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currcode,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
	return curr.format(val);
}
const numColor = (val: any) => {
	return val < 0 ? "red.500" : "green.500";
}
const getDuration = (milliDiff: number) => {

	const totalSeconds = Math.floor(milliDiff / 1000);
	const totalMinutes = Math.floor(totalSeconds / 60);
	const totalHours = Math.floor(totalMinutes / 60);

	const totalDays = Math.floor(totalHours / 24);
	const remSeconds = totalSeconds % 60;
	const remMinutes = totalMinutes % 60;
	const remHours = totalHours % 24;

	let days = totalDays > 0 ? totalDays + "d " : "";
	let hours = remHours > 0 ? remHours + "h " : "";
	let mins = remMinutes > 0 ? remMinutes + "m " : "";
	let secs = remSeconds > 0 ? remSeconds + "s " : "";

	const newtrade = useNewTrade();

	return (milliDiff < 1000 ? "" : "Duration: " + days + hours + mins + secs);
}

// const columns = columnsDataCheck;
export const TradesTable = React.forwardRef((props: { tradesData: Trade[], updatebuttons: any }, ref) => {

	const textColor = useColorModeValue("navy.700", "white");
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const toast = useToast();
	const newtrade = useNewTrade();
	const {dateformat} = useData();

	const tableRef = useRef();
	useImperativeHandle(ref, () => ({
		addTrade() {
			addRow(newtrade);
		},
		updateTable() {
			setData([...props.tradesData]);
		}
	}));

	

	//let defaultData = tradesData;
	const columns = [
		columnHelper.accessor('datetime', {
			id: 'date',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					DATE
				</Text>
			),
			cell: ({ row, getValue }) => (
				<>
					<HStack>
						<Flex>
							<Button variant='outline' size='sm' me='10px' onClick={() => row.toggleExpanded()} disabled={!row.original.closed}>
								{row.getIsExpanded() ? '-' : '+'}
							</Button>
						</Flex>
						<Flex direction='column'>
							<Text color={textColor} fontSize='sm' fontWeight='700'>
								{dayjs(getValue()).format(dateformat)}
							</Text>
							<Text color='secondaryGray.500' fontSize='sm' fontWeight='600'>
								{getDuration(row.original.duration)}
							</Text>
						</Flex>
					</HStack>
				</>
			)
		}),
		columnHelper.accessor('ticker', {
			id: 'ticker',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					TICKER
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('side', {
			id: 'side',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SIDE
				</Text>
			),
			cell: (info) => (

				<Badge
					colorScheme={info.getValue().toLocaleLowerCase() === "sell" ? 'red' : 'green'}
					color={info.getValue().toLocaleLowerCase() === "sell" ? 'red.500' : 'green.500'}
					fontSize='sm'
					fontWeight='600'>
					{info.getValue().toUpperCase()}
				</Badge>
			)
		}),
		columnHelper.accessor('size', {
			id: 'size',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SIZE
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('entry_avg', {
			id: 'entry',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					OPEN
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600' >
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('exit_avg', {
			id: 'exit',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					CLOSE
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('points', {
			id: 'points',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					POINTS
				</Text>
			),
			cell: (info) => (
				<Text color={numColor(info.getValue())} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('profitloss', {
			id: 'profitloss',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					P/L
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={numColor(getValue())} fontSize='sm' fontWeight='600'>
					{currformat(getValue(), row.original.currency)}
				</Text>
			)
		}),
		columnHelper.accessor('fees', {
			id: 'fees',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					FEES
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{currformat(getValue(), row.original.currency)}
				</Text>
			)
		}),
		columnHelper.accessor('nett', {
			id: 'nett',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					NETT
				</Text>
			),
			cell: ({ row, getValue }) => (
				<Text color={numColor(getValue())} fontSize='sm' fontWeight='600'>
					{currformat(getValue(), row.original.currency)}
				</Text>
			)
		}),
		columnHelper.display({
			id: 'edit',
			cell: TradeEditCell,
			enableResizing: false,
			size: 50
		}),
	];

	const [data, setData] = React.useState(() => [...props.tradesData]);
	const [expanded, setExpanded] = React.useState<ExpandedState>({})

	const getLastIndex = () => {
		let index = table.getFilteredRowModel().rows.length - 1;
		return index.toString();
	}

	const addRow = (newRow: Trade) => {
		props.tradesData.push(newRow);
		setData([...props.tradesData]);
		props.updatebuttons();

		setExpanded((old: {}) => ({
			...old,
			[getLastIndex()]: true,
		}));
	}

	const deleteRow = (index: number) => {
		props.tradesData.splice(index, 1);
		setData([...props.tradesData]);
		props.updatebuttons();
		if (!props.tradesData.length) {
			table.resetExpanded();
		}
	}

	const updateTrade = (data: any) => {
		if (data && data.length) {
			let tr: Trade = newtrade;
			tr.executions.push(data[0]);
			addRow(tr);
			toast({ title: 'Trade reversed and fees split', description: "Enter new executions to complete ...", status: 'success', position: 'top', duration: 3000, isClosable: false })
		}
		setData([...props.tradesData]); // Update data array
		table.getRowModel().rows.map((row) => {
			setExpanded((old: {}) => ({
				...old,
				[row.id]: !row.original.closed,
			}));
		});
	}

	const table = useReactTable({
		data,
		columns,
		onExpandedChange: setExpanded,
		state: {
			expanded
		},
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		//getSubRows: (row) => row?.tradeid,
		meta: {
			addRow: () => {
				addRow(newtrade);
			},
			removeRow: (rowIndex: number) => {
				deleteRow(rowIndex);
			},
		},
		debugTable: true,
	});
	return (
		<>
			<Flex direction='column'>
				{/*<Flex direction='row' justify={{ base: 'center', md: 'start' }} mb='15px' mt='10px'>
					<TableHeaderCell table={table} />
				</Flex>*/}
				<Table variant='simple' color='gray.500' mt="12px">
					<Thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											pe='10px'
											borderColor={borderColor}
											cursor='pointer'
											onClick={header.column.getToggleSortingHandler()}>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}{{
													asc: '',
													desc: '',
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										</Th>
									);
								})}
							</Tr>
						))}
					</Thead>
					<Tbody>
						{table.getRowModel().rows.map((row) => {//.rows.slice(0, 30).map((row) => {
							return (
								<>
									<Tr key={row.id}>
										{row.getVisibleCells().map((cell) => {
											return (
												<Td
													key={cell.id}
													fontSize={{ sm: '14px' }}
													minW={{ sm: '150px', md: '200px', lg: 'auto' }}
													borderColor='transparent'>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</Td>
											);
										})}
									</Tr>
									{row.getIsExpanded() && (
										<Tr key={row.original.tradeid}>
											<Td colSpan={11}>
												<TableCard title={''} table={<ExecutionsTable tradeData={row.original} updateTrade={updateTrade} />} showTitleBar={false} showBg={true} showMt={false} showPadding={false} loading={false} minh={'auto'} />
											</Td>
										</Tr>
									)}
								</>
							);
						})}
					</Tbody>
					{/*<Tfoot>
				<Tr>
					<Th>
						<TableFooterCell table={table} />
					</Th>
				</Tr>
			</Tfoot>*/}
				</Table>
			</Flex>

		</>


	);
});