import { Box, Button, Flex, FormLabel, Grid, GridItem, Heading, Icon, InputGroup, InputRightAddon, Select, Show, useColorModeValue, useToast } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react';
import { Mentor, MentorReview, MentorTag, MinimalStatistics, newMinStats, PeriodDates, Trade } from 'views/trader/trades/variables/types';
import MentorBar from '../components/MentorBar';
import Card from 'components/card/Card';
import DeleteMentorConfirm from 'views/trader/components/confirm/DeleteMentorConfirm';
import { FiDownloadCloud } from 'react-icons/fi';

import { PeriodDateRange } from 'views/trader/trades/variables/PeriodDateRange';
import dayjs from 'dayjs';
import api from 'services/api';
import { MinimalStatProcess } from 'views/trader/trades/variables/MinimalStatProcessor';
import MinimalStatBar from 'views/trader/components/display/MinimalStatBar';
import MinimalStatBarLarge from 'views/trader/components/display/MinimalStatBarLarge';
import MentorTradesTable from '../components/MentorTradesTable';
import MentorDirectoryDrawer from '../components/MentorDirectoryDrawer';
import MentorReviewTable from '../components/MentorReviewTable';
import MentorBenefits from '../components/help/MentorBenefits';
import MenteeHelpModal from '../components/help/MenteeHelpModal';
import { useData } from 'services/data/DataProvider';
import { MentorRadioGroup } from '../components/MentorRadioGroup';


const Menteepage = () => {
  const { mentors, usertrades } = useData();

  const [mentorsactive, setMentorsActive] = useState<MentorTag[]>([]);
  const [selectedmentor, setSelectedMentor] = useState<Mentor>(null)
  const [selectedmentorreviews, setSelectedMentorReviews] = useState<MentorReview[]>([]) // for updating review button on review table

  const [mentorspending, setMentorsPending] = useState<Mentor[]>([]);
  const [stats, setStats] = useState<MinimalStatistics>(newMinStats());
  const [trades, setTrades] = useState<Trade[]>([]);
  const [reviews, setReviews] = useState<Trade[]>([]);

  const [mentorReviews, setMentorReviews] = useState<MentorReview[]>([]);

  const bgcolor = useColorModeValue('white', 'navy.900');
  const [tradeSelection, setTradeSelection] = useState('');
  const [reviewSelection, setReviewSelection] = useState('');
  //const [range, setRange] = useState(["", ""]);
  const [fetching1, setFetching1] = useState(false);
  const [fetching2, setFetching2] = useState(false);
  const [statload, setstatload] = useState(false);
  const [showhelp, setshowhelp] = useState(false);

  const toast = useToast();

  useEffect(() => {
    let ma = mentors.filter((mt: Mentor) => mt.status !== 'pending' && mt.status !== 'requested')
    let marr: MentorTag[] = [];
    for (let m of ma) {
      marr.push({ mentorid: m.id, mentorname: m.mentorname, avatar: m.avatar, newcount: 0 });
    }
    setMentorsActive(marr);

    setSelectedMentor(ma.length ? ma[0] : null)
    let mp = mentors.filter((mt: Mentor) => mt.status === 'pending' || mt.status === 'requested')
    setMentorsPending(mp);
    setshowhelp(!ma.length && !mp.length);
  }, [mentors])

  const selectMentor = (e: ChangeEvent<HTMLSelectElement>) => {
    let m = mentors.filter((me: Mentor) => me.id === Number(e.target.value));
    selectedmentorchange(m.length ? m[0] : null);
  }
  const selectedmentorchange = (mentorid: number) => {
    //alert(JSON.stringify(mentors))
    let m = mentors.filter((mt: Mentor) => mt.id===mentorid);
    //alert(JSON.stringify(m))
    setSelectedMentor(m[0]);
    setReviews([]);
    setReviewSelection('');
    setTrades([]);
    setTradeSelection('');
  }

  const selectTrades = async (e: ChangeEvent<HTMLSelectElement>) => {
    setReviews([]);
    setReviewSelection('');
    setTradeSelection(e.target.value);
    if (e.target.value !== '' && e.target.value !== 'reviewed') {
      let r: PeriodDates = PeriodDateRange(e.target.value);
      let s = [dayjs(r.startthis).format('YYYY-MM-DD HH:mm:ss'), dayjs(r.endthis).format('YYYY-MM-DD HH:mm:ss')];
      //setRange(s);

      setFetching1(true);

      await api.post('/mentor/mentortrades', { mentor: selectedmentor, selection: e.target.value, start: s[0], end: s[1] }).then(async (res) => {
        if (!res.data.length) {
          switch (e.target.value) {
            case 'review':
              toast({ title: 'No trades found for review ...', description: '', status: 'info', position: 'top', duration: 3000, isClosable: false })
              break;
            case 'all':
              toast({ title: 'No shared trades found for this mentor ...', description: '', status: 'info', position: 'top', duration: 3000, isClosable: false })
              break;
            default:
              toast({ title: 'No shared trades found for this period ...', description: '', status: 'info', position: 'top', duration: 3000, isClosable: false })
              break;
          }
          setTrades(res.data);
        }
        else {
          setTrades(res.data);
          setstatload(true);
          await MinimalStatProcess(res.data, "USD").then((s) => {
            setStats(s);
            setstatload(false);
          })
        }
      }).catch((e) => {
        toast({ title: 'Error fetching trades ...', description: 'Check your network connection or try again in a few minutes', status: 'error', position: 'top', duration: 4000, isClosable: false })
      })
      setTimeout(() => {
        setFetching1(false);
      }, 500);
    }
    else {
      //setRange(["", ""]);
    }
  }

  const selectReviews = async (e: ChangeEvent<HTMLSelectElement>) => {

    setTrades([]);
    setTradeSelection('');
    setReviewSelection(e.target.value);
    let selection = e.target.value;

    let mr = mentorReviews.filter((r) => r.mentorid === selectedmentor.mentorid);
    if (mr.length > 0) {
      await updateReviews(mentorReviews, e.target.value);
      setSelectedMentorReviews(mentorReviews.filter((mrs) => mrs.mentorid === selectedmentor.mentorid));
      return;
    }

    setFetching2(true);

    await api.post('/mentor/getmentoreviews', { mentorid: selectedmentor.mentorid }).then(async (res) => {
      if (res.data !== false) {
        setMentorReviews([...mentorReviews, ...res.data]);
        setSelectedMentorReviews(res.data);
        await updateReviews(res.data, e.target.value);
        //sethidereviewfetch(res.data.length > 0)
      }
      else {
        toast({ title: 'Unable to sync mentor ' + selection + ' reviews ...', description: 'Check your network connection or try again in a few minutes', status: 'error', position: 'top', duration: 3000, isClosable: false })
      }
    }).catch((e) => {
      toast({ title: 'Unable to sync mentor ' + selection + ' reviews ...', description: 'Check your network connection or try again in a few minutes', status: 'error', position: 'top', duration: 3000, isClosable: false })
    })

    setTimeout(() => {
      setFetching2(false);
    }, 500);
  }

  const updateReviews = async (reviews: MentorReview[], selection: string): Promise<string> => {

    return new Promise(async (resolve) => {

      let trarray: Trade[] = [];

      for (let t of usertrades) {
        switch (selection) {
          case 'pending':
            let p = reviews.filter((rev: MentorReview) => (rev.tradeid === t.tradeid && rev.reviewed === false && rev.mentorid === selectedmentor.mentorid));
            if (p.length > 0) {
              trarray.push(t);
            }
            break;
          case 'completed':
            let c = reviews.filter((rev: MentorReview) => (rev.tradeid === t.tradeid && rev.reviewed === true && rev.mentorid === selectedmentor.mentorid));
            if (c.length > 0) {
              trarray.push(t);
            }
            break;
        }
      }

      setstatload(true);
      await MinimalStatProcess(trarray, "USD").then((s) => {
        setStats(s);
        setstatload(false);
      })

      if (!trarray.length) {
        switch (selection) {
          case 'pending': toast({ title: 'No trade reviews pending for ' + selectedmentor.mentorname + ' ...', description: '', status: 'info', position: 'top', duration: 3000, isClosable: false }); break;
          case 'completed': toast({ title: 'No trade reviews completed by ' + selectedmentor.mentorname + '...', description: '', status: 'info', position: 'top', duration: 3000, isClosable: false }); break;
        }
      }
      setReviews(trarray);
      resolve('');
    });
  }

  return (
    <>

      <Flex direction={'column'} width={'100%'} gap={5} flexWrap={'wrap'}>

        {showhelp &&
          <Card borderWidth={'1px'} bg={'transparent'} w='100%' mt='20px'>
            <Flex direction={'row'} justify={'space-between'}>
              <Heading size={'sm'} mt='-30px' bg={bgcolor} px='10px'>How it works</Heading>
              <Flex justify={'end'} mt='-41px' >
                <MentorDirectoryDrawer />
              </Flex>
            </Flex>
            <Flex justify={'center'}>
              <MentorBenefits />
            </Flex>
          </Card>
        }



        {mentorspending.length > 0 &&
          <Card borderWidth={'1px'} bg={'transparent'} w='100%' mt='20px'>
            <Flex direction={'row'} justify={'space-between'}>
              <Heading size={'md'} mt='-35px' bg={bgcolor} px='10px'>Pending</Heading>
              <Flex justify={'end'} mt='-41px' >
                <MentorDirectoryDrawer />
                <MenteeHelpModal />
              </Flex>
            </Flex>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }} gap={5} mt='30px'>
              {mentorspending.map((m, index) => (
                <GridItem w='100%'>
                  <Flex>
                    <MentorBar mentor={m} />
                  </Flex>
                </GridItem>
              ))}
            </Grid>
          </Card>
        }

        {mentorsactive.length > 0 &&
          <>
            <Card borderWidth={'1px'} bg={'transparent'} w='100%' mt='20px'>

              <Flex direction={'row'} justify={'space-between'}>
                <Heading size={'md'} mt='-35px' bg={bgcolor} px='10px'>Mentors</Heading>

                {mentorspending.length < 1 && <Flex justify={'end'} mt='-41px' >
                  <MentorDirectoryDrawer />
                  <MenteeHelpModal />
                </Flex>}
              </Flex>
              <Flex mt='10px'>
                <MentorRadioGroup mentortags={mentorsactive} selectmentor={selectedmentorchange} selectedmentorname={selectedmentor.mentorname}/>
              </Flex>

              <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }} gap={5} mt='10px' mb='10px'>

                {selectedmentor &&
                  <>

                    <GridItem w='100%'>
                      <FormLabel fontWeight='bold' fontSize='sm' mb='8px' color={'gray.400'}>{selectedmentor.mentorname.toUpperCase()}  -  trade reviews</FormLabel>
                      <InputGroup>
                        <Select onChange={selectReviews} value={reviewSelection}>
                          <option value="">Select ...</option>
                          <option value="pending">Pending</option>
                          <option value="completed">Completed</option>
                        </Select>
                        <Flex hidden={!fetching2}>
                          <InputRightAddon bg='transparent' borderWidth={0}>
                            <Button variant={'ghost'} colorScheme={'blue'} borderRadius={'5px'} isLoading={fetching2} loadingText='Fetching'>Fetch <Icon as={FiDownloadCloud} size={6} ms='10px'></Icon></Button>
                          </InputRightAddon>
                        </Flex>
                      </InputGroup>
                    </GridItem>
                    <GridItem w='100%'>
                      <FormLabel fontWeight='bold' fontSize='sm' mb='8px' color={'gray.400'}>{selectedmentor.mentorname.toUpperCase()}  -  shared trades</FormLabel>
                      <InputGroup>
                        <Select onChange={selectTrades} value={tradeSelection}>
                          <option value="">Select ...</option>
                          <optgroup label="Mentor's shared trades">
                            <option value='weekthis'>This week</option>
                            <option value='weeklast'>Last week</option>
                            <option value='monththis'>This month</option>
                            <option value='monthlast'>Last month</option>
                            <option value='quarterthis'>This quarter</option>
                            <option value='quarterlast'>Last quarter</option>
                            <option value='yearthis'>This year</option>
                            <option value='yearlast'>Last year</option>
                            <option value='all'>ALL trades</option>
                          </optgroup>
                        </Select>
                        <Flex hidden={!fetching1}>
                          <InputRightAddon bg='transparent' borderWidth={0}>
                            <Button variant={'ghost'} colorScheme={'blue'} borderRadius={'5px'} isLoading={fetching1} loadingText='Fetching'>Fetch <Icon as={FiDownloadCloud} size={6} ms='10px'></Icon></Button>
                          </InputRightAddon>
                        </Flex>
                      </InputGroup>
                    </GridItem>
                    <GridItem w='100%'>
                      <Flex direction={'column'}>
                        <FormLabel fontWeight='bold' fontSize='sm' mb='8px' color={'gray.400'}>{selectedmentor.mentorname.toUpperCase()} - cancel mentorship</FormLabel>
                        <DeleteMentorConfirm mentorshipid={selectedmentor ? selectedmentor.id : 0} label={'Cancel ' + selectedmentor.mentorname.toUpperCase()} btnsize='md' />
                      </Flex>
                    </GridItem>
                  </>
                }
              </Grid>
            </Card>

            <Box hidden={!trades.length} w="100%" mt="10px">
              <Show breakpoint='(min-width: 1800px)'>
                <MinimalStatBarLarge loading={statload} stats={stats} />
              </Show>
              <Show breakpoint='(max-width: 1799px)'>
                <MinimalStatBar loading={statload} stats={stats} />
              </Show>
            </Box>

            <Flex hidden={!trades.length} mt='15px' w='100%'>
              <Card borderWidth={'1px'} bg='transparent'>
                <Flex>
                  <Heading size={'sm'} mt='-32px' bg={bgcolor} px='10px'>Mentor Trades</Heading>
                </Flex>
                <Flex direction='column' w='100%' overflowX={'auto'}>
                  <Box>
                    <MentorTradesTable trades={trades} mentor={selectedmentor} mentee={null} updatePending={null} />
                  </Box>
                </Flex>
              </Card>
            </Flex>

            <Flex hidden={!reviews.length} mt='15px' w='100%'>
              <Card borderWidth={'1px'} bg='transparent'>
                <Flex>
                  <Heading size={'sm'} mt='-32px' bg={bgcolor} px='10px'>{selectedmentor && selectedmentor.mentorname.toUpperCase() + ' - '} Trade Reviews - {reviewSelection}</Heading>
                </Flex>
                <Flex direction='column' w='100%' overflowX={'auto'}>
                  <Box>
                    <MentorReviewTable trades={reviews} mentoreviews={selectedmentorreviews} />
                  </Box>
                </Flex>
              </Card>
            </Flex>

          </>
        }

      </Flex>

    </>
  )
}

export default Menteepage