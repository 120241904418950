import './assets/css/App.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
//import AuthProvider from "./provider/authProvider";


import App from './App';
import React from 'react';

const root = createRoot(document.getElementById("root") as HTMLElement);
//const root = createRoot(container);

/*root.render(
  <BrowserRouter >
    <App />
  </BrowserRouter>,

);*/

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);