import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Icon, IconButton, Text, useDisclosure } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { MdClose } from "react-icons/md"
import api from "services/api"
import { useAuth } from "services/auth/AuthProvider"
import { db } from "services/db"

export const DeleteMessageConfirm = (props: { mid: number, hidden: boolean }) => {

    const { user } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef();

    const [loading, setLoading] = useState(false);

    const confirmDelete = async () => {
        if (user.role === 'MENTOR') {
            setLoading(true);

            await api.post('/mentor/deletemessage', { id: props.mid }).then(async (res) => {
                if (res.data === true) {
                    await db.mentormessages.delete(props.mid).catch((e) => { })
                }
            });

            setLoading(false);
        }
        else {
            await db.mentormessages.where({ id: props.mid }).delete().catch(() => { })
        }

        onClose();
    }

    return (
        <>
            <IconButton hidden={props.hidden} isLoading={loading} bg={'transparent'} size={'sm'} mt='-5px' me='-15px' aria-label={''} onClick={onOpen} icon={<Icon as={MdClose}></Icon>}></IconButton>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete message
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {user.role === 'MENTOR' ? (
                                <Text color={'orange.500'} fontWeight={'700'}>
                                    The message will only be deleted for students who have not seen it yet.
                                </Text>
                            ) : (
                                <Text fontWeight={'700'}>
                                    The message will only be deleted for you.
                                </Text>
                            )}
                            <Text fontWeight={'700'} mt='20px'>
                                Are you sure?
                            </Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={confirmDelete} ml={3}>
                                Yes, delete!
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}