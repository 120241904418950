import { Flex } from "@chakra-ui/react";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import Footer from "components/footer/FooterAuthCentered";
import HomeHeader from "../sections/HomeHeader";

export default function LandingOtherLayout(props: { children: JSX.Element[], parent: string }) {

    const { children, parent } = props;

    return (
        <Flex direction="column" align="center"  m="0 auto" {...props}>
            <FixedPlugin />
            <HomeHeader parent={parent}/>
            {children}
            <Footer />
        </Flex>
    );
}