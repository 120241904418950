import { newStats, PeriodDates, PeriodTrades, Statistics, Trade } from "./types";

import { db } from "services/db";
import { PeriodDateRange } from "./PeriodDateRange";
import { PeriodTradesArray } from "./PeriodTrades";

export async function ProcessStats(trades: Trade[], period: string, currency: string) {

    let p: PeriodDates = PeriodDateRange(period);

    if (trades.length) {

        let t: PeriodTrades = PeriodTradesArray(trades, period, p);

        /// current period
        let count_c = t.current.length;
        let win_count = 0;
        let loss_count = 0;
        let gain_loss_total = 0;
        let win_total = 0;
        let loss_total = 0;
        let rvaluetotal = 0;
        let rvaluedays = 0;

        t.current.forEach((t: Trade, index: number) => {
            let ex = t.currency !== currency ? t.exchange : 1;
            gain_loss_total += (t.nett / ex);
            if (t.nett > 0) {
                win_count++;
                win_total += (t.nett / ex);
            }
            if (t.nett < 0) {
                loss_count++
                loss_total += (t.nett / ex);
            }
            if (t.rvalue !== 0) {
                rvaluetotal += t.rvalue;
                rvaluedays++;
            }
        });

        let profit_factor = win_total / (loss_total === 0 ? 1 : Math.abs(loss_total));
        let avg_pl = count_c > 0 ? (win_total + loss_total) / count_c : 0;
        let win_perc = count_c > 0 ? ((win_count / count_c)) * 100 : (win_count > 0 ? 100 : 0);
        let loss_perc = count_c > 0 ? ((loss_count / count_c)) * 100 : (loss_count > 0 ? 100 : 0);

        // previous period
        let count_p = t.previous.length;
        let p_win_count = 0;
        let p_loss_count = 0;
        let p_gain_loss_total = 0;
        let p_win_total = 0;
        let p_loss_total = 0;
        let p_rvaluetotal = 0;
        let p_rvaluedays = 0;

        t.previous.forEach((t: Trade, index: number) => {
            let ex = t.currency !== currency ? t.exchange : 1;
            p_gain_loss_total += (t.nett / ex);
            if (t.nett > 0) {
                p_win_count++;
                p_win_total += (t.nett / ex);
            }
            if (t.nett < 0) {
                p_loss_count++
                p_loss_total += (t.nett / ex);
            }
            if (t.rvalue !== 0) {
                p_rvaluetotal += t.rvalue;
                p_rvaluedays++;
            }
        });

        let p_profit_factor = p_win_total / (p_loss_total === 0 ? 1 : Math.abs(p_loss_total));
        let p_avg_pl = count_p > 0 ? (p_win_total + p_loss_total) / count_p : 0;
        let p_win_perc = count_p > 0 ? ((p_win_count / count_p) * 100) : (p_win_count > 0 ? 100 : 0);
        let p_loss_perc = count_p > 0 ? ((p_loss_count / count_p) * 100) : (p_loss_count > 0 ? 100 : 0);

        let avgw = (win_count > 0 ? win_total / win_count : 0);
        let avgl = (loss_count > 0 ? loss_total / loss_count : 0);
        let plratio = (avgl < 0 ? (avgw > 0 ? avgw : 1) / Math.abs(avgl) : avgw);

        let p_avgw = (p_win_count > 0 ? p_win_total / p_win_count : 0);
        let p_avgl = (p_loss_count > 0 ? loss_total / p_loss_count : 0);
        let p_plratio = (p_avgl < 0 ? (p_avgw > 0 ? p_avgw : 1) / Math.abs(p_avgl) : p_avgw);

        let wl_ratio = (loss_count > 0 ? win_count / loss_count : win_count);
        let p_wl_ratio = (p_loss_count > 0 ? p_win_count / p_loss_count : p_win_count);

        let avg_rvalue = rvaluedays > 0 ? rvaluetotal / rvaluedays : 0;
        let p_avg_rvalue = p_rvaluedays > 0 ? p_rvaluetotal / p_rvaluedays : 0;

        //stats object
        const Stats: Statistics = {
            id: 1,
            period: period,
            periodstr: p.period,
            currency: currency,
            trade_count: [count_c, count_p],
            pl_ratio: [plratio, p_plratio],
            wl_ratio: [wl_ratio, p_wl_ratio],
            avg_pl: [avg_pl, p_avg_pl],
            nett_gainloss: [gain_loss_total, p_gain_loss_total],
            profit_factor: [profit_factor, p_profit_factor],
            avg_rvalue: [avg_rvalue, p_avg_rvalue]
        }

        await db.statistics.put(Stats, 1).catch(error => { });
    }
    else {
        let newStat = newStats();
        await db.statistics.put(newStat, newStat.id).catch(error => { });
    }
}