import { MinimalStatistics, Trade } from "views/trader/trades/variables/types";

export async function MinimalStatProcess(trades: Trade[], currency: string): Promise<MinimalStatistics> {

    if (trades.length) {

        let count_c = trades.length;
        let win_count = 0;
        let loss_count = 0;
        let gain_loss_total = 0;
        let win_total = 0;
        let loss_total = 0;
        let rvaluetotal = 0;
        let rvaluedays = 0;

        trades.forEach((t: Trade, index: number) => {
            let ex = t.currency !== currency ? t.exchange : 1;
            gain_loss_total += (t.nett / ex);
            if (t.nett > 0) {
                win_count++;
                win_total += (t.nett / ex);
            }
            if (t.nett < 0) {
                loss_count++
                loss_total += (t.nett / ex);
            }
            if (t.rvalue !== 0) {
                rvaluetotal += t.rvalue;
                rvaluedays++;
            }
        });

        let profit_factor = win_total / (loss_total === 0 ? 1 : Math.abs(loss_total));
        let avg_pl = count_c > 0 ? (win_total + loss_total) / count_c : 0;


        let avgw = (win_count > 0 ? win_total / win_count : 0);
        let avgl = (loss_count > 0 ? loss_total / loss_count : 0);
        let plratio = (avgl < 0 ? (avgw > 0 ? avgw : 1) / Math.abs(avgl) : avgw);
        let wl_ratio = (loss_count > 0 ? win_count / loss_count : win_count);
        let avg_rvalue = rvaluedays > 0 ? rvaluetotal / rvaluedays : 0;

        const stat: MinimalStatistics = {
            currency: currency,
            trade_count: count_c,
            pl_ratio: plratio,
            wl_ratio: wl_ratio,
            avg_pl: avg_pl,
            nett_gainloss: gain_loss_total,
            profit_factor: profit_factor,
            avg_rvalue: avg_rvalue
        }

        return stat;
    }
    else {
        const stat: MinimalStatistics = {
            currency: currency,
            trade_count: 0,
            pl_ratio: 0,
            wl_ratio: 0,
            avg_pl: 0,
            nett_gainloss: 0,
            profit_factor: 0,
            avg_rvalue: 0
        }
        return stat;
    }
}