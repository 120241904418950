import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure, Text, useTab, useToast, UnorderedList, ListIcon, ListItem } from '@chakra-ui/react'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import api from 'services/api';
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';

const PortalUpdate = forwardRef((props: {}, ref) => {

    const { user } = useAuth();
    const toast = useToast();

    useImperativeHandle(ref, () => ({
        openInput() {
            onOpen();
        }
    }));

    const [loadingpmt, setLoadingpmt] = useState(false);

    const getPortal = async () => {
        setLoadingpmt(true);
        await db.users.get(user.id).then(async (u) => {
            await api.post('/subs/getpmtupdateurl', { sub_id: u.subscription.subscription_id, portal: true }).then((resp) => {
                if (resp.data !== "") {
                    window.open(resp.data, '_blank')
                    toast({ title: 'Refresh this page when you are done ...', description: "", status: 'info', position: 'top', duration: null, isClosable: true })
                }
                else{
                    toast({ title: 'Unable to open portal ...', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 4000, isClosable: true })
                }
            }).catch((e) => {
                toast({ title: 'Unable to reach server ...', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 4000, isClosable: true })
            })
        }).catch((e) => {})
        onClose();
        setLoadingpmt(false);
    }

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()

    return (
        <>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Manage subscription
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text mb='20px'>You will need to update your subscription via the portal.</Text>
                            <Text mb='20px'>In the portal you can:</Text>
                            <UnorderedList mb='20px'>
                                <ListItem>Update, change or cancel your subscription</ListItem>
                                <ListItem>Update payment method</ListItem>
                            </UnorderedList>
                            <Text mb='20px'>Remember to refresh this page once you're done.</Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='cyan' variant={'outline'} onClick={getPortal} ml={3} isLoading={loadingpmt} loadingText="Loading">
                                Yes, go to portal
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
})

export default PortalUpdate