import uuid from "react-uuid";

const useJornoUuid = () => {

	let uid = "";

	if (localStorage.getItem('jornomid')) {
		uid = localStorage.getItem('jornomid')
	}
	else {
		let u = uuid();
		localStorage.setItem('jornomid', u);
		uid = u;
	}
	return uid;
};

export default useJornoUuid;