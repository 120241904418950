import { Flex, FormLabel, Text } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { Mentor } from 'views/trader/trades/variables/types'
import DeleteMentorConfirm from 'views/trader/components/confirm/DeleteMentorConfirm';
import AcceptMentorConfirm from 'views/trader/components/confirm/MentorAcceptConfirm';

const MentorBar = (props: { mentor: Mentor }) => {

  const { mentor } = props;

  return (
    <>
      <Card mb='10px' >
        <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'} align={{ base: 'center', md: 'end' }}>
          <Flex justify={'space-between'}>
            <Flex direction={'column'}>
              <FormLabel fontWeight='bold' fontSize='sm' mb='8px' color={'gray.400'}>Mentor</FormLabel>
              <Text fontWeight={'700'} me='30px'>{mentor.mentorname}</Text>
            </Flex>
            <Flex direction={'column'}>
              <FormLabel fontWeight='bold' fontSize='sm' mb='8px' color={'gray.400'}>Status</FormLabel>
              <Text fontWeight={'700'} me='30px' color={'blue.500'}>{mentor.status === "pending" ? 'Invited' : 'Requested'}</Text>
            </Flex>
          </Flex>
          <Flex justify={'space-between'} mt={{ base: '20px', md: '0px' }}>
            {mentor.status === 'pending' &&
              <AcceptMentorConfirm mentorshipid={mentor.id} label={'Accept'} owner='mentee'/>
            }
            <DeleteMentorConfirm mentorshipid={mentor.id} label={mentor.status === 'pending' ? 'Decline' : 'Cancel request'} btnsize='sm' />
          </Flex>
          {/*<Button size='sm' colorScheme={'red'} onClick={cancelinvite} borderRadius={'5px'}>Cancel Invite<Icon as={TbMailCancel} size={5} ms='10px'></Icon></Button>*/}
        </Flex>
      </Card>
    </>
  )
}

export default MentorBar