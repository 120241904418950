import { useDisclosure, Button, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Icon, Select, FormLabel, Flex, InputGroup, Input, InputLeftAddon, useColorModeValue, FormControl, FormErrorMessage, VStack, useToast } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react';
import { RiUserSettingsLine } from "react-icons/ri";
import { Instrument } from '../trades/variables/types';
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';
import { Formik, Field } from 'formik';
import * as Yup from "yup";
import api from 'services/api';
import { DeleteInstumentConfirm } from './confirm/DeleteInstumentConfirm';
import { Timezones } from '../trades/variables/timezones';
import { useData } from 'services/data/DataProvider';

const UserInstrumentModal = (props: { islarge: boolean }) => {

    const { user, muuid } = useAuth();
    const { userinstruments } = useData();
    const {islarge} = props;

    const { isOpen, onOpen, onClose } = useDisclosure()
    const textColor = useColorModeValue("navy.700", "white");

    const [instrument, setInstrument] = useState<Instrument>(null);
    const [saving, setsaving] = useState(false);

    const [pointvalue, setpointvalue] = useState('');
    const [ticksize, setticksize] = useState('');
    const [timezone, setTimezone] = useState('');
    const timezones = Timezones;

    const toast = useToast();

    useEffect(() => { }, [instrument]);

    const updateticker = (e: ChangeEvent<HTMLInputElement>) => {
        instrument.ticker = e.target.value.trim().toUpperCase();
    }
    const updatedescription = (e: ChangeEvent<HTMLInputElement>) => {
        instrument.description = e.target.value.trim();
    }
    const updatecategory = (e: ChangeEvent<HTMLSelectElement>) => {
        instrument.category = e.target.value;
        instrument.subcategory = '';
    }
    const updatesector = (e: ChangeEvent<HTMLSelectElement>) => {
        instrument.subcategory = e.target.value;
    }
    const updatecurrency = (e: ChangeEvent<HTMLSelectElement>) => {
        instrument.currency = e.target.value;
        switch (e.target.value) {
            case 'USD': instrument.timezone = 'America/New_York'; break;
            case 'EUR': instrument.timezone = 'Europe/Berlin'; break;
            case 'GBP': instrument.timezone = 'Europe/London'; break;
            case 'CAD': instrument.timezone = 'America/New_York'; break;
            case 'AUD': instrument.timezone = 'Australia/Sydney'; break;
            case 'CHF': instrument.timezone = 'Europe/Zurich'; break;
            case 'HKD': instrument.timezone = 'Asia/Hong_Kong'; break;
            case 'JPY': instrument.timezone = 'Asia/ Tokyo'; break;
            case 'ZAR': instrument.timezone = 'Africa/Johannesburg'; break;
            default: instrument.timezone = ''; break;
        }
    }
    const updatetimezone = (e: ChangeEvent<HTMLSelectElement>) => {
        instrument.timezone = e.target.value;
    }
    const updatepointvalue = (e: ChangeEvent<HTMLInputElement>) => {
        setpointvalue(e.target.value);
        instrument.pointvalue = Number(e.target.value);
    }
    const updateticksize = (e: ChangeEvent<HTMLInputElement>) => {
        setticksize(e.target.value);
        instrument.ticksize = Number(e.target.value);
    }

    const selectInstrument = async (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === "") {
            setInstrument(null);
        }
        else {
            await db.instruments.get(Number(e.target.value)).then((i) => {
                if (i !== undefined) {
                    setInstrument(i);
                    setpointvalue(i.pointvalue.toString());
                    setticksize(i.ticksize.toString())
                }
            }).catch(() => { })
        }
    }

    const addInstrument = () => {
        let newInst: Instrument = {
            id: 0,
            userid: user.id,
            ticker: "",
            description: "",
            category: "",
            subcategory: "",
            currency: "",
            pointvalue: 0,
            ticksize: 0,
            relevance: 1,
            defaultstop: 0,
            commission: [],
            sync_due: false
        };
        setInstrument(newInst);
        setpointvalue('');
        setticksize('');
        let s = document.getElementById('tickerselect') as HTMLSelectElement;
        s.value = "";
    }

    const closeModal = () => {
        setInstrument(null);
        onClose();
    }

    const openModal = () => {
        if (user.role === "FREE") {
            toast({ title: 'FREE users cannot add instruments', description: "Please upgrade your subscription to add your own instruments", status: 'error', position: 'top', duration: 5000, isClosable: false })
        }
        else {
            onOpen();
        }
    }

    const resetInstrument = () => {
        setInstrument(null);
        let s = document.getElementById('tickerselect') as HTMLSelectElement;
        s.value = "";
    }

    return (
        <>
            {islarge ? (
                <>
                    <Button variant='outline' onClick={openModal} mb='20px' >
                        <Icon as={RiUserSettingsLine} h='20px' w='20px' me='10px'></Icon>
                        User Instruments
                    </Button>
                </>
            ) : (
                <>
                    <Button variant='outline' size={'sm'} onClick={openModal} borderRadius={'5px'} ms={{ base: '0px', md: "10px" }}>
                        <Icon as={RiUserSettingsLine} h='20px' w='20px' me='10px'></Icon>
                        User Instruments
                    </Button>
                </>
            )}


            <Modal isOpen={isOpen} onClose={closeModal} size={{ base: 'full', md: 'lg' }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>User instruments</ModalHeader>
                    <ModalCloseButton onClick={closeModal} />
                    <ModalBody>
                        <Flex direction={'column'}>
                            <Flex textAlign={'center'}>
                                <Text fontWeight='bold' fontSize='sm' mb='20px' color={'red.500'}>NOTE: Only use this if you cannot find your ticker or instrument in the database when uploading trades.</Text>
                            </Flex>

                            <Flex direction={'row'} justify={'space-between'}>
                                <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Select your instrument</FormLabel>
                            </Flex>
                            <Flex direction={'row'} justify={'space-between'}>
                                <Select onChange={selectInstrument} id="tickerselect" name="tickerselect">
                                    <option value="">Select ...</option>
                                    {userinstruments && userinstruments.map((inst: Instrument) => (
                                        <option key={inst.id} value={inst.id} >
                                            {inst.ticker} - {inst.description}
                                        </option>
                                    ))}
                                </Select>
                                {(instrument && instrument.id > 0) &&
                                    <DeleteInstumentConfirm instrument={instrument} updatemodal={resetInstrument} />
                                }
                                <Button colorScheme='blue' variant={'solid'} mr={3} borderRadius={'10px'} ms='5px' onClick={addInstrument}>
                                    Add new +
                                </Button>
                            </Flex>
                            {instrument &&
                                <>
                                    <Formik
                                        initialValues={{
                                            ticker: instrument.ticker,
                                            description: instrument.description,
                                            category: instrument.category,
                                            sector: instrument.subcategory,
                                            currency: instrument.currency,
                                            pointvalue: pointvalue,
                                            ticksize: ticksize,
                                            timezone: timezone
                                        }}
                                        onSubmit={async (values, { resetForm }) => {
                                            setsaving(true);
                                            delete instrument.sync_due;
                                            instrument.last_uuid = muuid;
                                            await api.post('/trade/saveinstrument', instrument)
                                                .then(async (res) => {
                                                    if (res.data === 'exists') {
                                                        toast({ title: 'TICKER already exists', description: "Please click 'Re-Sync Data' to fetch", status: 'error', position: 'top', duration: 3000, isClosable: false })
                                                    }
                                                    else {
                                                        delete res.data.last_uuid;
                                                        res.data.sync_due = false;
                                                        await db.instruments.put(res.data, res.data.id).then((r) => {
                                                            if (instrument.id > 0) {
                                                                toast({ title: 'Instrument updated', description: "", status: 'success', position: 'top', duration: 3000, isClosable: false })
                                                            }
                                                            else {
                                                                toast({ title: 'Instrument saved', description: "", status: 'success', position: 'top', duration: 3000, isClosable: false })
                                                            }
                                                            setInstrument(res.data);
                                                            let s = document.getElementById('tickerselect') as HTMLSelectElement;
                                                            s.value = res.data.id;
                                                            resetForm();
                                                        }).catch(() => { })
                                                    }

                                                })
                                                .catch(async (error) => {
                                                    if (instrument.id > 0) {
                                                        instrument.sync_due = true;
                                                        await db.instruments.put(instrument, instrument.id).catch(() => { })
                                                        toast({ title: 'Instrument updated locally ...', description: "Sync pending", status: 'success', position: 'top', duration: 3000, isClosable: false })
                                                    }
                                                    else {
                                                        toast({ title: 'Unable to save instrument', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false })
                                                    }

                                                });
                                            setsaving(false);
                                        }}
                                        validationSchema={Yup.object().shape({
                                            ticker: Yup.string().required('Underlying ticker required').test("missing sector", "Ticker already exists", async (item) => {
                                                try {
                                                    if (instrument.id < 1) {
                                                        return await db.instruments.where({ ticker: item.trim().toUpperCase() }).count() < 1;
                                                    }
                                                    else {
                                                        return true;
                                                    }
                                                } catch {
                                                    return true;
                                                }
                                            }),
                                            description: Yup.string().required('Description required   (eg: E-mini S&P 500 Futures)').max(50, "Maximum 50 characters"),
                                            category: Yup.string().required('Market required'),
                                            sector: Yup.string().required('Sector required').test("missing sector", "Sector required", (item) => {
                                                return instrument.subcategory.length > 0;
                                            }),
                                            currency: Yup.string().required('Currency required'),
                                            timezone: Yup.string().required('Timezone required'),
                                        })}
                                    >
                                        {({ handleChange, handleSubmit, errors, touched }) => (
                                            <form onSubmit={handleSubmit} onChange={handleChange}>
                                                <VStack gap={2} mt='30px'>

                                                    <FormControl isInvalid={!!errors.ticker && touched.ticker}>
                                                        <FormLabel fontWeight='bold' fontSize='sm' mb='8px' color={'gray.400'}>Short underlying exchange ticker</FormLabel>
                                                        <InputGroup>
                                                            <InputLeftAddon>
                                                                Ticker
                                                            </InputLeftAddon>
                                                            <Field as={Input} id="ticker" name="ticker" type='text' color={textColor} borderLeftRadius='0px' onChange={updateticker} value={instrument.ticker} />
                                                        </InputGroup>
                                                        <FormErrorMessage>{errors.ticker}</FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl isInvalid={!!errors.description && touched.description}>
                                                        <InputGroup mt='20px'>
                                                            <InputLeftAddon>
                                                                Description
                                                            </InputLeftAddon>
                                                            <Field as={Input} id="description" name="description" type='text' color={textColor} borderLeftRadius='0px' onChange={updatedescription} value={instrument.description} />
                                                        </InputGroup>
                                                        <FormErrorMessage>{errors.description}</FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl isInvalid={!!errors.category && touched.category}>
                                                        <InputGroup mt='20px'>
                                                            <InputLeftAddon>
                                                                Market
                                                            </InputLeftAddon>
                                                            <Field as={Select} id="category" name="category" onChange={updatecategory} value={instrument.category} borderLeftRadius={'0px'}>
                                                                <option value="">Select ...</option>
                                                                <option value="FUT">Futures</option>
                                                                <option value="FOREX">Forex</option>
                                                                <option value="CRYPTO">Crypto</option>
                                                                <option value="STOCK">Stocks</option>
                                                            </Field>
                                                        </InputGroup>
                                                        <FormErrorMessage>{errors.category}</FormErrorMessage>
                                                    </FormControl>

                                                    {(instrument.category !== "" && instrument.category === "FUT") &&
                                                        <>
                                                            <FormControl isInvalid={!!errors.sector && touched.sector}>
                                                                <InputGroup mt='20px'>
                                                                    <InputLeftAddon>
                                                                        Sector
                                                                    </InputLeftAddon>
                                                                    <Field as={Select} id="sector" name="sector" value={instrument.subcategory} onChange={updatesector} borderLeftRadius={'0px'}>
                                                                        <option value="">Select ...</option>
                                                                        <option value="Index">Index</option>
                                                                        <option value="Interest Rate">Interest Rate</option>
                                                                        <option value="Currency">Currency</option>
                                                                        <option value="Agricultural">Agricultural</option>
                                                                        <option value="Energy">Energy</option>
                                                                        <option value="Metals">Metals</option>
                                                                        <option value="Micro">Micro</option>
                                                                        <option value="Crypto">Crypto</option>
                                                                        <option value="Pair">Pair</option>
                                                                        <option value="Other">Other</option>
                                                                    </Field>
                                                                </InputGroup>
                                                                <FormErrorMessage>{errors.sector}</FormErrorMessage>
                                                            </FormControl>
                                                        </>
                                                    }
                                                    {(instrument.category !== "" && (instrument.category === "FOREX" || instrument.category === "CRYPTO")) &&
                                                        <>
                                                            <FormControl isInvalid={!!errors.sector && touched.sector}>
                                                                <InputGroup mt='20px'>
                                                                    <InputLeftAddon>
                                                                        Sector
                                                                    </InputLeftAddon>
                                                                    <Field as={Select} id="sector" name="sector" value={instrument.subcategory} onChange={updatesector} borderLeftRadius={'0px'}>
                                                                        <option value="">Select ...</option>
                                                                        <option value="Pair">Pair</option>
                                                                    </Field>
                                                                </InputGroup>
                                                                <FormErrorMessage>{errors.sector}</FormErrorMessage>
                                                            </FormControl>
                                                        </>
                                                    }
                                                    {(instrument.category !== "" && instrument.category === "STOCK") &&
                                                        <>
                                                            <FormControl isInvalid={!!errors.sector && touched.sector}>
                                                                <InputGroup mt='20px'>
                                                                    <InputLeftAddon>
                                                                        Sector
                                                                    </InputLeftAddon>
                                                                    <Field as={Select} id="sector" name="sector" value={instrument.subcategory} onChange={updatesector} borderLeftRadius={'0px'}>
                                                                        <option value="">Select ...</option>
                                                                        <option value="Basic Materials">Basic Materials</option>
                                                                        <option value="Consumer Discretionary">Consumer Discretionary</option>
                                                                        <option value="Consumer Staples">Consumer Staples</option>
                                                                        <option value="Energy">Energy</option>
                                                                        <option value="Finance">Finance</option>
                                                                        <option value="Health Care">Health Care</option>
                                                                        <option value="Industrials">Industrials</option>
                                                                        <option value="Miscellaneous">Miscellaneous</option>
                                                                        <option value="Real Estate">Real Estate</option>
                                                                        <option value="Technology">Technology</option>
                                                                        <option value="Telecommunications">Telecommunications</option>
                                                                        <option value="Utilities">Utilities</option>
                                                                        <option value="Other">Other</option>
                                                                    </Field>
                                                                </InputGroup>
                                                                <FormErrorMessage>{errors.sector}</FormErrorMessage>
                                                            </FormControl>
                                                        </>
                                                    }

                                                    <FormControl isInvalid={!!errors.currency && touched.currency}>
                                                        <InputGroup mt='20px'>
                                                            <InputLeftAddon>
                                                                Currency
                                                            </InputLeftAddon>
                                                            <Field as={Select} id="currency" name="currency" value={instrument.currency} onChange={updatecurrency} borderLeftRadius={'0px'}>
                                                                <option value="">Select ...</option>
                                                                <option value="USD">USD - US Dollar</option>
                                                                <option value="EUR">EUR - Euro</option>
                                                                <option value="GBP">GBP - British Pound</option>
                                                                <option value="CAD">CAD - Canadian Dollar</option>
                                                                <option value="AUD">AUD - Australian Dollar</option>
                                                                <option value="CHF">CHF - Swiss Franc</option>
                                                                <option value="HKD">HKD - Hong Kong Dollar</option>
                                                                <option value="JPY">JPY - Japanese Yen</option>
                                                                <option value="ZAR">ZAR - South African Rand</option>
                                                            </Field>
                                                        </InputGroup>
                                                        <FormErrorMessage>{errors.currency}</FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl isInvalid={!!errors.timezone && touched.timezone}>
                                                        <InputGroup mt='20px'>
                                                            <InputLeftAddon>
                                                                Timezone
                                                            </InputLeftAddon>
                                                            <Field as={Select} id="timezone" name="timezone" value={instrument.timezone} onChange={updatetimezone} borderLeftRadius={'0px'}>
                                                                <option value="">Select ...</option>
                                                                {timezones.map(zone => (
                                                                    <option key={zone.timezone} value={zone.timezone} >
                                                                        {zone.description}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                        </InputGroup>
                                                        <FormErrorMessage>{errors.timezone}</FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl isInvalid={Number(pointvalue) < 1}>
                                                        <InputGroup mt='20px' >
                                                            <InputLeftAddon>
                                                                Point Value
                                                            </InputLeftAddon>
                                                            <Input type="number" value={pointvalue} onChange={updatepointvalue} color={textColor}></Input>
                                                        </InputGroup>

                                                        <FormErrorMessage>Enter point value</FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl isInvalid={Number(ticksize) === 0 || ticksize.trim() === ''}>
                                                        <InputGroup mt='20px' >
                                                            <InputLeftAddon>
                                                                Tick size
                                                            </InputLeftAddon>
                                                            <Input type="number" value={ticksize} onChange={updateticksize} color={textColor}></Input>
                                                        </InputGroup>
                                                        <FormErrorMessage>Tick size required</FormErrorMessage>
                                                    </FormControl>

                                                    <Button type="submit" fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mt="24px" mb="24px" isLoading={saving} loadingText={instrument.id > 0 ? "Updating" : "Saving"}>{instrument.id > 0 ? "Update" : "Save"}</Button>
                                                </VStack>

                                            </form>
                                        )}
                                    </Formik>
                                </>
                            }
                        </Flex>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default UserInstrumentModal
