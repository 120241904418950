import { Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Icon, Flex, useColorModeValue, Button, Box, Show } from '@chakra-ui/react'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { BsClipboardData } from "react-icons/bs";
import { TbFaceIdError } from "react-icons/tb";
import TableCard from 'views/trader/trades/components/tables/TableCard';
import { MinimalStatistics, newMinStats, Trade } from 'views/trader/trades/variables/types';
import StrategyTradesTable from './StrategyTradesTable';
import { useAuth } from 'services/auth/AuthProvider';
import MinimalStatBar from 'views/trader/components/display/MinimalStatBar';
import MinimalStatBarLarge from 'views/trader/components/display/MinimalStatBarLarge';
import { MinimalStatProcess } from 'views/trader/trades/variables/MinimalStatProcessor';

const StrategyTradesModal = forwardRef((props: { data: { id: number, name: string, trades: Trade[], nett: number }, onclosemodal: any }, ref) => {

    const [title, setTitle] = useState('');
    const [ttype, setTtype] = useState('');
    const [smid, setSmid] = useState(0);
    const [trades, setTrades] = useState<Trade[]>([]);
    const drawerBg = useColorModeValue('white', 'navy.800');
    const { user } = useAuth();//useContext(AuthContext);

    const [statload, setstatload] = useState(false);

    const [stats, setStats] = useState<MinimalStatistics>(newMinStats());

    useImperativeHandle(ref, () => ({
        openModal(type: string, data: { id: number, name: string, trades: Trade[], nett: number }) {
            setTtype(type);
            onOpen();
        }
    }));

    useEffect(() => {
        setTrades(props.data.trades);
        setTitle(props.data.name);
        setSmid(props.data.id);
        setstatload(true);
        setTimeout(async () => {
            await MinimalStatProcess(props.data.trades, "USD").then((s) => {
                setStats(s);
                setstatload(false);
            })
        }, 1000);
    }, [props.data])

    const closemodal = async () => {
        onClose();
        //getDefaultGroup(user.id).then(async (grp) => {
        //    await reprocessStats(grp.groupid);
        //})
        props.onclosemodal();
    }

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Modal onClose={closemodal} isOpen={isOpen} size={'full'} >
                <ModalOverlay />
                <ModalContent bg={drawerBg}>
                    <ModalCloseButton onClick={closemodal} />
                    <ModalHeader>
                        <Button variant={'no-hover'} bg={ttype === "strategy" ? 'green.500' : 'red.500'} size={'lg'} mt='20px'>
                            <Icon as={ttype === "strategy" ? BsClipboardData : TbFaceIdError} boxSize={8} ms='20px' me='20px' />
                            <Text me='20px'>{title}</Text>
                        </Button>
                    </ModalHeader>
                    <ModalBody>
                        <Flex direction={'column'}>
                            <Box hidden={!trades.length} w="100%" mt="10px">
                                <Show breakpoint='(min-width: 1800px)'>
                                    <MinimalStatBarLarge loading={statload} stats={stats} />
                                </Show>
                                <Show breakpoint='(max-width: 1799px)'>
                                    <MinimalStatBar loading={statload} stats={stats} />
                                </Show>
                            </Box>
                            <Flex>
                                <TableCard title='Tagged trades' table={<StrategyTradesTable trades={trades} type={ttype} id={smid} closemodal={closemodal} />} showTitleBar={true} showBg={true} showMt={true} showPadding={true} loading={false} minh={'auto'} />
                            </Flex>
                        </Flex>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
})

export default StrategyTradesModal