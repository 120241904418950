import {
    Badge, Flex, FormControl, FormErrorMessage, Input,
    NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper,
    Select, Text, useColorModeValue
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useState, useEffect, ChangeEvent } from "react";
import { db } from "services/db";
import { currencyformat } from "views/trader/trades/variables/FormatData";
import StockModal from "../../modals/StockModal";
import { useData } from "services/data/DataProvider";

type Option = {
    label: string;
    value: string;
};

export const TableCell = (props: { getValue: any, row: any, column: any, table: any }) => {

    const {dateformat} = useData();
    
    const initialValue = props.getValue();
    const columnMeta = props.column.columnDef.meta;
    const colname: string = props.column.columnDef.meta.name;
    const tableMeta = props.table.options.meta;
    const [value, setValue] = useState(initialValue);
    const initError = (initialValue === "" || initialValue === null);
    const [isDateError, setisDateError] = useState(initError);
    const [isNumberError, setIsNumberError] = useState(initError);
    const [isSelectError, setIsSelectError] = useState(initError);

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);


    const formatdate = (date: string) => {
        return dayjs(date).format(dateformat)//new Date(date).toLocaleString();
    }

    const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
        if (columnMeta?.name === "datetime") {
            const date = new Date(e.target.value);
            const today = new Date();
            setisDateError(date.getTime() >= today.getTime());
            tableMeta?.updateData(props.row.index, props.column.id, value, date.getTime() <= today.getTime()); //.toISOString().slice(0, 16)
        }
        else {
            tableMeta?.updateData(props.row.index, props.column.id, value, e.target.validity.valid);
            setIsNumberError(!e.target.validity.valid);
        }
    };

    const onSelectChange = async (e: ChangeEvent<HTMLSelectElement>) => {
        setValue(e.target.value);
        tableMeta?.updateData(props.row.index, props.column.id, e.target.value, e.target.validity.valid);
        setIsSelectError(!e.target.validity.valid);

        if (colname === 'instrument') {

            localStorage.setItem("instrument", e.target.value);

            if (e.target.value === '') {
                props.table.getColumn('ticker').columnDef.meta.options = [];
            }
            else {
                let filtered = await db.instruments.where({ category: localStorage.getItem("instrument") }).toArray();

                filtered.sort((a, b) => a.ticker.toLowerCase().localeCompare(b.ticker.toLowerCase())).sort((a, b) => b.relevance - a.relevance)
                let arr: any[] = [];
                arr.push({ value: '', label: 'Select' });
                let count = 0;

                filtered.map((value: any) => {
                    if (value.relevance > 0) {
                        arr.push({ value: value.ticker, label: value.ticker });
                    }
                    else {
                        if (count < 1) {
                            count++;
                            arr.push({ value: '', label: '__________' })
                            arr.push({ value: value.ticker, label: value.ticker });
                        }
                        else {
                            arr.push({ value: value.ticker, label: value.ticker });
                        }
                    }
                })
                props.table.getColumn('ticker').columnDef.meta.options = arr;
            }
        }
        if (colname === 'ticker') {
            localStorage.setItem("ticker", e.target.value);
        }
    };

    const selectDisabled = () => {
        if (!props.row.original.editInstument) {
            if (columnMeta?.name === "instrument" || columnMeta?.name === "ticker") {
                return true;
            }
            return false;
        }
        else {
            return false;
        }
    }

    if (tableMeta?.editedRows[props.row.id]) {
        if (columnMeta?.type === "select") {
            return <Flex direction={'row'}>
                <FormControl isInvalid={isSelectError}>
                    <Select color={textColorPrimary} onChange={onSelectChange} value={initialValue} required={columnMeta?.required} disabled={selectDisabled()}>
                        {columnMeta?.options?.map((option: Option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>

                </FormControl>
                <StockModal hidden={props.row.original.instrument !== 'STOCK' || columnMeta?.name !== "ticker"} />
                {/*<Button size={'md'} borderRadius={'5px'} hidden={props.row.original.instrument !== 'STOCK' || columnMeta?.name !== "ticker"} borderLeftRadius={'0px'}><Icon as={Search2Icon}/></Button>*/}
            </Flex>
        }
        else {
            if (colname === 'datetime') {
                return <Flex>
                    <FormControl isInvalid={isDateError}>
                        <Input variant="auth" color={textColorPrimary} value={value} onChange={(e) => setValue(e.target.value)} onBlur={onBlur} min={columnMeta?.min} type={columnMeta?.type || "text"} required={columnMeta?.required} pattern={columnMeta?.pattern} />
                        {isDateError ? (
                            <FormErrorMessage>Future date entered</FormErrorMessage>
                        ) : (
                            null
                        )}
                    </FormControl>
                </Flex>
            }
            else {
                return <Flex>
                    <FormControl isInvalid={isNumberError}>
                        <NumberInput variant="outline" allowMouseWheel={true} onChange={(valueString) => setValue(Number(valueString))} defaultValue={value} min={columnMeta?.min} onBlur={onBlur} isRequired={columnMeta?.required}>
                            <NumberInputField color={textColorPrimary} />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                </Flex>
            }
        }
    }
    return <Flex>
        {columnMeta?.display === "badge" ? (
            <Badge
                colorScheme={value.toLocaleLowerCase() === "sell" ? 'red' : 'green'}
                color={value.toLocaleLowerCase() === "sell" ? 'red.500' : 'green.500'}
                fontSize='sm'
                fontWeight='600'
                hidden={value === ''}
            >
                {value.toUpperCase()}
            </Badge>
        ) : (
            <Text color={textColorPrimary} fontSize='sm' fontWeight='600'>{colname === 'fee' ? currencyformat(value, props.row.original.currency) : (colname === 'datetime' ? formatdate(value) : value)}</Text>
        )}
    </Flex>;
};
