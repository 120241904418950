import {
    Box,
    Flex,
    Image,
    Heading,
    Stack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon
} from "@chakra-ui/react";

import FadeInSection from "layouts/landing/sections/FadeInSection";
import "layouts/landing/sections/fadeffect.css"
import { BsCircleFill } from "react-icons/bs";

const HeroList = (props: { title: string, features: string[], left: boolean, image: string, minH: string, mt?: string, mb?: any }) => {

    const textColor = useColorModeValue('gray.700', 'gray.200');
    const textColorSecondary = useColorModeValue('gray.600', 'gray.400');

    return (
        <FadeInSection>
            <></>
            <Flex mt={props.mt} mb={props.mb} position={'relative'} align="center" maxW={{ xl: "1200px" }} justify={{ base: "center", md: "space-around", xl: "space-between" }} direction={{ base: "column", md: "row" }} wrap="nowrap" minH={props.minH} >
                {!props.left && <Box w={{ base: "80%", sm: "60%", md: "50%" }} mt={{ base: 12, md: 0 }}>
                    <Image src={props.image} />
                </Box>}
                <Stack spacing={6} w={{ base: "80%", md: "40%" }} align={["center", "center", "flex-start", "flex-start"]}>
                    <Heading as="h1" size="lg" fontWeight="bold" color={textColor} textAlign={["center", "center", "left", "left"]}>
                        {props.title}
                    </Heading>
                    <List spacing={1} justifyContent='flex-start' >
                        {props.features.map((feature, index) => (
                            <ListItem
                                key={index}
                                display='flex'
                                textAlign='start'
                                fontSize='md'
                                fontWeight='500'
                                color={textColorSecondary}
                                alignItems='center'
                                lineHeight='100%'
                                mt='10px !important'>
                                <ListIcon w='10px' h='10px' as={BsCircleFill} my='auto' color={textColor} />
                                {feature}
                            </ListItem>
                        ))}{' '}
                    </List>
                </Stack>
                {props.left && <Box w={{ base: "80%", sm: "60%", md: "50%" }} mt={{ base: 12, md: 0 }}>
                    <Image src={props.image} />
                </Box>}
            </Flex>
        </FadeInSection>
    );
}

export default HeroList
