
import {
    useDisclosure,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useToast,
    Icon,
    Text
} from '@chakra-ui/react'

import { useRef } from "react";
import { MdClose } from 'react-icons/md';
import api from 'services/api';
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';


const DeleteGroupConfirm = (props: { groupid: number, hidden: boolean }) => {

    const {muuid} = useAuth();
    const toast = useToast();

    const deletegroup = async () => {

        await db.accountgroups.get(props.groupid).then(async (grp) => {
            await db.trades.where({ groupid: grp.groupid }).count().then(async (t) => {
                await db.accounts.where({ groupid: grp.groupid }).count().then(async(a) => {
                    if (a === 0 && t === 0) {

                        delete grp.sync_due;
                        grp.last_uuid = muuid;

                        await api.post('/accounts/deletegroup', { group: grp})
                            .then(async (res) => {
                                if (res.status === 200) {

                                    if (res.data >= 0) {
                                        if (res.data > 0) {
                                            await db.accountgroups.update(res.data, { defaultgrp: true }).catch((err) => { })
                                        }
                                        await db.accountgroups.delete(grp.groupid);
                                        onClose();
                                        toast({ title: 'Group deleted ...', description: "", status: 'success', position: 'top', duration: 2000, isClosable: false })
                                    }
                                    else {
                                        toast({ title: 'Oops, server error', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false })
                                    }
                                }
                            }).catch(function (error) {
                                onClose();
                                toast({ title: 'Unable to delete group', description: "Verify your network connection ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                            });
                    }
                    else {
                        toast({ title: 'Cannot delete ...', description: "There are still trades or accounts in this group ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                    }
                })
            })
        })
    }

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    return (
        <>
            <Button hidden={props.hidden} variant='outline' onClick={onOpen} borderLeftRadius='0px' borderRightRadius={'10px'}>
                <Icon as={MdClose} boxSize={5} color={'red.500'}/>
            </Button>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} motionPreset='slideInBottom'>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete trading group
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Text mb='20px'>There are no accounts in this group, so it is safe to delete.</Text>
                            <Text mb='10px' fontWeight={'700'}>Are you sure?</Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={deletegroup} ml={3}>
                                Yes, Delete!
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default DeleteGroupConfirm