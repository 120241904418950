import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, FormControl, FormErrorMessage, Input, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { ChangeEvent, useState } from 'react'

const TemplateTitleInput = (props: { hidden: boolean, updateTitle: any, notes: string, size: string }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const textColor = useColorModeValue('gray.700', 'white')
    const [title, setTitle] = useState('');
    const [errormsg, setErrorMsg] = useState("");

    const toast = useToast();

    const updateTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
        if (e.target.value.trim().length > 20) {
            setErrorMsg("Max 20 characters");
        }
        else if (!e.target.value.trim().length) {
            setErrorMsg("Required");
        }
        else {
            setErrorMsg("");
        }
    }
    const submitText = () => {
        if (!titleInValid()) {
            props.updateTitle(title);
            onClose();
            setTitle("");
        }
    }
    const cancelText = () => {
        props.updateTitle("");
        onClose();
        setTitle("");
    }
    const titleInValid = () => {
        return title.trim().length > 20 || !title.length;
    }
    const openInput = () => {
        if (props.notes.trim().length > 0) {
            onOpen();
        }
        else {
            toast({ title: 'Enter some text ...', description: "", status: 'warning', position: 'top', duration: 2000, isClosable: false });
        }
    }

    return (
        <>
            <Button size={props.size} borderRadius={'5px'} ms={'3px'} borderWidth='1px' onClick={openInput} hidden={props.hidden}>
                Save
            </Button>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Name your template ...
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <FormControl isInvalid={titleInValid()}>
                                <Input size={'md'} color={textColor} onChange={updateTitle} value={title} ></Input>
                                <FormErrorMessage>{errormsg}</FormErrorMessage>
                            </FormControl>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={cancelText}>
                                Cancel
                            </Button>
                            <Button colorScheme='blue' onClick={submitText} ml={3}>
                                Save
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default TemplateTitleInput