import { Flex, Image } from '@chakra-ui/react'
import logoicon from 'assets/icons/logo1.png';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import './splash.css'

const SplashScreen = (props: { loading: boolean }) => {

    const [splashClass, setSplashClass] = useState("app-splash-screen");
    const [animation, setAnimation] = useState("flip")

    useEffect(() => {
        if (props.loading) {
            setSplashClass("app-splash-screen");
        }
        else {
            setAnimation("zoomout")
            setSplashClass("app-splash-screen splash-hidden");
        }
    }, [props.loading])

    useEffect(()=>{
        setTimeout(() => {
            setAnimation("pulse")
        }, 1000);
    },[])

    return (
        <>
            <Card w="100%" h='100%' className={splashClass}>
                <Flex justify={'center'} align={'center'} className="app-splash-content">
                    <Image src={logoicon} className={animation} />
                </Flex>
            </Card>
        </>
    )
}

export default SplashScreen