// Chakra imports
import { Avatar, Box, Button, Flex, Heading, Icon, IconButton, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { FiInstagram } from 'react-icons/fi';
import { TbBrandYoutubeFilled, TbUsers } from 'react-icons/tb';
import { SlGlobe } from "react-icons/sl";
import MentorRequestConfirm from 'views/trader/components/confirm/MentorRequestConfirm';
import { useState } from 'react';

export default function MentorBannerView(props: {
  avatar: string;
  name: string;
  username: string;
  description: string;
  strategies: string;
  s_show: boolean;
  trades: string;
  t_show: boolean;
  journals: string;
  j_show: boolean;
  x_url: string;
  y_url: string;
  i_url: string;
  w_url: string;
  mid: number;
  status: string;
  [x: string]: any;
}) {

  const {avatar, name, username, description, strategies, s_show, trades, t_show, journals, j_show, x_url, y_url, i_url, w_url, mid, status, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important',
  );
  const bgcolor = useColorModeValue('white', 'navy.800');

  const [mstatus, setMStatus] = useState(status);

  const openSocial = (url: string, soc: string) => {
    switch (soc) {
      case 'x': window.open("https://x.com/" + url, '_blank'); break;
      case 'yt': window.open("https://youtube.com/" + url, '_blank'); break;
      case 'ig': window.open("https://instagram.com/" + url, '_blank'); break;
      case 'web': window.open("https://" + url, '_blank'); break;
    }
  }
  const updatestatus = (st: string) =>{
    setMStatus(st);
  }


  return (
    <Card mb={{ base: '0px', lg: '20px' }} alignItems="center" {...rest}>
      <Box bgGradient="linear(to-b, brand.400, brand.600)" bgSize="cover" borderRadius="16px" h="90px" w="100%">
        <Flex w='100%' justify={'center'}>
          <IconButton hidden={!x_url.trim().length} aria-label={''} bg='transparent' icon={<Icon as={FaSquareXTwitter} boxSize={7} color={'white'} />} onClick={() => openSocial(x_url, 'x')} />
          <IconButton hidden={!y_url.trim().length} aria-label={''} bg='transparent' icon={<Icon as={TbBrandYoutubeFilled} boxSize={7} color={'white'} />} onClick={() => openSocial(y_url, 'yt')} />
          <IconButton hidden={!i_url.trim().length} aria-label={''} bg='transparent' icon={<Icon as={FiInstagram} boxSize={7} color={'white'} />} onClick={() => openSocial(i_url, 'ig')} />
          <IconButton hidden={!w_url.trim().length} aria-label={''} bg='transparent' icon={<Icon as={SlGlobe} boxSize={7} color={'white'} />} onClick={() => openSocial(w_url, 'web')} />
        </Flex>
      </Box>
      <Avatar mx="auto" src={avatar} h="87px" w="87px" mt="-43px" border="4px solid" borderColor={borderColor} />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm" fontWeight={'700'}>
        {username}
      </Text>
      <Card borderWidth={'1px'} w='75%' mt='30px' hidden={(!s_show && !t_show && !j_show) || (Number(strategies)<1 && Number(trades)<1 && Number(journals)<1 )}>
        <Flex justify={'center'}>
          <Heading size={'xs'} mt='-30px' bg={bgcolor} px='10px' color={textColorSecondary}>Shared</Heading>
        </Flex>
        <Flex w="100%" justify={'center'} direction={'row'} >
          <Flex mx="auto" align="center" direction="column" hidden={!s_show || Number(strategies) === 0}>
            <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
              {strategies}
            </Text>
            <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
              Strategies
            </Text>
          </Flex>
          <Flex mx="auto" align="center" direction="column" hidden={!t_show || Number(trades) === 0} >
            <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
              {trades}
            </Text>
            <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
              Trades
            </Text>
          </Flex>
          <Flex mx="auto" align="center" direction="column" hidden={!j_show || Number(journals) === 0}>
            <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
              {journals}
            </Text>
            <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
              Journals
            </Text>
          </Flex>
        </Flex>
      </Card>

      <Flex w="100%" mt="35px" mb='20px' justify={'center'}>
        <Box textAlign={'center'}>
          <Text color={textColorSecondary}>{description}</Text>
        </Box>
      </Flex>

      <Flex w="100%" justify={'center'}>
        {mstatus === '' ? (
          <MentorRequestConfirm mentorid={mid} setstatus={updatestatus}/>
        ) : (
          <Button variant={'outline'} colorScheme={'red'} isDisabled={true}><Icon as={TbUsers} boxSize={5} me='10px' />
            Mentorship {mstatus}
          </Button>
        )}

      </Flex>
    </Card>
  );
}
