import dayjs from 'dayjs';
import { db } from 'services/db';
import { AccountExecutions, newExecution } from 'views/trader/trades/variables/types';
//import { read, utils } from 'xlsx';
import { read, utils } from '@e965/xlsx';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export function getNinjaTrades(f: File): Promise<AccountExecutions[][]> {

    dayjs.extend(utc)
    dayjs.extend(timezone);

    return new Promise(async (resolve, reject) => {

        try {
            const ab = await f.arrayBuffer();
            const wb = read(ab);
            const ws = wb.Sheets[wb.SheetNames[0]];
            const data: any[] = utils.sheet_to_json(ws);

            //const accountarr: AccountExecutions[] = [];
            const accountarr: AccountExecutions[][] = [[], []];

            for (let e of data) {

                let i: string[] = e.Instrument.split(0, e.Instrument.indexOf(' '));
                let inst = await db.instruments.where("ticker").equalsIgnoreCase(i[0].trim()).toArray();
                // convert excel date, and display time of trade in original location
                let d = new Date(Math.round((e.Time - 25569) * 86400 * 1000)).toISOString().slice(0, -1);

                if (inst.length) { // IE if instruments supported/exists
                    const ex = newExecution();

                    ex.datetime = dayjs(d).format('YYYY-MM-DD HH:mm:ss');
                    ex.instrument = inst[0].category;
                    ex.ticker = i[0].trim();
                    ex.side = e.Action.toLowerCase().trim();
                    ex.size = Number(e.Quantity);
                    ex.price = Number(e.Price);
                    //let newStr = e.Commission.replace(/[^\d.]/g, '');
                    ex.fee = Number(e.Commission.replace(/[^\d.]/g, '')); // removes currency symbol from the commission
                    ex.currency = inst[0].currency;
                    ex.contract = (i.length > 1 ? i[1].trim() : '');
                    ex.orderid = e['Order ID'].toString().trim();

                    const acc = accountarr[0].find((a) => (a.account === e.Account));
                    if (acc) {
                        acc.executions.push(ex);
                    }
                    else {
                        let a: AccountExecutions = { account: e.Account, executions: [] };
                        a.executions.push(ex);
                        accountarr[0].push(a);
                    }
                }
                else {
                    const ex = newExecution();

                    ex.datetime = dayjs(d).format('YYYY-MM-DD HH:mm:ss');
                    ex.instrument = '';
                    ex.ticker = i[0].trim();
                    ex.side = e.Action.toLowerCase().trim();
                    ex.size = Number(e.Quantity);
                    ex.price = Number(e.Price);
                    ex.fee = Number(e.Commission.replace(/[^\d.]/g, '')); // removes currency symbol from the commission
                    ex.currency = 'USD';
                    ex.contract = (i.length > 1 ? i[1].trim() : '');
                    ex.orderid = e['Order ID'].toString().trim();

                    const acc = accountarr[1].find((a) => (a.account === e.Account));
                    if (acc) {
                        acc.executions.push(ex);
                    }
                    else {
                        let a: AccountExecutions = { account: e.Account, executions: [] };
                        a.executions.push(ex);
                        accountarr[1].push(a);
                    }
                }
            }

            resolve(accountarr);
        }
        catch (err) {
            reject(err);
        }
    });
}
