/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
																																																																																	   
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
	Box,
	Flex,
	Show
} from '@chakra-ui/react';

// Custom components
import StatBar from '../components/display/StatBar';
import TradesTable from 'views/trader/trades/components/tables/TradesTable';
import TableCard from './components/tables/TableCard';
import { db } from 'services/db';
import { useEffect, useRef } from 'react';
import StatBarLarge from '../components/display/StatBarLarge';
import UploadManualDrawer from './components/modals/UploadManualDrawer';
import { SyncAPI } from '../components/syncapi/SyncApi';
import { useAuth } from 'services/auth/AuthProvider';
import { useLoading } from '../../../services/loading/LoadingProvider';

export default function Trades() {

	const { user, setUser } = useAuth();
	const {loading, setLoading} = useLoading();
	const windowRef = useRef(null);

	useEffect(() => {
		setLoading(true);
		SyncAPI(user.id).then(async (res) => {
			setLoading(false);
			await db.users.get(user.id).then((u) => {
				setUser(u);
			})
		});
	}, [])

	return (

		<Flex pt={{ base: '130px', md: '80px', xl: '80px' }} direction="column" ref={windowRef} scrollMarginTop={'50px'}>
			<Box w="100%" mb="20px">
				<Show breakpoint='(min-width: 2400px)'>
					<StatBarLarge></StatBarLarge>
				</Show>
				<Show breakpoint='(max-width: 2399px)'>
					<StatBar />
				</Show>
			</Box>
			<Flex>
				<TableCard title='Trades' table={<TradesTable/>} button={<UploadManualDrawer />} showTitleBar={true} showBg={true} showMt={false} showPadding={true} loading={loading} minh={'auto'} />
			</Flex>
		</Flex>
	);
}

