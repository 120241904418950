
import {
    useDisclosure,
    Button,
    Icon,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
    DrawerBody,
    DrawerFooter,
    Flex,
    Text,
    useToast,
    InputGroup,
    InputLeftAddon,
    Select,
    useColorModeValue
} from "@chakra-ui/react";

import { MdAdd, MdClose, MdOutlineInsights } from 'react-icons/md';

import TableCard from "../tables/TableCard";
import { TradesTable } from '../tables/tradesuploadstable';
import { Account, AccountExecutions, AccountGroup, Execution, Trade } from '../../variables/types';
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { db } from "services/db";
import api from "services/api";
import UploadBrokerModal from "./UploadBrokerModal";
import { NewAccountModal } from "./AccountModal";
import uuid from 'react-uuid'
import { Container as ModalContainer } from "react-modal-promise";
import { BrokerInput } from "./BrokerStatements/BrokerInput";
import dayjs, { Dayjs } from "dayjs";
import FullScreenButton from "views/trader/components/FullScreenButton";
import { CommissionInput } from "views/trader/components/inputs/CommissionInput";
import { setDefaultAccount, setDefaultGroup } from "services/dbservice";
import { StoplossInput } from "views/trader/components/inputs/StoplossInput";
import useNewAccount from "../../hooks/useNewAccount";
import { useAuth } from "services/auth/AuthProvider";
import TickerModal from "./TickerModal";
import UserInstrumentModal from "views/trader/components/UserInstrumentModal";
import { SubscriptionLevelCheck } from "views/trader/subscription/components/SubscriptionLevelCheck";
import { useData } from "services/data/DataProvider";
import UploadCSVModal from "./UploadCSVModal";

const UploadManualDrawer = () => {

    const bgColor = useColorModeValue('gray.500', 'gray.600');
    const btnVariant = useColorModeValue('outline', 'outline');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { user, muuid, allowaccountadd, maxWeeklyTrades } = useAuth();
    const { accounts, accountgroups, defaultgroup } = useData();

    const [file, setFile] = useState<File>(null);
    const [tableData, setTableData] = useState<Trade[]>([]);
    const [processing, setprocessing] = useState(false);
    const [processfile, setProcessfile] = useState(false);
    const [manual, setManual] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const acctoast = 'acctoast';
    const tableRef = useRef(null);
    const tickerModalRef = useRef(null);
    const [selectedAccount, setSelectedAccount] = useState<Account>(null);
    const [groupid, setGroupid] = useState(0);
    const [t_platform, setT_platform] = useState("");
    const forexapi = 'api.frankfurter.app';
    const rates: { base: string, rates: any, updated: Dayjs }[] = [];
    const [fees, setFees] = useState<{ ticker: string, fee: number }[]>([]);
    const [stops, setStops] = useState<{ ticker: string, stop: number }[]>([]);
    const newaccount = useNewAccount();
    const commid = 'commission_toast';

    const openDrawer = async () => {
        setLoading(true);

        await SubscriptionLevelCheck(user.id, user.role).then((resp => {
            if (resp.trim() !== "") {
                toast({ title: 'Please cleanup your profile ...', description: <Text whiteSpace="pre-line">{"\n" + resp.trim() + "\n\n"}</Text>, status: 'error', position: 'top', duration: null, isClosable: true })
            }
            else {
                onOpen();
            }
        }))

        setLoading(false);
    }

    useEffect(() => {
        if (defaultgroup) {
            setGroupid(defaultgroup.groupid);
        }
    }, [defaultgroup])

    const closeDrawer = () => {
        setT_platform("");
        setUploadFile(null);
        setSelectedAccount(null);
        setManual(false);
        setFees([]);
        setStops([]);
        setTableData([]); // clear trades table
        onClose();
    }

    const setUploadFile = (f: File) => {
        setFile(f);
    }
    const setBrokerPlatform = (p: string) => {
        setT_platform(p);
    }

    const clearImport = () => {
        setT_platform("");
        setUploadFile(null);
        setSelectedAccount(null);
        let s = (document.getElementById('accountselect') as HTMLInputElement);
        s.value = "";

        setFees([]);
        setStops([]);
        tableData.length = 0;//.splice(0, tableData.length); // remove all array items
        tableRef.current.updateTable();
        setProcessfile(false);
    }

    const processStatement = async () => {

        setProcessfile(true);

        await BrokerInput(t_platform, file).then(async (res: { trades: Trade[], unfound: AccountExecutions[] }) => {
            if (!res.trades.length && !res.unfound.length) {
                toast({ title: 'Error processing trades ...', description: "Make SURE you are uploading the correct platfrom file", status: 'error', position: 'top', duration: 5000, isClosable: false })
                clearImport();
            }
            else {
                if (res.trades.length) {
                    await processTrades(res.trades).then((tr) => {
                        tableData.length = 0;
                        tableData.push(...tr);
                        setTableData(tableData);
                        tableRef.current.updateTable();
                        setProcessfile(false);
                    })
                }
                if (res.unfound.length) {
                    toast({ title: 'Some symbols not found ...', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false })
                    tickerModalRef.current.openModal(res.unfound);
                }
            }
        })
    }

    /////////////
    const processCSVImport = async (brokerid: string) => {

        setProcessfile(true);

        await BrokerInput(t_platform, file, brokerid).then(async (res: { trades: Trade[], unfound: AccountExecutions[] }) => {
            if (!res.trades.length && !res.unfound.length) {
                toast({ title: 'Error processing trades ...', description: "Make SURE you are uploading the correct platfrom file", status: 'error', position: 'top', duration: 5000, isClosable: false })
                clearImport();
            }
            else {
                if (res.trades.length) {
                    await processTrades(res.trades).then((tr) => {
                        tableData.length = 0;
                        tableData.push(...tr);
                        setTableData(tableData);
                        tableRef.current.updateTable();
                        setProcessfile(false);
                    })
                }
                if (res.unfound.length) {
                    toast({ title: 'Some symbols not found ...', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false })
                    tickerModalRef.current.openModal(res.unfound);
                }
            }
        })
    }
    ////////////

    const processNewTickers = async (trades: Trade[]) => {
        setProcessfile(true);
        if (trades.length) {
            await processTrades(trades).then((tr) => {
                tableData.push(...tr);
                setTableData(tableData);
                tableRef.current.updateTable();
                setProcessfile(false);
                toast({ title: 'Tickers added and trades updated...', description: "", status: 'info', position: 'top', duration: 3000, isClosable: false })
            })
        }
    }

    async function processTrades(trades: Trade[]): Promise<any> {
        return new Promise(async (resolve, reject) => {

            let bids: string[] = [];

            for (let tr of trades) {
                tr.userid = user.id;
                const bid = tr.brokerid.trim();
                if (!bids.includes(bid)) {
                    bids.push(bid);
                }
            }

            for (let brid of bids) {
                let acc = accounts.filter((a: Account) => a.brokerid === brid);
                if (acc.length) {
                    await removeDuplicates(trades, acc[0].accountid).then(async (trades2) => {
                        await updateTradeAccounts(trades2, acc[0]).then(async (trades3) => {
                            await updateFees(trades3).then(async (trades4) => {
                                await updateStops(trades4).then(async (trades5) => {
                                    await setExchange(trades5, acc[0]).then((trades6) => {
                                        trades = trades6;
                                    })
                                })
                            })
                        })
                    })
                }
                else {
                    if (t_platform !== 'tview') {
                        await addAccount(brid).then(async (acc) => {
                            if (acc !== undefined) {
                                await updateTradeAccounts(trades, acc).then(async (trades7) => {
                                    await updateFees(trades7).then(async (trades8) => {
                                        await updateStops(trades8).then(async (trades9) => {
                                            await setExchange(trades9, acc).then((trades10) => {
                                                trades = trades10;
                                            })
                                        })
                                    })
                                })
                            }
                            else {
                                trades = trades.filter((t) => t.brokerid !== brid);
                            }
                        });
                    }
                }
            }
            resolve(trades);
        });
    }

    async function addAccount(bid: string): Promise<any> {

        return new Promise(async (resolve) => {
            await allowaccountadd().then(async (r: boolean) => {
                if (r) {
                    toast({ title: 'New account detected ...', description: "", status: 'info', position: 'top', duration: 3000, isClosable: false })

                    let a = newaccount;
                    a.brokerid = bid;
                    a.platform = t_platform;
                    localStorage.setItem("newaccount", JSON.stringify(a)); // used in account modal

                    try {
                        let newacc = await NewAccountModal({ isOpen });
                        //updateTradeAccounts(newacc);
                        resolve(newacc);
                    }
                    catch (err) {
                        removeAccountTrades(err);
                        resolve(undefined);
                        toast({ title: 'Account creation cancelled ...', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false })
                    }
                    localStorage.removeItem("newaccount");
                }
                else {
                    if (!toast.isActive(acctoast)) {
                        toast({ id: acctoast, title: 'Maximum accounts reached ...', description: "Either delete an existing trading account or upgrade your subscription to create more trading accounts", status: 'error', position: 'top', duration: null, isClosable: true });
                    }
                    removeAccountTrades(bid);
                    resolve(undefined);
                }
            })
        })
    }

    async function removeDuplicates(tds: Trade[], accountid: number): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let duplicates = false;
            await db.trades.where({ userid: user.id, accountid: accountid }).toArray().then((trades) => {
                trades.forEach((t) => {
                    for (let ie = 0; ie < t.executions.length; ie++) {
                        for (let i = 0; i < tds.length; i++) {
                            for (let ex of tds[i].executions) {
                                if (ex.orderid === t.executions[ie].orderid) {
                                    tds.splice(i, 1);
                                    duplicates = true;
                                }
                            }
                        }
                    }
                })
            })
            if (!tds.length) {
                toast({ title: 'No new trades to import ..', description: "", status: 'info', position: 'top', duration: 3000, isClosable: false })
                clearImport();
            }
            else if (duplicates) {
                toast({ title: 'Duplicates removed ..', description: "", status: 'info', position: 'top', duration: 3000, isClosable: false })
            }
            resolve(tds);
        });
    }

    const addAccountClick = async () => {

        allowaccountadd().then(async (r: boolean) => {
            if (r) {
                let a = newaccount;
                a.platform = t_platform;
                a.brokerid = uuid();
                a.currency = "";
                localStorage.setItem("newaccount", JSON.stringify(a)); // used in account modal

                let s = (document.getElementById('accountselect') as HTMLInputElement);

                try {
                    let newacc = await NewAccountModal({ isOpen });
                    setSelectedAccount(newacc);
                    setGroupid(newacc.groupid);
                    s.value = newacc.accountid;

                    for (let tr of tableData) {
                        tr.accountid = newacc.accountid;
                        tr.groupid = newacc.groupid;
                    }
                    await updateFees(tableData).then(async (t2) => {
                        await updateStops(t2).then(async (t3) => {
                            await setExchange(t2, newacc).then((t4) => {
                                setTableData(t4)
                                tableRef.current.updateTable();
                            })
                        })
                    });

                    toast({ title: 'Account added ...', description: "", status: 'success', position: 'top', duration: 3000, isClosable: false })
                } catch (error) {
                    s.value = "";
                }
                localStorage.removeItem("newaccount");
            }
            else {
                toast({ title: 'Maximum accounts reached ...', description: "Either delete an existing trading account or upgrade your subscription to create more trading accounts", status: 'error', position: 'top', duration: 3000, isClosable: true });
            }
        })
    }

    async function setExchange(trades: Trade[], acc: Account): Promise<any> {
        return new Promise(async (resolve, reject) => {
            for (let tr of trades) {
                if (tr.accountid === acc.accountid && tr.currency !== acc.currency) {
                    let r = rates.filter((r) => r.base === acc.currency);
                    if (!r.length) {
                        await getExchange(acc.currency, tr.currency).then((ex) => {
                            tr.exchange = ex;
                        });
                    }
                    else {
                        try {
                            if (r[0].updated.isBefore(dayjs().subtract(1, 'day'))) {
                                await getExchange(acc.currency, tr.currency).then((ex) => {
                                    tr.exchange = ex;
                                });
                            }
                            else {
                                tr.exchange = r[0].rates[tr.currency];
                            }
                        }
                        catch (err) { tr.exchange = 1; }// exchange stays at default of 1
                    }
                }
            }
            resolve(trades);
        });
    }
    async function getExchange(basecurr: string, currency: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                return fetch('https://' + forexapi + '/latest?from=' + basecurr)
                    .then(resp => resp.json())
                    .then((data) => {
                        rates.push({ base: basecurr, rates: data.rates, updated: dayjs() });
                        resolve(data.rates[currency]);
                    }).catch((err) => {
                        resolve(1);
                    })
            }
            catch (err) {
                resolve(1);
            }
        });

    }
    async function updateTradeAccounts(trades: Trade[], a: Account): Promise<any> {
        return new Promise(async (resolve, reject) => {

            setGroupid(a.groupid);

            for (let tr of trades) {
                if (tr.brokerid === a.brokerid) {
                    tr.accountid = a.accountid;
                    tr.groupid = a.groupid;
                }
            }
            resolve(trades);
        });
    }

    async function updateFees(trades: Trade[]): Promise<any> {
        return new Promise(async (resolve, reject) => {

            for (let tr of trades) {

                const zfee = (e: Execution) => e.fee === 0;
                let zerofee = tr.executions.some(zfee);

                if (zerofee) {
                    let fee1 = fees.filter((f) => f.ticker === tr.ticker);
                    if (fee1.length) {
                        for (let ex of tr.executions) {
                            ex.fee = ex.size * fee1[0].fee;
                        }
                    }
                    else {
                        if (tr.executions[0].instrument === "FUT") {
                            await db.instruments.where({ ticker: tr.ticker }).toArray().then(async (i) => {
                                localStorage.setItem('comm', JSON.stringify({ instrument: i[0], platform: t_platform }));
                                try {
                                    await CommissionInput(isOpen).then((fee2) => {
                                        fees.push({ ticker: tr.ticker, fee: fee2 });
                                        for (let ex of tr.executions) {
                                            ex.fee = ex.size * fee2;
                                        }
                                    })
                                    if (!toast.isActive(commid)) {
                                        //toast({ id: commid, title: 'Commissions updated', description: "", status: 'success', position: 'top', duration: 3000, isClosable: false })
                                    }
                                }
                                catch (err) {
                                    toast({ title: 'Commissions not updated', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false })
                                    fees.push({ ticker: tr.ticker, fee: 0 });
                                }
                                localStorage.removeItem('comm')
                            });
                        }
                    }
                }
            }
            for (let tr of trades) {
                let fee = 0;
                for (let ex of tr.executions) {
                    fee += ex.fee;
                }
                tr.fees = fee;
                tr.nett = tr.profitloss - fee;
            }

            //localStorage.removeItem('comm');
            resolve(trades);
        });
    }
    async function updateStops(trades: Trade[]): Promise<any> {
        return new Promise(async (resolve, reject) => {
            for (let tr of trades) {
                let stopp = stops.filter((s) => s.ticker === tr.ticker);
                if (stopp.length) {
                    if (stopp[0].stop !== 0) {
                        tr.stop_price = tr.side.toLowerCase() === 'sell' ? tr.entry_avg + stopp[0].stop : tr.entry_avg - stopp[0].stop;
                        tr = await updateRfactor(tr);
                    }
                }
                else {
                    if (tr.ticker !== "") {
                        await db.instruments.where({ ticker: tr.ticker }).toArray().then(async (i) => {
                            localStorage.setItem('stopinstrument', JSON.stringify(i[0]));
                            try {
                                await StoplossInput(isOpen).then(async (stop) => {
                                    stops.push({ ticker: tr.ticker, stop: stop });
                                    tr.stop_price = tr.side.toLowerCase() === 'sell' ? tr.entry_avg + stop : tr.entry_avg - stop;
                                    ////// R-Value
                                    tr = await updateRfactor(tr);
                                    //////
                                })
                            }
                            catch (e) {
                                stops.push({ ticker: tr.ticker, stop: 0 });
                                tr.rvalue = 0;
                            }
                            localStorage.removeItem('stopinstrument')
                        }).catch((e) => { })
                    }
                }
            }
            resolve(trades);
        });
    }
    const updateRfactor = (tr: Trade): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            if (tr.stop_price > 0) {
                let stop_points;
                switch (tr.side.toUpperCase()) {
                    case 'BUY':
                        stop_points = tr.entry_avg - tr.stop_price;
                        break;
                    case 'SELL':
                        stop_points = tr.stop_price - tr.entry_avg;
                        break;
                }
                let R = (tr.points / tr.size) / stop_points;
                tr.rvalue = Number(R.toFixed(2));
            }
            else {
                tr.rvalue = 0;
            }
            resolve(tr);
        })
    }

    const removeAccountTrades = (brokerid: any) => {
        try {
            for (let i = 0; i < tableData.length; i++) {
                let tr = tableData[i];
                if (tr.brokerid === brokerid) {
                    tableData.splice(i, 1);
                    i--;
                }
            }
            tableRef.current.updateTable();
            if (!tableData.length) {
                clearImport();
            }
        }
        catch (err) { }
    }

    const updateButtons = () => {
        let m = (t: Trade) => t.accountid === 0;
        setManual(tableData.some(m)); // checks if any trade has accountid=0 and then breaks when true
        if (!tableData.length) {
            setSelectedAccount(null);
            let s = (document.getElementById('accountselect') as HTMLInputElement);
            s.value = "";
            setT_platform("");
        }
    }

    const addTrade = async () => {
        tableRef.current.addTrade();
        if (selectedAccount) {
            tableData[tableData.length - 1].accountid = selectedAccount.accountid; // add accountid
            tableData[tableData.length - 1].groupid = selectedAccount.groupid; // add groupid
            delete tableData[tableData.length - 1].brokerid; // remove brokerid
        }
        setT_platform('paper');
    }

    const changeSelectedAccount = async (e: ChangeEvent<HTMLSelectElement>) => {

        if (e.target.value !== "") {
            let a = accounts.filter((a: Account) => a.accountid === Number(e.target.value))

            setSelectedAccount(a[0]);
            setGroupid(a[0].groupid);

            await removeDuplicates(tableData, a[0].accountid).then((trades) => {
                setTableData(trades);
                tableRef.current.updateTable();
            });

            for (let tr of tableData) {
                tr.accountid = a[0].accountid;
                tr.groupid = a[0].groupid;
            }

            await updateStops(tableData).then(async (t3) => {
                await setExchange(t3, a[0]).then((t4) => {
                    setTableData(t4)
                    tableRef.current.updateTable();
                })
            })
        }
        else {
            setSelectedAccount(null);
            let s = (document.getElementById('accountselect') as HTMLInputElement);
            s.value = "";
            setT_platform("");
        }

    }

    const uploadTrades = async () => {
        setprocessing(true);

        if (tableData.length) {

            if (t_platform === 'tview' && !selectedAccount) {
                toast({ title: 'No account selected ...', description: "Select or add a new account ...", status: 'info', position: 'top', duration: 5000, isClosable: false })
                setprocessing(false);
                return;
            }
            if (!file && !selectedAccount) {
                toast({ title: 'No account selected ...', description: "Select or add a new account ...", status: 'info', position: 'top', duration: 5000, isClosable: false })
                setprocessing(false);
                return;
            }

            let tableDataOpen = tableData.filter((t: Trade) => t.closed === false);

            if (tableDataOpen.length) {
                setprocessing(false);
                toast({ title: 'Open trades ...', description: "Please complete or delete any open trades before uploading ...", status: 'warning', position: 'top', duration: 5000, isClosable: false })
                return;
            }

            for (let t of tableData) {
                delete t.sync_due; // only used locally
                delete t.brokerid;
                t.last_uuid = muuid;
            }

            await api.post('/trade/uploadtrades', { tableData })
                .then(async (res) => {
                    if (res.status === 200) {
                        if (res.data !== false) {

                            if (tableData.length > res.data.length && maxWeeklyTrades > 0) {
                                toast({ title: maxWeeklyTrades + ' weekly trades allowed ... ', description: <Text whiteSpace="pre-line">{"\nWe used your weekly quota but not all your trades were uploaded and saved.\n\nUpgrade your subscription to upload more weekly trades.\n\n"}</Text>, status: 'error', position: 'top', duration: null, isClosable: true })
                            }

                            for (let t of res.data) {
                                delete t.last_uuid;
                                t.sync_due = false;
                                t.base64Image = "";
                                db.trades.add(t).then(async () => {
                                    db.instruments.where({ ticker: t.ticker }).modify((inst) => { ++inst.relevance }).catch((e) => { });
                                }).catch(error => { });
                            }
                            setTableData([]); // clear trades table

                            setDefaultGroup(groupid, user.id).then(() => {
                                setDefaultAccount(tableData[0].accountid, groupid, user.id).catch(() => { });
                            }).catch(() => { });

                            setT_platform("");
                            setprocessing(false);
                            closeDrawer();
                        }
                        else {
                            toast({ title: 'Oops, server error ...', description: "Please try again in a few minutes ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                        }
                    }
                    else {
                        toast({ title: 'Upload failed', description: "Verify your network connection and try again ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                    }
                }).catch(function (error) {
                    setprocessing(false);
                    toast({ title: 'Upload failed', description: "Verify your network connection and try again ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                });
            setprocessing(false);
        }
        else {
            setprocessing(false);
            toast({ title: 'Nothing to upload ...', description: "Enter some trades to upload ...", status: 'info', position: 'top', duration: 3000, isClosable: false })
        }
    }

    return (
        <>
            <Flex align='center'>
                <Button onClick={() => openDrawer()} size='sm' w='190px' fontSize='sm' fontWeight='600' colorScheme="blue" variant={btnVariant} borderRadius='10px' borderWidth={'2px'} isLoading={loading}>
                    <Icon as={MdOutlineInsights} h='20px' w='20px' me='8px' />
                    UPLOAD Trades
                </Button>
            </Flex>
            <Drawer isOpen={isOpen} onClose={closeDrawer} size="full" placement='top'>
                <DrawerOverlay />
                <DrawerContent>
                    <ModalContainer />
                    <DrawerHeader>
                        <Flex direction={{ base: 'column', md: 'row' }} align='center'>
                            UPLOAD Trades
                            <DrawerCloseButton />
                            <FullScreenButton />
                        </Flex>
                    </DrawerHeader>
                    <DrawerBody>
                        <Flex direction={{ base: 'column', md: 'row' }}>
                            <Flex direction={'row'}>
                                <UploadBrokerModal clearimport={clearImport} setfile={setUploadFile} processfile={processStatement} setPlatform={setBrokerPlatform} hidden={!!file} />
                                <UploadCSVModal clearimport={clearImport} setfile={setUploadFile} processfile={processCSVImport} setPlatform={setBrokerPlatform} hidden={!!file} changeaccount={changeSelectedAccount} />
                            </Flex>
                            <Flex>

                                <Button hidden={!file || manual} mb='20px' isLoading={processfile} loadingText='Processing' size='sm' borderRadius='5px' rightIcon={<MdClose />} colorScheme='red' variant='outline' onClick={() => clearImport()}>Delete Statement</Button>
                                <Button hidden={!!file} size='sm' borderRadius='5px' ms='10px' mb='20px' rightIcon={<MdAdd />} colorScheme='blue' variant='solid' onClick={() => addTrade()}>ADD Trade</Button>
                            </Flex>

                            <Flex hidden={(!!file || !tableData.length) && t_platform !== 'tview'} mb='20px' direction={{ base: 'column', md: 'row' }}>
                                <InputGroup variant='outline' size='sm' ms={{ base: '0px', md: "10px" }} borderRadius="10px">
                                    <InputLeftAddon children="Account" borderLeftRadius='5px' fontWeight={'700'} bg={bgColor} color='white' />
                                    <Select id="accountselect" name="accountselect" onChange={changeSelectedAccount}>
                                        <option value="">Select account to use</option>
                                        {accountgroups.length && accountgroups.map((grp: AccountGroup, key: any) => {
                                            return <optgroup key={key} label={grp.groupname}>
                                                {accounts.map((acc: Account, key: any) => {
                                                    return acc.groupid === grp.groupid && <option key={acc.accountid} value={acc.accountid}>{acc.accname}</option>
                                                })}
                                            </optgroup>;
                                        })}
                                    </Select>
                                    <Button variant="outline" colorScheme="blue" borderRightRadius="5px" borderLeftRadius="0px" onClick={addAccountClick}>
                                        <Icon as={MdAdd} boxSize={5} />
                                    </Button>
                                </InputGroup>
                                <Flex mt={{ base: '20px', md: '0px' }}>
                                    <UserInstrumentModal islarge={false} />
                                </Flex>
                            </Flex>

                        </Flex>
                        <Flex maxW='1580px' hidden={!file && !selectedAccount}> {/*was !tableData.length*/}
                            <TableCard table={<TradesTable tradesData={tableData} ref={tableRef} updatebuttons={updateButtons} />} title={''} showTitleBar={false} showBg={false} showMt={false} showPadding={false} loading={false} minh={'auto'} />
                        </Flex>
                    </DrawerBody>
                    <DrawerFooter>
                        <Button variant="ghost" mr={3} onClick={closeDrawer}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={() => uploadTrades()} isLoading={processing} loadingText='Processing'>
                            Upload
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
                <TickerModal ref={tickerModalRef} postTrades={processNewTickers} />
            </Drawer>
        </>
    );
};

export default UploadManualDrawer;