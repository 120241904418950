import dayjs from "dayjs";
import { StatIntraday, StatMonthly, StatWeekday, Trade } from "views/trader/trades/variables/types";

export async function TimeSeriesData(trades: Trade[], currency: string): Promise<any> {
    return new Promise(async (resolve, reject) => {

        let intraday: StatIntraday[] = [];
        let weekdays: StatWeekday[] = [];
        let monthly: StatMonthly[] = [];
        let tickers: string[] = [];

        let hour = 0;
        while (hour < 24) {
            intraday.push({ hour: hour, ticker: [] })
            hour++;
        }
        let weekday = 0;
        while (weekday < 7) {
            weekdays.push({ day: weekday, ticker: [] })
            weekday++;
        }
        let month = 0;
        while (month < 12) {
            monthly.push({ month: month, ticker: [] })
            month++;
        }

        //// alert(JSON.stringify(weekdays));

        for (let tr of trades) {

            let i = tickers.findIndex((t) => t === tr.ticker);
            if (i < 0) {
                tickers.push(tr.ticker.trim());
            }

            let ex = (tr.currency !== currency ? tr.exchange : 1);
            let nett = (tr.nett / ex);

            let day = dayjs(tr.datetime);

            let iindex = intraday.findIndex((h) => h.hour === day.hour());
            let tindex = intraday[iindex].ticker.findIndex((t) => t.symbol.trim() === tr.ticker.trim());
            if (tindex > -1) {
                intraday[iindex].ticker[tindex].nett += nett;
            }
            else {
                intraday[iindex].ticker.push({ symbol: tr.ticker.trim(), nett: nett });
            }

            let windex = weekdays.findIndex((d) => d.day === day.day());
            let wtindex = weekdays[windex].ticker.findIndex((t) => t.symbol.trim() === tr.ticker.trim());
            if (wtindex > -1) {
                weekdays[windex].ticker[wtindex].nett += nett;
            }
            else {
                weekdays[windex].ticker.push({ symbol: tr.ticker.trim(), nett: nett });
            }

            let mindex = monthly.findIndex((m) => m.month === day.month());
            let mtindex = monthly[mindex].ticker.findIndex((t) => t.symbol.trim() === tr.ticker.trim());
            if (mtindex > -1) {
                monthly[mindex].ticker[mtindex].nett += nett;
            }
            else {
                monthly[mindex].ticker.push({ symbol: tr.ticker.trim(), nett: nett });
            }

            //intraday[index].nett += nett;
            //alert("day: " + day.format("HH:mm")+" HOUR: "+day.hour());
        }

        // alert(JSON.stringify(intraday));

        resolve({ tickers: tickers, day: intraday, week: weekdays, month: monthly });

    });
}