import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Icon, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import Card from 'components/card/Card'
import React, { useEffect } from 'react'
import { MdFullscreen } from 'react-icons/md'
import { drawdownChartData } from 'views/trader/trades/variables/types'
import DrawdownChart from '../charts/DrawdownChart'

const DrawdownDrawer = (props: { drawdownData: drawdownChartData, hidden: boolean }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    const drawerBg = useColorModeValue('white', 'navy.800');

    useEffect(() => {

    }, [])

    return (
        <>
            <Button variant='outline' onClick={onOpen} borderRadius={'5px'} size={'sm'} ms='5px' hidden={props.hidden}>
                <Icon as={MdFullscreen} boxSize={5} />
            </Button>
            <Drawer
                isOpen={isOpen}
                //placement='top'
                onClose={onClose}
                finalFocusRef={btnRef}
                size={'full'}
            >
                <DrawerOverlay />
                <DrawerContent bg={drawerBg} h='100%'>
                    <DrawerCloseButton />
                    <DrawerHeader>Drawdown</DrawerHeader>
                    <DrawerBody>
                        <Card h={'100%'} >
                            <DrawdownChart drawdownData={props.drawdownData} hidden={false} showtoolbar={true} height={'95%'} />
                        </Card>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default DrawdownDrawer