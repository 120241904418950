import { useColorModeValue } from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react'
import { StatStrat } from 'views/trader/trades/variables/types';

const WinGuage = (props: { hidden: boolean, stats: StatStrat }) => {

  const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  useEffect(() => {
    let p = Number(props.stats.wperc.toFixed());
    setVariables({ options: options, series: [p] });
  }, [props.stats])

  const options: ApexOptions = {
    chart: {
      type: 'radialBar',
      offsetY: -10
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          show: true,
          background: "#848287",
          strokeWidth: '100%',
          opacity: 0.1,
          margin: 0, // margin is in pixels
        },
        dataLabels: {
          name: {
            fontSize: '12px',
            fontFamily: 'DM Sans',
            color: '#848287',
            offsetY: 0
          },
          value: {
            offsetY: -40,
            fontSize: '16px',
            fontFamily: 'DM Sans',
            color: '#848287',
            formatter: function (val) {
              return val + "%";
            }
          }
        }
      }
    },
    fill: {
      opacity: 1.5,
      type: 'gradient',
      gradient: {
        type: 'diagonal1',
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 2,
        colorStops: [[{ offset: 0, color: '#EE5D50', opacity: 1 }, { offset: 120, color: '#01B574', opacity: 1 }]],
        inverseColors: false
      },
    },
    stroke: {
      dashArray: 4
    },
    labels: ['Win %'],


  };

  const [variables, setVariables] = useState({ options: {}, series: [] });

  return (
    <>
      <Chart
        type='radialBar'
        options={variables.options}
        width="100%"
        height="auto"
        series={variables.series}
      />

    </>
  )
}

export default WinGuage