import { Flex } from "@chakra-ui/react";
import Header from "./sections/Header";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import Footer from "components/footer/FooterAuthCentered";

export default function LandingLayout(props: { children: JSX.Element[] }) {

    const { children } = props;

    return (
        <Flex direction="column" align="center" m="20 auto" {...props}>
            <FixedPlugin />
            <Header />
            {children}
            <Footer />
        </Flex>
    );
}