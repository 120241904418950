import { Flex, FormLabel, Switch } from '@chakra-ui/react'
import { ChangeEvent } from 'react'

const StudentShare = (props: { label: string, checked: boolean, updateshare: any }) => {

    const setshare = async (e: ChangeEvent<HTMLInputElement>) => {
        props.updateshare(e.target.checked);
    }

    return (
        <Flex direction={'row'} align={'center'} me={{ base: '5px', md: '20px' }}>
            <FormLabel mt='7px'>{props.label}</FormLabel>
            <Switch isChecked={props.checked} colorScheme={'green'} onChange={setshare} />
        </Flex>
    )
}

export default StudentShare