import { useContext } from "react";
import AuthContext from "services/auth/AuthContext";
import { JournalEntry } from "../variables/types";
import { useAuth } from "services/auth/AuthProvider";


const useNewJournal = () => {
    const { user } = useAuth();//useContext(AuthContext);
    const newjorno: JournalEntry = {
		id: 0,
		userid: (user === null ? 0 : user.id),
		date: "",
		title: "",
		category: "",
		text: "",
		base64Image: "",
		last_uuid: "",
		sync_due: false,
		flagged: false, 
		published: false
	}
	return newjorno;
};

export default useNewJournal;