import { Flex, FormLabel } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const TextMaxLength = (props: { text: string, maxlength: number, justify: string }) => {

    useEffect(() => {
        updateCharCount(props.text.length);
    }, [props.text])

    const [charcount, setCharcount] = useState(0);
    const [color, setColor] = useState("secondaryGray.500");

    const updateCharCount = (count: number) => {
        setCharcount(count);
        let p = charcount / props.maxlength;
        setColor(count > 0 ? (p < 0.8 ? "secondaryGray.500" : (p > 0.9 ? "red.500" : "orange.500")) : "secondaryGray.500");
    }

    return (
        <Flex direction={'row'} justify={props.justify}>
            <FormLabel fontWeight='bold' fontSize='xs' mt='5px' color={color}>{charcount} / {props.maxlength}</FormLabel>
        </Flex>
    )
}

export default TextMaxLength