import { Card, Flex, Heading, Icon, Spacer, useColorModeValue, useMediaQuery, Image, Box } from '@chakra-ui/react';
import FadeInSection from '../../../../layouts/landing/sections/FadeInSection';

import ninja from 'assets/img/jorno/platforms/p_ninja.png';
import tvate from 'assets/img/jorno/platforms/p_tvate.png';
import tviewdark from 'assets/img/jorno/platforms/p_tviewdark.png';
import tviewlight from 'assets/img/jorno/platforms/p_tviewlight.png';
import sierradark from 'assets/img/jorno/platforms/p_sierradark.png';
import sierralight from 'assets/img/jorno/platforms/p_sierralight.png';
import ib from 'assets/img/jorno/platforms/ib.png';
import meta from 'assets/img/jorno/platforms/meta.png';

const Platforms = () => {
    const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
    const pageBg = useColorModeValue('white', 'navy.900');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const bdcolor = useColorModeValue('gray.200', 'gray.700');

    const array = [
        {
            id: 1,
            text: "Ninjatrader",
            image: ninja,
            height: '30px',
            url: "https://ninjatrader.com/"
        },
        {
            id: 2,
            text: "Tradovate",
            image: tvate,
            height: '40px',
            url: "https://tradovate.com/"
        },
        {
            id: 3,
            text: "TradingView",
            image: useColorModeValue(tviewdark, tviewlight),
            height: '30px',
            url: "https://tradingview.com/"
        },
        /*{
            id: 4,
            text: "SierraChart",
            image: useColorModeValue(sierradark, sierralight),
            height: '40px',
            url: "https://sierrachart.com/"
        },
        {
            id: 5,
            text: "InteractiveBrokers",
            image: ib,
            height: '40px',
            url: "https://interactivebrokers.com/"
        },
        {
            id: 6,
            text: "MetaTrader",
            image: meta,
            height: '60px',
            url: "https://interactivebrokers.com/"
        }*/
    ];
    return (
        <FadeInSection>
            <Flex justify="center">
                <Heading as="h1" size="lg" fontWeight="bold" color={textColor} mt={{ base: '50px', lg: '30px' }}>
                    Platforms
                </Heading>
            </Flex>
            <Flex minH="10vh" align="center" justify="space-between" w="full" maxW={{ xl: "1200px" }} py="5" px={isLargerThanMD ? '16' : '6'} flexWrap={'wrap'} flexDirection={isLargerThanMD ? 'row' : 'column'} mb={'80px'}>
                {array.map((arr) => (
                    <>
                        <Card
                            bg={pageBg}
                            height="90px"
                            width={isLargerThanMD ? '32%' : 'full'}
                            shadow="md"
                            cursor={'pointer'}
                            px="20"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="20px"
                            flexDirection="column"
                            textAlign="center"
                            mb={isLargerThanMD ? '0' : '4'}
                            borderWidth={'1px'}
                            borderColor={bdcolor}
                            mt='20px'
                            onClick={() => window.open(arr.url, '_blank')}
                        >
                            <Flex direction={'row'} alignItems="center">
                                <Image src={arr.image} h={arr.height} me="5" />
                                <Heading size="md" fontWeight="bold" color={textColor}>
                                    {arr.text}
                                </Heading>
                            </Flex>
                        </Card>
                        <Spacer />
                    </>
                ))}
            </Flex>
        </FadeInSection>
    );
}

export default Platforms