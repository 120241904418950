import { Button, Icon, IconButton, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';

export default function ThemeToggler(props: { [x: string]: any }) {
    const { ...rest } = props;
    const { colorMode, toggleColorMode } = useColorMode();
    const navbarIcon = useColorModeValue('gray.400', 'white');

    return (
        <>
            {/*<Button variant="no-hover" bg="transparent" p="0px" minW="unset" minH="unset" h="25px" w="max-content" onClick={toggleColorMode}>
                <Icon
                    me="10px" h="35px" w="35px"
                    color={navbarIcon}
                    as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
                />
            </Button>*/}
            <IconButton
                isRound={true}
                variant='outline no-hover'
                borderWidth={'1px'}
                color={navbarIcon}
                onClick={toggleColorMode}
                aria-label='Done'
                fontSize='20px'
                me="10px"
                icon={<Icon
                    h="20px" w="20px"
                    color={navbarIcon}
                    as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
                />}
            />
        </>

    );
}

