import { Trade } from "../variables/types";
import dayjs from "dayjs";
import { useAuth } from "services/auth/AuthProvider";


const useNewTrade = () => {
    
    const id = Math.floor(Math.random() * 10000);

    const { user, muuid } = useAuth();//useContext(AuthContext);

    let newRow: Trade = {
        tradeid: id,
        accountid: 0,
        groupid: 0,
        userid: (user === null ? 0 : user.id),
        //date: ["Enter executions below ...", 0],
        datetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
		duration: 0,
        ticker: '',
        currency: 'USD',
        side: '?',
        size: 0,
        entry_avg: 0,
        exit_avg: 0,
        stop_price: 0,
        target_price: 0,
        points: 0,
        profitloss: 0,
        rvalue: 0,
        target_rvalue: 0,
        fees: 0,
        nett: 0,
        rulescore: 0,
        executions: [],
        exchange: 1,
        closed: false,
        mistakes: [],
        strategies: [],
        mentornotes: '',
        notes: '',
        shared: false,
        req_review: false,
        reviewed: false,
        sync_due: false,
        last_uuid: muuid,
        base64Image: '',
        flagged: false
    };

    return newRow;
};

export default useNewTrade;