import { Box, Flex, FormLabel, Heading, Icon, Link, Spinner, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import Card from 'components/card/Card'
import IconBox from 'components/icons/IconBox';
import { LuCalendarCheck, LuCalendarCheck2, LuCalendarPlus } from 'react-icons/lu';
import { MdAdd, MdOutlineTimer, MdPercent, MdOutlineTrendingUp, MdNumbers } from 'react-icons/md';
import { PiMathOperationsBold } from 'react-icons/pi';
import { currencyformat, getDuration } from 'views/trader/trades/variables/FormatData';
import { StatWin } from 'views/trader/trades/variables/types';

const WinStats = (props: { loading: boolean, stats: StatWin, currency: string }) => {

  const bdColor = useColorModeValue('gray.200', 'gray.700');
  const iconColor = "gray.400";
  const boxBg = useColorModeValue('white', 'navy.800');
  const statColor = useColorModeValue('navy.700', 'gray.200');
  const spinnerColor = useColorModeValue('gray.300', 'brand.300');
  const bgcolor = useColorModeValue('white', 'navy.900');
  const headercolor = "green.500";
  const mbottom = '10px'

  return (
    <Card borderColor={bdColor} borderWidth='1px' h={'100%'} bg='transparent' mt='10px'>
      <Flex>
        <Heading size={'xs'} mt='-30px' bg={bgcolor} px='10px'>Winners</Heading>
      </Flex>

      <Box h={'100%'} justifyContent={'center'} alignContent={'center'} hidden={!props.loading}>
        <Flex justify={'center'}>
          <Spinner color={spinnerColor}></Spinner>
        </Flex>

      </Box>
      <Flex direction={'column'} hidden={props.loading}>
        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Total</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.wintotal}{'  ('}{props.stats.winperc.toFixed()}%{')'}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={MdNumbers} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px' >AVG win</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{currencyformat(props.stats.avgwin, props.currency)}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={PiMathOperationsBold} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Biggest win</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{currencyformat(props.stats.maxwin, props.currency)}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={MdAdd} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Best day</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{currencyformat(props.stats.bestday, props.currency)}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={LuCalendarCheck2} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Winning days</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.windays}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={LuCalendarPlus} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Max win streak</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.winstreak}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={MdOutlineTrendingUp} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600">AVG win duration</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{getDuration(props.stats.avg_duration)}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={MdOutlineTimer} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}

export default WinStats