import { Flex, Icon } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { MdFullscreen, MdOutlineFullscreenExit } from 'react-icons/md'

const FullScreenButton = () => {

    const [isFullscreen, setIsFullscreen] = useState(Boolean(document.fullscreenElement));

    // Watch for fullscreenchange
    useEffect(() => {
      function onFullscreenChange() {
        setIsFullscreen(Boolean(document.fullscreenElement));
      }
  
      document.addEventListener('fullscreenchange', onFullscreenChange);
  
      return () =>
        document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);
    
    return (
        <Flex hidden={isMobile} zIndex={1000} w="30px" h="30px" justify="center" align="center" bg={'blue.500'}  borderColor={'blue.500'} borderWidth={'2px'} borderRadius="10px" position="absolute" top="10px" right="55px" transition="0.3s" cursor="pointer" onClick={() => { isFullscreen ? document.exitFullscreen() : document.body.requestFullscreen() }} _hover={{ background: 'rgba(0, 0, 0, 0.25);' }}>
            <Icon as={isFullscreen ? MdOutlineFullscreenExit : MdFullscreen} color="#fff" fontSize="20px" />
        </Flex>
    )
}

export default FullScreenButton;