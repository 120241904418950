
import Chart from 'react-apexcharts';
import { Box, Flex, FormLabel, Heading, ScaleFade, Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { useEffect, useState } from 'react';
import { StatSide } from 'views/trader/trades/variables/types'
import { ApexOptions } from 'apexcharts';

const SideStatDonut = (props: { stats: StatSide, showtrades: any }) => {

    const [nochartdata, setnochartdata] = useState(false);
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const bgcolor = useColorModeValue('white', 'navy.900');

    useEffect(() => {
        setnochartdata(props.stats.long < 1 && props.stats.short < 1)
        let data = [props.stats.long, props.stats.short]
        options.series = data;
        setVariables({ options: options });
    }, [props.stats])

    useEffect(() => {
        let data = [props.stats.long, props.stats.short]
        options.series = data;
        //options.title.style.color = textColor;
        options.plotOptions.pie.donut.labels.value.color = textColor;
        options.plotOptions.pie.donut.labels.total.color = textColor;
        setVariables({ options: options });
    }, [textColor])

    const options: ApexOptions = {
        series: [],
        labels: ["Long", "Short"],
        colors: ['#01B574', '#EE5D50'],
        chart: {
            toolbar: {
                show: false
            },
            events: {
                click: function (event, chartContext, config) {
                    if (config.dataPointIndex > -1) {
                        let side = (config.dataPointIndex === 0 ? 'buy' : 'sell')
                        props.showtrades(side);
                    }
                }
            }
        },
        stroke: {
            width: 0
        },
        tooltip: {
            enabled: false
        },
        plotOptions: {
            pie: {
                customScale: 1,
                startAngle: 0,
                endAngle: 360,
                expandOnClick: true,
                donut: {
                    size: '60%',
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '14px',
                            fontFamily: 'DM Sans',
                            fontWeight: 600,
                            color: undefined,
                            offsetY: -5,

                        },
                        value: {
                            show: true,
                            fontSize: '12px',
                            fontFamily: 'DM Sans',
                            fontWeight: 400,
                            color: '#848287',
                            offsetY: 0,

                        },
                        total: {
                            show: true,
                            showAlways: false,
                            label: 'Total',
                            fontSize: '14px',
                            fontFamily: 'DM Sans',
                            fontWeight: 600,
                            color: '#848287',
                        }
                    }
                },
            }
        },
        /*title: {
            
            text: 'Long / Short',
            style: {
                fontFamily: 'DM Sans',
                color: textColor
            },
        },*/
        fill: {
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 0.15,
                opacityFrom: 0.9,
                opacityTo: 1
            }
        },
        legend: {
            show: false
        },
        dataLabels: {
            textAnchor: 'start',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: '12px',
                fontFamily: 'DM Sans',
                fontWeight: 'bold',
                colors: undefined
            },
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#848287',
                opacity: 0.5,
                dropShadow: {
                    enabled: false,
                }
            },
            dropShadow: {
                enabled: false,
            }
        },
        grid: {
            show: false,
        }
    };

    const [variables, setVariables] = useState({ options: options });


    return (
        <>
            <Card h='100%' borderWidth='1px' bg='transparent' mt='10px'>
                <Flex>
                    <Heading size={'xs'} mt='-30px' bg={bgcolor} px='10px'>Long/Short</Heading>
                </Flex>
                <Flex hidden={!nochartdata} justify={'center'} align={'center'} >
                    <ScaleFade initialScale={0.5} in={true} transition={{ exit: { delay: 0.5 }, enter: { duration: 1 } }}>
                        <Box p='10px' color={textColorSecondary} >
                            <Text color={textColorSecondary} fontSize='md' mt='20px' mb='20px' ms='20px'>No data to display</Text>
                        </Box>
                    </ScaleFade>
                </Flex>
                <Flex align={'center'} w='100%' h='100%' p='0px'>
                    <Chart
                        type='donut'
                        hidden={nochartdata}
                        options={variables.options}
                        width="100%"
                        height="auto"
                        series={variables.options.series}
                    />
                </Flex>

            </Card>
        </>
    )
}

export default SideStatDonut