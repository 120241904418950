/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useState, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import AuthContext from 'services/auth/AuthContext';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
  useToast
} from '@chakra-ui/react';
// Assets
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';
import { useAuth } from 'services/auth/AuthProvider';

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const { verifyPasswordResetToken, resetPassword } = useAuth();//useContext(AuthContext);


  const [submission, setSubmission] = useState(false);
  const [service, setService] = useState(false);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);


  useEffect(() => {
    if (searchParams.get("t") === null) { /// token sent with url in email
      navigate("/auth/signin");
    }
    else {
      let token = searchParams.get("t");
      verifyPasswordResetToken({ token }).then(async (res: any) => {
        if (res === 400) {
          toast({ title: 'Reset link expired or invalid', description: "Resend password reset email", status: 'error', position: 'top', duration: 3000, isClosable: true})
          navigate("/auth/forgotpassword");
        }
      }).catch(function (error: any) {
        toast({title: 'Service temporarily unavailable', description: "Please refresh the page in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false})
        setService(true);
      });
    }
  }, []);

  return (
    <CenteredAuth cardTop={{ base: '140px', md: '14vh' }} cardBottom={{ base: '50px', lg: 'auto' }} mx="0px">
      <Flex maxW={{ base: '100%', md: 'max-content' }} w="100%" mx={{ base: 'auto', lg: '0px' }} me="auto" justifyContent="center" px={{ base: '20px', md: '0px' }} flexDirection="column">
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">Password Reset</Heading>
          <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md" hidden={service}>Choose a new password.</Text>
          <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md" hidden={!service}>Sevice temporarily unavailable.</Text>
          <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md" hidden={!service}>Please refresh this page or click the email link again in a few minutes.</Text>
        </Box>
        <Flex zIndex="2" direction="column" w={{ base: '100%', md: '420px' }} maxW="100%" background="transparent" borderRadius="15px" mx={{ base: 'auto', lg: 'unset' }} me="auto" mb={{ base: '20px', md: 'auto' }}>
          <Formik
            initialValues={{
              pw1: "",
              pw2: ""
            }}
            onSubmit={(values) => {

              setSubmission(true);

              resetPassword({ token: searchParams.get("t"), password: values.pw1 }).then(async (res: any) => {
                if (res.status === 200) {
                  if (res.data.message === "reset") {
                    toast({ title: 'Password changed', description: "Sign in with your new credentials", status: 'success', position: 'top', duration: 3000, isClosable: true})
                    navigate("/auth/signin");
                  }
                  else {
                    toast({title: 'Reset link expired or invalid', description: "Resend password reset email", status: 'error', position: 'top', duration: 3000, isClosable: true})
                    navigate("/auth/forgotpassword");
                  }
                }
              }).catch(function (error: any) {
                toast({
                  title: 'Service temporarily unavailable', description: "Please refresh the page in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false})
                setSubmission(false);
                setService(true);
              });

            }}
            validationSchema={Yup.object().shape({
              pw1: Yup.string().required('Please enter new password.').min(10, 'Password minimum 10 characters'),
              pw2: Yup.string().required('Please retype your password.').oneOf([Yup.ref('pw1')], 'Your passwords need to match.')
            })}
          >
            {({ handleSubmit, values, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <FormControl hidden={service}>

                  <FormControl isInvalid={!!errors.pw1 && touched.pw1}>
                    <FormLabel ms="4px" mt="24px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                      New Password<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                      <Field
                        as={Input}
                        id="pw1"
                        name="pw1"
                        type={show ? 'text' : 'password'}
                        fontSize="sm" ms={{ base: '0px', md: '4px' }} placeholder="Min. 10 characters" size="lg" variant="auth"
                      />
                      <InputRightElement display="flex" alignItems="center" mt="4px">
                        <Icon color={textColorSecondary} _hover={{ cursor: 'pointer' }} as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={handleClick} />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.pw1}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.pw2 && touched.pw2}>
                    <FormLabel ms="4px" mt="24px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                      Repeat Password<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                      <Field
                        as={Input}
                        id="pw2"
                        name="pw2"
                        type={show ? 'text' : 'password'}
                        fontSize="sm" ms={{ base: '0px', md: '4px' }} placeholder="Min. 10 characters" size="lg" variant="auth"
                      />
                      <InputRightElement display="flex" alignItems="center" mt="4px">
                        <Icon color={textColorSecondary} _hover={{ cursor: 'pointer' }} as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={handleClick} />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.pw2}</FormErrorMessage>
                  </FormControl>

                  <Button type="submit" fontSize="sm" variant="brand" mt="24px" fontWeight="500" w="100%" h="50" mb="24px" isLoading={submission} loadingText='Updating'>Change</Button>

                </FormControl>
              </form>
            )}
          </Formik>

        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default SignIn;

