import { Flex, Spacer, Text, useMediaQuery, useColorModeValue, Heading } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import Card from "components/card/Card";
import FadeInSection from "../../../../layouts/landing/sections/FadeInSection";
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { BsJournalBookmark, BsClipboardData } from "react-icons/bs";
import { TbFaceIdError } from "react-icons/tb";
import { PiBrain } from "react-icons/pi";
import { TbAward } from "react-icons/tb";

const Benefits = () => {
    const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
    const pageBg = useColorModeValue('white', 'navy.900');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const iconColor = useColorModeValue('brand.400', 'blue.500');

    const array = [
        {
            id: 1,
            text: "Trading without a plan is gambling, plain and simple. Keep track of your strategies and their performance to see which ones are the most profitable over time.",
            icon: BsClipboardData,
            iconsize: 14
        },
        {
            id: 4,
            text: 'Powerful analytics help you see through the fog of daily trading activity. Track your trading performance over time using key industry-standard metrics.',
            icon: MdOutlineDashboardCustomize,
            iconsize: 14
        },
        {
            id: 2,
            text: 'Good trading psychology is critical to your success as a trader. Journaling provides the track record and confidence needed to execute without hesitation.',
            icon: PiBrain,
            iconsize: 16
        },
        {
            id: 3,
            text: "Consistent journaling provides an excellent resource to refer back to. You can record your days in great detail and reflect on your trading journey over time.",
            icon: BsJournalBookmark,
            iconsize: 12
        },
        {
            id: 5,
            text: "Everybody makes mistakes, but if you don't learn from them, you will repeat them again and again. Journaling helps you identify common mistakes.",
            icon: TbFaceIdError,
            iconsize: 16
        },
        {
            id: 6,
            text: 'In short, keeping a detailed journal of your trading habits and performance sets you on the path to consistent profitability. Why stick with the herd of losers?',
            icon: TbAward,
            iconsize: 14
        },
    ];
    return (
        <FadeInSection>
            <Flex justify="center">
                <Heading as="h1" size="lg" fontWeight="bold" color={textColor} mt={{ base: '50px', lg: '50px' }}>
                    Why journal?
                </Heading>
            </Flex>
            <Flex minH="40vh" alignItems="center" justifyContent="space-between" w="full" maxW={{ xl: "1200px" }} py="5" px={isLargerThanMD ? '16' : '6'} flexWrap="wrap" flexDirection={isLargerThanMD ? 'row' : 'column'} >
                {array.map((arr, index) => (
                    <>
                        <Card
                            key={index}
                            bg={pageBg}
                            height="250px"
                            width={isLargerThanMD ? '32%' : 'full'}
                            shadow="md"
                            p="6"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="20px"
                            flexDirection="column"
                            textAlign="center"
                            mb={isLargerThanMD ? '0' : '4'}
                            mt={'15px'}
                            borderWidth={'1px'}
                        >
                            <Icon as={arr.icon} boxSize={arr.iconsize} color={iconColor} mb="10" />
                            <Text>{arr.text}</Text>
                        </Card>
                        <Spacer />
                    </>
                ))}
            </Flex>
            <Flex justify="center" mt='20px'>
                <Text color={'gray.500'}>You probably don't need any more convincing!</Text>
            </Flex>
        </FadeInSection>
    );
};

export default Benefits;