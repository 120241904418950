import { Search2Icon } from '@chakra-ui/icons';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Icon, Text, Flex, FormLabel, Input, useColorModeValue, Box, useToast } from '@chakra-ui/react'
import { ChangeEvent, useRef, useState } from 'react'
import { MdAdd } from 'react-icons/md';
import api from 'services/api';
import { Instrument } from '../../variables/types';
import { db } from 'services/db';

const StockModal = (props: { hidden: boolean }) => {

    const { hidden } = props;

    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = useRef(null)

    const textColor = useColorModeValue('gray.700', 'gray.200');
    const [searching, setsearching] = useState(false);
    const [adding, setAdding] = useState(false);
    const [queryStr, setQueryStr] = useState('');
    const [queryRes, setQueryRes] = useState([]);

    const toast = useToast();

    const updatequery = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryStr(e.target.value.trim());
    }

    const findstock = async () => {
        setsearching(true);
        await api.post('/stocks/findstock', { query: queryStr }).then((res) => {
            setQueryRes(res.data);
            if (!res.data.length) {
                toast({ title: 'No matcing stocks found ...', description: "", status: 'info', position: 'top', duration: 3000, isClosable: false })
            }
        }).catch((e) => {
            toast({ title: 'Unable to search for stocks', description: "Check your internet connection", status: 'error', position: 'top', duration: 3000, isClosable: false })
        })

        setsearching(false);
    }

    const addStock = async (stock: any) => {
        setAdding(true);
        //{"id":1016,"symbol":"AAPL","name":"Apple Inc. Common Stock","sector":"Technology"}
        let newInst: Instrument = {
            id: stock.id,
            userid: 0,
            ticker: stock.symbol,
            description: stock.name,
            category: "STOCK",
            subcategory: stock.sector,
            currency: "USD",
            pointvalue: 1,
            ticksize: 0.01,
            relevance: 0,
            defaultstop: 0,
            commission: [],
            sync_due: false
        };

        await db.instruments.put(newInst, newInst.id).then(() => {
            localStorage.setItem("ticker", newInst.ticker);
            closemodal();
            toast({ title: newInst.ticker + ' added...', description: "Select it from the ticker dropdown", status: 'success', position: 'top', duration: 3000, isClosable: false })
        }).catch(() => { })
    }

    const closemodal = () => {
        setQueryStr('');
        setQueryRes([]);
        setAdding(false);
        setsearching(false);
        onClose();
    }
    const openmodal = () => {
        onOpen();
    }

    return (
        <>
            <Button size={'md'} borderRadius={'5px'} hidden={hidden} ms='3px'><Icon as={Search2Icon} onClick={openmodal} /></Button>
            <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size={'xl'} onOverlayClick={closemodal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Stock finder</ModalHeader>
                    <ModalCloseButton onClick={closemodal} />
                    <ModalBody>
                        <Flex direction={'column'}>
                            <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Enter symbol or name to search ...</FormLabel>
                            <Flex direction={'row'} mb='30px'>
                                <Input type='text' placeholder='stock name or symbol' color={textColor} onChange={updatequery} />
                                <Button variant={'outline'} colorScheme={'blue'} borderRadius={'10px'} ms='5px' onClick={findstock} isLoading={searching} isDisabled={!queryStr.trim().length}>Lookup</Button>
                            </Flex>
                            <Flex direction={'column'}>
                                {queryRes.map((stock, index) => (
                                    <Flex direction={'row'} key={index} justify={'space-between'} mt="20px">
                                        <Box w='15%'>
                                            <Text color={textColor} fontSize='sm' fontWeight='700' me='20px'>
                                                {stock.symbol}
                                            </Text>
                                        </Box>
                                        <Box w='70%' me='10px'>
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {stock.name}
                                            </Text>
                                            <Text color='secondaryGray.500' fontSize='sm' fontWeight='600'>
                                                {stock.sector}
                                            </Text>
                                        </Box>
                                        <Box w='15%'>
                                            <Button size={'sm'} onClick={() => { addStock(stock) }} isLoading={adding}><Icon as={MdAdd} />ADD</Button>
                                        </Box>
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={closemodal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default StockModal