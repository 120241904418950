/* eslint-disable */

import {
	Flex, Table, Tbody, Td,
	Th, Thead, Tr, useColorModeValue, Divider, IconButton,
	NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
	Select,
	Button,
	InputGroup,
	InputRightAddon,
	Icon,
	InputLeftAddon,
	Heading,
	Text,
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	SortingState,
	PaginationState,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable
} from '@tanstack/react-table';
// Custom components  
//import TradeModal from '../modals/TradeModal';
import { Mentee } from 'views/trader/trades/variables/types';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { getDuration } from 'views/trader/trades/variables/FormatData';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdOutlineRateReview } from 'react-icons/md';
import dayjs from 'dayjs';
import { useData } from 'services/data/DataProvider';
import DeleteMentorConfirm from 'views/trader/components/confirm/DeleteMentorConfirm';
import StudentTradesModal from './StudentTradesModal';
import { FiDownloadCloud } from 'react-icons/fi';


const columnHelper = createColumnHelper<Mentee>();

export default function MentorStudentsTable(props: { mentees: Mentee[], updatepending: any }) {

	const { dateformat } = useData();

	const [data, setData] = useState<Mentee[]>([]);
	const [loadingmenteeid, setLoadingmenteeid] = useState(0);
	const [selectedmenteeid, setSelectedMenteeid] = useState(0);
	const tradesModal = useRef(null);
	const [tradeSelection, setTradeSelection] = useState('');

	const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const btncolor = useColorModeValue('blue.400', 'cyan');
	const [tablesize, setTablesize] = useState(localStorage.getItem('tablesize') ? Number(localStorage.getItem('tablesize')) : 10);


	useEffect(() => {
		setData(props.mentees);
	}, [props.mentees])

	const calculateperiod = (m: Mentee) => {
		return getDuration(dayjs().diff(dayjs(m.started)));
	}

	const openTrades = (m: Mentee) => {
		setLoadingmenteeid(m.menteeid);
		tradesModal.current.openModal(m, tradeSelection);
	}
	const cancelMenteeLoading = () => {
		setLoadingmenteeid(0);
	}

	const selectTrades = async (e: ChangeEvent<HTMLSelectElement>, mid: number) => {
		setTradeSelection(e.target.value);
		setSelectedMenteeid(e.target.value === '' ? 0 : mid);
	}

	const columns = [
		columnHelper.accessor('menteename', {
			id: 'menteename',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					TRADER
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('started', {
			id: 'started',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SINCE
				</Text>
			),
			cell: (info: any) => (
				<>
					<Flex direction={'row'} gap={20} align={'center'} justify={'space-between'}>
						<Flex align='center'>
							<Flex direction='column'>
								<Text color={textColor} fontSize='sm' fontWeight='700'>
									{dayjs(info.getValue()).format(dateformat)}
								</Text>
								<Text color='secondaryGray.500' fontSize='sm' fontWeight='600'>
									{"Duration: " + calculateperiod(info.row.original)}
								</Text>
							</Flex>
						</Flex>
						<Flex>
							<DeleteMentorConfirm mentorshipid={info.row.original.id} label={'CANCEL Mentorship'} btnsize={'sm'} />
						</Flex>
					</Flex>
				</>
			)
		}),
		columnHelper.accessor('menteeid', {
			id: 'menteeid',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Trades
				</Text>
			),
			cell: (info) => (
				<>
					<Flex direction={'row'} gap={5}>
						<Flex>
							<InputGroup>
								<InputLeftAddon boxSize={8} minW={24}>
									<Heading size={'sm'} color={textColor}>Trades</Heading>
								</InputLeftAddon>
								<Select size={'sm'} minWidth={60} onChange={(e) => selectTrades(e, info.row.original.menteeid)} value={tradeSelection && selectedmenteeid === info.row.original.menteeid ? tradeSelection : ''} color={textColor}>
									<option value="">Select ...</option>
									{info.row.original.pending > 0 && <optgroup label='Review requested'>
										<option value='review'>{info.row.original.pending} PENDING Review</option>
									</optgroup>}
									<optgroup label='Shared trades'>
										<option value='weekthis'>This week</option>
										<option value='weeklast'>Last week</option>
										<option value='monththis'>This month</option>
										<option value='monthlast'>Last month</option>
										<option value='quarterthis'>This quarter</option>
										<option value='quarterlast'>Last quarter</option>
										<option value='yearthis'>This year</option>
										<option value='yearlast'>Last year</option>
										<option value='all'>ALL trades</option>
									</optgroup>
								</Select>
								<InputRightAddon isDisabled={tradeSelection === '' || selectedmenteeid !== info.row.original.menteeid} as={Button} boxSize={8} minW={28} onClick={() => openTrades(info.row.original)} isLoading={loadingmenteeid === info.row.original.menteeid}>
									<Flex direction={'row'} align={'center'}>
										{tradeSelection === 'review' && selectedmenteeid === info.row.original.menteeid ? (
											<>
												<Text color={btncolor}>Review</Text>
												<Icon as={MdOutlineRateReview} ms='10px' color={btncolor}></Icon>
											</>
										) : (
											<>
												<Text color={btncolor}>Fetch</Text>
												<Icon as={FiDownloadCloud} ms='10px' color={btncolor}></Icon>
											</>
										)}
									</Flex>
								</InputRightAddon>
							</InputGroup>
						</Flex>
						{info.row.original.pending > 0 && <Text color={'red.500'} fontWeight={'700'} mt='5px'>{info.row.original.pending} Pending review</Text>}
					</Flex>
				</>
			)
		}),
	];

	const updatePageSize = (size: number) => {
		setTablesize(size);
		localStorage.setItem('tablesize', size.toFixed());
		table.setPageSize(size);
	}

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: tablesize,
	})
	const [sorting, setSorting] = useState<SortingState>([{
		id: 'started',
		desc: true, // sort by name in descending order by default
	}]);
	const [columnVisibility, setColumnVisibility] = useState({});

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			pagination,
			columnVisibility
		},
		onColumnVisibilityChange: setColumnVisibility,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: setPagination,
		debugTable: true
	});


	return (
		<>
			<Table variant='simple' color='gray.500' mt="12px" maxWidth={'max-content'} minWidth={'max-content'}>
				<Thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {

								return (
									<Th
										key={header.id}
										colSpan={header.colSpan}
										pe='10px'
										borderColor={borderColor}
										cursor='pointer'
										onClick={header.column.getToggleSortingHandler()}>
										<Flex
											justifyContent='space-between'
											align='center'
											fontSize={{ sm: '10px', lg: '12px' }}
											color='gray.400'>
											{flexRender(header.column.columnDef.header, header.getContext())}{{
												asc: '',
												desc: '',
											}[header.column.getIsSorted() as string] ?? null}
										</Flex>
									</Th>
								);
							})}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map((row) => {
						return (
							<Tr key={row.id}>
								{row.getVisibleCells().map((cell) => {
									return (
										<Td
											key={cell.id}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor='transparent'>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<Divider mt='10px' hidden={table.getRowCount() <= 10} />
			<Flex direction={{ base: 'column', md: 'row' }} justify='center' hidden={table.getRowCount() <= 10}>
				<Flex direction='row' justify='center' hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
					<Text mt='23px' ms='20px' me='5px'>Page</Text>
					<Text as='b' mt='23px' ms='5px' me='20px'>{table.getState().pagination.pageIndex + 1} of{' '}{table.getPageCount().toLocaleString()}</Text>
					<Text mt='23px' color={textColorSecondary}>Go to page: </Text>
					<NumberInput ms='10px' mt='20px' size='sm' min={1} max={table.getPageCount()} w='85px' allowMouseWheel={true} hidden={table.getRowCount() <= table.getState().pagination.pageSize}
						onChange={(valueString) => table.setPageIndex(valueString ? Number(valueString) - 1 : 0)}
					>
						<NumberInputField color={textColor} borderRadius='5px' />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
				</Flex>
				<Flex direction='row' justify='center'>
					<Select size='sm' mt='20px' ms='10px' me='10px' borderRadius='5px' alignContent='end' w='120px' value={tablesize}//table.getState().pagination.pageSize}
						onChange={e => { updatePageSize(Number(e.target.value)) }}
					>
						{[10, 20, 30, 40, 50, 100].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								Show {pageSize}
							</option>
						))}
					</Select>
					<Flex hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowLeft />} onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()} />
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowLeft />} onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowRight />} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
						<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowRight />} onClick={() => table.lastPage()} disabled={!table.getCanNextPage()} />
					</Flex>
				</Flex>
			</Flex>
			<StudentTradesModal ref={tradesModal} cancelLoading={cancelMenteeLoading} updatepending={props.updatepending} />
		</>
	);

}
