import { Flex, Spacer, useMediaQuery, useColorModeValue, Heading } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import Card from "components/card/Card";
import FadeInSection from "../../../../layouts/landing/sections/FadeInSection";
import { MdOutlineCurrencyExchange, MdOutlineTimer } from 'react-icons/md';
import { TbCoinBitcoin, TbCertificate } from "react-icons/tb";

const Markets = () => {
    const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
    const pageBg = useColorModeValue('white', 'navy.900');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const iconColor = useColorModeValue('brand.400', 'blue.500');

    const array = [
        {
            id: 1,
            text: "Futures",
            icon: MdOutlineTimer,
            iconsize: 14
        },
        {
            id: 2,
            text: "Forex",
            icon: MdOutlineCurrencyExchange,
            iconsize: 12
        },
        {
            id: 4,
            text: 'Stocks',
            icon: TbCertificate,
            iconsize: 14
        },
        {
            id: 3,
            text: 'Crypto',
            icon: TbCoinBitcoin,
            iconsize: 14
        }

    ];
    return (
        <FadeInSection>
            <Flex justify="center">
                <Heading as="h1" size="lg" fontWeight="bold" color={textColor} mt={{ base: '50px', lg: '100px' }}>
                    Markets
                </Heading>
            </Flex>
            <Flex minH="10vh" alignItems="center" justify="space-between" w="full" maxW={{ xl: "1200px" }} py="5" px={isLargerThanMD ? '16' : '6'} flexDirection={isLargerThanMD ? 'row' : 'column'} mb={'50px'}>
                {array.map((arr) => (
                    <>
                        <Card
                            bg={pageBg}
                            height="100px"
                            width={isLargerThanMD ? '32%' : 'full'}
                            shadow="md"
                            px="16"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="20px"
                            flexDirection="column"
                            textAlign="center"
                            mb={isLargerThanMD ? '0' : '4'}
                            borderWidth={'1px'}
                            me='10px'
                        >
                            <Flex direction={'row'} alignItems="center">
                                <Icon as={arr.icon} boxSize={arr.iconsize} color={iconColor} me="10" />
                                <Heading as="h4" size="md" fontWeight="bold" color={textColor}>
                                    {arr.text}
                                </Heading>
                            </Flex>
                        </Card>
                        <Spacer />
                    </>
                ))}
            </Flex>
        </FadeInSection>

    );
};

export default Markets;