/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Chakra imports

import { useState } from "react";
import { NavLink } from 'react-router-dom';
import { Formik, Field } from "formik";
import * as Yup from "yup";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  useColorModeValue,
  Text,
  useToast,
  Link,
  Icon
} from '@chakra-ui/react';

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';
import { MdMarkEmailRead } from "react-icons/md";
import { useAuth } from "services/auth/AuthProvider";

function ForgotPassword() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const [submission, setSubmission] = useState(false);
  const [mailsent, setMailsent] = useState(false);
  const toast = useToast();
  const { resetPasswordEmail } = useAuth();//useContext(AuthContext);

  const handleResend = () => {
    setMailsent(false);
  };

  return (
    <CenteredAuth cardTop={{ base: '140px', md: '24vh' }} cardBottom={{ base: '50px', lg: 'auto' }}>
      <Flex w="100%" maxW="max-content" me="auto" h="100%" justifyContent="center" alignItems="center" px={{ base: '25px', md: '0px' }} flexDirection="column">
        <Box me="auto" mb="34px">
          <Heading color={textColor} fontSize={{ base: '3xl', md: '36px' }} mb="16px">Forgot password?</Heading>
          <Text hidden={mailsent} color={textColorSecondary} fontSize="md" w={{ base: '100%', lg: '456px' }} maxW="100%"> Let's email you a password reset link.</Text>
          <Text hidden={!mailsent} color={textColor} fontSize="md" w={{ base: '100%', lg: '456px' }} maxW="100%" mt="30px"> Click the link in the email we've sent you ...</Text>
          <Text hidden={!mailsent} color={textColorSecondary} fontSize="md" w={{ base: '100%', lg: '456px' }} maxW="100%" mt="30px"> Check your junk folder if you can't find it ...</Text>

        </Box>
        <Flex zIndex="2" direction="column" w={{ base: '100%', md: '420px' }} maxW="100%" background="transparent" borderRadius="15px" mx={{ base: 'auto', lg: 'unset' }} me="auto" mb={{ base: '20px', md: 'auto' }}>
          <FormControl hidden={mailsent}>

            <Formik
              initialValues={{
                email: ""
              }}
              onSubmit={(values) => {
                setSubmission(true);

                resetPasswordEmail(values).then(async (res: any) => {
                  if (res.status === 200) {
                    if (res.data.message === 'sent') {
                      toast({ title: 'Reset email sent!', description: "Check your email and click the link.", status: 'success', position: 'top', duration: 3000, isClosable: false, icon: <Icon as={MdMarkEmailRead} boxSize={6} /> })
                      setSubmission(false);
                      setMailsent(true);
                    }
                    else if (res.data.message === 'invalid') {
                      toast({ title: 'Invalid request', description: "Email address not recognised ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                      setSubmission(false);
                    }
                  }
                  else {
                    toast({ title: 'Invalid request', description: "Check your email and try again", status: 'error', position: 'top', duration: 3000, isClosable: false })
                    setSubmission(false);
                  }
                }).catch(function (error: any) {
                  toast({
                    title: 'Service temporarily unavailable', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false
                  })
                  setSubmission(false);
                });

              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email("Valid email required").required("Valid email required")
              })}
            >
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl isInvalid={!!errors.email && touched.email}>
                    <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">Email<Text color={brandStars}>*</Text></FormLabel>
                    <Field
                      as={Input}
                      id="email"
                      name="email"
                      variant="auth" fontSize="sm" type="email" placeholder="you@email.com" size="lg"
                    />
                    <FormErrorMessage mb="15px">Valid email required</FormErrorMessage>
                    <Button type="submit" fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mt="24px" mb="24px" isLoading={submission} loadingText='Sending'>Email password reset link</Button>
                  </FormControl>
                </form>
              )}
            </Formik>

          </FormControl>

          <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
            <Text hidden={!mailsent} color={textColorDetails} fontWeight="400" fontSize="14px" mx={{ base: 'auto', lg: 'unset' }} textAlign={{ base: 'center', lg: 'left' }} mt="30px">
              Haven't received it?
              <Link color={textColorBrand} as="span" ms="5px" fontWeight="500" onClick={handleResend}>
                Resend a new email
              </Link>
            </Text>
          </Flex>
          <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
            <Text color={textColorDetails} fontWeight="400" fontSize="14px" mx={{ base: 'auto', lg: 'unset' }} textAlign={{ base: 'center', lg: 'left' }} mt="25px">Remembered your password?
              <NavLink to="/auth/signin">
                <Text color={textColorBrand} as="span" ms="5px" fontWeight="600">Sign In</Text>
              </NavLink>
            </Text>
          </Flex>

        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default ForgotPassword;

