/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
																																																																																	   
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Grid, Flex, GridItem, useToast, FormLabel, Icon, Box, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { db } from 'services/db';
import JournalCalendar from './components/JournalCalendar';
import JournalDrawer from './components/JournalDrawer';
import { SyncAPI } from '../components/syncapi/SyncApi';
import { JournalEntry } from '../trades/variables/types';
import Card from "components/card/Card";
import { RiFlag2Line } from "react-icons/ri";
import { MdCalendarMonth, MdOutlineQueryStats, MdOutlineTipsAndUpdates } from 'react-icons/md';
import { BsJournalText } from "react-icons/bs";
import { useAuth } from 'services/auth/AuthProvider';
import api from 'services/api';
import JournalDrawerMentor from './components/JournalDrawerMentor';
import { useLoading } from '../../../services/loading/LoadingProvider';
import { useData } from 'services/data/DataProvider';
// Custom components

export default function Default() {

	const { journals } = useData();

	const { user, setUser, allowjournaladd, IsMentored } = useAuth();
	const [flaggedjournals, setflaggedjournals] = useState([]);
	const { loading, setLoading } = useLoading();
	const [loadingid, setLoadingid] = useState(0);
	const journalRef = useRef(null);
	const journalRefMentor = useRef(null);
	const toast = useToast();
	const [userjournals, setuserjournals] = useState([]);
	const [mentorjournals, setmentorjournals] = useState([]);

	useEffect(() => {
		setuserjournals(journals);
		setflaggedjournals(journals.filter((j: any) => j.flagged))
	}, [journals])

	useEffect(() => {
		getMentorJournals();
	}, []);

	const getMentorJournals = () =>{
		if (IsMentored) {
			api.post('/mentor/journals').then(async (resp) => {
				if (resp.data !== false) {
					if(resp.data.length){ // to avoid uneccesary re-render
						setmentorjournals(resp.data);
					}
				}
			}).catch((e) => {
				toast({ title: 'Unable to retrieve mentor journals ...', description: "Check your internet connection and refresh the page", status: 'error', position: 'top', duration: 3000, isClosable: true });
			})
		}
	}

	useEffect(() => {
		setLoading(true);
		SyncAPI(user.id).then(async (res) => {
			setLoading(false);
			await db.users.get(user.id).then((u) => {
				setUser(u);
			});
		});
	}, [])

	const openjournal = async (id: any, published: string) => {
		try {
			if (JSON.parse(published)) {
				setLoadingid(id);
				await api.post('/mentor/getjournal', { id: id }).then(async (resp) => {
					if (resp.data !== false) {
						let j: JournalEntry = resp.data;
						journalRefMentor.current.openModal(j);
					}
					else {
						toast({ title: 'Unable to retrieve mentor journal entry ...', description: "Try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: true });
					}
					setLoadingid(0);
				}).catch((e) => {
					toast({ title: 'Unable to retrieve mentor journal entry ...', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: true });
					setLoadingid(0);
				})
			}
			else {
				journalRef.current.openModal(id, "");
			}
		}
		catch (e) { }
	}
	const newjournalentry = (date: string) => {
		allowjournaladd(date).then((r: boolean) => {
			if (r) {
				journalRef.current.openModal(0, date);
			}
			else {
				toast({ title: 'Max weekly journal entries reached ...', description: "Upgrade your subscription to allow more journal entries per week", status: 'error', position: 'top', duration: 3000, isClosable: true });
			}
		})
	}

	function eventColor(category: string) {
		switch (category) {
			case 'general': return "#01B574";
			case 'education': return "#3965FF";
			case 'outlook': return "#ffa63f";
			case 'review': return "#EA4848";
		}
	}

	function renderIcon(category: string, me: string) {
		switch (category) {
			case 'general': return <Icon as={BsJournalText} boxSize={5} color={'white'} me={me} />;
			case 'education': return <Icon as={MdOutlineTipsAndUpdates} boxSize={5} color={'white'} me={me} />;
			case 'outlook': return <Icon as={MdCalendarMonth} boxSize={5} color={'white'} me={me} />;
			case 'review': return <Icon as={MdOutlineQueryStats} boxSize={5} color={'white'} me={me} />;
			default: return null;
		}
	}
	const updatecategory = (cat: string) => {
		if (cat !== "") {
			setflaggedjournals(userjournals.filter((j: any) => j.flagged && j.category === cat))
		}
		else {
			setflaggedjournals(userjournals.filter((j: any) => j.flagged))
		}
	}

	return (
		<>
			<Flex direction={'column'} pt={{ base: '130px', md: '80px', xl: '80px' }} maxH={'100%'}>
				<Grid gridTemplateRows='repeat(1, 1fr)' gridTemplateColumns='repeat(4, 1fr)' gap={5} >
					<GridItem colSpan={flaggedjournals.length ? { base: 4, lg: 3 } : 4} rowSpan={1}>
						<JournalCalendar newjournalentry={newjournalentry} openjournal={openjournal} userJournals={userjournals} mentorJournals={mentorjournals} loading={loading} loadingid={loadingid} updatecategory={updatecategory} />
					</GridItem>
					<GridItem colSpan={{ base: 4, md: 2, lg: 1 }} rowSpan={1} hidden={!flaggedjournals.length} >
						<Card borderWidth={'1px'} maxH={'1200px'} >
							<Flex direction={'row'}>
								<Icon as={RiFlag2Line} boxSize={4} me='10px' mt='5px'></Icon>
								<FormLabel>Flagged entries</FormLabel>
							</Flex>
							<Flex direction={'column'} mt='20px' overflowY={'auto'}>
								{flaggedjournals.map((arr) => (
									<Box key={arr.id} height={'auto'} bg={eventColor(arr.category)} w='100%' borderRadius={'5px'} h={'40px'} p={'10px'} mt='10px' onClick={() => openjournal(arr.id, 'false')} _hover={{ background: 'rgba(0, 0, 0, 0.25);' }} cursor={'pointer'}>
										<Flex direction={'row'} justify={'start'}>
											{renderIcon(arr.category, '10px')}
											<Text fontSize={{ base: 'xs', md: 'md' }} fontWeight={'700'} color={'white'}>{arr.title}</Text>
										</Flex>
									</Box>
								))}
							</Flex>
						</Card>
					</GridItem>
				</Grid>
				<JournalDrawer ref={journalRef} />
				<JournalDrawerMentor ref={journalRefMentor} />
			</Flex>
		</>
	);
}
