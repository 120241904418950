import { Box, Flex, FormLabel, Heading, Icon, IconButton, Spinner, Text, useToast } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import Card from 'components/card/Card'
import IconBox from 'components/icons/IconBox';
import { IoMdPulse } from 'react-icons/io';
import { MdChecklistRtl, MdNumbers, MdOutlineInventory, MdOutlineWarningAmber, MdPercent } from 'react-icons/md';
import { RiPulseLine } from 'react-icons/ri';
import { StatSummary } from 'views/trader/trades/variables/types';

const SummaryStats = (props: { loading: boolean, stats: StatSummary, currency: string }) => {

  const bdColor = useColorModeValue('gray.200', 'gray.700');
  const iconColor = "gray.400";
  const boxBg = useColorModeValue('white', 'navy.800');
  const statColor = useColorModeValue('navy.700', 'gray.200');
  const spinnerColor = useColorModeValue('gray.300', 'brand.300');
  const bgcolor = useColorModeValue('white', 'navy.900');
  const headercolor = "blue.500";
  const mbottom = '10px'
  const toast = useToast();

  return (
    <Card borderColor={bdColor} borderWidth='1px' h={'100%'} bg='transparent' mt='10px'>
      <Flex>
        <Heading size={'xs'} mt='-30px' bg={bgcolor} px='10px'>Summary</Heading>
      </Flex>

      <Box h={'100%'} justifyContent={'center'} alignContent={'center'} hidden={!props.loading}>
        <Flex justify={'center'}>
          <Spinner color={spinnerColor}></Spinner>
        </Flex>
      </Box>

      <Flex direction={'column'} hidden={props.loading}>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Profit factor</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.profit_factor.toFixed(2)}</Text>
          </Flex>
          <Flex>
            <IconButton w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'} onClick={() => toast({ title: 'Profit factor', description: 'Ratio of TOTAL WIN (nett) vs TOTAL LOSS (nett) for the selected period', status: 'info', position: 'top', duration: 5000, isClosable: true })}
              icon={<Icon color={iconColor} as={MdPercent} w="20px" h="20px" />} aria-label={''} />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Avg R-Value realised</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.avg_R !== 0 ? props.stats.avg_R.toFixed(2) : '-'}</Text>
          </Flex>
          <Flex>
            <IconButton w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'} onClick={() => toast({ title: 'AVG R-Value realised', description: 'Average R-Value realised for the selected period (trades with no stop or target are excluded)', status: 'info', position: 'top', duration: 5000, isClosable: true })}
              icon={<Icon color={iconColor} as={RiPulseLine} w="20px" h="20px" />} aria-label={''} />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Avg R-Value targeted</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.avg_R_target!==0?props.stats.avg_R_target.toFixed(2):'-'}</Text>
          </Flex>
          <Flex>
            <IconButton w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'} onClick={() => toast({ title: 'AVG R-Value targeted', description: 'Average R-Value targeted for the selected period (trades with no stop or target are excluded)', status: 'info', position: 'top', duration: 5000, isClosable: true })}
              icon={<Icon color={iconColor} as={RiPulseLine} w="20px" h="20px" />} aria-label={''} />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>AVG Rulescore</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.avg_Rulescore.toFixed()}%</Text>
          </Flex>
          <Flex>
            <IconButton w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'} onClick={() => toast({ title: 'AVG Rulescore', description: 'Average rulescore where rules are used (ignored if no rules used)', status: 'info', position: 'top', duration: 5000, isClosable: true })}
              icon={<Icon color={iconColor} as={MdChecklistRtl} w="20px" h="20px" />} aria-label={''} />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>AVG Daily Trades</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.avg_trades.toFixed()}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={MdNumbers} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Primary strategy</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.max_strategy}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={MdOutlineInventory} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>

        <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
          <Flex direction={'column'}>
            <Text color={headercolor} fontSize="sm" fontWeight="600" >Primary mistake</Text>
            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.max_mistake}</Text>
          </Flex>
          <Flex>
            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
              icon={<Icon color={iconColor} as={MdOutlineWarningAmber} w="20px" h="20px" />}
            />
          </Flex>
        </Flex>
      </Flex>




    </Card>
  )
}

export default SummaryStats