
import HeroLeft from "layouts/landing/sections/HeroLeft";
import LandingLayout from "layouts/landing";

import dashlight from 'assets/img/jorno/light.png';
import dashdark from 'assets/img/jorno/dark.png';
import { useColorModeValue } from "@chakra-ui/system";
import Benefits from "./pages/Benefits";
import Markets from "./pages/Markets";
import Platforms from "./pages/Platforms";
import { Flex } from "@chakra-ui/react";

export default function Landing() {

    const image = useColorModeValue(dashlight, dashdark);

    return (
        <LandingLayout>
            <Flex w='100%' left="0" right="0" bgRepeat="no-repeat" top="0" bgGradient="linear(to-b, brand.600, brand.600)" justify={'center'} p='20px'>
                <HeroLeft
                    title="Trading journal and analytics"
                    //subtitle="Trading is a marathon, not a get-rich-quick scheme. To progress from dreamer to pro trader, you need to be dedicated and consistent. Keeping a journal of your wins, losses, and lessons gives you the best chance of rising above the majority who never succeed in the markets."
                    subtitle="Ready to take your trading to the next level? Track your performance, identify patterns, improve decision-making and foster discipline and consistency for long-term trading success."
                    image={image}
                    minH='30vh'
                    mt={'60px'}
                    mb={'60px'}
                />
            </Flex>
            <Benefits />
            <Markets />
            <Platforms />
        </LandingLayout>
    );
}