import { Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useDisclosure, Flex, Input, FormControl, Icon, InputGroup, InputRightElement, useToast, FormLabel, Text } from '@chakra-ui/react'
import React, { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiEyeCloseLine } from 'react-icons/ri'
import { TbUserCheck } from 'react-icons/tb';
import { useAuth } from 'services/auth/AuthProvider';

const PasswordEntry = forwardRef((props: { ifVerifiedAction: any }, ref) => {

  useImperativeHandle(ref, () => ({
    openInput() {
      onOpen();
    }
  }));

  const { user, verifyPassword } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()
  const textColorSecondary = 'gray.400';

  const [show, setShow] = useState(false);
  const [wp, setWp] = useState('');
  const [verifying, setVerifying] = useState(false);
  const handleShowClick = () => setShow(!show);
  const toast = useToast();

  const updateField = (e: ChangeEvent<HTMLInputElement>) => {
    setWp(e.target.value.trim());
  }

  const doverification = async () => {
    if (wp === '') {
      toast({ title: 'Enter password', description: "", status: 'warning', position: 'top', duration: 2000, isClosable: false })
    }
    else {
      setVerifying(true);

      await verifyPassword({ username: user.username, password: wp }).then((resp: any) => {
        if (resp === undefined) {
          toast({ title: 'Unable to verify', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false })
          setVerifying(false);
          closeInput();
        }
        else {
          if (resp.data === true) {
            setVerifying(false);
            props.ifVerifiedAction();
            closeInput();
          }
          else {
            toast({ title: 'Verification failed ... try again', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false })
            setVerifying(false);
          }
        }
      }).catch(() => {
        toast({ title: 'Unable to verify', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 4000, isClosable: false })
        setVerifying(false);
      })
    }

  }
  const closeInput = () => {
    setWp('');
    onClose();
  }

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={closeInput} closeOnOverlayClick={false}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <Flex direction={'row'}>
                <Icon as={TbUserCheck} boxSize={7} me='20px' /><Text>Please confirm</Text>
              </Flex>
            </AlertDialogHeader>
            <AlertDialogBody>
              <Flex>
                <FormControl>
                  <FormLabel size={'xs'} mb='10px' ms='10px'>Enter your password ...</FormLabel>
                  <InputGroup size="md">
                    <Input id="password" name="password" type={show ? 'text' : 'password'} fontSize="sm" ms={{ base: '0px', md: '4px' }} placeholder="Min. 10 characters" size="lg" variant="auth" onChange={updateField} />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} _hover={{ cursor: 'pointer' }} as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={handleShowClick} />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeInput}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={doverification} ml={3} isLoading={verifying} loadingText='Verifying'>
                Verify
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
});

export default PasswordEntry