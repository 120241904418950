/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Spinner,
  useToast
} from '@chakra-ui/react';
import CenteredAuth from 'layouts/auth/variants/Centered';

import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from 'services/auth/AuthProvider';

function ForgotPassword() {
  // Chakra color mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toast = useToast();

  const [showresend, setShowResend] = useState(true);
  const [resent, setResent] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const { verifyEmailResendToken, verifyEmail } = useAuth();//useContext(AuthContext);

  if (searchParams.get("t") === null) { // t is the token sent in email, use token to retrieve username before verifying : /auth/verify?t=tokenvalue
    navigate("/auth/signin");
  }

  const handleResend = async () => {
    setShowResend(false);

    await verifyEmailResendToken({ token: searchParams.get("t") }).then(async (res) => {

      if (res.data.message === "resent") {
        toast({ title: 'Resent verification email', description: "Check your junk folder if you can't find it.", status: 'success', position: 'top', duration: null, isClosable: true })
        setShowResend(true);
        setResent(true);
      }
      if (res.data.message === "invalid" || res.data.message === "failed") {
        toast({ title: 'Verification failed', description: "Verification link invalid. Sign in with your credentials to get a new link.", status: 'error', position: 'top', duration: 5000, isClosable: true })
        navigate("/auth/signin");
      }
    }).catch(async (err) => {
      toast({ title: 'Resending email failed', description: "Please try resend again in a few minutes", status: 'error', position: 'top', duration: 5000, isClosable: true })
      setVerifying(false);
      setShowResend(true);
    });
  };


  const handleVerify = async () => {
    setVerifying(true);
    //verify();

    await verifyEmail({ token: searchParams.get("t") }).then(async (res) => {

      if (res.status === 200) {

        setVerifying(false);
        if (res.data.message === "verified") {
          toast({title: 'Verified, thank you!',description: "You can now sign in", status: 'success', position: 'top', duration: 3000, isClosable: true })
          navigate("/auth/signin");
        }
        if (res.data.message === "invalid") {
          toast({ title: 'Verification failed', description: "Verification link invalid. Sign in with your credentials to get a new link.", status: 'error', position: 'top', duration: 5000, isClosable: true })
          navigate("/auth/signin");
        }
        if (res.data.message === "expired") {
          toast({ title: 'Verification expired', description: "Click link below to send a new verification email", status: 'info', position: 'top', duration: 5000, isClosable: true, })
        }
      }

    }).catch(async (err) => {
      toast({
        title: 'Verification failed', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 5000, isClosable: true
      })
      setVerifying(false);
    });
  };

  return (
    <CenteredAuth cardTop={{ base: '140px', md: '24vh' }} cardBottom={{ base: '50px', lg: 'auto' }}>
      <Flex w="100%" maxW="max-content" mx={{ base: 'auto', lg: '0px' }} me="auto" h="100%" justifyContent="center" px={{ base: '25px', md: '0px' }} flexDirection="column">

      </Flex>
      <Flex w="100%" maxW="max-content" mx={{ base: 'auto', lg: '0px' }} me="auto" h="100%" justifyContent="center" px={{ base: '25px', md: '0px' }} flexDirection="column">
        <Box mb="34px">
          <Heading
            color={textColor} fontSize="36px" mb="16px" mx={{ base: 'auto', lg: 'unset' }} textAlign={{ base: 'center', lg: 'left' }}>Email Verification
          </Heading>
        </Box>
        <Flex zIndex="2" direction="column" w={{ base: '100%', md: '425px' }} maxW="100%" background="transparent" borderRadius="15px" mx={{ base: 'auto', lg: 'unset' }} me="auto" mb={{ base: '20px', md: 'auto' }}>

          <Text hidden={!resent && showresend} mb="36px" ms="4px" mt="25px" color={textColor} fontWeight="400" fontSize="md">Verification email resent, you're safe to close this window.</Text>
          <Button hidden={resent} type="submit" variant="brand" fontSize="14px" fontWeight="500" w="100%" h="50" mb="24px" isLoading={verifying} loadingText='Verifying' onClick={handleVerify}>Verify Now</Button>

          <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="0px">
            <Text hidden={resent} color={textColorDetails} fontWeight="400" fontSize="14px" mx={{ base: 'auto', lg: 'unset' }} textAlign={{ base: 'center', lg: 'left' }}>
              Not working or link expired?
              <Link color={textColorBrand} as="span" ms="5px" fontWeight="500" onClick={handleResend}>
                Resend verification email
              </Link>
            </Text>
          </Flex>
          <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="25px">
            <Spinner hidden={showresend} />
          </Flex>
        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default ForgotPassword;



