
import axios from 'axios';
import { useEffect } from 'react'
import axiosRetry from 'axios-retry';
import { useAuth } from './auth/AuthProvider';

const api = axios.create({
  baseURL: "/api" //  "/api"   "http://localhost:8080/api"
})

axiosRetry(api, {
  retries: 3, retryDelay: axiosRetry.exponentialDelay
});

const AuthInterceptor = (props: { children: any }) => {

  const { signout } = useAuth();

  api.defaults.withCredentials = true;

  useEffect(() => {

    const resInterceptor = (response: any) => {
      return response;
    }

    const errInterceptor = async (error: any) => {

      const origreq = error.config;

      if (error.message.toLowerCase() === "network error") { // ie API not reachable
        return Promise.resolve();
      }
      else if (error.response.status) {

        if (error.response.status === 401 && !origreq._retry) {

          origreq._retry = true;

          try {
            await api.post("/auth/refreshtoken", {}).catch((error) => {
              if (error.response.status === 400) {
                signout();
                return Promise.resolve();
              }
            });
            return await api(origreq);
          } catch (err) {
            signout();
            return Promise.resolve();
          }
        }
        else if (error.response.status === 400) {
          signout();
          return Promise.resolve();
        }

        return Promise.reject(error);
      }
      else {
        return Promise.resolve();
      }
    }

    const interceptor = api.interceptors.response.use(resInterceptor, errInterceptor);

    return () => api.interceptors.response.eject(interceptor);

  }, [])

  return props.children;
}

export default api;
export { AuthInterceptor }