import {
    Box,
    Flex,
    Image,
    Heading,
    Stack,
    useColorModeValue
} from "@chakra-ui/react";

import FadeInSection from "./FadeInSection";
import "./fadeffect.css"

const HeroLeft = (props: { title: string, subtitle: string, image: string, minH: string, mt?:string, mb?:string }) => {

    const textColor = useColorModeValue('white', 'white');

    return (
        <FadeInSection>
            <></>
            <Flex mt={props.mt} mb={props.mb} position={'relative'} align="center" maxW={{ xl: "1200px" }} justify={{ base: "center", md: "space-around", xl: "space-between" }} direction={{ base: "column", md: "row" }} wrap="nowrap" minH={props.minH} >
                <Stack spacing={6} w={{ base: "80%", md: "40%" }} align={["center", "center", "flex-start", "flex-start"]}>
                    <Heading as="h1" size="lg" fontWeight="bold" color={textColor} textAlign={["center", "center", "left", "left"]}>
                        {props.title}
                    </Heading>
                    <Heading as="h2" size="md" color={textColor} opacity="0.8" fontWeight="normal" lineHeight={1.5} textAlign={["center", "center", "left", "left"]}>
                        {props.subtitle}
                    </Heading>
                </Stack>
                <Box w={{ base: "80%", sm: "60%", md: "50%" }} mt={{ base: 12, md: 0 }}>
                    <Image src={props.image} />
                </Box>
            </Flex>
        </FadeInSection>
    );
}

export default HeroLeft
