/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Button, Flex, Heading, Stack, useColorModeValue, Text, useToast, Image, FormLabel } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useEffect, useRef, useState } from 'react';
import PackSubsciption from './components/PackSubscription';
import { useAuth } from 'services/auth/AuthProvider';
import api from 'services/api';
import { SubscriptionProduct } from '../trades/variables/types';
import ChangeSubscription from './components/ChangeSubscriptionModal';
import lemon from 'assets/svg/lemonsqueezy.svg';
import PortalUpdate from './components/PortalUpdate';


export default function SubscriptionPage() {

  const { user, signout, usersubscription } = useAuth();

  const [umail, setUmail] = useState('');
  const [products, setProducts] = useState<SubscriptionProduct[]>([]);
  //const [userSubscription, setUserSubscription] = useState<Subscription>(newSubscription());
  //const [downgradesub, setDowngradesub] = useState('');

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bgcolor = useColorModeValue('white', 'navy.900');
  const toast = useToast();
  const subRef = useRef(null);
  const portalRef = useRef(null);

  const [activeButton, setActiveButton] = useState({
    monthly: true,
    yearly: false,
  });


  useEffect(() => {
    api.post('/subs/usermailandproducts')
      .then((res) => {
        if (res.data === false) {
          setUmail('');
          setProducts([]);
        }
        else {
          setUmail(res.data.email);
          setProducts(res.data.products);

          //alert(JSON.stringify(res.data.products))

          try {
            window.createLemonSqueezy();
            window.LemonSqueezy.Setup({
              eventHandler: (event) => {
                if (event.event) {
                  if (event.event === "Checkout.Success") {
                    //alert(JSON.stringify(event));
                    window.LemonSqueezy.Url.Close();
                    signout();
                    toast({ title: 'Subscribed successfully ...', description: "Please sign in again to access plan features", status: 'success', position: 'top', duration: 4000, isClosable: true })
                  }
                  else if (event.event === "PaymentMethodUpdate.Updated") {
                    window.LemonSqueezy.Url.Close();
                    toast({ title: 'Payment method updated', description: "", status: 'success', position: 'top', duration: 3000, isClosable: true })
                  }
                }
              }
            })
          }
          catch (e) { }
        }
      }).catch(function (error) {
        setUmail('');
      });
  }, [])

  const dosubscibe = async (isUpgrade: boolean, sub: string) => {
    if (!isUpgrade) {// ie password verify to downgrade
      if (sub === 'FREE') {
        toast({ title: 'Downgrade to FREE', description: <Text whiteSpace="pre-line">{"\nPlease cancel your subscription by clicking the ACTIVE button.\n\nYou're back on the FREE plan automatically when it expires.\n\n"}</Text>, status: 'info', position: 'top', duration: 7000, isClosable: true })
      }
      else {
        subDowngrade(sub);
      }
    }
    else {
      subUpgrade(sub)
    }
  }

  const subUpgrade = (sub: string) => {
    if (umail === '') {
      toast({ title: 'Server unavailable', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: true, })
    }
    else {
      if (usersubscription && usersubscription.subscription_id > 0) {
        if (products.length > 0) {
          subRef.current.openInput(sub, true, activeButton.monthly, usersubscription, products, false);
        }
        else {
          toast({ title: 'Server unavailable', description: "Please check your internet connection, or refresh page and try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: true, })
        }
      }
      else {
        if (products.length > 0) {
          let pr = products.filter((p) => p.role === sub && p.period === (activeButton.monthly ? "MONTHLY" : "YEARLY"));
          const live = "https://tradejorno.lemonsqueezy.com/checkout/buy/" + pr[0].store;
          const url = live + '?embed=1&media=0&checkout[email]=' + umail + '&checkout[custom][user_id]=' + user.id;// + '&dark=' + (theme === 'dark' ? 1 : 0);
          window.LemonSqueezy.Url.Open(url);
        }
        else {
          toast({ title: 'Server unavailable', description: "Please check your internet connection, or refresh page and try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: true, })
        }
      }
    }
  }
  const subDowngrade = (sub: string) => {
    if (!products.length) {
      toast({ title: 'Server unavailable', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: true, })
    }
    else {
      subRef.current.openInput(sub, false, activeButton.monthly, usersubscription, products, false);
    }
  }

  const pmtupdate = (url: string) => {
    if (url.includes("/billing?")) { // for non-card payments, opens portal in new window
      window.open(url, '_blank')
    }
    else { // for card payments, opens overlay
      window.LemonSqueezy.Url.Open(url);
    }
  }

  const updateBilling = (sub: string) => {
    if (!products.length) {
      toast({ title: 'Server unavailable', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: true, })
    }
    else {
      if (usersubscription.status === "cancelled") {
        subRef.current.openInput(user.role, true, usersubscription.period.toUpperCase() === "MONTHLY", usersubscription, products, true);
      }
      else {
        subRef.current.openInput(user.role, true, usersubscription.period.toUpperCase() !== "MONTHLY", usersubscription, products, true);
      }

    }
  }
  const openPortal = () =>{
    portalRef.current.openInput();
  }

  return (
    <>
      <Flex direction={{ base: 'column', xl: 'row' }} pt={{ base: '130px', md: '100px' }}>
        <Flex direction="column" width="stretch">
          <Card borderWidth={'1px'} bg={'transparent'}>

            <Flex>
              <Heading size={'md'} mt='-35px' bg={bgcolor} px='10px'>Management</Heading>
            </Flex>
            <Flex direction="column" alignSelf="center" justifySelf="center" overflow="hidden">
              <Flex direction="column" textAlign="center" justifyContent="center" align="center" mb="38px">
                <Flex mb={'20px'} mt={'40px'} zIndex="2" bg="brand.900" borderRadius="60px" p="6px">
                  <Button variant="no-hover" w="135px" h="40px" fontSize="xs" color={activeButton.monthly ? 'brand.500' : 'white'} bg={activeButton.monthly ? 'gray.200' : 'transparent'} onClick={() => setActiveButton({ monthly: true, yearly: false })} borderRadius="60px">
                    MONTHLY
                  </Button>
                  <Button variant="no-hover" w="135px" h="40px" fontSize="xs" color={activeButton.yearly ? 'brand.500' : 'white'} bg={activeButton.yearly ? 'gray.200' : 'transparent'} onClick={() => setActiveButton({ monthly: false, yearly: true })} borderRadius="60px">
                    YEARLY
                  </Button>
                </Flex>
                <Stack direction={{ sm: 'column', lg: 'row' }} alignItems="flex-end" spacing="20px" mt="40px" mb={{ base: '0px', md: '40px' }}>

                  <PackSubsciption title="FREE" button="FREE" dosubscription={dosubscibe} updatepayment={pmtupdate} changebilling={updateBilling} openportal={openPortal}
                    price={
                      <Text textAlign="start" w="100%" color={textColor} fontSize="35px" fontWeight="bold">
                        {activeButton.monthly ? '$0' : '$0'}
                        <Text as="span" color="secondaryGray.600" fontSize="35px" fontWeight="bold">
                          {activeButton.monthly ? '/mo' : '/yr'}
                        </Text>
                      </Text>
                    }
                    details="(Yes, that's ZERO!)"
                    monthly ={activeButton.monthly}
                    benefits={[
                      '1 trading account',
                      '1 trading group',
                      '1 journal entry per week',
                      '25 trades per week',
                      'No Mentoring access',
                    ]}
                  />

                  <PackSubsciption title="Novice trader" button="NOVICE" dosubscription={dosubscibe} updatepayment={pmtupdate} changebilling={updateBilling} openportal={openPortal}
                    price={
                      <Text textAlign="start" w="max-content" color={textColor} fontSize="35px" fontWeight="bold">
                        {activeButton.monthly ? '$12.99' : '$139.99'}
                        <Text as="span" color="secondaryGray.600" fontSize="35px" fontWeight="bold">
                          {activeButton.monthly ? '/mo' : '/yr'}
                        </Text>
                      </Text>
                    }
                    details={activeButton.monthly ? '(Per trader)' : '(1 month is FREE)'}
                    monthly ={activeButton.monthly}
                    benefits={[
                      '5 trading accounts',
                      '2 trading groups',
                      '2 journal entries per week',
                      '250 trades per week',
                      '1 mentor',
                    ]}
                  />

                  <PackSubsciption title="PRO trader" button="PRO" highlighted={true} dosubscription={dosubscibe} updatepayment={pmtupdate} changebilling={updateBilling} openportal={openPortal}
                    price={
                      <Text textAlign="start" w="max-content" color={textColor} fontSize="35px" fontWeight="bold">
                        {activeButton.monthly ? '$24.99' : '$269.99'}
                        <Text as="span" color="secondaryGray.600" fontSize="35px" fontWeight="bold">
                          {activeButton.monthly ? '/mo' : '/yr'}
                        </Text>
                      </Text>
                    }
                    details={activeButton.monthly ? '(Per trader)' : '(1 month is FREE)'}
                    monthly ={activeButton.monthly}
                    benefits={[
                      '20 trading accounts',
                      '5 trading groups',
                      '7 journal entries per week',
                      '1500 trades per week',
                      'Up to 10 mentors',
                    ]}
                  />

                  <PackSubsciption title="Mentor" button="MENTOR" dosubscription={dosubscibe} updatepayment={pmtupdate} changebilling={updateBilling} openportal={openPortal}
                    price={
                      <Text textAlign="start" w="max-content" color={textColor} fontSize="35px" fontWeight="bold">
                        {activeButton.monthly ? '$39.99' : '$438.99'}
                        <Text as="span" color="secondaryGray.600" fontSize="35px" fontWeight="bold">
                          {activeButton.monthly ? '/mo' : '/yr'}
                        </Text>
                      </Text>
                    }
                    details={activeButton.monthly ? '(Per trader)' : '(1 month is FREE)'}
                    monthly ={activeButton.monthly}
                    benefits={[
                      'Unlimited trading accounts',
                      'Unlimited trading groups',
                      'Unlimited journal entries per week',
                      'Unlimited trades per week',
                      'Mentor other traders',
                    ]}
                  />
                </Stack>
              </Flex>
              <Flex direction={'row'} justify={'center'} mb='20px'>
                <Image src={lemon} boxSize={6}></Image>
                <FormLabel size={'sm'} ms='10px' color={'gray.400'}><a color={textColor} href={'https://www.lemonsqueezy.com/'} target='_blank'>Payments powered by  Lemon Squeezy - TradeJorno does not save or manage your card details</a> </FormLabel>
              </Flex>

            </Flex>

          </Card>
        </Flex>
      </Flex>
      <ChangeSubscription ref={subRef} gotoportal={openPortal}/>
      <PortalUpdate ref={portalRef}/>
    </>

  );
}


