import { Flex, Icon, IconButton, useToast, Text } from "@chakra-ui/react";
import { MouseEvent, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { db } from "services/db";
import { seteuid } from "process";

export const TableEditCell = (props: { row: any, table: any }) => {
    const meta = props.table.options.meta;
    const validRow = meta?.validRows[props.row.id];
    const disableSubmit = validRow ? Object.values(validRow)?.some(item => !item) : false;
    const [editable, setEditable] = useState(true);

    const toast = useToast();

    useEffect(() => {
        db.instruments.where({ ticker: props.row.original.ticker }).count().then((c) => {
            setEditable(c > 0);
        });
    }, [])

    const setEditedRows = (e: MouseEvent<HTMLButtonElement>) => {
        if (editable) {
            const elName = e.currentTarget.name;
            meta?.setEditedRows((old: []) => ({
                ...old,
                [props.row.id]: !old[props.row.id],
            }));
            if (elName !== "edit") {
                e.currentTarget.name === "cancel" ? meta?.revertData(props.row.index) : meta?.updateRow(props.row.index);
            }
        }
        else{
            toast({ title: "Ticker '"+props.row.original.ticker+"' was deleted", description: <Text whiteSpace="pre-line">{"\nGo to 'ACCOUNT SETTINGS' to fix this:\n\n1) If you DIDN'T delete it, click 'Re-Sync Data'\n\n or ...\n\n2) Add instrument ticker '"+props.row.original.ticker+"' again\n\nThen come back here to edit fees.\n\n"}</Text>, status: 'error', position: 'top', duration: null, isClosable: true })                                      
        }
    };

    return (
        <>
            <Flex justifyContent='start'>
                {meta?.editedRows[props.row.id] ? (
                    <>
                        <IconButton
                            variant='solid'
                            colorScheme='blue'
                            size='sm'
                            aria-label='done'
                            name='done'
                            icon={<Icon as={FaCheck} w="16px" h="16px" />}
                            onClick={setEditedRows}
                            disabled={disableSubmit}
                            ms='5px' me='5px'
                            borderRadius={'5px'}
                        />
                    </>
                ) : (
                    <>
                        <IconButton
                            variant='outline'
                            size='sm'
                            aria-label='edit'
                            name='edit'
                            icon={<Icon as={MdEdit} w="16px" h="16px" />}
                            onClick={setEditedRows}
                            ms='5px' me='5px'
                            borderRadius={'5px'}
                        />
                    </>
                )}
            </Flex>
        </>
    );
};
