import { createContext, useContext, useState } from "react";

const LoadingContext = createContext({} as any);

export const LoadingProvider = (props: { children: any }) => {

    const [loading, updateLoading] = useState(false);

    const setLoading = (load: boolean) =>{
        updateLoading(load);
    }

    return (
        <LoadingContext.Provider value={{ loading, setLoading }}>
            {props.children}
        </LoadingContext.Provider>
    );

}

export const useLoading = () => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoading error");
    }
    return context;
};