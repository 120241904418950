import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Icon, useToast, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import IconBox from 'components/icons/IconBox';
import { useState } from 'react';
import { BsClipboardData, BsJournalBookmark } from 'react-icons/bs';
import { LuLayoutTemplate } from 'react-icons/lu';
import { MdInsights, MdOutlineAccountTree, MdOutlineDashboardCustomize } from 'react-icons/md';
import { TbUserQuestion } from 'react-icons/tb';
import api from 'services/api';
import { JUserStats } from 'views/trader/trades/variables/types';

const UserStats = (props: { traderid: number }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const headercolor = useColorModeValue('', '');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [userstats, setUserstats] = useState<JUserStats>({
        trades: 0,
        accounts: 0,
        groups: 0,
        strategies: 0,
        platforms: [],
        journals: 0
    });

    const openmodal = async () => {
        setLoading(true);

        await api.post('/admin/stats', { uid: props.traderid }).then(async (res) => {
            if (res.data === false) {
                toast({ title: 'Error fetching stats... try again', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false })
            }
            else {
                setUserstats(res.data);
            }
        }).catch(async (error) => {
            toast({ title: 'Error fetching stats... try again', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false })
        });

        setLoading(false);
        onOpen();
    }

    return (
        <>
            <Button onClick={openmodal} size={'sm'} colorScheme={'blue'} ms='10px' isLoading={loading}>
                <Icon as={TbUserQuestion} boxSize={5}></Icon>
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size={'sm'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Trader stats</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction={'column'}>

                            <Flex direction={'row'} mb={'20px'}>
                                <Flex me='30px'>
                                    <IconBox w="40px" h="40px" bg={'transparent'} borderWidth={'1px'} borderRadius={'25%'}
                                        icon={<Icon as={MdInsights} w="20px" h="20px" color={'blue.500'}/>}
                                    />
                                </Flex>
                                <Flex direction={'column'}>
                                    <Text fontSize="sm" fontWeight="600" mb='3px' color={'blue.500'}>Trades</Text>
                                    <Text color={textColor} fontSize="sm" fontWeight="500" >{userstats.trades}</Text>
                                </Flex>
                            </Flex>

                            <Flex direction={'row'} mb={'20px'}>
                                <Flex me='30px'>
                                    <IconBox w="40px" h="40px" bg={'transparent'} borderWidth={'1px'} borderRadius={'25%'}
                                        icon={<Icon as={MdOutlineDashboardCustomize} w="20px" h="20px" color={'cyan'}/>}
                                    />
                                </Flex>
                                <Flex direction={'column'}>
                                    <Text color={'cyan'} fontSize="sm" fontWeight="600" mb='3px' >Accounts</Text>
                                    <Text color={textColor} fontSize="sm" fontWeight="500" >{userstats.accounts}</Text>
                                </Flex>
                            </Flex>

                            <Flex direction={'row'} mb={'20px'}>
                                <Flex me='30px'>
                                    <IconBox w="40px" h="40px" bg={'transparent'} borderWidth={'1px'} borderRadius={'25%'}
                                        icon={<Icon as={MdOutlineAccountTree} w="20px" h="20px" color={'cyan'}/>}
                                    />
                                </Flex>
                                <Flex direction={'column'}>
                                    <Text color={'cyan'} fontSize="sm" fontWeight="600" mb='3px' >Groups</Text>
                                    <Text color={textColor} fontSize="sm" fontWeight="500" >{userstats.groups}</Text>
                                </Flex>
                            </Flex>

                            <Flex direction={'row'} mb={'20px'}>
                                <Flex me='30px'>
                                    <IconBox w="40px" h="40px" bg={'transparent'} borderWidth={'1px'} borderRadius={'25%'}
                                        icon={<Icon as={BsClipboardData} w="20px" h="20px" color={'green.500'}/>}
                                    />
                                </Flex>
                                <Flex direction={'column'}>
                                    <Text color={'green.500'} fontSize="sm" fontWeight="600" mb='3px' >Strategies</Text>
                                    <Text color={textColor} fontSize="sm" fontWeight="500" >{userstats.strategies}</Text>
                                </Flex>
                            </Flex>

                            <Flex direction={'row'} mb={'20px'}>
                                <Flex me='30px'>
                                    <IconBox w="40px" h="40px" bg={'transparent'} borderWidth={'1px'} borderRadius={'25%'}
                                        icon={<Icon as={BsJournalBookmark} w="20px" h="20px" color={'orange.500'}/>}
                                    />
                                </Flex>
                                <Flex direction={'column'}>
                                    <Text color={'orange.500'} fontSize="sm" fontWeight="600" mb='3px' >Journals</Text>
                                    <Text color={textColor} fontSize="sm" fontWeight="500" >{userstats.journals}</Text>
                                </Flex>
                            </Flex>

                            <Flex direction={'row'} mb={'20px'}>
                                <Flex me='30px'>
                                    <IconBox w="40px" h="40px" bg={'transparent'} borderWidth={'1px'} borderRadius={'25%'}
                                        icon={<Icon as={LuLayoutTemplate} w="20px" h="20px" color={'red.500'}/>}
                                    />
                                </Flex>
                                <Flex direction={'column'}>
                                    <Text color={'red.500'} fontSize="sm" fontWeight="600" mb='3px' >Platforms</Text>
                                    <Text color={textColor} fontSize="sm" fontWeight="500" >{userstats.platforms}</Text>
                                </Flex>
                            </Flex>

                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default UserStats