import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Heading, Text, useColorModeValue, Image, Icon, UnorderedList, ListItem } from '@chakra-ui/react'
import Card from 'components/card/Card'

import listlight from 'assets/img/jorno/help/listed_light.png';
import listdark from 'assets/img/jorno/help/listed_dark.png';
import profilelight from 'assets/img/jorno/help/profile_light.png';
import profiledark from 'assets/img/jorno/help/profile_dark.png';
import sharelight from 'assets/img/jorno/help/share_light.png';
import sharedark from 'assets/img/jorno/help/share_dark.png';
import reqlight from 'assets/img/jorno/help/request_light.png';
import reqdark from 'assets/img/jorno/help/request_dark.png';
import messlight from 'assets/img/jorno/help/mess_light.png';
import messdark from 'assets/img/jorno/help/mess_dark.png';
import { RiMegaphoneLine } from 'react-icons/ri';
import { LuMousePointerClick } from "react-icons/lu";

const MentorHelp = () => {

    const bgcolor = useColorModeValue('white', 'navy.900');
    const accol = useColorModeValue('gray.400', 'gray.500');
    const listedimg = useColorModeValue(listlight, listdark);
    const profileimg = useColorModeValue(profilelight, profiledark);
    const shareimg = useColorModeValue(sharelight, sharedark);
    const reqimg = useColorModeValue(reqlight, reqdark);
    const messimg = useColorModeValue(messlight, messdark);

    return (

            <Flex direction={'column'}>
                <Text mb='30px' mt='10px'>As a mentor, you have some useful functionailty available to you:</Text>
                <Accordion allowToggle>

                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>Profile sharing</Text></Flex></Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text mb='20px'>Setup your mentor profile by clicking the 'Mentor Profile' button on the top right.</Text>
                            <Image src={profileimg} mb='20px' />
                            <Text mb='20px'>Be sure to toggle on 'Listed in mentor directory' if you want traders on the platform to be able to find you.</Text>
                            <Image src={listedimg} />
                            <Text mb='20px' mt='20px'>Of course, you can choose to remain private and connect with students by other means.</Text>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>Mentor trade sharing</Text></Flex></Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text mb='20px'>You can share trade examples with your students.</Text>
                            <Text mb='20px'>Just toggle on 'Share with students' next to the bottom save button on the trade record.</Text>
                            <Image src={shareimg} />
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>Student trade reviews</Text></Flex></Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text mb='20px'>Review and add comments to students trades. You can setup templates to speed up the review process.</Text>
                            <Text mb='20px'>Only trades that the student has chosen to share with you will be visible. Students will need to toggle on 'Request mentor review' when saving their trade record.</Text>
                            <Text mb='20px'>This can be found next to the save button on the trade record.</Text>
                            <Image src={reqimg} />
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>Strategy sharing</Text></Flex></Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text mb='20px'>You can share your strategies with your students.</Text>
                            <Text mb='20px'>Students can then copy the strategy to their profile to adapt or use as they see fit.</Text>
                            <Text mb='20px'>Just toggle on 'Share with students' next to the bottom save button.</Text>
                            <Image src={shareimg} />
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>Journal publishing</Text></Flex></Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text mb='20px'>You can share journal entries with your students.</Text>
                            <Text mb='20px'>Choose the correct category for the message you wish to convey.</Text>
                            <Text mb='20px'>This could include educational content, outlooks for the week ahead, reviews of the most recent trading period or just general journals.</Text>
                            <Text mb='20px'>Just toggle on 'Share with students' next to the save button on the journal entry.</Text>
                            <Image src={shareimg} />
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>Student messaging</Text></Flex></Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text mb='20px'>Once you have active mentorships, a notifcation button will appear on the top right to open the messaging window.</Text>
                            <Flex direction={'row'} align={'center'}>
                                <Text mb='20px'>Just click the foghorn to send a broadcast</Text>
                                <Icon boxSize={5} as={LuMousePointerClick} ms='10px' mt='-20px'/>
                            </Flex>
                            <Icon boxSize={7} as={RiMegaphoneLine} color={'red.500'} mb='20px'></Icon>
                            <Text mb='20px'>You can send messages to your entire mentorship group or individual students.</Text>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>Subscription discount</Text></Flex></Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text mb='20px'>We take a snapshot sometime in the 48 hours before your subscription is due to renew. Each time, your subscription will get a FREE month added if you meet the following criteria:</Text>
                            <UnorderedList mb='20px'>
                                <ListItem>You have 10 or more active and subscribed students.</ListItem>
                                <ListItem>You mentor profile is listed in the mentor directory at the time of the snapshot (see how in the profile sharing section above).</ListItem>
                            </UnorderedList>
                            <Text mb='20px'>If you meet the criteria, we push your renewal date back by a month, giving you an extra FREE month each time.</Text>
                        </AccordionPanel>
                    </AccordionItem>

                </Accordion>
            </Flex>

    )
}

export default MentorHelp