
import api from "services/api";
import { db } from "services/db";
import { Account, AccountGroup, DayNote, Instrument, JournalEntry, Mentee, Mentor, Mistake, NotesTemplate, Strategy, Trade } from "../../trades/variables/types";
import useJornoUuid from "views/trader/trades/hooks/useJornoUuid";

export const SyncAPI = (async (user_id: number) => {

    const uuid = useJornoUuid();

    return new Promise(async (resolve, reject) => {

        let lastid = (localStorage.getItem("lastmessage") ? Number(localStorage.getItem("lastmessage")) : 0);

        await api.post('/sync/checkapiupdates', { uuid: uuid, lastmessageid: lastid }).then(async (res) => {
            if (res.data.length) {

                await api.post('/sync/getapiupdates', { uuid: uuid, lastmessageid: lastid, updates: res.data }).then(async (res) => {

                    for (const resp in res.data) {
                        switch (resp) {
                            case 'trades':
                                for (let trade of res.data.trades) {
                                    await db.trades.get(trade.tradeid).then(async (t) => {
                                        if (t !== undefined) {
                                            trade.sync_due = t.sync_due;
                                        }
                                        else {
                                            trade.sync_due = false;
                                        }
                                        delete trade.last_uuid;
                                        await db.trades.put(trade, trade.tradeid).catch(error => { });
                                    }).catch((err) => { })
                                }
                                /*res.data.trades.forEach(async (trade: Trade) => {
                                    await db.trades.get(trade.tradeid).then(async (t) => {
                                        if (t !== undefined) {
                                            trade.sync_due = t.sync_due;
                                        }
                                        else {
                                            trade.sync_due = false;
                                        }
                                        delete trade.last_uuid;
                                        await db.trades.put(trade, trade.tradeid).catch(error => { });
                                    }).catch((err) => { })
                                })*/
                                break;
                            case 'accounts':
                                res.data.accounts.forEach(async (acc: Account) => {
                                    await db.accounts.get(acc.accountid).then(async (a) => {
                                        if (a !== undefined) {
                                            acc.sync_due = a.sync_due;
                                        }
                                        else {
                                            acc.sync_due = false;
                                        }
                                        delete acc.last_uuid;
                                        await db.accounts.put(acc, acc.accountid).catch(error => { });
                                    })
                                })
                                break;
                            case 'groups':
                                res.data.groups.forEach(async (grp: AccountGroup) => {
                                    await db.accountgroups.get(grp.groupid).then(async (g) => {
                                        if (g !== undefined) {
                                            grp.sync_due = g.sync_due;
                                        }
                                        else {
                                            grp.sync_due = false;
                                        }
                                        delete grp.last_uuid;
                                        await db.accountgroups.put(grp, grp.groupid).catch(error => { });
                                    })
                                })
                                break;
                            case 'strategies':
                                res.data.strategies.forEach(async (strat: Strategy) => {
                                    await db.strategies.get(strat.id).then(async (s) => {
                                        if (s !== undefined) {
                                            strat.sync_due = s.sync_due;
                                        }
                                        else {
                                            strat.sync_due = false;
                                        }
                                        delete strat.last_uuid;
                                        await db.strategies.put(strat, strat.id).catch(error => { });
                                    })
                                })
                                break;
                            case 'templates':
                                res.data.templates.forEach(async (tmp: NotesTemplate) => {
                                    await db.templates.get(tmp.id).then(async (t) => {
                                        if (t !== undefined) {
                                            tmp.sync_due = t.sync_due;
                                        }
                                        else {
                                            tmp.sync_due = false;
                                        }
                                        delete tmp.last_uuid;
                                        await db.templates.put(tmp, tmp.id).catch(error => { });
                                    })
                                })
                                break;
                            case 'instruments':
                                res.data.instruments.forEach(async (tmp: Instrument) => {
                                    await db.instruments.get(tmp.id).then(async (t) => {
                                        if (t !== undefined) {
                                            tmp.sync_due = t.sync_due;
                                        }
                                        else {
                                            tmp.sync_due = false;
                                        }
                                        delete tmp.last_uuid;
                                        await db.instruments.put(tmp, tmp.id).catch(error => { });
                                    })
                                })
                                break;
                            case 'daynotes':
                                res.data.daynotes.forEach(async (note: DayNote) => {
                                    await db.daynotes.get(note.id).then(async (dn) => {
                                        if (dn !== undefined) {
                                            note.sync_due = dn.sync_due;
                                        }
                                        else {
                                            note.sync_due = false;
                                        }
                                        delete note.last_uuid;
                                        await db.daynotes.put(note, note.id).catch(error => { });
                                    })
                                })
                                break;
                            case 'mistakes':
                                res.data.mistakes.forEach(async (mist: Mistake) => {
                                    await db.mistakes.get(mist.id).then(async (m) => {
                                        if (m !== undefined) {
                                            mist.sync_due = m.sync_due;
                                        }
                                        else {
                                            mist.sync_due = false;
                                        }
                                        delete mist.last_uuid;
                                        await db.mistakes.put(mist, mist.id).catch(error => { });
                                    })
                                })
                                break;
                            case 'journals':
                                res.data.journals.forEach(async (jn: JournalEntry) => {
                                    await db.journals.get(jn.id).then(async (j) => {
                                        if (j !== undefined) {
                                            jn.sync_due = j.sync_due;
                                        }
                                        else {
                                            jn.sync_due = false;
                                        }
                                        delete jn.last_uuid;
                                        await db.journals.put(jn, jn.id).catch(error => { });
                                    })
                                })
                                break;
                            case 'mentees':
                                res.data.mentees.forEach(async (mr: Mentee) => {
                                    await db.mentees.get(mr.id).then(async (m) => {
                                        if (m !== undefined) {
                                            mr.sync_due = m.sync_due;
                                        }
                                        else {
                                            mr.sync_due = false;
                                        }
                                        delete mr.last_uuid;
                                        await db.mentees.put(mr, mr.id).catch(error => { });
                                    })
                                })
                                break;
                            case 'deletementees':
                                res.data.deletementees.forEach(async (id: number) => {
                                    await db.mentees.delete(id).catch(() => { });
                                    await db.mentormessages.where({ mentorshipid: id }).delete().catch((e) => { })
                                });
                                break;
                            case 'mentors':
                                res.data.mentors.forEach(async (mr: Mentor) => {
                                    await db.mentors.get(mr.id).then(async (m) => {
                                        if (m !== undefined) {
                                            mr.sync_due = m.sync_due;
                                        }
                                        else {
                                            mr.sync_due = false;
                                        }
                                        delete mr.last_uuid;
                                        await db.mentors.put(mr, mr.id).catch(error => { });
                                    })
                                })
                                break;
                            case 'deletementors':
                                res.data.deletementors.forEach(async (id: number) => {
                                    await db.mentors.delete(id).catch(() => { });
                                    await db.mentormessages.where({ mentorshipid: id }).delete().catch((e) => { })
                                });
                                break;
                            case 'mentormessages':
                                for (let mess of res.data.mentormessages) {
                                    mess.seen = false;
                                    await db.mentormessages.put(mess, mess.id).catch(error => { });
                                }
                                await db.mentormessages.orderBy('id').last().then((m) => {
                                    if (m !== undefined) {
                                        localStorage.setItem("lastmessage", m.id.toFixed())
                                    }
                                    else {
                                        localStorage.setItem("lastmessage", '0')
                                    }
                                }).catch((e) => { localStorage.setItem("lastmessage", '0') })
                                break;
                            case 'deletions':
                                res.data.deletions.trades.forEach(async (id: number) => {
                                    await db.trades.delete(id).catch(() => { });
                                });
                                res.data.deletions.accounts.forEach(async (id: number) => {
                                    await db.accounts.delete(id).catch(() => { });
                                    await db.trades.where({ accountid: id }).delete().catch(() => { }); /// extra here to delete account trades, below will catch error if trades not found
                                });
                                res.data.deletions.groups.forEach(async (id: number) => {
                                    await db.accountgroups.delete(id).catch(() => { });
                                });
                                res.data.deletions.strategies.forEach(async (id: number) => {
                                    await db.strategies.delete(id).catch(() => { });
                                });
                                res.data.deletions.templates.forEach(async (id: number) => {
                                    await db.templates.delete(id).catch(() => { });
                                });
                                res.data.deletions.daynotes.forEach(async (id: number) => {
                                    await db.daynotes.delete(id).catch(() => { });
                                });
                                res.data.deletions.mistakes.forEach(async (id: number) => {
                                    await db.mistakes.delete(id).catch(() => { });
                                });
                                res.data.deletions.journals.forEach(async (id: number) => {
                                    await db.journals.delete(id).catch(() => { });
                                });
                                res.data.deletions.instruments.forEach(async (id: number) => {
                                    await db.instruments.delete(id).catch(() => { });
                                });
                                break;
                            case 'subscription':
                                await db.users.put(res.data.subscription, res.data.subscription.id).then(async () => {
                                    await db.users.toCollection().modify(user => {
                                        user.current_user = (user.id === res.data.subscription.id ? 1 : 0);
                                    }).catch((err) => { });
                                }).catch((e) => { })
                                delete res.data.subscription.subscription;
                                localStorage.setItem('juser', JSON.stringify(res.data.subscription));
                                window.location.reload();

                                break;
                        }
                    }

                }).catch(async (err) => { });

            }

        }).catch(async (err) => { });

        await PostAPIupdates().then(() => {
            resolve('');
        })
    });

});

export const PostAPIupdates = (async () => {

    let uuid = localStorage.getItem("jornomid");

    return new Promise(async (resolve, reject) => {

        let syncs = 0;

        let trades = await db.trades.toArray();
        let tr_filter = trades.filter((trade) => trade.sync_due === true);
        for (let tr of tr_filter) {
            tr.last_uuid = uuid;
            delete tr.sync_due;
        }
        syncs += tr_filter.length;

        let strats = await db.strategies.toArray();
        let st_filter = strats.filter((strat) => strat.sync_due === true);
        for (let st of st_filter) {
            st.last_uuid = uuid;
            delete st.sync_due;
        }
        syncs += st_filter.length;

        let accounts = await db.accounts.toArray();
        let ac_filter = accounts.filter((acc) => acc.sync_due === true);
        for (let ac of ac_filter) {
            ac.last_uuid = uuid;
            delete ac.sync_due;
        }
        syncs += ac_filter.length;

        let groups = await db.accountgroups.toArray();
        let g_filter = groups.filter((g) => g.sync_due === true);
        for (let gr of g_filter) {
            gr.last_uuid = uuid;
            delete gr.sync_due;
        }
        syncs += g_filter.length;

        let templates = await db.templates.toArray();
        let tmp_filter = templates.filter((t) => t.sync_due === true);
        for (let tp of tmp_filter) {
            tp.last_uuid = uuid;
            delete tp.sync_due;
        }
        syncs += tmp_filter.length;

        let daynotes = await db.daynotes.toArray();
        let dn_filter = daynotes.filter((dn) => dn.sync_due === true);
        for (let dn of dn_filter) {
            dn.last_uuid = uuid;
            delete dn.sync_due;
        }
        syncs += dn_filter.length;

        let mistakes = await db.mistakes.toArray();
        let m_filter = mistakes.filter((m) => m.sync_due === true);
        for (let m of m_filter) {
            m.last_uuid = uuid;
            delete m.sync_due;
        }
        syncs += m_filter.length;

        let journals = await db.journals.toArray();
        let j_filter = journals.filter((j) => j.sync_due === true);
        for (let j of j_filter) {
            j.last_uuid = uuid;
            delete j.sync_due;
        }
        syncs += j_filter.length;

        let mentees = await db.mentees.toArray();
        let me_filter = mentees.filter((m) => m.sync_due === true);
        for (let m of me_filter) {
            m.last_uuid = uuid;
            delete m.sync_due;
        }
        syncs += me_filter.length;

        let mentors = await db.mentors.toArray();
        let mr_filter = mentors.filter((m) => m.sync_due === true);
        for (let m of mr_filter) {
            m.last_uuid = uuid;
            delete m.sync_due;
        }
        syncs += mr_filter.length;

        let instruments = await db.instruments.toArray();
        let i_filter = instruments.filter((i) => i.sync_due === true);
        for (let i of i_filter) {
            i.last_uuid = uuid;
            delete i.sync_due;
        }
        syncs += i_filter.length;

        if (syncs > 0) {

            const updates: { trades: Trade[], strategies: Strategy[], accounts: Account[], groups: AccountGroup[], templates: NotesTemplate[], daynotes: DayNote[], mistakes: Mistake[], journals: JournalEntry[], mentees: Mentee[], mentors: Mentor[], instruments: Instrument[] } = {
                trades: tr_filter, strategies: st_filter, accounts: ac_filter, groups: g_filter, templates: tmp_filter, daynotes: dn_filter, mistakes: m_filter, journals: j_filter, mentees: me_filter, mentors: mr_filter, instruments: i_filter
            };

            await api.post('/sync/postapiupdates', updates).then(async (res) => {
                try {
                    if (res.data === true) {
                        if (tr_filter.length) {
                            await db.trades.toCollection().modify(trade => {
                                trade.sync_due = false;
                                //trade.last_uuid = uuid;
                            });
                        }
                        if (st_filter.length) {
                            await db.strategies.toCollection().modify(strat => {
                                strat.sync_due = false;
                                //strat.last_uuid = uuid
                            });
                        }
                        if (ac_filter.length) {
                            await db.accounts.toCollection().modify(acc => {
                                acc.sync_due = false;
                                //acc.last_uuid = uuid;
                            });
                        }
                        if (g_filter.length) {
                            await db.accountgroups.toCollection().modify(grp => {
                                grp.sync_due = false;
                            })
                        }
                        if (tmp_filter.length) {
                            await db.templates.toCollection().modify(tmp => {
                                tmp.sync_due = false;
                            })
                        }
                        if (dn_filter.length) {
                            await db.daynotes.toCollection().modify(dn => {
                                dn.sync_due = false;
                            })
                        }
                        if (m_filter.length) {
                            await db.mistakes.toCollection().modify(m => {
                                m.sync_due = false;
                            })
                        }
                        if (j_filter.length) {
                            await db.journals.toCollection().modify(j => {
                                j.sync_due = false;
                            })
                        }
                        if (me_filter.length) {
                            await db.mentees.toCollection().modify(m => {
                                m.sync_due = false;
                            })
                        }
                        if (mr_filter.length) {
                            await db.mentors.toCollection().modify(m => {
                                m.sync_due = false;
                            })
                        }
                        if (i_filter.length) {
                            await db.instruments.toCollection().modify(m => {
                                m.sync_due = false;
                            })
                        }
                    }
                }
                catch (err) { }

            }).catch((err) => { })
        }

        resolve('');
    })
})
