import { Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Icon, Flex, useColorModeValue, Button, Box, Show } from '@chakra-ui/react'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { MdInsights, MdOutlineTrendingDown, MdOutlineTrendingUp } from 'react-icons/md';
import TableCard from 'views/trader/trades/components/tables/TableCard';
import { MinimalStatistics, newMinStats, Trade } from 'views/trader/trades/variables/types';
import ShowTradesTable from './ShowTradesTable';
import MinimalStatBar from './display/MinimalStatBar';
import MinimalStatBarLarge from './display/MinimalStatBarLarge';
import { MinimalStatProcess } from '../trades/variables/MinimalStatProcessor';

const ShowTradesModal = forwardRef((props: {}, ref) => {

    const drawerBg = useColorModeValue('white', 'navy.800');
    ///const { user } = useAuth();//useContext(AuthContext);

    const [title, setTitle] = useState('');
    const [trades, setTrades] = useState<Trade[]>([]);
    const [titleColor, setTitleColor] = useState(null);
    const [titleIcon, setTitleIcon] = useState(<Icon as={MdInsights} boxSize={8} ms='20px' me='20px' />);
    const [statload, setstatload] = useState(false);

    const btnRef = useRef(null);

    const [stats, setStats] = useState<MinimalStatistics>(newMinStats());

    useImperativeHandle(ref, () => ({
        async openModal(trades: Trade[], title: string, type: string) {
            onOpen();
            setTitle(title);
            switch (type) {
                case 'BUY':
                    setTitleColor('green');
                    setTitleIcon(<Icon as={MdOutlineTrendingUp} boxSize={8} ms='20px' me='20px' />);
                    break;
                case 'SELL':
                    setTitleColor('red');
                    setTitleIcon(<Icon as={MdOutlineTrendingDown} boxSize={8} ms='20px' me='20px' />);
                    break;
                default:
                    setTitleColor(null);
                    setTitleIcon(<Icon as={MdInsights} boxSize={8} ms='20px' me='20px' />);
            }
            setTimeout(() => { /// for smoother loading
                trades.sort((a: Trade, b: Trade) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime());
                setTrades(trades);
                setstatload(true);
                setTimeout(async () => {
                    await MinimalStatProcess(trades, "USD").then((s) => {
                        setStats(s);
                        setstatload(false);
                    })
                }, 1000);
            }, 500);
        }
    }));

    const closemodal = async () => {
        onClose();
        setTrades([]);
        //getDefaultGroup(user.id).then(async (grp) => {
        //    await reprocessStats(grp.groupid);
        //})
    }

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Modal onClose={closemodal} isOpen={isOpen} size={'full'} autoFocus={false} returnFocusOnClose={false}>
                <ModalOverlay />
                <ModalContent bg={drawerBg} >
                    <ModalCloseButton onClick={closemodal} />
                    <ModalHeader>
                        <Button variant={'outline'} size={'lg'} mt='20px' colorScheme={titleColor} borderWidth={3} >
                            {titleIcon}
                            <Text me='20px'>{title}</Text>
                        </Button>
                    </ModalHeader>
                    <ModalBody>
                        <Flex direction={'column'}>
                            <Box hidden={!trades.length} w="100%" mt="10px">
                                <Show breakpoint='(min-width: 1800px)'>
                                    <MinimalStatBarLarge loading={statload} stats={stats} />
                                </Show>
                                <Show breakpoint='(max-width: 1799px)'>
                                    <MinimalStatBar loading={statload} stats={stats} />
                                </Show>
                            </Box>
                            {trades.length > 0 && <Flex>
                                <TableCard title='Tagged trades' table={<ShowTradesTable trades={trades} closemodal={closemodal} />} showTitleBar={true} showBg={true} showMt={true} showPadding={true} loading={false} minh={'auto'} />
                            </Flex>}
                        </Flex>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
})

export default ShowTradesModal