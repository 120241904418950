/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { NavLink, useNavigate } from 'react-router-dom';
import React, { ChangeEvent, useState } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useColorModeValue,
  useToast,
  Spinner,
  Stack
} from '@chakra-ui/react';

// Custom components

// Assets
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import CenteredSignUp from 'layouts/auth/variants/CenteredSignUp';
import { useAuth } from 'services/auth/AuthProvider';

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const toast = useToast();
  const navigate = useNavigate();

  const [show, setShow] = React.useState(false);
  const [accept, setAccept] = React.useState(false);
  const [submission, setSubmission] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [resend, setResend] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [pwstrength, setpwstrength] = useState('');
  const [pwstrengthcol, setpwstrengthcol] = useState('');
  const { signup, verifyEmailResendUser } = useAuth();//useContext(AuthContext);

  const handleClick = () => setShow(!show);
  const handleAccept = (e: any) => setAccept(e.target.checked);

  const handleResend = async () => {
    setResend(true);

    await verifyEmailResendUser({ username: userName }).then(async (res: any) => {
      if (res.status === 200) {
        if (res.data.message === "resent") {
          toast({ title: 'Resent verification email', description: "Check your junk folder if you can't find it.", status: 'success', position: 'top', duration: 5000, isClosable: true })
          setResend(false);
        }
        if (res.data.message === "invalid") {
          toast({
            title: 'Verification link invalid',
            description: "Please try signing up again",
            status: 'error',
            position: 'top',
            duration: 5000,
            isClosable: true,
          })
          navigate("/auth/signup");
        }
      }
    }).catch(async (err: any) => {
      toast({
        title: 'Verification resend failed',
        description: "Please try again in a few minutes",
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      })
      setResend(false);
    });
  };

  const evaluatePasswordStrength = (e: ChangeEvent<HTMLInputElement>) => {

    let password = e.target.value.trim();

    if (password.length) {
      let score = 0;

      let p_length = 0;
      let p_lower = 0;
      let p_upper = 0;
      let p_number = 0;
      let p_special = 0;

      if (!password) return '';

      // Check password length
      if (password.length > 9) score += 1;
      // Contains lowercase
      if (/[a-z]/.test(password)) score += 1;
      // Contains uppercase
      if (/[A-Z]/.test(password)) score += 1;
      // Contains numbers
      if (/\d/.test(password)) score += 1;
      // Contains special characters
      if (/[^A-Za-z0-9]/.test(password)) score += 1;

      switch (score) {
        case 0:
        case 1:
        case 2:
          setpwstrength('Weak'); setpwstrengthcol('red.500'); break;
        case 3:
          setpwstrength('Medium'); setpwstrengthcol('orange.500'); break;
        case 4:
        case 5:
          setpwstrength('Strong'); setpwstrengthcol('green.500'); break;
      }
    }
    else{
      setpwstrength('');
      setpwstrengthcol('');
    }
  }

  return (
    <CenteredSignUp cardTop={{ base: '60px', md: '14vh' }} cardBottom={{ base: '50px', lg: 'auto' }}>
      <Flex maxW="max-content" mx={{ base: 'auto', lg: '0px' }} me="auto" justifyContent="center" px={{ base: '20px', md: '0px' }} flexDirection="column">
        <Box me="auto">
          <Heading color={textColor} fontSize={{ base: '34px', lg: '36px' }} mb="10px">
            Sign Up
          </Heading>
          <Text hidden={success} mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
            Enter your email and password to sign up!
          </Text>
          <Text hidden={!success} mb="36px" ms="4px" mt="40px" color={textColor} fontWeight="400" fontSize="md">
            You have signed up successfully! 👍
          </Text>
          <Text hidden={!success} mb="36px" ms="4px" mt="40px" color={textColor} fontWeight="400" fontSize="md">
            Please click the verification link in the email we've just sent you ...
          </Text>
          <Text hidden={!success} mb="36px" ms="4px" mt="40px" color={textColor} fontWeight="400" fontSize="md">
            It may be in your junk folder ...
          </Text>
        </Box>
        <Flex zIndex="2" direction="column" w={{ base: '100%', md: '420px' }} maxW="100%" background="transparent" borderRadius="15px" mx={{ base: 'auto', lg: 'unset' }} me="auto" mb={{ base: '20px', md: 'auto' }}>
          <FormControl hidden={success}>
            <Formik
              initialValues={{
                username: "",
                email: "",
                password: "",
                acceptpolicy: true
              }}
              onSubmit={(values) => {
                setSubmission(true);

                signup(values).then(async (res: any) => {
                  if (res === 400 || res === 401) {
                    toast({ title: 'Sign up failed', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 5000, isClosable: true })
                    setSubmission(false);
                  }
                  else if (res.status === 200) {
                    if (res.data.message === "username") {
                      toast({ title: 'Username not available', description: "Please choose something different.", status: 'error', position: 'top', duration: 3000 })
                      setSuccess(false);
                      setSubmission(false);
                    }
                    else if (res.data.message === "email") {
                      toast({ title: 'Email not available', description: "Please enter a different email", status: 'error', position: 'top', duration: 3000 })
                      setSuccess(false);
                      setSubmission(false);
                    }
                    else {
                      toast({ title: 'Signed up successfully', description: "Please check your emails for verification", status: 'success', position: 'top', duration: 5000, isClosable: true })
                      setSuccess(true);
                      setUserName(values.username);
                    }
                  }
                }).catch(function (error: any) {
                  toast({ title: 'Service temporarily unavailable', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false })
                  setSubmission(false);
                  setSuccess(false);
                });;

              }}
              validationSchema={Yup.object().shape({
                username: Yup.string().required('Please enter new username.').min(5, 'Username minimum 5 characters').max(20, 'Username maximum 20 characters'),
                email: Yup.string().email("Valid email required").required("Valid email required").max(50, 'Email maximum 50 characters'),
                password: Yup.string().required('Please enter new password.').min(10, 'Password minimum 10 characters').max(40, 'Password maximum 40 characters')
                  .matches(/[a-z]/, 'Lowercase letter needed')
                  .matches(/[A-Z]/, 'Uppercase letter needed')
                  .matches(/\d/, 'Number needed')
                  .matches(/[^A-Za-z0-9]/, 'Special character needed')
              })}>
              {({handleChange, handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit} onChange={handleChange}>

                  <FormControl isInvalid={!!errors.username && touched.username}>
                    <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                      Username<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Field
                      as={Input}
                      id="username"
                      name="username"
                      variant="auth" fontSize="sm" ms={{ base: '0px', md: '0px' }} type="text" placeholder="Choose username" fontWeight="500" size="lg"
                    />
                    <FormErrorMessage mb="24px" ms='20px'>{errors.username}</FormErrorMessage>
                  </FormControl>


                  <FormControl isInvalid={!!errors.email && touched.email}>
                    <FormLabel mt="24px" display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                      Email<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Field
                      as={Input}
                      id="email"
                      name="email"
                      variant="auth" fontSize="sm" ms={{ base: '0px', md: '0px' }} type="email" placeholder="you@email.com" fontWeight="500" size="lg"
                    />
                    <FormErrorMessage mb="24px" ms='20px'>Valid email required</FormErrorMessage>
                  </FormControl>


                  <FormControl isInvalid={!!errors.password && touched.password}>
                    <Stack direction="row" justify="space-between">
                      <FormLabel ms="4px" mt="24px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                        Password<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Text color={pwstrengthcol} fontSize="sm" fontWeight="500" mt="24px" tabIndex={-1}>
                        {pwstrength}
                      </Text>
                    </Stack>

                    <InputGroup size="md">
                      <Field
                        as={Input}
                        id="password"
                        name="password"
                        type={show ? 'text' : 'password'}
                        fontSize="sm" ms={{ base: '0px', md: '4px' }} placeholder="Enter strong password" size="lg" variant="auth" onChange={evaluatePasswordStrength}
                      />
                      <InputRightElement display="flex" alignItems="center" mt="4px">
                        <Icon color={textColorSecondary} _hover={{ cursor: 'pointer' }} as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={handleClick} />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage ms='20px'>{errors.password}</FormErrorMessage>
                  </FormControl>

                  <Flex justifyContent="space-between" align="center" mb="24px">

                    <FormControl display="flex" alignItems="start" mt="24px" isInvalid={!!errors.acceptpolicy && touched.acceptpolicy}>
                      <Checkbox id="acceptpolicy" colorScheme="brand" me="10px" mt="3px" onChange={handleAccept} />
                      <FormLabel htmlFor="acceptpolicy" mb="0" fontWeight="normal" color={textColorSecondary} fontSize="sm" >
                        Agree to the{' '}
                        <Link href="/home/terms" target="_blank" fontWeight="600" color={textColor}>Terms and Conditions,</Link>{' '}
                        and our{' '}
                        <Link href="/home/privacy" target="_blank" fontWeight="600" color={textColor}>Privacy Policy</Link>
                      </FormLabel>
                      <FormErrorMessage>{errors.acceptpolicy}</FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Button type="submit" variant="brand" fontSize="14px" fontWeight="500" w="100%" h="50" mb="24px" isDisabled={!accept} isLoading={submission} loadingText='Creating account'>Create my account</Button>

                </form>
              )}
            </Formik>
          </FormControl>

          <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
            <Text hidden={!success} color={textColorDetails} fontWeight="400" fontSize="14px" mx={{ base: 'auto', lg: 'unset' }} textAlign={{ base: 'center', lg: 'left' }} >
              Haven't received it?
              <Link color={textColorBrand} as="span" ms="5px" fontWeight="500" onClick={handleResend}>
                Resend verification email
              </Link>
            </Text>
          </Flex>
          <Flex flexDirection="column" justifyContent="center" alignItems={{ base: 'center', lg: 'start' }} maxW="100%" mt="40px">
            <Spinner hidden={!resend} />
          </Flex>

          <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="0px">
            <Text hidden={success} color={textColorDetails} fontWeight="400" fontSize="sm">
              Already a member?
              <NavLink to="/auth/signin">
                <Text color={textColorBrand} as="span" ms="5px" fontWeight="600">
                  Sign in
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </CenteredSignUp>
  );
}

export default SignUp;

