// Chakra imports
import { Flex, Spinner, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
export default function StatisticBox(props: {
  illustration: JSX.Element;
  focused?: boolean;
  title: string;
  value: string;
  detail: JSX.Element;
  [x: string]: any;
}) {
  const { illustration, focused, statcolor, title, value, detail, loading, ...rest } = props;

  // Chakra Color Mode
  const spinnerColor = useColorModeValue('gray.300', 'brand.300');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue(
    'secondaryGray.600',
    'secondaryGray.500',
  );
  return (
    <Card flexDirection="row" w="100%" p="25px" {...rest} justifyContent='center' borderWidth={'1px'} bg='transparent'>
      <Flex hidden={!loading} padding='16px'>
        <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='xl' ></Spinner>
      </Flex>
      <Flex align="center" justify="space-between" w="100%" hidden={loading}>
        <Flex direction="column">
          <Text color={focused ? 'secondaryGray.400' : textColorSecondary} fontSize="sm" fontWeight="500" mb="10px" lineHeight="100%">
            {title}
          </Text>
          <Text color={focused ? 'white' : (statcolor ? statcolor : textColor)} fontSize="2xl" fontWeight="700" lineHeight="100%" mb="8px" >
            {value}
          </Text>
          {detail}
        </Flex>
        {illustration}
      </Flex>
    </Card>
  );
}

