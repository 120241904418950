import { Link } from "react-router-dom";
import { Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useAuth } from "services/auth/AuthProvider";

const MenuItem = (props: { children: any, isLast?: any, to: string, color: string, hidden: boolean }) => {
    return (
        <Text mb={{ base: props.isLast ? 0 : 8, sm: 0 }} mr={{ base: 0, sm: props.isLast ? 0 : 8 }} display="block" hidden={props.hidden}>
            <Link to={props.to}>{props.children}</Link>
        </Text>
    );
};

const LandingMenu = (props: { parent: string }) => {

    const textColor = useColorModeValue('white', 'white');
    const { isAuthenticated } = useAuth();
    const juser = localStorage.getItem('juser');


    return (
        <Flex align="center" justify={["center", "space-between", "flex-end", "flex-end"]} direction={["column", "row", "row", "row"]} pt={[4, 4, 0, 0]} mt={{ base: "30px", md: "0px" }}>
            <MenuItem to="/home" color={textColor} hidden={props.parent === 'home'}>Home </MenuItem>
            <MenuItem to="/home/features" color={textColor} hidden={props.parent === 'features'}>Features </MenuItem>
            <MenuItem to="/home/pricing" color={textColor} hidden={props.parent === 'pricing'}>Pricing </MenuItem>
            {!isAuthenticated || !juser ? (
                <>
                    <MenuItem to="/auth/signin" color={textColor} isLast hidden={props.parent === 'signin'}>
                        <Button variant={'outline'} colorScheme={'brand'} size="sm" rounded="md" color={'white'} me='10px'>
                            Login
                        </Button>
                    </MenuItem>
                    <MenuItem to="/auth/signup" color={textColor} hidden={props.parent === 'signup'}>
                        <Button variant={'outline'} colorScheme={'cyan'} size="sm" rounded="md" fontWeight={'bold'}>
                            Sign up FREE
                        </Button>
                    </MenuItem>
                </>
            ) : (
                <>
                    <MenuItem to="/trader/dashboard" color={textColor} hidden={props.parent === 'signup'}>
                        <Button variant={'solid'} bg={'navy.700'} color={'white'} size="sm" rounded="md" borderWidth={'1px'} borderColor={'white'}>
                            Dashboard
                        </Button>
                    </MenuItem>
                </>
            )}
        </Flex>
    )
}

export default LandingMenu