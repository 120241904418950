import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Icon, useColorModeValue } from '@chakra-ui/react'
import MentorHelp from './MentorHelp'
import { LuHelpCircle } from "react-icons/lu";
import MenteeHelp from './MenteeHelp';

const MenteeHelpModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const bgcolor = useColorModeValue('white', 'navy.900');
    return (
        <>
            <Button variant={'outline'} borderWidth={'1px'} bg={bgcolor} onClick={onOpen} zIndex={10} ms='5px' >
                <Icon as={LuHelpCircle} boxSize={6} />
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent bg={bgcolor}>
                    <ModalHeader>Mentee help</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <MenteeHelp />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} mt={3} onClick={onClose}>
                            Got it!
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default MenteeHelpModal