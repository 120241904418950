import { Flex, Table, Tbody, Td, Tfoot, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { useState } from "react";
import { Execution, newExecution, Trade } from 'views/trader/trades/variables/types';
import dayjs from 'dayjs';

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable
} from "@tanstack/react-table";

import { FooterCell } from "./FooterCell";
import { ProcessTrade } from 'views/trader/trades/variables/TradeProcessor';
import { TableCell } from './TableCell';
import { EditCell } from './EditCell';
import { useData } from 'services/data/DataProvider';

export const ExecutionsTable = (props: { tradeData: Trade, updateTrade: any }) => {
    
    const {instruments} = useData();

    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [data, setData] = useState<Execution[]>(props.tradeData.executions);
    const [editedRows, setEditedRows] = useState({});
    const [validRows, setValidRows] = useState({});

    const getLastIndex = () => {
        let index = table.getFilteredRowModel().rows.length - 1;
        return index.toString();
    }

    const addRow = (newRow: Execution) => {
        const d = dayjs(newRow.datetime).format('YYYY-MM-DD HH:mm:ss');
        newRow.datetime = d;

        if (props.tradeData.executions.length) {
            localStorage.setItem('instrument', props.tradeData.executions[0].instrument);
            localStorage.setItem('ticker', props.tradeData.executions[0].ticker);
            newRow.instrument = props.tradeData.executions[0].instrument;
            newRow.ticker = props.tradeData.executions[0].ticker;
        }

        props.tradeData.executions.push(newRow);
        sortExecutions();

        setData([...props.tradeData.executions]); // Update data array

        setEditedRows((old: {}) => ({
            ...old,
            [getLastIndex()]: true,
        }));

        if (!localStorage.getItem("instrument")) {
            setValidRows((old: { [key: string]: [any: {}] }) => ({ ...old, [getLastIndex()]: { ...old[getLastIndex()], 'instrument': false } }));
        }
        if (!localStorage.getItem("ticker")) {
            setValidRows((old: { [key: string]: [any: {}] }) => ({ ...old, [getLastIndex()]: { ...old[getLastIndex()], 'ticker': false } }));
        }
        setValidRows((old: { [key: string]: [any: {}] }) => ({ ...old, [getLastIndex()]: { ...old[getLastIndex()], 'side': false } }));
        setValidRows((old: { [key: string]: [any: {}] }) => ({ ...old, [getLastIndex()]: { ...old[getLastIndex()], 'size': false } }));
        setValidRows((old: { [key: string]: [any: {}] }) => ({ ...old, [getLastIndex()]: { ...old[getLastIndex()], 'price': false } }));
        setValidRows((old: { [key: string]: [any: {}] }) => ({ ...old, [getLastIndex()]: { ...old[getLastIndex()], 'fee': false } }));
        updateRowEditing();
    }

    const updateRow = (index: number, newRow: Execution) => {
        const d = dayjs(newRow.datetime).format('YYYY-MM-DD HH:mm:ss');
        newRow.datetime = d;
        props.tradeData.executions.splice(index, 1, newRow);
        sortExecutions();
        setData([...props.tradeData.executions]);
        processTrade();
    }

    const deleteRow = (index: number) => {
        props.tradeData.executions.splice(index, 1);
        setData([...props.tradeData.executions]);
        updateRowEditing();
        processTrade();

    }

    const updateRowEditing = () => {
        props.tradeData.executions.forEach((exec: Execution, index: number) => {
            if (props.tradeData.executions.length < 2 && index === 0) {
                exec.editInstument = true;
            }
            else {
                exec.editInstument = false;
            }
        });
        setData([...props.tradeData.executions]);
    }

    const sortExecutions = () => {
        props.tradeData.executions.sort(function (a: Execution, b: Execution) {
            return new Date(a.datetime).getTime() - new Date(b.datetime).getTime();
        });
    }

    const processTrade = async () => {
        ProcessTrade({ trade: props.tradeData }).then((res) => {
            props.updateTrade(res);
        });
    }

    const getInstrumentList = () => {
        let filtered = instruments.filter((value: any) => value.category === localStorage.getItem("instrument"));

        let arr: any[] = [];
        arr.push({ value: '', label: 'Select' });
        let count = 0;

        filtered.sort((a: any, b: any) => a.ticker.toLowerCase().localeCompare(b.ticker.toLowerCase())).sort((a: any, b: any) => b.relevance - a.relevance)
        filtered.map((value: any) => {
            if (value.relevance > 0) {
                arr.push({ value: value.ticker, label: value.ticker });
            }
            else {
                if (count < 1) {
                    count++;
                    arr.push({ value: '', label: '__________' })
                    arr.push({ value: value.ticker, label: value.ticker });
                }
                else {
                    arr.push({ value: value.ticker, label: value.ticker });
                }
            }
        })
        return arr;
    }

    const columnHelper = createColumnHelper<Execution>();

    const columns = [
        columnHelper.accessor('datetime', {
            header: 'DATE/TIME',
            cell: TableCell,
            meta: {
                name: 'datetime',
                type: 'datetime-local',
                display: 'text',
                required: true,
                max: new Date().toISOString().slice(0, 16),
                validate: (value: string) => {
                    const date = new Date(value);
                    const today = new Date();
                    return date <= today;
                }
            },
        }),
        columnHelper.accessor('instrument', {
            header: 'INSTRUMENT',
            cell: TableCell,
            meta: {
                name: 'instrument',
                type: 'select',
                display: 'text',
                options: [
                    { value: '', label: 'Select' },
                    { value: '', label: '--------' },
                    { value: 'FUT', label: 'Futures' },
                    { value: 'FOREX', label: 'Forex' },
                    { value: 'CRYPTO', label: 'Crypto' },
                    { value: 'STOCK', label: 'Stock' },
                ],
                required: true,
            },
        }),
        columnHelper.accessor('ticker', {
            header: 'TICKER',
            cell: TableCell,
            meta: {
                name: 'ticker',
                type: 'select',
                display: 'text',
                options: getInstrumentList(),
                enableColumnEdit: true,
                required: true,
            },
        }),
        columnHelper.accessor('side', {
            header: 'SIDE',
            cell: TableCell,
            meta: {
                name: 'side',
                type: 'select',
                display: 'badge',
                options: [
                    { value: '', label: 'Select' },
                    { value: '', label: '--------' },
                    { value: 'buy', label: 'Buy' },
                    { value: 'sell', label: 'Sell' },
                ],
                required: true,
            },
        }),
        columnHelper.accessor('size', {
            header: 'SIZE',
            cell: TableCell,
            meta: {
                name: 'size',
                type: 'number',
                min: 1,
                display: 'text',
                required: true,
            },
        }),
        columnHelper.accessor('price', {
            header: 'PRICE',
            cell: TableCell,
            meta: {
                name: 'price',
                type: 'number',
                min: 1,
                display: 'text',
                required: true,
            },
        }),
        columnHelper.accessor('fee', {
            header: 'TOTAL FEE',
            cell: TableCell,
            meta: {
                name: 'fee',
                type: 'number',
                min: 0,
                display: 'text',
                required: true
            },
        }),
        columnHelper.display({
            id: 'edit',
            cell: EditCell,
            enableResizing: false,
            size: 50
        }),
    ];

    const table = useReactTable<Execution>({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        enableRowSelection: true,
        meta: {
            editedRows,
            setEditedRows,
            validRows,
            setValidRows,
            revertData: (rowIndex: number) => {
                setData((old) =>
                    old.map((row, index) =>
                        index === rowIndex ? props.tradeData.executions[rowIndex] : row
                    )
                );
            },
            updateRow: (rowIndex: number) => {
                updateRow(rowIndex, data[rowIndex]);
            },
            updateData: (rowIndex: number, columnId: string, value: string, isValid: boolean) => {
                setData((old) =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex],
                                [columnId]: value,
                            };
                        }
                        return row;
                    })
                );
                setValidRows((old: { [key: string]: [any: {}] }) => ({ ...old, [rowIndex]: { ...old[rowIndex], [columnId]: isValid } }));
            },
            addRow: () => {
                addRow(newExecution());
            },
            removeRow: (rowIndex: number) => {
                deleteRow(rowIndex);
            },
            removeSelectedRows: (selectedRows: number[]) => {
                selectedRows.forEach((rowIndex) => {
                    deleteRow(rowIndex);
                });
            },
        },
    });

    //const meta = table.options.meta;

    return (
        <Table variant='simple' color='gray.500' mt="12px">
            <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                            return (
                                <Th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    pe='10px'
                                    borderColor={borderColor}
                                    cursor='pointer'
                                    onClick={header.column.getToggleSortingHandler()}>
                                    <Flex
                                        justifyContent='space-between'
                                        align='center'
                                        fontSize={{ sm: '10px', lg: '12px' }}
                                        color='gray.400'>
                                        {flexRender(header.column.columnDef.header, header.getContext())}{{
                                            asc: '',
                                            desc: '',
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </Flex>
                                </Th>
                            );
                        })}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.slice(0, 11).map((row) => {
                    return (
                        <Tr key={row.id}>
                            {row.getVisibleCells().map((cell) => {
                                return (
                                    <Td
                                        key={cell.id}
                                        fontSize={{ sm: '14px' }}
                                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                        borderColor='transparent'>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                );
                            })}
                        </Tr>
                    );
                })}
            </Tbody>
            <Tfoot >
                <Tr hidden={props.tradeData.closed}>
                    <Th>
                        <FooterCell table={table} />
                    </Th>
                </Tr>
            </Tfoot>
        </Table>
    );
};
