import { Box, Flex, InputGroup, InputLeftAddon, Select } from '@chakra-ui/react'
import { useData } from 'services/data/DataProvider';

const StatPeriod = (props: { hidden: boolean }) => {

    const { period, setPeriod } = useData();

    return (
        <Flex direction='row' justify={{ base: 'center', md: 'end' }} hidden={props.hidden}>
            <Box  ms='20px' me='20px'>
                <InputGroup variant='outline' size='sm'>
                    <InputLeftAddon children="PERIOD" fontWeight={'600'} borderLeftRadius={'5px'} borderRightRadius={'0px'}/>
                    <Select onChange={e => setPeriod(e.target.value)} value={period} borderRightRadius='5px'>
                        <option value='all'>ALL</option>
                        <option value='weekthis'>This week</option>
                        <option value='weeklast'>Last week</option>
                        <option value='monththis'>This month</option>
                        <option value='monthlast'>Last month</option>
                        <option value='quarterthis'>This quarter</option>
                        <option value='quarterlast'>Last quarter</option>
                        <option value='yearthis'>This year</option>
                        <option value='yearlast'>Last year</option>
                    </Select>

                </InputGroup>
            </Box>

        </Flex>
    )
}

export default StatPeriod;