/* eslint-disable */

import {
  Badge,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import * as React from 'react';
import { Execution, Trade } from 'views/trader/trades/variables/types';
import Container from "react-modal-promise";
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useData } from 'services/data/DataProvider';

// Assets

type RowObj = {
  index: number
  order: number;
  ticker: string;
  date: string;
  side: string;
  size: number;
  price: number;
  fee: number;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function MentorTradeModalExecutionsTable(props: { trade: Trade }) {
  //const { executionsData } = props;
  const {dateformat} = useData();

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');


  const [data, setData] = React.useState<RowObj[]>([]);

  ///////////////////////////
  const [editedRows, setEditedRows] = useState({});
  const [validRows, setValidRows] = useState({});
  ///////////////////////////

  useEffect(() => {
    updateData();
  }, [])


  const updateData = () => {
    data.splice(0, data.length); /// empty existing data
    for (let i = 0; i < props.trade.executions.length; i++) {
      const ex: Execution = props.trade.executions[i];
      const order = i + 1;
      const e: RowObj = {
        index: i,
        order: order,
        ticker: ex.contract,
        date: ex.datetime,
        side: ex.side,
        size: ex.size,
        price: ex.price,
        fee: ex.fee
      }
      data.push(e);
    }
    setData([...data]);
  }


  const columns = [
    columnHelper.accessor('order', {
      id: 'order',
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          #
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('ticker', {
      id: 'ticker',
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          Contract
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('date', {
      id: 'date',
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          Date/Time
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {dayjs(info.getValue()).format(dateformat)}
        </Text>
      ),
    }),
    columnHelper.accessor('side', {
      id: 'side',
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400" >
          Side
        </Text>
      ),
      cell: (info) => (
        <Badge
          colorScheme={info.getValue().toLocaleLowerCase() === "sell" ? 'red' : 'green'}
          color={info.getValue().toLocaleLowerCase() === "sell" ? 'red.500' : 'green.500'}
          fontSize='sm'
          fontWeight='600'>
          {info.getValue().toUpperCase()}
        </Badge>
      ),
    }),
    columnHelper.accessor('size', {
      id: 'size',
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          Size
        </Text>

      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('price', {
      id: 'price',
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          Price
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('fee', {
      id: 'fee',
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          Total Fee
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue().toFixed(2)}
          </Text>
        </Flex>
      ),
    })
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    meta: {
      editedRows,
      setEditedRows,
      validRows,
      setValidRows,
      revertData: (rowIndex: number) => {
        setData((old) =>
          old.map((row, index) =>
            index === rowIndex ? data[rowIndex] : row
          )
        );
      },
      updateData: (rowIndex: number, columnId: string, value: string, isValid: boolean) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
        setValidRows((old: { [key: string]: [any: {}] }) => ({ ...old, [rowIndex]: { ...old[rowIndex], [columnId]: isValid } }));
      }
    },
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Box>
        <Container />
        <Table variant="simple" color="gray.500" mb="24px" >
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 11)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
