import { Button, Flex, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Switch, useColorModeValue, useDisclosure, Text, Image, useToast, Spinner, PinInput, PinInputField, FormControl, FormErrorMessage, Icon } from '@chakra-ui/react'

import { ChangeEvent, useState } from 'react'
import api from 'services/api';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Card from 'components/card/Card';
import { MdOutlineTimer } from 'react-icons/md';

const TotpModal = () => {

    const spinnerColor = useColorModeValue('gray.300', 'brand.300');

    const { isOpen, onOpen, onClose } = useDisclosure()

    const textColor = useColorModeValue('navy.700', 'white');
    const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
    const bgcolor = useColorModeValue('white', 'gray.700');

    const [loading, setLoading] = useState(false);
    const [verifying, setVerifying] = useState(false);
    const [qrcode, setQrcode] = useState("");
    const [mfa, setmfa] = useState(false);
    const [secret, setsecret] = useState("");
    const toast = useToast();

    const updatemfa = async (e: ChangeEvent<HTMLInputElement>) => {
        setLoading(e.target.checked);
        await api.post('/user/updatetotp', { isOtp: e.target.checked }).then(async (res) => {
            setmfa(res.data.secret !== "");
            setsecret(res.data.secret);
            setQrcode(res.data.qrCode);
            if (res.data.secret !== "") {
                toast({ title: 'NEW 2-FA credentials created', description: <Text whiteSpace="pre-line">{"\nGreat! Your account is more secure.\n\nPlease scan the QR code into your authenticator app and enter the code below to verify that it is working ...\n\n"}</Text>, status: 'success', position: 'top', duration: 10000, isClosable: true })
            }
            else {
                toast({ title: '2-FA credentials deleted', description: 'This makes your account less secure ...', status: 'error', position: 'top', duration: 3000, isClosable: true })
            }
        }).catch((e) => {
            alert(JSON.stringify(e))
            toast({ title: 'Unable to update ..', description: 'Please check your internet connection or try again in a few minutes.', status: 'error', position: 'top', duration: 3000, isClosable: false })
        })
        setLoading(false);
    }
    const getmfa = async () => {
        setLoading(true);
        await api.post('/user/gettotp').then(async (res) => {
            //alert(JSON.stringify(res.data));
            setmfa(res.data.secret.trim() !== "");
            setQrcode(res.data.qrCode);
            setsecret(res.data.secret);
            onOpen();
        }).catch((e) => {
            toast({ title: 'Unable to fetch 2-Factor Authentication details ...', description: 'Please check your internet or try again in a few minutes', status: 'error', position: 'top', duration: 3000, isClosable: true })
        })
        setLoading(false);
    }
    const closeModal = () => {
        setQrcode('');
        setsecret('');
        setmfa(false);
        onClose();
    }
    const clipboardcopy = () => {
        navigator.clipboard.writeText(secret);
        toast({ title: 'Copied to clipboard', description: '', status: 'info', position: 'top', duration: 2000, isClosable: false })
    }

    return (

        <>
            <Button size='md' ms='10px' onClick={getmfa} colorScheme={'teal'} variant={'outline'} borderWidth={'2px'} isLoading={loading}>
                <Icon as={MdOutlineTimer} me='10px' boxSize={5}></Icon>2-FA
            </Button>

            <Modal isOpen={isOpen} onClose={closeModal} closeOnOverlayClick={false} size={{ base: 'full', md: 'lg' }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Flex>
                            <FormLabel size={'lg'} htmlFor='is2fa'>Enable 2-Factor Authentication</FormLabel>
                            <Switch id='is2fa' onChange={updatemfa} isChecked={mfa} />
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton onClick={closeModal} />
                    <ModalBody>
                        <Flex direction={'column'}  >
                            <Flex justify={'center'} mt='40px' hidden={!loading}>
                                <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='xl' ></Spinner>
                            </Flex>
                            {mfa &&
                                <>
                                    <Flex direction={'column'} justify={'center'} textAlign={'center'}>
                                        <Text mb='20px' color={'gray.500'}>Scan QR code with your authenticator app.</Text>
                                        <Flex>
                                            <Image src={qrcode} w='100%'></Image>
                                        </Flex>
                                        <Text mb='20px' mt='30px' color={'gray.500'}>Or click below to copy secret to clipboard</Text>
                                        <Button variant={'outline'} colorScheme={'teal'} onClick={clipboardcopy}>{secret}</Button>
                                    </Flex>

                                    <Card borderWidth={'1px'} w='100%' mt='50px' bg={'transparent'}>
                                        <Flex justify="center" direction={'column'}>
                                            <Flex justify="center">
                                                <FormLabel mt="-31px" mb='20px' bg={bgcolor} px='10px'>
                                                    VERIFY - enter authenticator app pin
                                                </FormLabel>
                                            </Flex>

                                            <Formik
                                                initialValues={{
                                                    pin1: "",
                                                    pin2: "",
                                                    pin3: "",
                                                    pin4: "",
                                                    pin5: "",
                                                    pin6: ""
                                                }}
                                                onSubmit={async (values) => {
                                                    setVerifying(true);
                                                    let otpstr = values.pin1 + values.pin2 + values.pin3 + values.pin4 + values.pin5 + values.pin6;
                                                    await api.post('/user/testotp', { otp: otpstr }).then(async (res) => {
                                                        if (res.data === true) {
                                                            toast({ title: 'One-time PIN verified', description: '', status: 'success', position: 'top', duration: 2000, isClosable: false })
                                                        }
                                                        else {
                                                            toast({ title: 'One-time PIN verification failed', description: '', status: 'error', position: 'top', duration: 3000, isClosable: false })
                                                        }
                                                    }).catch((e) => {
                                                        toast({ title: 'Unable to verify', description: '', status: 'error', position: 'top', duration: 3000, isClosable: false })
                                                    })
                                                    setVerifying(false);

                                                }}
                                                validationSchema={Yup.object().shape({
                                                    pin1: Yup.string().required('Enter 6-digit PIN').test("Enter 6-digit PIN", "Enter 6-digit PIN", (schema) => {
                                                        return schema.length > 0;
                                                    }),
                                                    pin2: Yup.string().required('Enter 6-digit PIN').test("Enter 6-digit PIN", "Enter 6-digit PIN", (schema) => {
                                                        return schema.length > 0;
                                                    }),
                                                    pin3: Yup.string().required('Enter 6-digit PIN').test("Enter 6-digit PIN", "Enter 6-digit PIN", (schema) => {
                                                        return schema.length > 0;
                                                    }),
                                                    pin4: Yup.string().required('Enter 6-digit PIN').test("Enter 6-digit PIN", "Enter 6-digit PIN", (schema) => {
                                                        return schema.length > 0;
                                                    }),
                                                    pin5: Yup.string().required('Enter 6-digit PIN').test("Enter 6-digit PIN", "Enter 6-digit PIN", (schema) => {
                                                        return schema.length > 0;
                                                    }),
                                                    pin6: Yup.string().required('Enter 6-digit PIN').test("Enter 6-digit PIN", "Enter 6-digit PIN", (schema) => {
                                                        return schema.length > 0;
                                                    })
                                                })}
                                            >
                                                {({ handleSubmit, errors, touched }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <FormControl>
                                                            <FormControl isInvalid={(!!errors.pin1 && touched.pin1) || (!!errors.pin2 && touched.pin2) || (!!errors.pin3 && touched.pin3) || (!!errors.pin4 && touched.pin4) || (!!errors.pin5 && touched.pin5) || (!!errors.pin6 && touched.pin6)}>
                                                                <Flex justify="center">
                                                                    <PinInput otp>
                                                                        <Field as={PinInputField} type="text" variant="auth" id="pin1" name="pin1" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="10px"></Field>
                                                                        <Field as={PinInputField} type="text" variant="auth" id="pin2" name="pin2" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="10px"></Field>
                                                                        <Field as={PinInputField} type="text" variant="auth" id="pin3" name="pin3" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="10px"></Field>
                                                                        <Field as={PinInputField} type="text" variant="auth" id="pin4" name="pin4" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="10px"></Field>
                                                                        <Field as={PinInputField} type="text" variant="auth" id="pin5" name="pin5" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="10px"></Field>
                                                                        <Field as={PinInputField} type="text" variant="auth" id="pin6" name="pin6" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="0px"></Field>
                                                                    </PinInput>
                                                                </Flex>
                                                                <Flex justify="center">
                                                                    <FormErrorMessage>{errors.pin1 || errors.pin2 || errors.pin3 || errors.pin4 || errors.pin5 || errors.pin6}</FormErrorMessage>
                                                                </Flex>
                                                            </FormControl>
                                                            <Button type="submit" colorScheme={'teal'} fontSize="md" variant="solid" fontWeight="500" w="100%" h="50" mb="10px" mt="20px" isLoading={verifying} loadingText='Verifying'>VERIFY</Button>
                                                        </FormControl>
                                                    </form>
                                                )}
                                            </Formik>
                                        </Flex>

                                    </Card>


                                </>
                            }
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' variant={'outline'} mr={3} onClick={closeModal}>
                            Done
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default TotpModal