import { Flex, Icon, IconButton, Text, useColorModeValue, useToast } from '@chakra-ui/react'

import { useEffect, useState } from 'react'
import StatisticBox from './AccountStatistics'

// Assets
import { MdArrowUpward, MdOutlineBarChart, MdInsights, MdArrowDownward, MdHorizontalRule } from 'react-icons/md';
import { RiDashboard3Line } from "react-icons/ri";
import { FaPercent } from "react-icons/fa6";
import { newStats, Statistics } from '../../trades/variables/types';
import { currencyformat } from '../../trades/variables/FormatData';
import { useLoading } from 'services/loading/LoadingProvider';
import { useData } from 'services/data/DataProvider';

const StatBarLarge = () => {

    const {loading} = useLoading();
    const {statistics} = useData();
    
    const textColor = useColorModeValue('navy.700', 'white');
    const iconBg = useColorModeValue('secondaryGray.300', 'navy.700');
    const iconColor = useColorModeValue('brand.500', 'white');
    const [stats, setStats] = useState<Statistics[]>([newStats()]);

    const [pfactorcolor, setPFactorColor] = useState('');
    const [nettcolor, setNettcolor] = useState('');
    const [avgcolor, setAvgcolor] = useState('');
    const [plcolor, setPlcolor] = useState('');
    const [wlcolor, setWlcolor] = useState('');
    const [rvaluecolor, setRvaluecolor] = useState('');

    const toast = useToast();

    useEffect(() => {
        setStats([statistics]);
    }, [statistics])

    /*useLiveQuery(async () => {
        if (!localStorage.getItem('resync') || localStorage.getItem('resync') === 'false') {
            await db.statistics.toArray().then((s) => {
                if (s.length) {
                    setStats(s);
                }
            })
        }
    });*/

    useEffect(() => {
        setPFactorColor(stats.length ? (stats[0].profit_factor[0] > 1 ? 'green.500' : (stats[0].profit_factor[0] > 0.5 ? 'orange.500' : 'red.500')) : 'secondaryGray.600')
        setNettcolor(stats.length ? (stats[0].nett_gainloss[0] > 0 ? 'green.500' : 'red.500') : 'secondaryGray.500');
        setAvgcolor(stats.length ? (stats[0].avg_pl[0] > 0 ? 'green.500' : 'red.500') : 'secondaryGray.500');
        setPlcolor(stats.length ? (stats[0].pl_ratio[0] >= 1 ? 'green.500' : (stats[0].pl_ratio[0] >= 0.5 ? 'orange.500' : 'red.500')) : 'secondaryGray.600');
        setWlcolor(stats.length ? (stats[0].wl_ratio[0] >= 1 ? 'green.500' : (stats[0].wl_ratio[0] >= 0.5 ? 'orange.500' : 'red.500')) : 'secondaryGray.600');
        setRvaluecolor(stats.length ? (stats[0].avg_rvalue[0] >= 1 ? 'green.500' : (stats[0].avg_rvalue[0] > 0 ? 'orange.500' : 'red.500')) : 'secondaryGray.600');
    }, [stats])


    return (
        <>

            <Flex direction='row'>
                <StatisticBox title={'Trades total'} value={stats.length ? stats[0].trade_count[0].toFixed() : '0'} loading={loading} me='20px'
                    detail={
                        <Flex align="center">
                            <Text color={textColor} fontSize="sm" fontWeight="500">{stats.length ? (stats[0].period === 'all' ? '' : stats[0].trade_count[1].toFixed()) : 'No data'}</Text>
                            <Text color="secondaryGray.600" fontSize="sm" mx="4px">{stats.length ? (stats[0].period === 'all' ? 'All data' : (stats[0].period.includes('this') ? 'last ' : 'previous ') + (stats.length ? stats[0].periodstr : 'period')) : ''}</Text>
                        </Flex>
                    }
                    illustration={
                        <IconButton
                            isRound={true}
                            icon={<Icon color={iconColor} as={MdInsights} w="38px" h="38px" />}
                            w="80px" h="80px"
                            bg={iconBg}
                            onClick={() => toast({ title: 'Trades total', description: 'Total number of trades for the selected period', status: 'info', position: 'top', duration: 5000, isClosable: true })}
                            aria-label={''}>
                        </IconButton>
                    }
                />

                <StatisticBox title={'Nett gain/loss'} value={stats.length ? currencyformat(stats[0].nett_gainloss[0], stats[0].currency) : stats[0].currency} statcolor={nettcolor} loading={loading} me='20px'
                    detail={
                        <Flex align="center">
                            <Text color={nettcolor} fontSize="sm" mx="4px" fontWeight="700">{stats.length ? (stats[0].period === 'all' ? '' : currencyformat(stats[0].nett_gainloss[1], stats[0].currency)) : 'No data'}</Text>
                            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">{stats.length ? (stats[0].period === 'all' ? 'All data' : stats[0].period.includes('this') ? 'last ' + stats[0].periodstr : 'previous ' + stats[0].periodstr) : ''}</Text>
                        </Flex>
                    }
                    illustration={
                        <IconButton
                            isRound={true}
                            icon={<Icon color={nettcolor} as={stats.length ? (stats[0].nett_gainloss[0] > 0 ? MdArrowUpward : MdArrowDownward) : MdHorizontalRule} w="35px" h="35px" />}
                            w="80px" h="80px"
                            bg={iconBg}
                            onClick={() => toast({ title: 'Nett gain/loss', description: 'Nett gain/loss (less fees) for the selected period', status: 'info', position: 'top', duration: 5000, isClosable: true })}
                            aria-label={''}>
                        </IconButton>
                    }
                />

                <StatisticBox title={'Average P/L'} value={stats.length ? currencyformat(stats[0].avg_pl[0], stats[0].currency) : stats[0].currency} statcolor={avgcolor} loading={loading} me='20px'
                    detail={
                        <Flex align="center">
                            <Text color={avgcolor} fontSize="sm" mx="4px" fontWeight="700">{stats.length ? (stats[0].period === 'all' ? '' : (currencyformat(stats[0].avg_pl[1], stats[0].currency))) : 'No data'}</Text>
                            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">{stats.length ? (stats[0].period === 'all' ? 'All data' : (stats[0].period.includes('this') ? 'last ' : 'previous ') + (stats.length ? stats[0].periodstr : 'period')) : ''}</Text>
                        </Flex>
                    }
                    illustration={
                        <IconButton
                            isRound={true}
                            icon={<Icon color={avgcolor} as={MdOutlineBarChart} w="35px" h="35px" />}
                            w="80px" h="80px"
                            bg={iconBg}
                            onClick={() => toast({ title: 'Average P/L', description: 'Average P/L per trade for the selected period', status: 'info', position: 'top', duration: 5000, isClosable: true })}
                            aria-label={''}>
                        </IconButton>
                    }
                />

                <StatisticBox title={'Average R-Value'} value={stats.length ? stats[0].avg_rvalue[0].toFixed(2) : '0.00'} loading={loading} statcolor={rvaluecolor} me='20px'
                    detail={
                        <Flex align="center">
                            <Text color={rvaluecolor} fontSize="sm" mx="4px" fontWeight="700">{stats.length ? stats[0].period === 'all' ? '' : (stats[0].avg_rvalue[1].toFixed(2)) : '0'}</Text>
                            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">{stats.length ? (stats[0].period === 'all' ? 'All data' : (stats[0].period.includes('this') ? 'last ' : 'previous ') + (stats.length ? stats[0].periodstr : 'period')) : ''}</Text>
                        </Flex>
                    }
                    illustration={
                        <IconButton
                            isRound={true}
                            icon={<Icon color={rvaluecolor} as={RiDashboard3Line} w="35px" h="35px" />}
                            w="80px" h="80px"
                            bg={iconBg}
                            onClick={() => toast({ title: 'Average R-Value', description: 'Average Risk/Reward for the selected period', status: 'info', position: 'top', duration: 5000, isClosable: true })}
                            aria-label={''}>
                        </IconButton>
                    }
                />

                <StatisticBox title={'P/L Ratio'} value={stats.length ? stats[0].pl_ratio[0].toFixed(2) : '0.00 '} statcolor={plcolor} loading={loading} me='20px'
                    detail={
                        <Flex align="center">
                            <Text color={plcolor} fontSize="sm" mx="4px" fontWeight="700">{stats.length ? (stats[0].period === 'all' ? '' : stats[0].pl_ratio[1].toFixed(2)) : '0.00'}</Text>
                            <Text color="secondaryGray.600" fontSize="sm" mx="4px">{stats.length ? stats[0].period === 'all' ? 'All data' : ((stats[0].period.includes('this') ? 'last ' : 'previous ') + (stats.length ? stats[0].periodstr : 'period')) : 'No data'}</Text>
                        </Flex>
                    }
                    illustration={
                        <IconButton
                            isRound={true}
                            icon={<Icon color={plcolor} as={FaPercent} w="30px" h="30px" />}
                            w="80px" h="80px"
                            bg={iconBg}
                            onClick={() => toast({ title: 'P/L Ratio', description: 'Ratio of AVG WIN vs AVG LOSS for the selected period', status: 'info', position: 'top', duration: 5000, isClosable: true })}
                            aria-label={''}>
                        </IconButton>
                    }
                />

                <StatisticBox title={'W/L Ratio'} value={stats.length ? stats[0].wl_ratio[0].toFixed(2) : '0.00 '} statcolor={wlcolor} loading={loading}
                    detail={
                        <Flex align="center">
                            <Text color={wlcolor} fontSize="sm" mx="4px" fontWeight="700">{stats.length ? (stats[0].period === 'all' ? '' : stats[0].wl_ratio[1].toFixed(2)) : '0.00'}</Text>
                            <Text color="secondaryGray.600" fontSize="sm" mx="4px">{stats.length ? stats[0].period === 'all' ? 'All data' : ((stats[0].period.includes('this') ? 'last ' : 'previous ') + (stats.length ? stats[0].periodstr : 'period')) : 'No data'}</Text>
                        </Flex>
                    }
                    illustration={
                        <IconButton
                            isRound={true}
                            icon={<Icon color={wlcolor} as={FaPercent} w="30px" h="30px" />}
                            w="80px" h="80px"
                            bg={iconBg}
                            onClick={() => toast({ title: 'W/L Ratio', description: 'Ratio of WINNERS vs LOSERS for the selected period', status: 'info', position: 'top', duration: 5000, isClosable: true })}
                            aria-label={''}>
                        </IconButton>
                    }
                />

                {/*<StatisticBox title={'Profit Factor'} value={stats.length ? stats[0].profit_factor[0].toFixed(2) : '0.00'} loading={loading} statcolor={pfactorcolor}
                    detail={
                        <Flex align="center">
                            <Text color={pfactorcolor} fontSize="sm" mx="4px" fontWeight="700">{stats.length ? stats[0].period === 'all' ? '' : (stats[0].profit_factor[1].toFixed(2)) : '0'}</Text>
                            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">{stats.length ? (stats[0].period === 'all' ? 'All data' : (stats[0].period.includes('this') ? 'last ' : 'previous ') + (stats.length ? stats[0].periodstr : 'period')) : ''}</Text>
                        </Flex>
                    }
                    illustration={
                        <IconButton
                            isRound={true}
                            icon={<Icon color={pfactorcolor} as={RiDashboard3Line} w="35px" h="35px" />}
                            w="80px" h="80px"
                            bg={iconBg}
                            onClick={() => toast({ title: 'Profit Factor', description: 'Ratio of WIN TOTAL vs LOSS TOTAL for the selected period', status: 'info', position: 'top', duration: 5000, isClosable: true })}
                            aria-label={''}>
                        </IconButton>
                    }
                />*/}



            </Flex>
        </>

    )
}

export default StatBarLarge