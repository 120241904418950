import dayjs from 'dayjs';
import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { db } from 'services/db';
import uuid from 'react-uuid';
import { useLiveQuery } from 'dexie-react-hooks';
import { newSubscription, Subscription } from 'views/trader/trades/variables/types';

const AuthContext = createContext({} as any);

export const AuthProvider = (props: { children: any }) => {

  const navigate = useNavigate();
  
  const [IsMentored, setIsMentored] = useState(false);
  const [HasMentees, setHasMentees] = useState(false);
  const [usersubscription, setUserSubscription] = useState<Subscription>(newSubscription());

  const [user, setUser] = useState(() => {
    let up = localStorage.getItem("juser");
    if (up) {
      return JSON.parse(up);
    }
    else {
      db.users.get({ current_user: 1 }).then((u) => {
        if (u !== undefined) {
          localStorage.setItem('user', user);
          return u;
        }
        else {
          return null;
        }
      }).catch(() => {
        try {
          signout();
        }
        catch (e) { }
        return null;
      });
    }
    return null;
  });

  const [muuid, setMUuid] = useState(() => {
    let uuidret = localStorage.getItem("jornomid");
    if (uuidret) {
      return uuidret;
    }
    else {
      localStorage.setItem("chakra-ui-color-mode", 'dark');
      uuidret = uuid();
      localStorage.setItem('jornomid', uuidret);
      return uuidret;
    }
  });

  

  const signup = async (payload: any) => {
    return await api.post("/auth/signup", payload)
      .then(async (res) => {
        return res;
      }).catch(async (err) => {
        return err.response.data;
      });
  };

  const signin = async (payload: any) => {
    return await api.post("/auth/signin", payload)
      .then(async (res) => {
        return res;
      }).catch(async (err) => {
        return err.response.status;
      });
  };



  const signout = async () => {
    db.users.toCollection().modify(u => {
      u.current_user = 0;
    })
    setUser(null);
    localStorage.removeItem('juser');
    try {
      await api.post("/auth/signout", { id: user.id }).catch((err) => { });
    } catch (e) { }

    navigate("/auth/signin");
  };

  const resetPasswordEmail = async (payload: any) => {
    return await api.post("/auth/resetpasswordemail", payload)
      .then(async (res) => {
        return res;
      }).catch(async (err) => {
        return err.response.status;
      });
  };

  const resetPassword = async (payload: any) => {
    return await api.post("/auth/resetpassword", payload)
      .then(async (res) => {
        return res;
      }).catch(async (err) => {
        return err.response.status;
      });
  };

  const verifyPasswordResetToken = async (payload: any) => {
    return await api.post("/auth/verifypasswordresettoken", payload)
      .then(async (res) => {
        return res;
      }).catch(async (err) => {
        return err.response.status;
      });
  };

  const verifyPassword = async (payload: any) => {
    return await api.post("/user/verifypassword", payload)
      .then(async (res) => {
        return res;
      }).catch(async (err) => {
        return err.response.status;
      });
  };

  const verifyEmailResendUser = async (payload: any) => {
    return await api.post("/auth/verifyresenduser", payload)
      .then(async (res) => {
        return res;
      }).catch(async (err) => {
        return err.response.status;
      });
  };

  const verifyEmailResendToken = async (payload: any) => {
    return await api.post("/auth/verifyresendtoken", payload)
      .then(async (res) => {
        return res;
      }).catch(async (err) => {
        return err.response.status;
      });
  };

  const verifyEmail = async (payload: any) => {
    return await api.post("/auth/verify", payload)
      .then(async (res) => {
        return res;
      }).catch(async (err) => {
        return err.response.status;
      });
  };

  const allowaccountadd = async () => {
    let allow = false;
    await db.accounts.where({ userid: user.id }).count().then((c) => {
      switch (user.role) {
        case "FREE": allow = c < 1; break;
        case "NOVICE": allow = c < 5; break;
        case "PRO": allow = c < 20; break;
        case "MENTOR":
        case "ADMIN": allow = true; break;
        default: allow = false; break;
      }
    }).catch(() => { })
    return allow;
  }

  const allowgroupadd = async () => {
    let allow = false;
    await db.accountgroups.where({ userid: user.id }).count().then((c) => {
      switch (user.role) {
        case "FREE": allow = c < 1; break;
        case "NOVICE": allow = c < 2; break;
        case "PRO": allow = c < 5; break;
        case "MENTOR":
        case "ADMIN": allow = true; break;
        default: allow = false; break;
      }
    }).catch(() => { })
    return allow;
  }

  const allowjournaladd = async (date: string) => {
    let allow = false;
    await db.journals.where({ userid: user.id }).toArray().then((j) => {
      let jarr = j.filter((j1) => dayjs(j1.date).isSame(date, 'week'));
      switch (user.role) {
        case "FREE": allow = jarr.length < 1; break;
        case "NOVICE": allow = jarr.length < 2; break;
        case "PRO": allow = jarr.length < 7; break;
        case "MENTOR":
        case "ADMIN": allow = true; break;
        default: allow = false; break;
      }
    }).catch(() => { })
    return allow;
  }


  const [maxWeeklyTrades, setmaxWeeklyTrades] = useState(10);

  useEffect(() => {
    if (user) {
      let max = 25; // ie for FREE
      switch (user.role) {
        case "NOVICE": max = 250; break;
        case "PRO": max = 1500; break;
        case "MENTOR":
        case "ADMIN": max = 0; break;
      }
      setmaxWeeklyTrades(max);
    }
  }, [user]);

  const allowMentor = async () => {

    let allow = false; // ie for FREE, MENTORS

    await db.mentors.where({ userid: user.id, status: 'active' }).count().then((c) => {
      switch (user.role) {
        case "NOVICE": allow = c < 1; break;
        case "PRO": allow = c < 10; break;
      }
    })

    return allow;
  }



  useLiveQuery(async () => {
    if (user) {
      await db.mentors.where({ userid: (user.id), status: 'active' }).toArray().then((r) => {
        setIsMentored(r.length > 0)
      }).catch(() => { setIsMentored(false) })
    }

  }, [user])


  useLiveQuery(async () => {
    if (user) {
      await db.users.get({ current_user: 1 }).then((u) => {
        if (u !== undefined) {
          setUserSubscription(u.subscription);
        }
      })
    }

  }, [user])

  useLiveQuery(async () => {
    if (user) {
      await db.mentees.where({ userid: (user.id), status: 'active' }).toArray().then((r) => {
        setHasMentees(r.length > 0)
      }).catch(() => { setHasMentees(false) })
    }

  }, [user])


  const isAuthenticated = !!user;

  return (
    <AuthContext.Provider value={{ muuid, user, isAuthenticated, usersubscription, HasMentees, IsMentored, setUser, signup, signin, signout, allowaccountadd, allowgroupadd, allowjournaladd, maxWeeklyTrades, allowMentor, resetPasswordEmail, resetPassword, verifyPassword, verifyPasswordResetToken, verifyEmailResendUser, verifyEmailResendToken, verifyEmail }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
