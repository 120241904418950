
import {
    useDisclosure,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useToast,
    Flex,
    Icon, Text
} from '@chakra-ui/react'

import { useRef, useState } from "react";
import { MdOutlineWarningAmber } from 'react-icons/md';
import api from 'services/api';
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';


const DeleteTradeConfirm = (props: { id: number, hidden: boolean, closemodal: any }) => {

    const { muuid } = useAuth();
    const toast = useToast();
    const [processing, setProcessing] = useState(false);


    const deletetrade = () => {

        setProcessing(true);

        api.post('/trade/deletetrade', { tradeid: props.id, last_uuid: muuid })
            .then(async (res) => {
                if (res.status === 200) {
                    if (res.data === true) {
                        db.trades.delete(props.id).then(() => {
                            //reprocessstats();
                            setProcessing(false);
                            onClose();
                            props.closemodal(props.id);
                        });
                    }
                }
                else {
                    setProcessing(false);
                }
            }).catch(function (error) {
                setProcessing(false);
                onClose();
                toast({ title: 'Unable to delete', description: "Verify your network connection ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
            });
    }

    /*const reprocessstats = async () => {
        getDefaultAccount(defaultgroup.groupid).then(async (acc) => {
            if (acc) {
                await db.trades.where({ groupid: defaultgroup.groupid, accountid: acc.accountid }).toArray().then(async (trades) => {
                    await ProcessStats(trades, localStorage.getItem("period"), localStorage.getItem("currency"));
                })
            }
            else {
                getDefaultGroup(user.id).then(async (grp) => {
                    if (grp) {
                        await db.trades.where({ groupid: defaultgroup.groupid }).toArray().then(async (trades) => {
                            await ProcessStats(trades, localStorage.getItem("period"), localStorage.getItem("currency"));
                        })
                    }
                })
            }
        })
        setProcessing(false);
        onClose();
        props.closemodal(props.id);
    }*/

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    return (
        <>
            <Button colorScheme='red' variant='outline' onClick={onOpen} hidden={props.hidden}>
                Delete
            </Button>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} motionPreset='slideInBottom'>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            <Flex direction={'row'}>
                                <Icon as={MdOutlineWarningAmber} boxSize={8} color={'red.500'} />
                                <Text fontSize='lg' fontWeight='bold' color={'red.500'} ms='20px' mt='5px'>
                                    Delete Trade
                                </Text>
                            </Flex>
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Text>
                                Deleting the trade cannot be undone ...
                            </Text>
                            <Text fontWeight={'700'} mt='20px' fontSize='lg'>
                                Are you sure?
                            </Text>

                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={deletetrade} ml={3} isLoading={processing} loadingText="Deleting">
                                Yes, Delete!
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default DeleteTradeConfirm