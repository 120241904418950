import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Icon, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { MdClear } from "react-icons/md"
import api from "services/api"
import { db } from "services/db"
import useJornoUuid from "views/trader/trades/hooks/useJornoUuid"
import { Instrument } from "views/trader/trades/variables/types"

export const DeleteInstumentConfirm = (props: { instrument: Instrument, updatemodal: any }) => {

    const {instrument, updatemodal} = props;

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const [deleting, setdeleting] = useState(false);

    const toast = useToast();

    const uuid = useJornoUuid();

    const confirmDelete = async () => {
        delete instrument.sync_due;
        instrument.last_uuid = uuid;
        //alert(JSON.stringify(instrument));

        setdeleting(true);
        await api.post('/trade/deleteinstrument', instrument)
            .then(async (res) => {
                if (res.data === true) {
                    await db.instruments.delete(instrument.id).then(() => {
                        updatemodal();
                        toast({ title: 'Instrument deleted ...', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false })
                    })
                }
                else {
                    toast({ title: 'Unable to delete instrument ...', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false })
                }

            })
            .catch(async (error) => {
                toast({ title: 'Unable to delete instrument ...', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false })
            });
        setdeleting(false);
        onClose();
    }

    return (
        <>
            <Button variant={'outline'} colorScheme={'red'} ms='5px' borderRadius={'10px'} onClick={onOpen}><Icon as={MdClear} boxSize={5}></Icon></Button>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Instument
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>
                                Any trades using this instrument will remain but you will no longer be able to edit their executions or enter new trades for ticker '{instrument.ticker}'
                            </Text>
                            <Text fontWeight={'700'} mt='20px'>
                                Are you sure?
                            </Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={confirmDelete} ml={3} isLoading={deleting} loadingText="Deleting">
                                Yes, delete {instrument.ticker}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}