// Chakra imports
import { Box, Button, Flex, Icon, Text, useColorModeValue, Image, useToast, IconButton } from '@chakra-ui/react';

// Custom components


// Assets
import { MdClear, MdDownloading, MdScreenshotMonitor } from 'react-icons/md';
import { useDropzone } from 'react-dropzone';

export default function AvatarDropzone(props: { [x: string]: any, imagefile: string, setimagefile: (arg0: string) => void }) {

	const { setimagefile, ...rest } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const imageColor = useColorModeValue('gray.300', 'gray.500');
	const bg = useColorModeValue('gray.100', 'navy.700');
	const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');

	const buttonBg = useColorModeValue('white', 'navy.800');

	const toast = useToast();

	const brand = useColorModeValue('brand.500', 'brand.400');

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		maxFiles: 1,
		maxSize: 100 * 1000,
		accept: {
			'image/jpeg': [],
			'image/png': []
		},
		onDrop: (acceptedFile: any, rejectedFile: any) => {
			if (acceptedFile.length === 1) {
				const file = acceptedFile.map((file: any) => Object.assign(file, {
					key: file.path,
					preview: URL.createObjectURL(file)
				}));
				imageToBase64(file[0])
			} else {
				toast({ title: 'Not accepted ...', description: "Max file size 100kb", status: 'error', position: 'top', duration: 3000, isClosable: false });
			}
		}
	});

	const imageToBase64 = (imageData: File) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			setimagefile(reader.result.toString());
		};
		reader.readAsDataURL(imageData);
	}

	return (
		<>

			{props.imagefile.length ? (
				<>
					<Flex direction={'column'}>
						<Flex justify={'end'}>
							<IconButton colorScheme={'red'} mb='10px' variant={'outline'} aria-label={''} onClick={() => setimagefile('')} icon={<Icon as={MdClear} boxSize={7} color={'red.500'} />} />
						</Flex>
						<Flex w="100%" h="100%" justify="center" align="center" >{/*align="center"*/}
							<Flex>
								<Image src={props.imagefile} w="100%" maxH={'700px'} cursor="pointer" />
							</Flex>
						</Flex>
					</Flex>

				</>
			) : (
				<Flex
					align='center'
					justify='center'
					bg={bg}
					border='1px dashed'
					borderColor={borderColor}
					borderRadius='16px'
					w='100%'
					h='100%'
					cursor='pointer'
					{...getRootProps({ className: 'dropzone' })}
					{...rest}
				>
					<input {...getInputProps()} />
					<Button h={'100%'} w={'100%'} bg={buttonBg}>
						{isDragActive ? (
							<Flex align="center" position="absolute" mt="0" mb="0" mr="0" ml="0" >
								<Icon as={MdDownloading} me='20px' boxSize={7} />
								<Text fontSize="md">DROP it ...</Text>
							</Flex>
						) : (
							<Flex justify={'center'} >
								<Box p='20px'>
									<Icon as={MdScreenshotMonitor} w='80px' h='80px' mb='20px' color={imageColor} />
									<Flex direction={{ base: 'column', md: 'row' }}>
										<Text
											mb='12px'
											fontSize='md'
											w='100%'
											maxW='100%'
											fontWeight='700'
											color={textColor}
											whiteSpace='pre-wrap'>
											Drop image here, or{' '}
										</Text>
										<Text as='span' fontSize='md' fontWeight='700' color={brand}>
											click to browse
										</Text>
									</Flex>
									<Flex direction={{ base: 'column', md: 'row' }} justify={'center'}>
										<Text
											mt='20px'
											me='5px'
											fontSize='sm'
											fontWeight='500'
											color='secondaryGray.500'
											white-space='pre-wrap !important'>
											Image file only.
										</Text>
										<Text
											mt={{ base: '10px', md: '20px' }}
											fontSize='sm'
											fontWeight='500'
											color='secondaryGray.500'
											white-space='pre-wrap !important'>
											Max 100kb.
										</Text>
									</Flex>
								</Box>
							</Flex>
						)}
					</Button>
				</Flex>
			)}

		</>

	);
}
