
import {
    Button,
    Text,
    Flex,
    Checkbox,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useColorModeValue
} from "@chakra-ui/react";

import { ArrowForwardIcon } from '@chakra-ui/icons'
import { useEffect, useState } from "react";
import { create } from "react-modal-promise";
import { db } from "services/db";
import { newInstrument } from "views/trader/trades/variables/types";

const CommissionInputDialog = ({ isOpen, onResolve, onReject }) => {

    const [commarr, setCommarr] = useState({ instrument: newInstrument(0), platform: '' });

    const [commission, setCommission] = useState(null);
    const [instrument, setInstrument] = useState(commarr.instrument);
    const [commindex, setcommindex] = useState(null);
    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const [loading, setLoading] = useState(false);
    const [defChecked, setDefChecked] = useState(true);
    const [t_platform, setT_platform] = useState('')

    const updateCommission = async (comm) => {
        setCommission(comm);
        instrument.commission[commindex] = comm;
    }

    const returnCommission = async () => {
        if (commission !== null && commission !== undefined) {
            setLoading(true);
            if (defChecked) {
                db.instruments.put(instrument, instrument.id);
            }
            onResolve(instrument.commission[commindex]);
            setLoading(false);
        }
    }
    const reject = () => onReject(0);

    const updateDefault = (e) => {
        setDefChecked(e.target.checked);
    }

    useEffect(() => {
        let ca = JSON.parse(localStorage.getItem('comm'));
        setCommarr(ca)
        setInstrument(ca.instrument);
        updateInstrument(ca.platform);
    }, [])

    const updateInstrument = (platform) => {
        switch (platform) {
            case 'ninja':
                while (instrument.commission.length < 1) {
                    instrument.commission.push(0);
                }
                setT_platform("NINJATRADER");
                setCommission(typeof instrument.commission[0] !== undefined ? instrument.commission[0] : null);
                setcommindex(0);
                break;
            case 'tvate':
                while (instrument.commission.length < 2) {
                    instrument.commission.push(0);
                }
                setT_platform("TRADOVATE");
                setCommission(typeof instrument.commission[1] !== undefined ? instrument.commission[1] : null);
                setcommindex(1);
                break;
            case 'tview':
                while (instrument.commission.length < 3) {
                    instrument.commission.push(0);
                }
                setT_platform("TRADINGVIEW");
                setCommission(typeof instrument.commission[2] !== undefined ? instrument.commission[2] : null);
                setcommindex(2);
                break;
            case 'paper':
                while (instrument.commission.length < 4) {
                    instrument.commission.push(0);
                }
                setT_platform("MANUAL ENTRY");
                setCommission(typeof instrument.commission[3] !== undefined ? instrument.commission[3] : null);
                setcommindex(3);
                break;
        }
    }

    return (
        <>
            <AlertDialog isOpen={isOpen} onClose={reject} motionPreset='slideInBottom' closeOnOverlayClick={false}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            <Flex direction={'column'} align={'center'}>
                                <Text fontWeight={'700'} mb='20px'>{t_platform} <ArrowForwardIcon /> fee </Text>
                                <Text fontWeight={'700'} color={'red.500'}>{instrument.description}</Text>
                            </Flex>
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Flex justify='center'>
                                <Text fontWeight={'500'} mb='20px' me='5px'>
                                    Confirm single unit execution fee for
                                </Text>
                                <Text fontWeight={'700'} mb='20px'>
                                    {instrument.ticker}
                                </Text>
                            </Flex>
                            <Flex justify='center' mt='10px' mb='10px'>
                                <NumberInput defaultValue={instrument.commission[commindex]} min={0} allowMouseWheel={true} onChange={(valueString) => updateCommission(valueString ? Number(valueString) : null)} step={0.01} isInvalid={commission === null || commission !== undefined}>
                                    <NumberInputField color={textColor} />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Flex>
                            <Flex direction={'row'} justify={'space-between'} mt='30px' mb='20px'>
                                <Checkbox isChecked={defChecked} onChange={(e) => updateDefault(e)}>Save as default?</Checkbox>
                                <Button colorScheme='red' onClick={returnCommission} ml={3} isLoading={loading} loadingText='Updating'>
                                    Accept
                                </Button>
                            </Flex>
                        </AlertDialogBody>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export const CommissionInput = create(CommissionInputDialog)