import { Button, Flex, useColorModeValue, Text } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { useEffect, useState } from 'react';

import { currencyformat } from '../../../trades/variables/FormatData';
import { Trade } from '../../../trades/variables/types'
import { db } from 'services/db';

const DashCalendarModalTrade = (props: { trade: Trade, opentrade: any }) => {

    const { opentrade, ...rest } = props;

    const bdColor = useColorModeValue('gray.200', 'gray.600');
    const bgColor = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue("navy.700", "white");
    const btnbgColor = useColorModeValue('gray.200', 'blackAlpha.500');

    const [nett, setNett] = useState("");

    const [accountname, setAccountname] = useState('');

    useEffect(() => {
        db.accounts.get(props.trade.accountid).then((acc) => {
            if (acc !== undefined) {
                setAccountname(acc.accname);
            }
        })
        setNett(currencyformat(props.trade.nett, props.trade.currency))
    }, [props.trade])


    const showTrade = () => {
        opentrade(props.trade.tradeid);
    }

    return (
        <>
            <Card borderColor={bdColor} borderWidth='1px' bg={bgColor} mb='8px' p={'10px'} onClick={showTrade} cursor={'pointer'}>
                <Flex direction={'row'} justify={'space-between'}>
                    {/*<Button variant='outline' size={'sm'} borderRadius='10px' onClick={showTrade}>
                        {accountname}
                    </Button>*/}
                    <Button size={'sm'} bg={btnbgColor} color={textColor} fontSize={{base:'sm', md:'md'}} fontWeight='600' ms={'20px'} borderRadius={'5px'} onClick={showTrade}>
                        {props.trade.ticker}
                    </Button>
                    <Button size={'sm'} bg={'btnbgColor'} color={props.trade.side === "sell" ? 'red.500' : 'green.500'} fontSize={{base:'sm', md:'md'}} fontWeight='600' ms={'20px'} borderRadius={'5px'} onClick={showTrade}>
                        {props.trade.side.toUpperCase()}
                    </Button>

                    <Button variant={'ghost'} size={'sm'} color={props.trade.nett < 0 ? 'red.500' : 'green.500'} fontSize={{base:'sm', md:'md'}} fontWeight='600' ms={'20px'} borderRadius={'5px'} w={'135px'} onClick={showTrade}>
                        {nett}
                    </Button>
                    <Button variant={'ghost'} size={'sm'} color={props.trade.rvalue > 1 ? 'green.500' : (props.trade.rvalue > 0 ? "orange.500" : (props.trade.rvalue < 0 ? "red.500" : textColor))} fontSize={{base:'sm', md:'md'}} fontWeight='600' ms={'20px'} borderRadius={'5px'} w={'135px'} onClick={showTrade}>
                        {props.trade.rvalue != 0 ? props.trade.rvalue + ' R' : 'no stop'}
                    </Button>

                </Flex>
            </Card>
        </>
    )
}

export default DashCalendarModalTrade