import { InputGroup, InputLeftAddon, Input, InputRightAddon, Button, Icon, useColorModeValue, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast, FormControl, Text } from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { RiMailSendLine } from 'react-icons/ri'
import { MdOutlineMarkEmailRead } from "react-icons/md";
import api from 'services/api';
import { db } from 'services/db';
import { useAuth } from 'services/auth/AuthProvider';


const MenteeInvite = () => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { user, muuid } = useAuth();

    const textColor = useColorModeValue('navy.700', 'white');
    const emailregex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const [email, setEmail] = useState('');
    const [valid, setValid] = useState(false);
    const [sending, setSending] = useState(false);
    const toast = useToast();

    const validateEmail = (m: string) => {
        if (m.match(emailregex)) {
            setValid(true);
        } else {
            setValid(false);
        }
    }

    const updateinvitemail = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value.trim());
        validateEmail(e.target.value);
    }

    const sendinvite = async () => {
        if (valid) {
            setSending(true);
            await api.post('/mentor/invite', { email: email, last_uuid: muuid }).then(async (res) => {
                if (res.data.message) {
                    switch (res.data.message) {
                        case 'subs': toast({ title: 'The user has a FREE account ...', description: "They need to upgrade their subscription to access mentorship", status: 'info', position: 'top', duration: 4000, isClosable: false }); break;
                        case 'count': toast({ title: 'Maximum mentors reached ...', description: "The trader needs to cancel an existing mentorship (pending or active) or upgrade their plan to 'PRO' to accept more invites", status: 'info', position: 'top', duration: 4000, isClosable: false }); break;
                        case 'pending':
                        case 'requested':
                        case 'active': toast({ title: 'Mentorship is already ' + res.data.message + ' for this user ...', description: "", status: 'info', position: 'top', duration: 3000, isClosable: false }); break;
                        case 'self': toast({ title: 'You cannot mentor yourself ...', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false }); break;
                        case 'mentor': toast({ title: 'Mentors cannot invite other mentors ...', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false }); break;
                        case 'email': toast({ title: 'Email address is not registered', description: "Please confirm the email address with your student", status: 'error', position: 'top', duration: 4000, isClosable: false }); break;
                        case 'failed': toast({ title: 'Request failed ...', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false }); break;
                    }
                }
                else {
                    res.data.sync_due = false;
                    delete res.data.last_uuid;
                    await db.mentees.put(res.data, res.data.id).then(() => {
                        toast({ title: 'Mentorship invitation sent ...', description: "We have emailed the trader your invite.", status: 'success', position: 'top', duration: 3000, isClosable: false });
                        closeinvite();
                    })
                    /*res.data.id = res.data.id + 10; // for testing to add extra mentee invites
                    await db.mentees.put(res.data, res.data.id).then(() => {
                        //toast({ title: 'Mentorship invitation sent ...', description: "", status: 'success', position: 'top', duration: 3000, isClosable: false });
                        //closeinvite();
                    })*/
                }
                setSending(false);
            }).catch((e) => {
                toast({ title: 'Unable to reach server ...', description: "Please check your internet connection and/or try again in a few minutes", status: 'error', position: 'top', duration: 4000, isClosable: false });
                closeinvite();
            })
        }
    }

    const closeinvite = () => {
        setEmail('');
        setValid(false);
        setSending(false);
        onClose();
    }

    return (
        <>
            <Button hidden={user.role !== 'MENTOR'} onClick={onOpen} variant={'outline'} colorScheme={'blue'} ms='5px' me='10px' borderWidth={'2px'}>Invite student<Icon as={MdOutlineMarkEmailRead} size={6} ms='10px'></Icon></Button>
            <Modal isOpen={isOpen} onClose={closeinvite} size={'lg'} closeOnOverlayClick={!sending}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Invite new student</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6} pt={6}>
                        <Text mb='30px'>Use the email they have registered with TradeJorno.</Text>
                        <FormControl isInvalid={!valid && email.trim().length > 0}>
                            <InputGroup>
                                <InputLeftAddon>
                                    Email
                                </InputLeftAddon>
                                <Input type='text' color={textColor} value={email} onChange={updateinvitemail} placeholder='Enter email address' ></Input>
                                <InputRightAddon as={Button} isDisabled={!valid} isLoading={sending} loadingText={'Sending'} onClick={sendinvite} bg={'blue.500'} color={'white'}>
                                    Send<Icon as={RiMailSendLine} size={5} ms='20px'></Icon>
                                </InputRightAddon>
                            </InputGroup>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button hidden={sending} variant={'outline'} colorScheme={'red'} onClick={closeinvite}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default MenteeInvite