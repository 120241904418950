import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import { PeriodDates, PeriodTrades, Trade } from "./types";

export function PeriodTradesArray(trades: Trade[], period: string, range: PeriodDates) {

    dayjs.extend(isBetween);

    //alert(period);

    const p_curr: any[] = [];
    const p_prev: any[] = [];

    if (period === 'all') {
        trades.forEach((t: Trade, index: number) => {
            p_curr.push(t);
        });
    }
    else {
        trades.forEach((t: Trade, index: number) => {
            if (dayjs(t.datetime).isBetween(range.startthis, range.endthis, null, '[)')) {
                p_curr.push(t);
            }
            if (dayjs(t.datetime).isBetween(range.startlast, range.endlast, null, '[)')) {
                p_prev.push(t);
            }
        });
    }

    //p_curr.sort((a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime());

    //alert(JSON.stringify(p_curr));

    let tradearray: PeriodTrades ={
        current: p_curr,
        previous: p_prev
    }

    return tradearray;

}