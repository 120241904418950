import { Box, Flex, FormLabel, Heading, Icon, Spinner, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import Card from 'components/card/Card';
import IconBox from 'components/icons/IconBox';
import { MdAdd, MdNumbers, MdOutlineCalculate, MdOutlineThumbUp, MdPercent } from 'react-icons/md';
import RuleScore from 'views/trader/trades/components/fields/RuleScore';
import { currencyformat } from 'views/trader/trades/variables/FormatData';
import { StatStrat } from 'views/trader/trades/variables/types';
import { HiOutlineEmojiHappy } from "react-icons/hi";

const StatLoser = (props: { loading: boolean, stats: StatStrat, currency: string }) => {

    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const iconColor = "gray.400";
    const boxBg = useColorModeValue('white', 'navy.800');
    const statColor = useColorModeValue('navy.700', 'gray.200');
    const spinnerColor = useColorModeValue('gray.300', 'brand.300');
    const bgcolor = useColorModeValue('white', 'navy.900');
    const textColorSecondary = useColorModeValue('secondaryGray.500', 'secondaryGray.500');

    const headercolor = "red.500";
    const mbottom = '10px'

    return (
        <Card borderColor={bdColor} borderWidth='1px' h={'100%'} bg='transparent' mt='15px'>
            <Flex>
                <Heading size={'xs'} mt='-30px' bg={bgcolor} px='10px'>Strategy losers</Heading>
            </Flex>

            <Box h={'100%'} justifyContent={'center'} alignContent={'center'} hidden={!props.loading}>
                <Flex justify={'center'}>
                    <Spinner color={spinnerColor}></Spinner>
                </Flex>
            </Box>

            {props.stats.lcount < 1 &&
                <Flex direction={'column'} justify={'center'} align={'center'} hidden={props.loading}>
                    <Icon as={HiOutlineEmojiHappy} boxSize={10} color={textColorSecondary} />
                    <Text color={textColorSecondary} fontSize='md' mt='20px'>No losing trades</Text>
                </Flex>
            }
            {props.stats.lcount > 0 &&
                <Flex direction={'column'} hidden={props.loading}>

                    <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                        <Flex direction={'column'}>
                            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Trades</Text>
                            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.lcount}</Text>
                        </Flex>
                        <Flex>
                            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
                                icon={<Icon color={iconColor} as={MdNumbers} w="20px" h="20px" />}
                            />
                        </Flex>
                    </Flex>

                    <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                        <Flex direction={'column'}>
                            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Lose %</Text>
                            <Text color={statColor} fontSize="sm" fontWeight="500" >{props.stats.lperc.toFixed()}%</Text>
                        </Flex>
                        <Flex>
                            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
                                icon={<Icon color={iconColor} as={MdPercent} w="20px" h="20px" />}
                            />
                        </Flex>
                    </Flex>

                    <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                        <Flex direction={'column'}>
                            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px' >AVG loss</Text>
                            <Text color={statColor} fontSize="sm" fontWeight="500" >{currencyformat(props.stats.lavg, props.currency)}</Text>
                        </Flex>
                        <Flex>
                            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
                                icon={<Icon color={iconColor} as={MdOutlineCalculate} w="20px" h="20px" />}
                            />
                        </Flex>
                    </Flex>

                    <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                        <Flex direction={'column'}>
                            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='3px'>Biggest loss</Text>
                            <Text color={statColor} fontSize="sm" fontWeight="500" >{currencyformat(props.stats.lmax, props.currency)}</Text>
                        </Flex>
                        <Flex>
                            <IconBox w="40px" h="40px" bg={boxBg} borderWidth={'1px'} borderRadius={'25%'}
                                icon={<Icon color={iconColor} as={MdAdd} w="20px" h="20px" />}
                            />
                        </Flex>
                    </Flex>

                    <Flex direction={'row'} justify={'space-between'} mb={mbottom}>
                        <Flex direction={'column'}>
                            <Text color={headercolor} fontSize="sm" fontWeight="600" mb='10px'>AVG Rulescore</Text>
                            <RuleScore rulescore={props.stats.lavgscore} width={'100%'} size={'lg'} />
                        </Flex>
                    </Flex>
                </Flex>
            }


        </Card>
    )
}

export default StatLoser