// Chakra imports
import { Badge, List, ListItem, ListIcon, Text, Button, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
// Assets
import { BsCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

export default function PackPricing(props: {
	title: string;
	desc: string;
	button: string;
	price: JSX.Element | string;
	details: string;
	monthly: boolean;
	benefits: string[];
	highlighted?: boolean;
}) {
	const navigate = useNavigate();
	const { title, desc, button, price, details, monthly, benefits, highlighted } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue('gray.400', 'gray.400');
	const yearlyColor = useColorModeValue('cyan.400', 'cyan');

	const signup = () =>{
		navigate("/auth/signup")
	}

	return (
		<Card
			p='20px'
			pb='45px'
			pt={highlighted ? '60px' : '30px'}
			w={{ sm: '375px', md: '450px', lg: '330px' }}
			alignItems='flex-start'
			justifyContent='flex-start'
			overflow='unset !important'>
			<Badge
				display={highlighted ? 'block' : 'none'}
				w='max-content'
				position='absolute'
				fontSize='sm'
				color='teal.500'
				bg='teal.100'
				fontWeight='bold'
				textTransform='unset'
				left='50%'
				borderRadius='70px'
				transform='translate(-50%,-250%)'>
				BEST VALUE
			</Badge>
			<Text fontSize='30px' color={textColor} fontWeight='700'>
				{title}
			</Text>
			<Text mb='30px' fontSize='md' color='secondaryGray.600' fontWeight='500'>
				{desc}
			</Text>
			<Button w='100%' variant={highlighted ? 'brand' : 'lightBrand'} mb='30px' onClick={signup}>
				{button}
			</Button>
			{price}
			<Text fontSize='md' color={monthly? 'secondaryGray.600': yearlyColor} fontWeight='700' mt='5px'>
				{details}
			</Text>
			<List spacing={3} justifyContent='flex-start' >
				{benefits.map((benefit, index) => (
					<ListItem
						key={index}
						display='flex'
						textAlign='start'
						fontSize='md'
						fontWeight='500'
						color={textColorSecondary}
						alignItems='center'
						lineHeight='100%'
						mt='30px !important'>
						<ListIcon w='10px' h='10px' as={BsCircleFill} my='auto' color={textColor} />
						{benefit}
					</ListItem>
				))}{' '}
			</List>
		</Card>
	);
}
