
import {
    useDisclosure,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Text,
    Flex,
    Icon,
    useToast,
    List,
    ListItem,
    ListIcon
} from '@chakra-ui/react'
import { useNetworkState } from '@uidotdev/usehooks';

import { useRef, useState } from "react";
import { IoMdCloseCircle } from 'react-icons/io';
import { MdOutlineWarningAmber, MdWarningAmber } from 'react-icons/md';
import { AiOutlineUserDelete } from "react-icons/ai";
import api from 'services/api';
import { db } from 'services/db';
import { useAuth } from 'services/auth/AuthProvider';
import PasswordEntry from '../inputs/PasswordEntry';
import dayjs from 'dayjs';
import { useData } from 'services/data/DataProvider';


const DeleteProfile = () => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const pwRef = useRef(null);
    const {dateformat} = useData();

    const { signout, user } = useAuth();//useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const network = useNetworkState();

    const [userverified, setuserverified] = useState<boolean>(false);
    const [renewal, setRenewal] = useState('');
    const [status, setStatus] = useState('');

    const openConfirm = async () => { //// need to change this so any subscriptions automatically cancelled
        if (user.role !== 'FREE' && user.role !== 'ADMIN') {
            await db.users.get(user.id).then((u) => {
                let canx = u.subscription.status.toLowerCase() === 'cancelled';
                setStatus(u.subscription.status.toLowerCase());
                let endrenew = canx ? u.subscription.ends_at : u.subscription.renews_at;
                setRenewal(dayjs(endrenew).format(dateformat));
            })
        }

        setuserverified(false);
        onOpen();
    }
    const closeDialog = () => {
        setuserverified(false);
        setRenewal('');
        setStatus('');
        onClose();
    }

    const verifyUserPw = async () => {
        pwRef.current.openInput();
    }

    const updateVerifcation = () => {
        setuserverified(true);
    }

    const handleDelete = async () => {

        if (network.online) {
            try {
                if (!userverified) {
                    verifyUserPw();
                }
                else {
                    setLoading(true);
                    setTimeout(async () => {

                        await api.post('/user/deleteprofile')
                            .then(async (res) => {
                                if (res.status === 200) {
                                    if (res.data === true) {
                                        signout();
                                        toast({ title: 'Sorry to see you go ...', description: "Feel free to sign up again anytime", status: 'info', position: 'top', duration: 5000, isClosable: true })
                                        db.delete({ disableAutoOpen: false }); //Delete database and recreate it whenever the db instance is accessed again
                                    }
                                    else if (res.data === false) {
                                        toast({ title: 'Oops, error ...', description: <Text fontWeight={'bold'} whiteSpace="pre-line">{"\nProfile not deleted ...\n\nPlease cancel any active subscriptions and then try again.\n\n"}</Text>, status: 'warning', position: 'top', duration: null, isClosable: true })
                                        closeDialog();
                                    }
                                }
                                else {
                                    toast({ title: 'Oops, error ...', description: "Profile not deleted", status: 'error', position: 'top', duration: 3000, isClosable: false })
                                }
                            })
                            .catch((error) => {
                                toast({ title: 'Oops, error ...', description: "Profile not deleted", status: 'error', position: 'top', duration: 3000, isClosable: false })
                            })
                        setLoading(false);
                    }, 1000);
                }
            }
            catch (err) {
                if (err === "failed") {
                    toast({ title: 'Oops, error ...', description: "No server connection ... profile not deleted", status: 'error', position: 'top', duration: 3000, isClosable: false })
                }
            }
        }
        else {
            toast({ title: 'No network connection ...', description: "Make sure your device is connected to the internet and try again", status: 'error', position: 'top', duration: 3000, isClosable: false });
        }
    };

    return (
        <>
            <Button colorScheme='red' variant='outline' onClick={openConfirm} mb='20px'>
                <Icon as={AiOutlineUserDelete} h='20px' w='20px' me='10px'></Icon>
                Delete profile
            </Button>
            <PasswordEntry ref={pwRef} ifVerifiedAction={updateVerifcation} />
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={closeDialog} >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold' bg='red.500'>
                            <Flex direction={'row'} hidden={userverified}>
                                <Icon as={MdOutlineWarningAmber} boxSize={8} color={'white'} />
                                <Text fontSize='lg' fontWeight='bold' color={'white'} ms='20px' mt='5px'>
                                    Delete Profile
                                </Text>
                            </Flex>
                            <Flex direction={'row'} hidden={!userverified}>
                                <Icon as={MdOutlineWarningAmber} boxSize={8} color={'white'} />
                                <Text fontSize='lg' fontWeight='bold' color={'white'} ms='20px' mt='5px'>
                                    WAIT
                                </Text>
                            </Flex>
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Flex direction={'column'} hidden={userverified} mt='20px'>

                                {(status === 'active' || status === 'cancelled') &&
                                    <>
                                        <Text fontWeight={'700'} mb='20px'>
                                            {status.toUpperCase()} subscription {status === 'active' ? 'renews' : 'expires'} on {renewal}
                                        </Text>
                                        <Text fontWeight={'700'} mb='20px' color={'red.500'}>
                                            NOTE: You will forfeit any paid subscriptions and lose access to TradeJorno IMMEDIATELY.
                                        </Text>
                                        <Text fontWeight={'700'} mb='20px' color={'red.500'}>
                                            Would you rather not cancel your subscription and use the remaining service you have paid for before deleting your profile?
                                        </Text>
                                    </>
                                }

                                <Text fontWeight={'700'} mb='20px'>
                                    This action will ...
                                </Text>
                                <List spacing={3} mb='20px'>
                                    <ListItem>
                                        <ListIcon as={IoMdCloseCircle} color='red.500' />
                                        Delete ALL data and accounts from our servers
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={MdWarningAmber} color='orange.500' />
                                        You will have to sign up again to use the service
                                    </ListItem>
                                </List>

                            </Flex>
                            <Flex direction={'column'} hidden={!userverified} mt='20px'>
                                <Text fontWeight={'700'} color={'red.500'} mb='20px'>
                                    DATA CANNOT BE RECOVERED IF YOU PROCEED
                                </Text>
                                <Text fontWeight={'700'}>
                                    Are you sure?
                                </Text>
                            </Flex>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button colorScheme='red' onClick={handleDelete} isLoading={loading} loadingText={'Deleting'}>
                                {userverified ? 'Verified, DELETE!' : 'DELETE'}
                            </Button>
                            <Button colorScheme={'blue'} ml={3} ref={cancelRef} onClick={closeDialog}>
                                NO, go back!
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default DeleteProfile