import { Icon } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { RiMailSendLine } from "react-icons/ri";
import { useData } from 'services/data/DataProvider';
import { Mentee, Mentor } from 'views/trader/trades/variables/types';

const MenuNotification = (props: { name: string }) => {

    const { name } = props;
    const { mentors, mentees } = useData();
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (mentors) {
            let mrp = mentors.filter((m: Mentor) => m.status === 'pending');
            let mrr = mentors.filter((m: Mentor) => m.status === 'requested');
            setCount(mrp.length + mrr.length);
            if (mentees) {
                let mep = mentees.filter((m: Mentee) => m.status === 'pending');
                let mer = mentees.filter((m: Mentee) => m.status === 'requested');
                setCount(mrp.length + mrr.length + mep.length + mer.length);
            }
        }


    }, [mentors, mentees])

    return (
        <>
            {name === "Mentorship" && count > 0 ? (<Icon as={RiMailSendLine} color='red.500' boxSize={5} ms='20px'>{count}</Icon>) : (<></>)}
        </>

    )
}

export default MenuNotification