import { AccountGroup } from "../variables/types";
import { useAuth } from "services/auth/AuthProvider";


const useNewAccountGroup = () => {

    const { user, muuid } = useAuth();//useContext(AuthContext);

    const newaccgroup: AccountGroup = {
		groupid: 0,
		userid:  (user === null ? 0 : user.id),
		groupname: "",
		currency: "",
		defaultgrp: true,
		sync_due: false,
		last_uuid: muuid
	}
	return newaccgroup;
};

export default useNewAccountGroup;