import { Badge, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { HSeparator } from 'components/separator/Separator';
import { useEffect, useState } from 'react'
import { db } from 'services/db';
import { Mentor, MentorMessage, MentorTag } from 'views/trader/trades/variables/types';
import MentorMessageBlock from './MentorMessageBlock';
import dayjs from 'dayjs';
import { useData } from 'services/data/DataProvider';
import { MentorRadioGroup } from '../MentorRadioGroup';

const MenteeMessages = () => {

  const { dateformat, mentors, mentormessages } = useData();

  const [mentorTags, setMentorTags] = useState<MentorTag[]>([]);
  const [loading, setLoading] = useState(false);

  const [pvtmessages, setPvtMessages] = useState<MentorMessage[]>([]);
  const [pvtnewcount, setpvtnewcount] = useState(0);
  const [grpmessages, setGrpMessages] = useState<MentorMessage[]>([]);
  const [grpnewcount, setgrpnewcount] = useState(0);
  const [selectedMentorName, setSelectedMentorName] = useState('');

  useEffect(() => {
    let arr: MentorTag[] = [];
    for (let men of mentors) {
      let ma = mentormessages.filter((m: MentorMessage) => (m.seen === false && men.mentorid === m.mentorid));
      arr.push({ mentorid: men.mentorid, mentorname: men.mentorname, avatar: men.avatar, newcount: ma.length });
    }
    setMentorTags(arr);
    if (arr.length < 2) {
      loadMessages(arr[0].mentorid);
      setSelectedMentorName(arr[0].mentorname)
    }
  }, [mentors, mentormessages])

  const setMentor = (mentorid: number) => {
    loadMessages(mentorid);
    let mt = mentorTags.filter((m) => m.mentorid === mentorid);
    setSelectedMentorName(mt[0].mentorname)
  }


  const loadMessages = async (mentorid: number) => {
    setLoading(true);
    await db.mentormessages.where({ mentorid: mentorid }).reverse().toArray().then((ms) => {
      let pvt = ms.filter((m) => m.mentorshipid > 0);
      let pvtcount = pvt.filter((m) => !m.seen);
      setpvtnewcount(pvtcount.length);
      setPvtMessages(pvt)
      let grp = ms.filter((m) => m.mentorshipid === 0);
      let grpcount = grp.filter((m) => !m.seen);
      setgrpnewcount(grpcount.length);
      setGrpMessages(grp);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
  }

  return (
    <>
      <Tabs size={'sm'} colorScheme='green' variant='solid-rounded' >
        <TabList flexWrap={'wrap'} mb='20px'>
          {/*mentorTags.map((m, index) => (
            <Tab mb='10px' onClick={() => loadMessages(m.mentorid)}><Text fontWeight={'700'}>{m.mentorname}</Text>{m.newcount > 0 && <Badge colorScheme='red' variant='solid' ms='10px'>{m.newcount}</Badge>}</Tab>
          ))*/}
          <Flex mt='10px'>
            <MentorRadioGroup mentortags={mentorTags} selectmentor={setMentor} selectedmentorname={selectedMentorName} />
          </Flex>
        </TabList>
        <HSeparator mb='20px' />
      </Tabs>
      {selectedMentorName !== '' &&
        <Tabs variant='solid-rounded' isFitted colorScheme='blue' size={'sm'} >
          <TabList>
            <Tab><Text fontWeight={'700'}>PRIVATE</Text>{pvtnewcount > 0 && <Badge bg={'red.500'} variant='solid' ms='10px'>{pvtnewcount}</Badge>}</Tab>
            <Tab><Text fontWeight={'700'}>GROUP</Text>{grpnewcount > 0 && <Badge bg={'red.500'} variant='solid' ms='10px'>{grpnewcount}</Badge>}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {loading ? (
                <></>
              ) : (
                <>
                  {pvtmessages.map((m, index) => (
                    <MentorMessageBlock
                      content={m.content}
                      time={dayjs(m.sent).format(dateformat)}
                      side={m.seen ? 'left' : 'right'}
                      seen={m.seen}
                      allowclose={true}//user.role === 'MENTOR'}
                      mid={m.id}
                    />
                  ))}
                </>
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <></>
              ) : (
                <>
                  {grpmessages.map((m, index) => (
                    <MentorMessageBlock
                      content={m.content}
                      time={dayjs(m.sent).format(dateformat)}
                      side={m.seen ? 'left' : 'right'}
                      seen={m.seen}
                      allowclose={true}//user.role === 'MENTOR'}
                      mid={m.id}
                    />
                  ))}
                </>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      }


    </>
  )
}

export default MenteeMessages