import { Flex, Spinner, Switch, useToast } from '@chakra-ui/react'
import { ChangeEvent, useRef, useState } from 'react'
import { UserEnableConfirm } from './UserEnableConfirm';
import api from 'services/api';

const UserEnable = (props: { userid: number, initcheck: boolean, updateEnable: any }) => {

    const [checked, setChecked] = useState(props.initcheck);
    const [loading, setLaoding] = useState(false);
    const toast = useToast();

    const cRef = useRef(null);

    const setEnabled = (e: ChangeEvent<HTMLInputElement>) => {
        cRef.current.openInput(e.target.checked);
    }
    const setEnabledConfirm = async (e: boolean) => {
        setLaoding(true);

        await api.post('/admin/enableuser', { uid: props.userid, enable: e }).then(async (res) => {
            setChecked(res.data);
            props.updateEnable(props.userid, res.data);
        }).catch(async (error) => {
            toast({ title: 'Error updating', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false })
        });
        setLaoding(false);
    }

    return (
        <>
            {loading ? (
                <Spinner thickness='4px' speed='0.65s' size='sm' />
            ) : (
                <Switch size='sm' colorScheme={'green'} isChecked={checked} onChange={setEnabled} />
            )}
            <UserEnableConfirm ref={cRef} confirmaction={setEnabledConfirm} />
        </>

    )
}

export default UserEnable