import { Button, Flex, FormControl, FormErrorMessage, Icon, Input, InputGroup, InputLeftAddon, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Select, Stack, useDisclosure, useToast } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { Field, Formik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react'
import api from 'services/api';
import { db } from 'services/db';
import { AccountGroup } from '../../variables/types';
import * as Yup from "yup";
import { MdEdit } from 'react-icons/md';
import { setDefaultGroup } from 'services/dbservice';
import useNewAccountGroup from '../../hooks/useNewAccountGroup';
import { useAuth } from 'services/auth/AuthProvider';
import { useData } from 'services/data/DataProvider';

const AccountGroupEdit = (props: { groupid: number}) => {

    const { user, muuid } = useAuth();//suseContext(AuthContext);
    const {accountgroups} = useData();

    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const bgColor = useColorModeValue('gray.500', 'blackAlpha.500');
    const [processing, setprocessing] = useState(false);
    const toast = useToast();

    const [group, setGroup] = useState(useNewAccountGroup());
    const [groups, setGroups] = useState([]);

    const openInput = async () => {
        await db.accountgroups.get(props.groupid).then((g) => {
            setGroup(g);
            onOpen();
        })
    }

    useEffect(()=>{
        setGroups(accountgroups);
    },[accountgroups])

    const reject = () => {
        setprocessing(false);
        onClose();
    }

    const resolve = (grp: AccountGroup) => {
        setprocessing(false);
        onClose();
    }

    const changeCurrency = (e: ChangeEvent<HTMLSelectElement>) => {
        group.currency = e.target.value;
    }

    return (
        <>
            <Button colorScheme={'gray'} variant="outline" borderLeftRadius={'10px'} borderRightRadius={'0px'} onClick={openInput}>
                <Icon as={MdEdit} boxSize={5} />
            </Button>
            <Modal isOpen={isOpen} onClose={reject} size="lg" closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit trading group</ModalHeader>
                    <ModalBody>

                        <Formik
                            initialValues={{
                                groupname: group.groupname,
                                currency: group.currency
                            }}
                            onSubmit={(values) => {

                                setprocessing(true);
                                setTimeout(async () => {

                                    delete group.sync_due; // remove as sync_due not sotred on server
                                    group.groupname = values.groupname;
                                    group.currency = values.currency;
                                    group.last_uuid = muuid;

                                    await api.post('/accounts/savegroup', { group: group }).then(async (res) => {

                                        if (res.data > 0) {

                                            delete group.last_uuid;
                                            group.sync_due = false;
                                            group.groupid = res.data;

                                            await db.accountgroups.put(group, group.groupid).then(async () => {
                                                setDefaultGroup(group.groupid, user.id).catch(() => { })
                                            }).catch(() => { });

                                            setprocessing(false);
                                            resolve(group);
                                            toast({ title: 'Account group saved', description: "", status: 'success', position: 'top', duration: 3000, isClosable: false })
                                        }
                                        else {
                                            toast({ title: 'Oops, error ...', description: "Group not saved", status: 'error', position: 'top', duration: 3000, isClosable: false })
                                            setprocessing(false);
                                            reject();
                                        }
                                    }).catch((error) => {
                                        toast({ title: 'Oops, error ...', description: "Check your internet connection", status: 'error', position: 'top', duration: 3000, isClosable: false })
                                        setprocessing(false);
                                    })
                                }, 250);
                            }}

                            validationSchema={Yup.object().shape({
                                groupname: Yup.string().required('Required ...').min(4, 'Username minimum 4 characters').max(20, 'Username maximum 20 characters').test("accname duplicate", "Group name already used", (item) => {
                                    return groups.filter((g) => (g.groupname.toLowerCase().trim() === item.toLowerCase().trim()) && g.groupid!==group.groupid).length < 1;
                                }),
                                currency: Yup.string().required('Required ...'),
                            })}
                        >
                            {({ handleChange, handleSubmit, errors, touched },) => (
                                <form onSubmit={handleSubmit} onChange={handleChange}>
                                    <Stack spacing={4} mt='25px'>

                                        <FormControl isInvalid={!!errors.groupname && touched.groupname}>
                                            <InputGroup size='md'>
                                                <InputLeftAddon bg={bgColor} color={'white'} w='150px'>
                                                    Group name
                                                </InputLeftAddon>
                                                <Field
                                                    as={Input}
                                                    id="groupname"
                                                    name="groupname"
                                                    color={textColor} placeholder='Enter trading group name ...'
                                                    borderLeftRadius={'0px'}
                                                >
                                                </Field>
                                            </InputGroup>
                                            <Flex justify="right">
                                                <FormErrorMessage>{errors.groupname}</FormErrorMessage>
                                            </Flex>

                                        </FormControl>

                                        <FormControl isInvalid={!!errors.currency && touched.currency}>
                                            <InputGroup variant='outline' size='md'>
                                                <InputLeftAddon children="Base currency" bg={bgColor} color={'white'} w='150px' />
                                                <Field
                                                    as={Select}
                                                    id="currency"
                                                    name="currency"
                                                    onChange={changeCurrency}
                                                    borderLeftRadius={'0px'}
                                                >
                                                    <option value="">Select currency ...</option>
                                                    <option value="USD">USD - US Dollar</option>
                                                    <option value="EUR">EUR - Euro</option>
                                                    <option value="GBP">GBP - British Pound</option>
                                                    <option value="CAD">CAD - Canadian Dollar</option>
                                                    <option value="AUD">AUD - Australian Dollar</option>
                                                    <option value="CHF">CHF - Swiss Franc</option>
                                                    <option value="HKD">HKD - Hong Kong Dollar</option>
                                                    <option value="JPY">JPY - Japanese Yen</option>
                                                </Field>
                                            </InputGroup>
                                        </FormControl>
                                    </Stack>
                                    <Flex justify='right'>
                                        <Button mt='25px' mb='15px' colorScheme='red' me={3} onClick={reject}>
                                            Cancel
                                        </Button>
                                        <Button mt='25px' mb='15px' type='submit' colorScheme='blue' ms={3} isLoading={processing} loadingText='Processing'>
                                            Save
                                        </Button>
                                    </Flex>
                                </form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
};

export default AccountGroupEdit