
import {
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    InputGroup,
    Input,
    Icon,
    FormControl,
    Flex,
    useToast,
    ModalOverlay,
    FormErrorMessage,
    InputRightElement
} from "@chakra-ui/react";

import api from "services/api";
import { MdOutlinePermIdentity } from 'react-icons/md';
import AuthContext from 'services/auth/AuthContext';
import { useAuth } from "services/auth/AuthProvider";

import { Formik, Field } from "formik";
import * as Yup from "yup";

import { useState, useContext } from "react";

import { create } from "react-modal-promise";

const UsernameInputDialog = ({ isOpen, onResolve, onReject }) => {

    const textColorSecondary = 'gray.400';
    const [processing, setprocessing] = useState(false);
    const { signout, user, muuid } = useAuth();//useContext(AuthContext);
    const toast = useToast();

    const cancelchange = () => {
        onReject("cancel");
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={cancelchange} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Change your username ...</ModalHeader>
                    <ModalBody>
                        <Formik
                            initialValues={{
                                username: "",
                            }}
                            onSubmit={async (values) => {
                                setprocessing(true);
                                setTimeout(async () => {

                                    await api.post('/user/editusername', {uname: values.username.trim(), last_uuid: muuid}).then((resp) => {
                                        if (resp.data.message === "true") {
                                            user.username = values.username;
                                            
                                            onResolve(values.username.trim());
                                            signout(false);
                                            toast({ title: 'Username changed ...', description: "Sign in with your new username", status: 'success', position: 'top', duration: 3000, isClosable: false })
                                        }
                                        else {
                                            toast({ title: 'Sorry ... username not available', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false })
                                        }
                                    }).catch((err) => {
                                        toast({ title: 'Username update failed', description: "Verify your network connection and try again ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                                        onReject(0)
                                    })
                                    setprocessing(false);

                                }, 500);
                            }}

                            validationSchema={Yup.object().shape({
                                username: Yup.string().required('Required ...').min(5, 'Username minimum 5 characters').max(20, 'Username maximum 20 characters'),
                            })}
                        >
                            {({ handleSubmit, errors, touched },) => (
                                <form onSubmit={handleSubmit}>

                                    <FormControl isInvalid={!!errors.username && touched.username}>
                                        <InputGroup size="md">
                                            <Field as={Input} id="username" name="username" type={'text'} fontSize="sm" ms={{ base: '0px', md: '4px' }} placeholder="Minimum 5 characters" size="lg" variant="auth" />
                                            <InputRightElement display="flex" alignItems="center" mt="4px">
                                                <Icon color={textColorSecondary} _hover={{ cursor: 'pointer' }} as={MdOutlinePermIdentity} />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage ms='10px'>{errors.username}</FormErrorMessage>
                                    </FormControl>

                                    <Flex justify='right'>
                                        <Button mt='25px' mb='15px' colorScheme='red' me={3} onClick={cancelchange}>
                                            Cancel
                                        </Button>
                                        <Button mt='25px' mb='15px' type='submit' colorScheme='blue' ms={3} isLoading={processing} loadingText='Verifying'>
                                            Update
                                        </Button>
                                    </Flex>
                                </form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export const UsernameInput = create(UsernameInputDialog)