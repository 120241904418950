import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Account, Trade } from 'views/trader/trades/variables/types';

const SideBarChart = (props: { data: { trades: Trade[], accounts: Account[] }, equitychange: number }) => {

    useEffect(() => {

        let d = [];
        let equity = 0;
        let initbal = 0;

        for (let a of props.data.accounts) {
            initbal += a.init_balance;
        }
        equity += initbal;

        d.push(equity.toFixed(2));

        for (let t of props.data.trades) {
            equity += t.nett;
            d.push(equity.toFixed(2));
        }

        let s = [{ name: 'Equity', data: d }]

        setVariables({ options: options, series: s });

    }, [props.data])

    function getcolor() {
        return props.equitychange > 0 ? '#01B574' : '#EE5D50'
    }

    const options: ApexOptions = {
        chart: {
            toolbar: {
                show: false
            }
        },
        markers: {
            size: 0,
            colors: '#868CFF',
            strokeColors: 'white',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            shape: 'circle',
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            showNullDataPoints: true
        },
        tooltip: {
            theme: 'dark',

        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            categories: [],
            labels: {
                show: false,
                style: {
                    colors: 'white',
                    fontSize: '12px',
                    fontWeight: '500'
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false
        },
        legend: {
            show: false
        },
        grid: {
            show: false,
        },
        fill: {
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 0.1,
                opacityFrom: 0.3,
                opacityTo: 0.9,
                colorStops: [
                    [
                        {
                            offset: 0,
                            color: getcolor(), //#01B574 green.500, #EE5D50 red.500
                            opacity: 1
                        },
                        {
                            offset: 100,
                            color: getcolor(),
                            opacity: 0.25
                        }
                    ]
                ]
            }
        }
    };

    const [variables, setVariables] = useState({ options: options, series: [] });

    return (
        // @ts-ignore
        <Chart
            options={variables.options}
            type="line"
            width="100%"
            height="100%"
            series={variables.series}
        />
    );
};


export default SideBarChart;