import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Icon, useToast, Flex, Text, InputGroup, InputLeftAddon, Input, useColorModeValue, FormLabel } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import { FaRegCreditCard } from 'react-icons/fa6'
import { MdOutlineEditCalendar } from 'react-icons/md'
import { RiUserSettingsLine } from 'react-icons/ri'
import { TbUserCheck } from 'react-icons/tb'
import api from 'services/api'
import { useAuth } from 'services/auth/AuthProvider'
import { useData } from 'services/data/DataProvider'
import { db } from 'services/db'
import { DeleteSubscriptionConfirm } from 'views/trader/components/confirm/DeleteSubscriptionConfirm'
import { newSubscription, Subscription } from 'views/trader/trades/variables/types'

const ManageSubscription = (props: { highlighted: boolean, btnColor: string, updatepayment: any, changeBilling: any, gotoportal: any }) => {

    const { user, setUser, signout } = useAuth();
    const {dateformat} = useData();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [userSubscription, setUserSubscription] = useState<Subscription>(newSubscription());
    const [iscancelled, setIsCancelled] = useState(false);
    const [renewal, setRenewal] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingpmt, setLoadingpmt] = useState(false);
    const [loadingcancel, setLoadingcancel] = useState(false);
    const [billing, setBilling] = useState('');

    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const toast = useToast();

    const openmodal = async () => {

        setLoading(true);
        await db.users.get({ current_user: 1 }).then((u) => {
            setUserSubscription(u.subscription);
            setBilling(u.subscription.period.toUpperCase());
            let canx = u.subscription.status.toLowerCase() === 'cancelled';
            setIsCancelled(canx);
            let endrenew = canx ? u.subscription.ends_at : u.subscription.renews_at;
            setRenewal(dayjs(endrenew).format(dateformat));
            setLoading(false);
            onOpen();
        }).catch(() => {
            setLoading(false);
            toast({ title: 'User subscription not found ...', description: "Please sign in again to retrieve details", status: 'error', position: 'top', duration: 4000, isClosable: true })
            signout();
        });

    }

    const getpmturl = async () => {
        setLoadingpmt(true);
        await db.users.get(user.id).then(async (u) => {
            await api.post('/subs/getpmtupdateurl', { sub_id: u.subscription.subscription_id, portal: false }).then((resp) => {
                onClose();
                props.updatepayment(resp.data);
            }).catch((e) => {
                toast({ title: 'Unable to update ...', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 4000, isClosable: true })
            })
        }).catch((e) => {

        })
        setLoadingpmt(false);
    }

    const cancelVerify = async () => {

        setLoadingcancel(true);

        await api.post('/subs/cancelsub').then(async (res) => {
            if (res.data === false) {
                props.gotoportal();
                //toast({ title: 'Error cancelling subscription ...', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 4000, isClosable: true })
            }
            else {
                res.data.current_user = true;

                await db.users.put(res.data, res.data.id).then(async () => {
                    await db.users.toCollection().modify(user => {
                        user.current_user = (user.id === res.data.id ? 1 : 0);
                    }).catch((err) => { });

                }).catch((e) => { })

                setUser(res.data);
                delete res.data.subscription; // so it's not saved in localstorage
                localStorage.setItem('juser', JSON.stringify(res.data));
                toast({ title: 'Subscription cancelled successfully', description: <Text whiteSpace="pre-line">{"\nYour current plan will remain active until expiry\n\nYou can resume anytime prior to expiry"}</Text>, status: 'info', position: 'top', duration: 5000, isClosable: true })

                //toast({ title: 'Subscription cancelled successfully', description: "Your current plan will remain active until expiry", status: 'info', position: 'top', duration: 5000, isClosable: true })
            }
        }).catch(() => {
            toast({ title: 'Error cancelling subscription ...', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 4000, isClosable: true })
        })

        setLoadingcancel(false);
        onClose();
    }

    const updatebilling = () => {
        onClose();
        props.changeBilling();
    }


    return (
        <>
            <Button w='100%' variant={props.highlighted ? 'brand' : 'outline'} colorScheme={props.btnColor} mb='30px' onClick={openmodal} isLoading={loading} loadingText='Fetching details'>
                ACTIVE <Icon as={RiUserSettingsLine} boxSize={5} ms='20px'></Icon>
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{user.role} subscription</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction={'column'}>
                            <InputGroup>
                                <InputLeftAddon>
                                    <Text fontWeight={'700'}>STATUS</Text>
                                </InputLeftAddon>
                                <Input isReadOnly={true} color={iscancelled ? 'red.500' : textColor} value={userSubscription.status.toUpperCase()} fontWeight={'700'} />
                            </InputGroup>
                            <InputGroup mt='20px'>
                                <InputLeftAddon>
                                    <Text fontWeight={'700'}>{iscancelled ? "ENDS" : "RENEWS"}</Text>
                                </InputLeftAddon>
                                <Input isReadOnly={true} color={iscancelled ? 'red.500' : textColor} value={renewal} fontWeight={'700'} />
                            </InputGroup>


                        </Flex>
                        <Flex direction={'column'}>


                            {!iscancelled &&
                                <>
                                    <Flex justify={'center'} mt='20px'>
                                        <FormLabel>Billing interval: {billing}</FormLabel>
                                    </Flex>
                                    <Button variant={'outline'} colorScheme={'orange'} onClick={updatebilling} mt='20px' isDisabled={loadingcancel}>
                                        Change billing interval <Icon as={MdOutlineEditCalendar} ms='20px' boxSize={5}></Icon>
                                    </Button>
                                    <Button variant={'outline'} colorScheme={'blue'} onClick={getpmturl} mt='20px' isLoading={loadingpmt} loadingText='Loading' isDisabled={loadingcancel}>
                                        Update payment details <Icon as={FaRegCreditCard} ms='20px' boxSize={5}></Icon>
                                    </Button>
                                </>
                            }
                            {iscancelled ? (
                                <Button colorScheme={'green'} mt='20px' onClick={updatebilling} isLoading={loading} loadingText='Loading'>
                                    Resume subscription <Icon as={TbUserCheck} ms='20px' boxSize={5}></Icon>
                                </Button>
                            ) : (
                                <DeleteSubscriptionConfirm subscription={userSubscription} role={user.role} confirmdelete={cancelVerify} loadingbtn={loadingcancel} />
                            )}
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant={'ghost'} mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </>
    )
}

export default ManageSubscription