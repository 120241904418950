
import {
    useDisclosure,
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalOverlay,
    ModalFooter, Flex, Icon, Select, InputGroup, InputLeftAddon, useColorModeValue, Input, FormLabel, useToast, FormControl, FormErrorMessage
} from "@chakra-ui/react";

import Card from "components/card/Card";
import { Field, FieldInputProps, Form, Formik, FormikProps } from "formik";
import { ChangeEvent, useEffect, useState } from "react";

import { MdAdd, MdClose, MdEdit } from 'react-icons/md';
import api from "services/api";
import { db } from "services/db";
import { newStrategyRule, Strategy, StrategyRule } from "../../variables/types";
import ImageDropzone from "../fields/ImageDropzone";
import StrategyRuleInput from "../fields/StrategyRuleInput";
import { DeleteConfirm } from "../../../components/confirm/DeleteConfirm";
import JornoQuill from "views/trader/components/inputs/JornoQuill";
import NotesTemplates from "../fields/NotesTemplates";
import uuid from "react-uuid";
import useNewStrategy from "../../hooks/useNewStrategy";
import { useAuth } from "services/auth/AuthProvider";

const StrategiesModal = (props: { updateStrategy: any, notifyChanges: any, deleteStrategy: any }) => {

    const { isOpen: isStratOpen, onOpen: onStratOpen, onClose: onStratClose } = useDisclosure();
    const bdColor = useColorModeValue('gray.200', 'gray.600');
    const bgColor = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue('gray.700', 'white');

    const [userStrategies, setUserStrategies] = useState([]);
    const [strategy, setStrategy] = useState<Strategy>(null);
    const [rules, setRules] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [tempreset, setTempreset] = useState('');

    const [processingstrat, setProcessingStrat] = useState(false);
    const [addDisable, setAddDisable] = useState(false);

    const [image, setImage] = useState("");
    const { user, muuid } = useAuth();//useContext(AuthContext);

    const [edited, setEdited] = useState(false);

    const toast = useToast();
    const newstrat = useNewStrategy();

    useEffect(() => {

    }, [rules])

    const loadUserStrategy = (e: ChangeEvent<HTMLSelectElement>) => {

        clearStrategy();

        let id = Number(e.target.value);

        if (id > 0) {
            let str = userStrategies.filter((s) => s.id === id);
            let strat_deep_copy = JSON.parse(JSON.stringify(str[0]));
            setStrategy(strat_deep_copy);
            setName(strat_deep_copy.name);
            setDescription(strat_deep_copy.description);
            setRules(strat_deep_copy.rules);
            setImage(strat_deep_copy.base64Image);
        }
    }

    const stratmodalopen = async () => {
        loadStrategies();
        onStratOpen();
    }
    const loadStrategies = async () => {
        await db.strategies.where({ userid: user.id }).toArray().then((strats) => {
            setUserStrategies(strats);
        })
    }

    const modalClosed = () => {
        //alert(JSON.stringify(strategy));
        clearStrategy();
        onStratClose();
    }
    const clearStrategy = () => {
        setStrategy(null);
        setRules([]);
        setName("");
        setDescription("");
        setImage('');
        setEdited(false);
    }

    const setStratname = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        strategy.name = e.target.value.trim();
        setEdited(true);
    }
    const setStratDescription = (content: string) => {
        if (strategy) {
            strategy.description = content.trim();
            setDescription(content.trim());
            setEdited(true);
        }
    }

    const addNewStrategy = () => {
        clearStrategy();
        setStrategy(newstrat);
        let s = document.getElementById('selectedstrat') as HTMLSelectElement;
        s.value = '0';
        setEdited(true);
        setTempreset(uuid());
    }
    const addRule = async () => {
        let s = newStrategyRule();
        s.id = rules.length ? rules[rules.length - 1].id + 1 : 1;
        s.text = '';
        s.sid = strategy.id;
        strategy.rules = [...rules, s];
        setRules(strategy.rules);
        setEdited(true);
        setAddDisable(true);
    }
    const removeRule = (id: number) => {
        strategy.rules = rules.filter((r) => r.id !== id);
        setRules(strategy.rules);
        setEdited(true);
    }
    const setRuleText = (rule: StrategyRule) => {
        const update = rules.map(oldrule => oldrule.id === rule.id ? rule : oldrule)
        setRules(update);
        strategy.rules = update;
        setEdited(true);
        setAddDisable(false);
    }
    const removeBlankRules = () => {
        const update = rules.filter((r) => r.text.trim().length > 0)
        setRules(update);
        strategy.rules = update;
    }

    const deleteStrategy = (id: number) => {

        delete strategy.sync_due; // variable not used on API
        strategy.last_uuid = muuid;

        api.post('/strategies/deletestrategy', strategy).then(async (res) => {
            if (res.data === true) {
                await db.strategies.delete(id).then(() => {
                    props.deleteStrategy(strategy.id)
                    let str = userStrategies.filter((s: Strategy) => s.id !== id);
                    setUserStrategies(str);
                    clearStrategy();
                })
            }
            else {
                toast({ title: 'Oops, error ...', description: "Strategy not deleted ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
            }
        }).catch((err) => {
            toast({ title: 'Oops, error ...', description: "Strategy not deleted ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
        })
    }

    const saveStrategy = () => {

        setProcessingStrat(true);

        removeBlankRules();

        strategy.last_uuid = muuid;
        delete strategy.sync_due;

        let str = strategy.description.replace(/(<([^>]+)>)/ig, '').trim();
        if (!str.length) {
            strategy.description = "";
        }

        let url = (strategy.id > 0 ? 'updatestrategy' : 'savestrategy');

        setTimeout(() => {
            api.post('/strategies/' + url, strategy)
                .then(async (res) => {
                    if (res.status === 200) {
                        if (res.data === 0) {
                            toast({ title: 'Oops, error ...', description: "Strategy not saved", status: 'error', position: 'top', duration: 3000, isClosable: false })
                        }
                        else {
                            //res.data.sync_due = false;
                            strategy.sync_due = false;
                            strategy.id = res.data;

                            await db.strategies.put(strategy, strategy.id).then(() => {
                                props.updateStrategy(strategy);
                                if (strategy.id > 0) {
                                    props.notifyChanges(strategy);
                                }
                            });
                        }
                    }
                    setProcessingStrat(false);
                    modalClosed();
                }).catch(async function (error) {
                    if (strategy.id > 0) {
                        strategy.sync_due = true;
                        await db.strategies.put(strategy, strategy.id).then(async () => {
                            props.updateStrategy(strategy);
                            if (strategy.id > 0) {
                                props.notifyChanges(strategy);
                            }
                            modalClosed();
                        });
                    }
                    else {
                        toast({ title: 'Error saving strategy ...', description: "Are you connected to the internet?", status: 'error', position: 'top', duration: 3000, isClosable: false });
                    }
                    setProcessingStrat(false);
                });
        }, 250);
    }

    function validateName(value: string) {
        let error
        let index = userStrategies.findIndex((s) => s.name.trim() === name.trim());
        if (index > -1 && strategy.id === 0) {
            error = 'Name already used'
        }
        else if (index > -1 && strategy.id !== userStrategies[index].id) {
            error = 'Name already used'
        }
        else if (!name.trim().length) {
            error = 'Name required'
        }
        else if (name.length < 3) {
            error = "Minimum 3 characters"
        }
        return error
    }
    function validateDescription(value: string) {
        let error
        if (!description.trim().length) {
            error = 'Description is required'
        }
        return error
    }

    const updateimage = async (file: any) => {
        setImage(file);
        strategy.base64Image = file;
        setEdited(true);
    }


    return (
        <>
            <Button variant={'outline'} p='0px' size={'sm'} onClick={stratmodalopen} borderRadius='5px' ms='3px' me='3px'>
                <Icon as={MdEdit} boxSize={5} />
            </Button>

            <Modal isOpen={isStratOpen} onClose={modalClosed} size={{base: 'full', md:'2xl'}} onCloseComplete={modalClosed} closeOnOverlayClick={false} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Strategy details</ModalHeader>
                    <ModalCloseButton onClick={modalClosed} />

                    <Formik
                        initialValues={{ name: name, description: description }}
                        onSubmit={(values, actions) => {
                            saveStrategy();
                        }}
                    >
                        {(props) => (
                            <Form>
                                <ModalBody>
                                    <Flex>
                                        <InputGroup size='md'>
                                            <InputLeftAddon fontWeight={'700'} >
                                                Strategy
                                            </InputLeftAddon>
                                            <Select id='selectedstrat' onChange={loadUserStrategy} borderLeftRadius={'0px'} isDisabled={edited}>
                                                <option value='0'>Select to view/edit</option>
                                                {userStrategies !== null &&
                                                    userStrategies.map((strat, index) => (
                                                        <option key={strat.id} value={strat.id}>
                                                            {strat.name}
                                                        </option>
                                                    ))
                                                }
                                            </Select>
                                            <Button variant={'outline'} size={'md'} onClick={addNewStrategy} borderRadius='5px' ms='3px' me='3px'>
                                                New
                                            </Button>
                                            {strategy && <DeleteConfirm hidden={false} id={strategy.id} size={'md'} me={'0px'} title={'Delete strategy'} message={'Strategy will remain on trades where used until removed, but will no longer be available for tagging a trade or performance analysis.'} callbackfunction={deleteStrategy}></DeleteConfirm>}
                                            {/*<Button variant={'outline'} p='0px' size={'md'} onClick={deleteStrategy} borderRadius='5px'  disabled={!strategy || strategy.id === 0}>
                                                <Icon as={MdClose} boxSize={5} color={'red.500'} />
                                            </Button>*/}
                                        </InputGroup>
                                    </Flex>
                                    <Flex hidden={!strategy}>
                                        <Card borderColor={bdColor} borderWidth='1px' mt={'20px'} bg={bgColor}>
                                            <Field name='name' validate={validateName}>
                                                {({ field, form }: { field: FieldInputProps<string>, form: FormikProps<{ name: string, description: string }> }) => (
                                                    <FormControl isInvalid={form.errors.name && form.touched.name} mb={'20px'}>
                                                        <Flex justify={'space-between'}>
                                                            <FormLabel fontWeight='bold' fontSize='sm' >Name</FormLabel>
                                                            <FormErrorMessage mt='0px' mb='8px'>{form.errors.name}</FormErrorMessage>
                                                        </Flex>
                                                        <Input {...field} placeholder='Enter name ...' color={textColor} onChange={setStratname} value={name} />
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name='description' border='1px solid' borderColor={bdColor} borderRadius={'10px'} validate={validateDescription}>
                                                {({ field, form }: { field: FieldInputProps<string>, form: FormikProps<{ name: string, description: string }> }) => (
                                                    <FormControl isInvalid={form.errors.description && form.touched.description}>
                                                        <Flex justify={'space-between'}>
                                                            <FormLabel fontWeight='bold' fontSize='sm' mt='10px'>Description</FormLabel>
                                                            <NotesTemplates type={'strategy'} notes={description} setnotes={setStratDescription} reset={tempreset} mb={'8px'} size={'sm'} />
                                                            <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                                                        </Flex>
                                                        {/*<Textarea {...field} placeholder='Enter description ...' value={description} onChange={setStratDescription}></Textarea>*/}
                                                        <JornoQuill content={description} updatecontent={setStratDescription} allowimages={false} placeHolder={'Describe your strategy ...'} />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </Card>
                                    </Flex>
                                    <Flex hidden={!strategy}>
                                        <Card borderColor={bdColor} borderWidth='1px' mt={'20px'} bg={bgColor}>
                                            <Flex direction={'row'} justify={'space-between'}>
                                                <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Rules {'('}optional{')'}</FormLabel>
                                                <Button isDisabled={addDisable} variant={'outline'} size={'sm'} onClick={() => addRule()} borderRadius='5px'>
                                                    <Icon as={MdAdd} boxSize={5} me={'5px'}/>Add
                                                </Button>
                                            </Flex>

                                            <Flex direction={'column'} mt='20px'>
                                                {rules.map((srule) => (
                                                    <StrategyRuleInput key={Math.floor(Math.random() * 10000)} rule={srule} removerule={removeRule} updatetext={setRuleText}></StrategyRuleInput>
                                                ))}
                                            </Flex>
                                        </Card>
                                    </Flex>
                                    <Flex hidden={!strategy}>
                                        <Card borderColor={bdColor} borderWidth='1px' mt={'20px'} bg={bgColor}>
                                            <Flex direction={'row'} justify={'space-between'}>
                                                <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Example image {'('}optional{')'}</FormLabel>
                                                <Flex hidden={!image.length} w="30px" h="30px" justify="center" align="center" background="rgba(0, 0, 0, 0.5)" borderRadius="10px" position="absolute" top="15px" right="15px" transition="0.3s" cursor="pointer" onClick={() => updateimage('')} _hover={{ background: 'rgba(0, 0, 0, 1);' }}>
                                                    <Icon as={MdClose} color="#fff" fontSize="20px" />
                                                </Flex>
                                            </Flex>
                                            <ImageDropzone imagefile={image} setimagefile={updateimage} />
                                        </Card>
                                    </Flex>
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="ghost" mr={3} onClick={modalClosed}>
                                        Cancel
                                    </Button>
                                    <Button type='submit' colorScheme="blue" isLoading={processingstrat} loadingText="Saving ..." hidden={!strategy}>
                                        Save
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>

                </ModalContent>
            </Modal>
        </>
    );
};

export default StrategiesModal;