import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Icon, Text, useDisclosure } from "@chakra-ui/react"
import { useRef } from "react"
import { MdClose } from "react-icons/md"

export const DeleteConfirm = (props: { id: number, title: string, message: string, size: string, me: string, ms?: string, callbackfunction: any, hidden: boolean, loading?: boolean }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()

    const confirmDelete = () => {
        onClose();
        props.callbackfunction(props.id);
    }

    return (
        <>
            <Button variant={'outline'} p='0px' size={props.size} onClick={onOpen} borderRadius='5px' me={props.me} ms={props.ms} disabled={props.id < 1} hidden={props.hidden} isLoading={props.loading}>
                <Icon as={MdClose} boxSize={5} color={'red.500'} />
            </Button>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {props.title}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>
                                {props.message}
                            </Text>
                            <Text fontWeight={'700'} mt='20px'>
                                Are you sure?
                            </Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={confirmDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}