import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Icon, Text, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement, useToast, Heading } from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { BiUserX } from 'react-icons/bi'
import { IoIosNuclear } from 'react-icons/io'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiEyeCloseLine } from 'react-icons/ri'
import api from 'services/api'
import { Subscription } from 'views/trader/trades/variables/types'

const UserDelete = (props: { traderid: number, deleteuser: any, sub: Subscription }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [show, setShow] = useState(false);
    const [wp, setWp] = useState('');
    const handleShowClick = () => setShow(!show);
    const textColorSecondary = 'gray.400';
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const updateField = (e: ChangeEvent<HTMLInputElement>) => {
        setWp(e.target.value.trim());
    }

    const handleDelete = async () => {
        if (wp.length) {
            setLoading(true);

            await api.post('/admin/deleteuser', { uid: props.traderid, p: wp }).then(async (res) => {
                if (res.data === false) {
                    toast({ title: 'Error deleting... try again', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false })
                }
                else {
                    props.deleteuser(props.traderid);
                }
            }).catch(async (error) => {
                toast({ title: 'Error deleting', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false })
            });
            setLoading(false);
        }
        else {
            toast({ title: 'Enter password', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false })
        }
    }

    const closeConfirm = () => {
        setWp('');
        onClose();
    }

    return (
        <>
            <Button onClick={onOpen} size={'sm'} colorScheme={'red'}>
                <Icon as={BiUserX} boxSize={5}></Icon>
            </Button>

            <Modal isOpen={isOpen} onClose={closeConfirm}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight='bold' bg='red.500'>
                        <Flex direction={'row'} align={'center'}>
                            <Icon as={IoIosNuclear} h='35px' w='35px' me='10px' color={'white'}></Icon>
                            <Heading size={'md'} color={'white'}>Delete user profile</Heading>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction={'column'}>
                            <Text fontWeight={'700'} color={'red.500'} mb='20px' mt='20px'>
                                ARE YOU SURE?
                            </Text>
                            {props.sub &&
                                <Flex>
                                    <Text fontWeight={'700'} color={'orange.500'} mb='20px'>
                                        User has a {props.sub.status.toUpperCase()} subscription
                                    </Text>
                                </Flex>
                            }
                            <Flex>
                                <FormControl>
                                    <FormLabel size={'xs'} mb='10px' ms='10px'>Enter password ...</FormLabel>
                                    <InputGroup size="md">
                                        <Input id="password" name="password" type={show ? 'text' : 'password'} fontSize="sm" ms={{ base: '0px', md: '4px' }} placeholder="Min. 10 characters" size="lg" variant="auth" onChange={updateField} />
                                        <InputRightElement display="flex" alignItems="center" mt="4px">
                                            <Icon color={textColorSecondary} _hover={{ cursor: 'pointer' }} as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={handleShowClick} />
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                            </Flex>

                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant={'ghost'} mr={3} onClick={closeConfirm}>
                            Cancel
                        </Button>
                        <Button colorScheme={'red'} variant={'outline'} onClick={handleDelete} isLoading={loading}>DELETE</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default UserDelete