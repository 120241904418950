import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import { Field, Formik } from 'formik';
import * as Yup from "yup";
import { useRef, useState } from 'react'
import api from 'services/api';



const RecoveryConfirm = () => {

    const [sending, setSending] = useState(false);

    const toast = useToast();
    const textColor = useColorModeValue('navy.700', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const textColorSecondary = 'gray.400';
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef();



    return (
        <>
            <Button variant={'ghost'} size={'sm'} mt="20px" _hover={{ background: "transparent" }} onClick={onOpen} isLoading={sending}>
                <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
                    Need recovery?
                </Text>
            </Button>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent >
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Need a recovery code?
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Flex direction={'column'}>
                                <FormControl>
                                    <FormLabel size={'ms'} color={textColorSecondary}>Enter your registered email </FormLabel>
                                    <Formik
                                        initialValues={{
                                            email: ""
                                        }}
                                        onSubmit={async (values) => {
                                            setSending(true);
                                            await api.post('/auth/sendrecovery', { email: values.email })
                                                .then((res) => {
                                                    if (res.status === 200) {
                                                        if (res.data.message==='sent') {
                                                            toast({ title: 'Recovery email sent', description: "Check your junk folder if you can't find it.", status: 'success', position: 'top', duration: 5000, isClosable: true, })
                                                            onClose();
                                                        }
                                                        else if (res.data.message==='email') {
                                                            toast({ title: 'Invalid email', description: "Please enter the correct email address", status: 'error', position: 'top', duration: 3000, isClosable: true, })
                                                        }
                                                        else if (res.data.message==='none') {
                                                            toast({ title: '2-FA not activated', description: "User has not turned on 2-FA", status: 'error', position: 'top', duration: 3000, isClosable: true, })
                                                        }
                                                        else {
                                                            toast({ title: 'Recovery email not sent ..', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 5000, isClosable: true })
                                                            onClose();
                                                        }
                                                    }
                                                }).catch(function (error) {
                                                    toast({ title: 'Recovery email not sent ..', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 5000, isClosable: true })
                                                    onClose();
                                                });
                                            setTimeout(() => {
                                                setSending(false);
                                            }, 2000);

                                        }}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string().email("Valid email required").required("Valid email required")
                                        })}
                                    >
                                        {({ handleSubmit, errors, touched }) => (
                                            <form onSubmit={handleSubmit}>
                                                <FormControl isInvalid={!!errors.email && touched.email}>
                                                    <Field
                                                        as={Input}
                                                        id="email"
                                                        name="email"
                                                        variant="auth" fontSize="sm" type="email" placeholder="you@email.com" size="lg"
                                                    />
                                                    <FormErrorMessage mb="15px">Valid email required</FormErrorMessage>
                                                    <Button type="submit" fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mt="24px" mb="24px" isLoading={sending} loadingText='Sending'>Send recovery code</Button>
                                                </FormControl>
                                            </form>
                                        )}
                                    </Formik>

                                </FormControl>
                            </Flex>
                        </AlertDialogBody>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog >
        </>


    )
}

export default RecoveryConfirm