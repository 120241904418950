import { Button, Checkbox, Flex, Icon, Tooltip, useColorModeValue } from '@chakra-ui/react'
import {  MdClose } from 'react-icons/md'
import { StrategyRule } from '../../variables/types'

const StrategyRuleSelect = (props: { rule: StrategyRule, ignoreRule: any, useRule: any }) => {

    const textColor = useColorModeValue('gray.700', 'white');

    const ignoreRule = () => {
        props.ignoreRule(props.rule.id, props.rule.sid)
    }

    return (
        <Flex direction={'row'}>
            <Tooltip label='Ignore rule' fontSize='md'>
                <Button variant={'outline'} size={'sm'} onClick={ignoreRule} borderRadius='5px' p='0px' me='20px' mb='10px' colorScheme={'red'}>
                    <Icon as={MdClose} boxSize={4} color={'red.500'} />
                </Button>
            </Tooltip>

            <Checkbox size='md' colorScheme='blue' me='10px' mb='15px' mt='5px' color={textColor} onChange={(e) => props.useRule(e, props.rule)} isChecked={props.rule.used} >
                {props.rule.text}
            </Checkbox>
        </Flex>
    )
}
export default StrategyRuleSelect