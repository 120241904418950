import { useRadio, Box, useRadioGroup, Stack, HStack, Text, Image, Flex, Badge } from "@chakra-ui/react"
import { chakra, useColorModeValue } from "@chakra-ui/system"
import { MentorTag } from "views/trader/trades/variables/types";
import jlogo from 'assets/img/jorno/jlogo.png'
import { useEffect, useState } from "react";

export function MentorRadioGroup(props: { mentortags: MentorTag[], selectmentor: any, selectedmentorname: string }) {

    function CustomRadio(props: { [x: string]: any; image: any }) {
        const { image, ...radioProps } = props;
        const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } =
            useRadio(radioProps)

        return (
            <chakra.label {...htmlProps} cursor='pointer'>
                <input {...getInputProps({})} hidden />
                <Box
                    {...getRadioProps()}
                    bg={state.isChecked ? 'teal.300' : 'transparent'}
                    w={16}

                    p={1}
                    rounded='full'
                >
                    <Image src={image} w={16} h={14} rounded='full' {...getLabelProps()} />

                </Box>

            </chakra.label>
        )
    }

    const { mentortags, selectmentor, selectedmentorname } = props;
    const [selectedMentor, setSelectedMentor] = useState(0);
    const selectcolor = useColorModeValue('teal.400', 'teal.300');
    const unselectcolor = useColorModeValue('gray.400', 'gray.500');

    useEffect(() => {
        //let mtag = mentortags.filter((mt) => mt.mentorname === selectedmentorname);
        //setSelectedMentor(mtag.length ? mtag[0].mentorid : 0);
        handleChange(selectedmentorname);
    }, [selectedmentorname])

    const handleChange = (value: any) => {
        let m = mentortags.filter((m) => m.mentorname === value);
        if (m.length) {
            setSelectedMentor(m[0].mentorid);
            selectmentor(m[0].mentorid);
        }

    }

    const { value, getRadioProps, getRootProps } = useRadioGroup({
        defaultValue: selectedmentorname,
        onChange: handleChange,
    })

    return (
        <Stack {...getRootProps()} mb={'20px'}>
            <HStack gap={5}>
                {mentortags.map((mentor: MentorTag) => {
                    return (
                        <>
                            <Flex direction={'column'}>
                                <Text fontWeight={'700'} mb='10px' color={(selectedMentor && selectedMentor === mentor.mentorid) ? selectcolor : unselectcolor}>{mentor.mentorname}</Text>
                                <CustomRadio
                                    key={mentor.mentorid}
                                    image={mentor.avatar === "" ? jlogo : mentor.avatar}
                                    {...getRadioProps({ value: mentor.mentorname })}
                                />
                                {mentor.newcount > 0 &&
                                    <Flex mt='-20px' ms='20px'>
                                        <Badge bg={'red.500'} variant='solid' ms='10px'>{mentor.newcount}</Badge>
                                    </Flex>}
                            </Flex>

                        </>
                    )
                })}
            </HStack>
        </Stack>
    )
}