
import {
    useDisclosure,
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalOverlay,
    ModalFooter, Flex, Icon, useColorModeValue, FormLabel, useToast
} from "@chakra-ui/react";

import Card from "components/card/Card";
import { useEffect, useState } from "react";
import { MdAdd, MdEdit } from 'react-icons/md';
import api from "services/api";
import { db } from "services/db";
import { Mistake } from "../../variables/types";
import MistakeInput from "../fields/MistakeInput";
import useNewMistake from "../../hooks/useNewMistake";
import { useAuth } from "services/auth/AuthProvider";
import { useData } from "services/data/DataProvider";

const MistakeModal = (props: { updateMistakes: any, notifyChanges: any, addnewsavedtag: any, deletemistake: any, largebtn: boolean }) => {

    const { muuid } = useAuth();
    const { mistakes } = useData();
    const { updateMistakes, notifyChanges, addnewsavedtag, deletemistake, largebtn } = props;
    const { isOpen: isMistOpen, onOpen: onmistOpen, onClose: onMistClose } = useDisclosure();
    const bdColor = useColorModeValue('gray.200', 'gray.600');
    const bgColor = useColorModeValue('white', 'gray.700');
    const [userMistakes, setUserMistakes] = useState([]);
    const [addDisable, setAddDisable] = useState(false);
    const newmistake = useNewMistake();
    const toast = useToast();

    const mistakemodalopen = async () => {
        onmistOpen();
    }

    useEffect(()=>{
        setUserMistakes(mistakes);
        setAddDisable(false);
    }, [mistakes])


    const modalClosed = () => {
        removeBlankMistakes();
        onMistClose();
    }

    const addMistake = async () => {
        let m: Mistake = newmistake; // initial id = 1
        setUserMistakes([...userMistakes, m]);
        setAddDisable(true);
    }

    const removeBlankMistakes = () => {
        const blank = userMistakes.filter((m) => m.name.trim().length < 1);
        blank.forEach(async (m) => {
            await db.mistakes.delete(m.id);
        })
    }

    const saveMistake = async (mistake: Mistake) => {

        return new Promise(async (resolve, reject) => {
            mistake.last_uuid = muuid;
            delete mistake.sync_due;

            let url = (mistake.id > 0 ? 'updatemistake' : 'savemistake');

            await api.post('/strategies/' + url, mistake).then(async (res) => {
                if (res.data === false) {
                    toast({ title: 'Oops, error ...', description: "Mistake not " + (mistake.id > 0 ? 'updated' : 'saved') + " ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                }
                else {
                    res.data.sync_due = false;
                    delete res.data.last_uuid;

                    await db.mistakes.put(res.data, res.data.id).then(async () => {
                        if (mistake.id > 0) {
                            updateMistakes({ name: res.data.name, id: res.data.id });
                            notifyChanges({ name: res.data.name, id: res.data.id });
                        }
                        else {
                            addnewsavedtag(res.data);
                        }
                    })
                }
            }).catch(async (e) => {
                if (mistake.id > 0) {
                    mistake.sync_due = true;
                    delete mistake.last_uuid;
                    await db.mistakes.put(mistake, mistake.id).then(async () => {
                        if (mistake.id > 0) {
                            updateMistakes({ name: mistake.name, id: mistake.id });
                            notifyChanges({ name: mistake.name, id: mistake.id });
                        }
                    })
                }
            })
            setAddDisable(false);
            resolve('');
        });
    }


    return (
        <>
            {!largebtn && <Button variant={'outline'} p='0px' size={'sm'} onClick={mistakemodalopen} borderRadius='5px' ms='3px' me='3px'>
                <Icon as={MdEdit} boxSize={5} />
            </Button>}
            {largebtn &&
                <Button variant={'outline'} size={'sm'} onClick={mistakemodalopen} borderRadius='5px'><Icon as={MdEdit} boxSize={5} me='10px' /> EDIT Mistakes</Button>
            }
            <Modal isOpen={isMistOpen} onClose={modalClosed} size={{ base: 'full', md: '2xl' }} onCloseComplete={modalClosed} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update mistakes</ModalHeader>
                    <ModalCloseButton onClick={modalClosed} />
                    <ModalBody>
                        <Flex>
                            <Card borderColor={bdColor} borderWidth='1px' mt={'20px'} bg={bgColor}>
                                <Flex direction={'row'} justify={'space-between'}>
                                    <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Mistakes</FormLabel>
                                    <Button variant={'outline'} size={'sm'} onClick={() => addMistake()} borderRadius='5px' isDisabled={addDisable}>
                                        <Icon as={MdAdd} boxSize={5} me={'5px'} />Add
                                    </Button>
                                </Flex>
                                <Flex direction={'column'} mt='20px'>
                                    {userMistakes !== null &&
                                        userMistakes.map((mist, index) => (
                                            <MistakeInput key={mist.id} updatemistake={saveMistake} mistake={mist} userMistakes={userMistakes} deletemistake={deletemistake}></MistakeInput>
                                        ))
                                    }
                                </Flex>
                            </Card>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={modalClosed}>
                            Done
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default MistakeModal;