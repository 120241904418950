import { useToast } from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./jornoquill.css"

const JornoQuillReadOnly = (props: { content: string,  placeHolder: string}) => {

    const { content, placeHolder } = props;

    const quillref = useRef<ReactQuill | null>(null);
    const [quillcontent, setquillcontent] = useState("");

    useEffect(() => {
        setquillcontent(content);
    }, [content])

    //const myColors = ["white", "#8F9BBA", "#707EAE", "black", "#422AFB", "#422AFB", "#3965FF", "#01B574", "#FFB547", "#E31A1A"];
    const formats = ["font", "header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "link", "color", "image", "background", "align"];

    const modules = useMemo(
        () => ({
            toolbar: false,
        }),
        []
    );


    return (
        <>
            <ReactQuill
                ref={quillref}
                theme="snow"
                modules={modules}
                formats={formats}
                value={quillcontent}
                placeholder={placeHolder}
                readOnly={true}
            />
        </>
    );
}
export default JornoQuillReadOnly;