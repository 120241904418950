import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Flex, Heading, Icon, Text, useToast, InputGroup, InputLeftAddon, Select, useColorModeValue, OrderedList, ListIcon, ListItem, UnorderedList } from '@chakra-ui/react'
import React, { ChangeEvent, useEffect, useState } from 'react'
import FileDropZone from '../fields/FileDropZone'
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs'
import { MdAdd } from 'react-icons/md'
import { AccountGroup, Account } from '../../variables/types'
import { useData } from 'services/data/DataProvider'
import uuid from 'react-uuid'
import useNewAccount from '../../hooks/useNewAccount'
import { NewAccountModal } from './AccountModal'
import { useAuth } from 'services/auth/AuthProvider'

const UploadCSVModal = (props: { clearimport: any, setfile: any, processfile: any, setPlatform: any, changeaccount: any, hidden: boolean }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef(null)
    const [broker, setBroker] = useState('');
    const [fileselected, setfileselected] = useState(false);
    const toast = useToast();
    const newaccount = useNewAccount();
    const { allowaccountadd } = useAuth();
    const { accounts, accountgroups } = useData();
    const bgColor = useColorModeValue('gray.500', 'gray.600');
    const [selectedAccount, setSelectedAccount] = useState<Account>(null);

    const clearFile = () => {
        props.setfile(null);
        props.setPlatform('');
    }

    useEffect(() => {
        if (selectedAccount) {
            let s = (document.getElementById('accountselect') as HTMLInputElement);
            s.value = String(selectedAccount.accountid);
        }

    }, [accounts, selectedAccount])

    const updateFileChoice = (file: any) => {
        if (file) {
            props.setfile(file);
            setfileselected(true);
        }
        else {
            setfileselected(false);
            clearFile();
        }
    }

    const processcsv = () => {
        if (fileselected) {
            if (selectedAccount) {
                onClose();
                props.processfile(selectedAccount.brokerid);
            }
            else {
                toast({ title: 'No account selected', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false })
            }
        }
        else {
            toast({ title: 'No file selected', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false })
        }
    }

    const templatedownload = () => {
        try {
            fetch('/import.csv')
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'tradejorno_import.csv';
                        a.click();
                    });
                }).catch((e) => {
                    toast({ title: 'Cannot fetch template', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false })
                });
        } catch (e) {
            toast({ title: 'Cannot fetch template', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false })
        }
    };

    const modalOpened = () => {
        props.setPlatform('paper')
        onOpen();
    }
    const modalClosed = () => {
        clearFile();
        setBroker('');
        onClose();
        //setMetaTrader('');
    }

    const changeAccount = async (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value !== "") {
            let a = accounts.filter((a: Account) => a.accountid === Number(e.target.value));
            if (a.length) {
                setSelectedAccount(a[0]);
            }
        }
    }

    const addAccount = () => {
        allowaccountadd().then(async (r: boolean) => {
            if (r) {
                let a = newaccount;
                a.platform = "paper";
                a.brokerid = uuid();
                a.currency = "";
                localStorage.setItem("newaccount", JSON.stringify(a)); // used in account modal



                try {
                    let newacc = await NewAccountModal({ isOpen });
                    setSelectedAccount(newacc);

                    toast({ title: 'Account added ...', description: "", status: 'success', position: 'top', duration: 3000, isClosable: false })
                } catch (error) {

                }
                localStorage.removeItem("newaccount");
            }
            else {
                toast({ title: 'Maximum accounts reached ...', description: "Either delete an existing trading account or upgrade your subscription to create more trading accounts", status: 'error', position: 'top', duration: 3000, isClosable: true });
            }
        })
    }

    return (
        <>
            <Button onClick={modalOpened} size='sm' borderRadius='5px' ms='10px' mb='20px' rightIcon={<BsFileEarmarkSpreadsheet />} colorScheme='green' variant='outline' hidden={props.hidden}>
                Import .CSV
            </Button>
            <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={modalClosed} size={{ base: 'full', md: "md" }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Flex direction={'row'} align={'center'}>
                            <Icon as={BsFileEarmarkSpreadsheet} me='15px'></Icon>
                            <Heading size={'md'}>Import .CSV file</Heading>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex mb='40px' mt='10px'>
                            <UnorderedList>
                                <ListItem>Download the template below</ListItem>
                                <ListItem>Read and clear instruction rows</ListItem>
                                <ListItem>Leave header row in place</ListItem>
                                <ListItem>Populate the table with your data</ListItem>
                            </UnorderedList>
                        </Flex>
                        <Flex justify={'center'} mt='30px' direction={'column'} textAlign={'center'}>
                            <Button variant={'outline'} colorScheme={'green'} onClick={templatedownload}>DOWNLOAD template .csv file</Button>
                            {/*<Text mt='30px'>Download the template above, read and clear instructions, populate the table with your data (leave headers in place)</Text>*/}

                        </Flex>

                        <Flex mt='30px'>
                            <FileDropZone setdropfile={updateFileChoice} broker={broker} />
                        </Flex>

                        <Flex mb='20px' direction={{ base: 'column', md: 'row' }} mt='40px'>
                            <InputGroup variant='outline' size='sm' ms={{ base: '0px', md: "10px" }} borderRadius="10px">
                                <InputLeftAddon children="Account" borderLeftRadius='5px' fontWeight={'700'} bg={bgColor} color='white' />
                                <Select id="accountselect" name="accountselect" onChange={changeAccount} value={selectedAccount ? selectedAccount.accountid : ''}>
                                    <option value="">Select account to use</option>
                                    {accountgroups.length && accountgroups.map((grp: AccountGroup, key: any) => {
                                        return <optgroup key={key} label={grp.groupname}>
                                            {accounts.map((acc: Account, key: any) => {
                                                return acc.groupid === grp.groupid && <option key={acc.accountid} value={acc.accountid}>{acc.accname}</option>
                                            })}
                                        </optgroup>;
                                    })}
                                </Select>
                                <Button variant="outline" colorScheme="blue" borderRightRadius="5px" borderLeftRadius="0px" onClick={addAccount}>
                                    <Icon as={MdAdd} boxSize={5} />
                                </Button>
                            </InputGroup>
                        </Flex>


                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' onClick={modalClosed}>Cancel</Button>
                        <Button colorScheme={'green'} variant='solid' ms={3} onClick={processcsv}>Import</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default UploadCSVModal