import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Flex, Radio, RadioGroup, Stack, useDisclosure, Text, useToast } from '@chakra-ui/react'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import api from 'services/api'
import { useAuth } from 'services/auth/AuthProvider'
import { db } from 'services/db'
import PasswordEntry from 'views/trader/components/inputs/PasswordEntry'
import { Subscription, SubscriptionProduct } from 'views/trader/trades/variables/types'

const ChangeSubscription = forwardRef((props: { gotoportal: any }, ref) => {//(props: { subscription: Subscription, newsub: string }) => {

    const { user, setUser } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const pwRefVer = useRef(null);

    const [usersubscription, setUsersubscription] = useState<Subscription>(null);
    const [subproducts, setSubproducts] = useState<SubscriptionProduct[]>([]);
    const [isbillingchange, setBillingchange] = useState(false);

    const [newsub, setNewsub] = useState('');
    const [isupgrade, setIsupgrade] = useState(null);
    const [ismonthly, setIsmonthly] = useState(null);
    const [product_id, setProduct_id] = useState(0);
    const [variant_id, setVariant_id] = useState(0);
    const [billing, setBilling] = useState('');

    const [processing, setProcessing] = useState(false);

    const toast = useToast();


    useImperativeHandle(ref, () => ({
        openInput(sub: string, isUpgrade: boolean, isMonthly: boolean, userSubscription: Subscription, products: SubscriptionProduct[], changeBilling?: boolean) {
            setNewsub(sub);
            setUsersubscription(userSubscription)
            setBilling(userSubscription.period.toUpperCase());
            setSubproducts(products);
            setIsupgrade(isUpgrade);
            setIsmonthly(isMonthly);

            let pm = products.filter((p) => p.role === sub && p.period === 'MONTHLY');
            let py = products.filter((p) => p.role === sub && p.period === 'YEARLY');
            let pds = [];
            pds.push(pm[0]);
            pds.push(py[0]);
            setSubproducts(pds);

            setBillingchange(changeBilling);

            onOpen();
        }
    }));

    useEffect(() => {
        if (subproducts.length) {
            setPrices();
        }
    }, [subproducts])

    const setPrices = () => {
        setProduct_id(subproducts[0].product_id); // same for yearly
        setVariant_id(ismonthly ? subproducts[0].variant_id : subproducts[1].variant_id);
    }

    const changeVariant = (e: string) => {
        let variant = Number(e);
        setIsmonthly(variant === subproducts[0].variant_id);
        setVariant_id(Number(e));
    }

    const verifyChange = () => {
        pwRefVer.current.openInput();
    }
    const doChange = async () => {
        setProcessing(true);

        await api.post('/subs/updatesub', { subscription_id: usersubscription.subscription_id, product_id: product_id, variant_id: variant_id }).then(async (res) => {
            if (res.status === 200) {
                if (res.data === false) {
                    onClose();
                    props.gotoportal();
                    //toast({ title: 'Error updating subscription ...', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 4000, isClosable: true })
                }
                else {
                    //alert(JSON.stringify(res.data));
                    res.data.current_user = true;

                    await db.users.put(res.data, res.data.id).then(async () => {
                        await db.users.toCollection().modify(user => {
                            user.current_user = (user.id === res.data.id ? 1 : 0);
                        }).catch((err) => { });
                    }).catch((e) => { })

                    setUser(res.data);
                    delete res.data.subscription; // so it's not saved in localstorage
                    localStorage.setItem('juser', JSON.stringify(res.data));
                    toast({ title: 'Subscription updated successfully', description: "", status: 'info', position: 'top', duration: 2000, isClosable: true })
                    window.location.reload();
                }
            }
        }).catch(() => {
            toast({ title: 'Error updating subscription ...', description: "Please check your internet connection or try again in a few minutes", status: 'error', position: 'top', duration: 4000, isClosable: true })
        })

        setProcessing(false)
        //alert("SUB-ID: " + usersubscription.subscription_id + " \nPROD-ID: " + product_id + " \nVARIANT-ID: " + variant_id)

    }

    return (
        <>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' color={isbillingchange ? "" : (isupgrade ? "cyan.500" : "red.500")}>
                            {isbillingchange ? (
                                <>
                                    Change {newsub.toUpperCase()} plan billing period
                                </>
                            ) : (
                                <>
                                    {isupgrade ? "UPGRADE" : "DOWNGRADE"} to {newsub.toUpperCase()} plan
                                </>
                            )}

                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text mb='30px' fontWeight={'700'}>NOTE: Subscription changes take immediate effect.</Text>
                            {(usersubscription && usersubscription.status === 'cancelled') &&
                                <Text mb='30px' fontWeight={'700'}>This will RESUME your cancelled subscription.</Text>
                            }
                            {isupgrade ? (
                                <Text mb='30px' fontWeight={'700'}>You will be billed PRO-RATA, crediting you for any previous payments (if applicable).</Text>
                            ) : (
                                <>

                                    {user.role === 'MENTOR' && <Text mb='30px' fontWeight={'700'} color={'red.500'}>Any active mentorships will be cancelled immediately.</Text>}
                                    <Text mb='30px' fontWeight={'700'}>Credits (if applicable) will be applied for any previous payments and will be used PRO-RATA for future renewals until depleted. Once credits are used up, you will be billed normally.</Text>
                                </>
                            )}
                            <RadioGroup value={variant_id.toString()} mb='30px' onChange={(e) => changeVariant(e)}>
                                <Stack spacing={5} direction='column'>
                                    {subproducts.map((prod, key) => {
                                        return <>
                                            <Flex direction='row' justify={'space-between'} hidden={usersubscription.status !== 'cancelled' && isbillingchange && billing === prod.period}>
                                                <Radio colorScheme='blue' value={prod.variant_id.toString()} fontWeight={'700'}>
                                                    <Text fontWeight={prod.variant_id === variant_id ? 'bold' : 'normal'}>{prod.period === 'MONTHLY' ? 'Monthly' : 'Yearly - 1 month FREE!'}</Text>
                                                </Radio>
                                                <Text me='10px' fontWeight={prod.variant_id === variant_id ? 'bold' : 'normal'}>${prod.price.toFixed(2)}</Text>
                                            </Flex>
                                        </>
                                    })}
                                </Stack>
                            </RadioGroup>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme={isupgrade ? "blue" : "red"} onClick={verifyChange} ml={3} isLoading={processing} loadingText="Processing">
                                {isupgrade ? "GO " + (ismonthly ? 'Monthly' : 'Yearly') : "Downgrade"}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <PasswordEntry ref={pwRefVer} ifVerifiedAction={doChange} />

        </>
    )
})

export default ChangeSubscription