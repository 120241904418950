
import {
    useDisclosure,
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalOverlay,
    ModalFooter, Flex, Icon, useColorModeValue, FormLabel
} from "@chakra-ui/react";

import Card from "components/card/Card";

import { useState } from "react";

import { MdAdd } from 'react-icons/md';
import { AccountAdjustment, newAdjustment } from "../../variables/types";
import AdjustmentInput from "../fields/AdjustmentInput";


const AdjustmentModal = (props: { adjust: AccountAdjustment[], updateAdjustments: any }) => {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const bdColor = useColorModeValue('gray.200', 'gray.600');
    const bgColor = useColorModeValue('white', 'gray.700');

    const [adjustments, setAdjustments] = useState([]);

    const openModal = async () => {
        for (let a of props.adjust) { // add temporary id for editing
            let tempid = Math.floor(Math.random() * 10000);
            a.id = tempid;
            adjustments.push(a);
            setAdjustments(adjustments);
        }
        onOpen();
    }

    const modalClosed = () => {
        for (let i = 0; i < adjustments.length; i++) {
            if (adjustments[i].reason.trim() === "" || adjustments[i].amount===0) {
                adjustments.splice(i, 1);
                i--;
            }
            else {
                delete adjustments[i].id; // remove id as only used here when editing
            }
        }
        props.updateAdjustments(adjustments);
        adjustments.splice(0, adjustments.length); // empty the adjustments array
        onClose();
    }

    const addAdjustment = () => {
        setAdjustments([...adjustments, newAdjustment()]);
    }
    const updateAdjustment = (adj: AccountAdjustment) => {
        let index = adjustments.findIndex((a) => a.id === adj.id);
        let adjust = adjustments.slice();
        adjust = [...adjust.slice(0, index), adj, ...adjust.slice(index + 1)];
        setAdjustments(adjust);
    }
    const removeAdjustment = (id: number) => {
        let adj = adjustments.filter((a) => a.id !== id);
        setAdjustments(adj);
        props.updateAdjustments(adj);
    }

    return (
        <>

            <Button mt='15px' mb='15px' variant={'outline'} onClick={openModal} >
                Balance adjustments
            </Button>
            <Modal isOpen={isOpen} onClose={modalClosed} size="2xl" onCloseComplete={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Adjust account balance</ModalHeader>
                    <ModalBody>
                        <Flex>
                            <Card borderColor={bdColor} borderWidth='1px' mt={'20px'} bg={bgColor}>
                                <Flex direction={'row'} justify={'space-between'}>
                                    <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Adjustments</FormLabel>
                                    <Button variant={'outline'} size={'sm'} onClick={addAdjustment} borderRadius='5px'>
                                        <Icon as={MdAdd} boxSize={5} me={'5px'} />Add
                                    </Button>
                                </Flex>
                                <Flex direction={'column'} mt='20px'>
                                    {adjustments !== null &&
                                        adjustments.map((adj, index) => (
                                            <AdjustmentInput key={adj.id} adjust={adj} updateadjust={updateAdjustment} removemadjust={removeAdjustment}></AdjustmentInput>
                                        ))
                                    }
                                </Flex>
                            </Card>

                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={modalClosed}>
                            Done
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AdjustmentModal;