import { Box, Text } from "@chakra-ui/react";
import { useColorModeValue, Flex } from "@chakra-ui/react";
import { TJLogo } from "components/icons/Icons";
import { TJIcon } from "components/icons/Icons";

const HeaderLogo = () => {
    let logoColor = useColorModeValue('white', 'white');
    return (
        <Box  w="280px" color={'white'}>
            <Flex direction={'column'}>
                <Flex direction={'row'}>
                    <TJIcon h="35px" w="35px" color={logoColor} />
                    <TJLogo h="25px" w="210px" ms={'10px'} mt={'5px'} color={logoColor} />
                </Flex>
                <Flex direction={'row'}>
                    <Text ms='53px' size={'sm'} color={'gray.400'} flexWrap={'nowrap'}>Journal your trading journey</Text>
                </Flex>
            </Flex>
        </Box>
    );
}

export default HeaderLogo