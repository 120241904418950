import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Icon, Text, useDisclosure } from "@chakra-ui/react"
import { useRef } from "react"
import { MdClose } from "react-icons/md"

export const DeleteJournalConfirm = (props: { deletejournal: any, hidden:boolean }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()

    const confirmDelete = () => {
        onClose();
        props.deletejournal();
        
    }

    return (
        <>
            <Button variant={'outline'} colorScheme='red' mt={'20px'} onClick={onOpen} me={'5px'} hidden={props.hidden}>
                Delete
            </Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Journal Entry
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text color={'red.500'} fontWeight={'700'}>
                                This will PERMANENTLY delete this entry ...
                            </Text>
                            <Text fontWeight={'700'} mt='20px'>
                                Are you sure?
                            </Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={confirmDelete} ml={3}>
                                Yes, delete!
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}