import { Flex, Spacer, Text, useMediaQuery, useColorModeValue, Heading, UnorderedList, ListItem } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import Card from "components/card/Card";
import FadeInSection from 'layouts/landing/sections/FadeInSection';
import { BsClipboardData } from "react-icons/bs";
import { TbFaceIdError, TbMessageShare, TbRosetteDiscountCheck } from "react-icons/tb";
import { MdOutlineInsights } from "react-icons/md";
import { RiMegaphoneLine } from "react-icons/ri";
import { PiBrain } from "react-icons/pi";

const MentorBenefits = () => {
    const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
    const pageBg = useColorModeValue('white', 'navy.900');
    const lighttextColor = useColorModeValue('gray.500', 'gray.400');
    const iconColor = useColorModeValue('brand.400', 'blue.500');
    const bgcolor = useColorModeValue('white', 'navy.900');

    const array = [
        {
            id: 1,
            text: "Mentors can share trades, strategies and categorized journal entries with students.",
            icon: BsClipboardData,
            iconsize: 14
        },
        {
            id: 2,
            text: "Students can request trade reviews for mentors to view and provide feedback on.",
            icon: MdOutlineInsights,
            iconsize: 16
        },
        {
            id: 3,
            text: 'In-app messaging helps mentors to keep students up to speed and engaged.',
            icon: RiMegaphoneLine,
            iconsize: 16
        },
    ];
    return (
        <FadeInSection>
            <Flex textAlign={'center'} direction={'column'} >
                <Flex direction={{base:'column', md: 'row'}} justify={'center'} mt={{ base: '20px', md: '0px' }}>
                    <Text fontWeight={'700'} mt='10px' mb='10px'>Stuggling to find consistency?</Text>
                    <Text fontWeight={'700'} mt='10px' mb='10px' ms='10px'>Why not get a mentor to guide you?</Text>
                </Flex>

                <Text mt='10px' mb='10px' color={lighttextColor} fontWeight={'500'}>This can be a formal paid mentorship, or free guidance from an experienced friend or family member.</Text>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" w="full" maxW={{ xl: "1200px" }} py="5" px={isLargerThanMD ? '16' : '6'} flexWrap="wrap" flexDirection={isLargerThanMD ? 'row' : 'column'} >
                {array.map((arr, index) => (
                    <>
                        <Card
                            key={index}
                            bg={pageBg}
                            height="200px"
                            width={isLargerThanMD ? '32%' : 'full'}
                            shadow="md"
                            p="6"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="20px"
                            flexDirection="column"
                            textAlign="center"
                            mb={isLargerThanMD ? '0' : '4'}
                            mt={'10px'}
                            borderWidth={'1px'}
                        >
                            <Icon as={arr.icon} boxSize={arr.iconsize} color={iconColor} mb="5" />
                            <Text>{arr.text}</Text>
                        </Card>
                        <Spacer />
                    </>
                ))}
            </Flex>
            <Flex justify="center" mt='10px' mb='20px'>
                <Text color={lighttextColor} fontWeight={'700'}>And lots more ...</Text>
            </Flex>
            <Flex textAlign={'center'} direction={'column'} >
                <Text fontWeight={'700'} mt='10px' mb='10px'>How do you start a mentorship?</Text>
            </Flex>
            <Flex alignItems="center" justifyContent="center" gap={5} w="full" maxW={{ xl: "1200px" }} py="5" px={isLargerThanMD ? '16' : '6'} flexWrap="wrap" flexDirection={isLargerThanMD ? 'row' : 'column'} mt='10px'>
                <Card width={isLargerThanMD ? '40%' : 'full'} borderWidth={'1px'} borderRadius="20px" bg={'transparent'} mb={isLargerThanMD ? '0' : '4'} py='30px'>
                    <Flex justify={'center'}>
                        <Heading size={'sm'} mt='-40px' bg={bgcolor} color={'gray.500'} px='10px'>Mentors</Heading>
                    </Flex>
                    <Flex justify={'center'}>
                        <UnorderedList>
                            <ListItem>Invite students using their registered email address.</ListItem>
                            <Flex justify={'center'} py='5px'>
                                <Text color={'gray.500'}>or</Text>
                            </Flex>
                            <ListItem>List profile in the directory so traders can find you.</ListItem>
                            <Flex justify={'center'} py='5px' mt='20px'>
                                <Text color={'gray.500'}>* MENTOR plan required</Text>
                            </Flex>

                        </UnorderedList>
                    </Flex>
                </Card>
                <Card width={isLargerThanMD ? '40%' : 'full'} borderWidth={'1px'} borderRadius="20px" bg={'transparent'} mb={isLargerThanMD ? '0' : '4'} py='30px'>
                    <Flex justify={'center'}>
                        <Heading size={'sm'} mt='-40px' bg={bgcolor} color={'gray.500'} px='10px'>Students</Heading>
                    </Flex>
                    <Flex justify={'center'}>
                        <UnorderedList>
                            <ListItem>Request a mentorship from the directory.</ListItem>
                            <Flex justify={'center'} py='5px'>
                                <Text color={'gray.500'}>or</Text>
                            </Flex>
                            <ListItem>Provide your registered email to your mentor.</ListItem>
                            <Flex justify={'center'} py='5px' mt='20px'>
                                <Text color={'gray.500'}>* NOVICE or PRO plan required</Text>
                            </Flex>
                        </UnorderedList>
                    </Flex>
                </Card>
                <Flex direction={'row'} align={'center'} mt='20px'>
                    <Icon as={TbRosetteDiscountCheck} boxSize={7} me='10px' color={'gray.500'}/>
                    <Text color={lighttextColor} fontWeight={'500'}>Mentors with 10 or more active students are eligible for BIG subscription discounts, check mentor help for details!</Text>
                </Flex>

            </Flex>
        </FadeInSection>
    );
};

export default MentorBenefits;