import { useContext } from "react";
import AuthContext from "services/auth/AuthContext";
import { Mistake } from "../variables/types";
import { useAuth } from "services/auth/AuthProvider";


const useNewMistake = () => {

    const { user } = useAuth();//useContext(AuthContext);

    const newmistake: Mistake = {
		name: "",
		id: 0,
		userid: (user === null ? 0 : user.id),
		sync_due: false
	}
	return newmistake;
};

export default useNewMistake;