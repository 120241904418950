
import {
    Button,
    Text,
    Flex,
    FormLabel,
    Checkbox,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useColorModeValue
} from "@chakra-ui/react";

import { ArrowForwardIcon } from '@chakra-ui/icons'

import { useEffect, useState } from "react";

import { create } from "react-modal-promise";

import { db } from "services/db";
import { newInstrument } from "views/trader/trades/variables/types";

const StoplossInputDialog = ({ isOpen, onResolve, onReject }) => {

    //let instrument = JSON.parse(localStorage.getItem('stopinstrument'));

    const [instrument, setinstrument] = useState(newInstrument(0));
    const [defChecked, setDefChecked] = useState(true);
    const [loading, setLoading] = useState(false);
    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const [stoploss, setStoploss] = useState(null);

    useEffect(() => {
        let inst = JSON.parse(localStorage.getItem('stopinstrument'));
        setinstrument(inst);
        setStoploss(inst.defaultstop === 0 ? null : inst.defaultstop);
    }, [])

    const updateDefault = (e) => {
        setDefChecked(e.target.checked);
    }
    const updateStoploss = async (stop) => {
        setStoploss(stop);
    }
    const returnStoploss = async () => {
        if (stoploss !== null) {
            setLoading(true);
            if (defChecked) {
                instrument.defaultstop = stoploss;
                await db.instruments.put(instrument, instrument.id).catch(() => { });
            }
            setLoading(false);
            onResolve(stoploss);
        }
    }
    const reject = () => onReject(0);

    return (
        <>
            <AlertDialog isOpen={isOpen} onClose={onReject} motionPreset='slideInBottom' closeOnOverlayClick={false}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            <Flex direction={'column'} align={'center'}>
                                <Text fontWeight={'700'} mb='20px'>STOP LOSS <ArrowForwardIcon /> default</Text>
                                <Text fontWeight={'700'} color={'red.500'}>{instrument.description}</Text>
                            </Flex>
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Flex justify='center'>
                                <Text fontWeight={'500'} mb='20px' me='5px'>
                                    Do you want to use a default stoploss for {instrument.ticker}?
                                </Text>
                            </Flex>
                            <Flex justify='center'>
                                <Text fontWeight={'700'} size={'xs'} mb='20px' color={'gray.400'}>
                                    (or you can enter them manually later)
                                </Text>
                            </Flex>
                            <Flex justify='center' mb='10px'>
                                <FormLabel fontWeight='bold' fontSize='sm' mt='5px'>ENTER POINTS</FormLabel>
                            </Flex>
                            <Flex justify='center' mt='10px' mb='10px'>
                                <NumberInput defaultValue={stoploss} allowMouseWheel={true} onChange={(valueString) => updateStoploss(valueString ? Number(valueString) : null)} isInvalid={stoploss === null}>
                                    <NumberInputField color={textColor} />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Flex>
                            <Flex direction={'row'} justify={'space-between'} mt='30px' mb='20px'>
                                <Checkbox isChecked={defChecked} onChange={(e) => updateDefault(e)}>Save as default?</Checkbox>
                                <Flex>
                                    <Button colorScheme='red' onClick={reject} ml={3} isLoading={loading} loadingText='Updating'>
                                        No thanks!
                                    </Button>
                                    <Button colorScheme='blue' onClick={returnStoploss} ml={3} isLoading={loading} loadingText='Updating'>
                                        Accept
                                    </Button>
                                </Flex>
                            </Flex>
                        </AlertDialogBody>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export const StoplossInput = create(StoplossInputDialog)