import { Account } from "../variables/types";
import { useAuth } from "services/auth/AuthProvider";


const useNewAccount = () => {

	const { user, muuid } = useAuth();//useContext(AuthContext);

	const newacc: Account = {
		accountid: 0,
		groupid: 0,
		userid: (user === null ? 0 : user.id),
		accname: '',
		adjustments: [],
		init_balance: 0,
		currency: 'USD',
		platform: '',
		brokerid: '',
		defaultacc: true,
		sync_due: false,
		last_uuid: muuid
	}
	return newacc;
};

export default useNewAccount;