
export const InstrumentsList = [
	{
		"id" : 1,
		"ticker" : "10YR",
		"description" : "Micro 10-Year Yield Futures",
		"category" : "FUT",
		"subcategory" : "Interest Rate",
		"currency" : "USD",
		"pointvalue" : 1000.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 2,
		"ticker" : "ZB",
		"description" : "U.S. Treasury Bond Futures",
		"category" : "FUT",
		"subcategory" : "Interest Rate",
		"currency" : "USD",
		"pointvalue" : 1000.0,
		"ticksize" : 0.03125,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 3,
		"ticker" : "ZT",
		"description" : "2-Year T-Note Futures",
		"category" : "FUT",
		"subcategory" : "Interest Rate",
		"currency" : "USD",
		"pointvalue" : 2000.0,
		"ticksize" : 0.00390625,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 4,
		"ticker" : "ZF",
		"description" : "5-Year T-Note Futures",
		"category" : "FUT",
		"subcategory" : "Interest Rate",
		"currency" : "USD",
		"pointvalue" : 1000.0,
		"ticksize" : 0.0078125,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 5,
		"ticker" : "ZN",
		"description" : "10-Year T-Note Futures",
		"category" : "FUT",
		"subcategory" : "Interest Rate",
		"currency" : "USD",
		"pointvalue" : 1000.0,
		"ticksize" : 0.015625,
		"relevance" : 2,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 6,
		"ticker" : "UB",
		"description" : "Ultra U.S. Treasury Bond",
		"category" : "FUT",
		"subcategory" : "Interest Rate",
		"currency" : "USD",
		"pointvalue" : 1000.0,
		"ticksize" : 0.03125,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 7,
		"ticker" : "DD",
		"description" : "Big Dow DJIA $25 Futures",
		"category" : "FUT",
		"subcategory" : "Index",
		"currency" : "USD",
		"pointvalue" : 25.0,
		"ticksize" : 1.0,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 8,
		"ticker" : "DJ",
		"description" : "DJIA $10 Futures",
		"category" : "FUT",
		"subcategory" : "Index",
		"currency" : "USD",
		"pointvalue" : 10.0,
		"ticksize" : 1.0,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 9,
		"ticker" : "SP",
		"description" : "S&P 500 Futures",
		"category" : "FUT",
		"subcategory" : "Index",
		"currency" : "USD",
		"pointvalue" : 250.0,
		"ticksize" : 0.1,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 10,
		"ticker" : "ES",
		"description" : "E-mini S&P 500 Futures",
		"category" : "FUT",
		"subcategory" : "Index",
		"currency" : "USD",
		"pointvalue" : 50.0,
		"ticksize" : 0.25,
		"relevance" : 88,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 11,
		"ticker" : "NQ",
		"description" : "E-mini NASDAQ 100 Futures",
		"category" : "FUT",
		"subcategory" : "Index",
		"currency" : "USD",
		"pointvalue" : 20.0,
		"ticksize" : 0.25,
		"relevance" : 11,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 12,
		"ticker" : "YM",
		"description" : "E-mini Dow ($5) Futures",
		"category" : "FUT",
		"subcategory" : "Index",
		"currency" : "USD",
		"pointvalue" : 5.0,
		"ticksize" : 1.0,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 13,
		"ticker" : "NKD",
		"description" : "Nikkei/USD Futures",
		"category" : "FUT",
		"subcategory" : "Index",
		"currency" : "USD",
		"pointvalue" : 5.0,
		"ticksize" : 5.0,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 14,
		"ticker" : "RTY",
		"description" : "E-mini Russell 2000 Index Futures",
		"category" : "FUT",
		"subcategory" : "Index",
		"currency" : "USD",
		"pointvalue" : 50.0,
		"ticksize" : 0.1,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 15,
		"ticker" : "EMD",
		"description" : "E-mini S&P MidCap 400 Futures",
		"category" : "FUT",
		"subcategory" : "Index",
		"currency" : "USD",
		"pointvalue" : 100.0,
		"ticksize" : 0.1,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 16,
		"ticker" : "RF",
		"description" : "Russel 1000 Mini Index Futures",
		"category" : "FUT",
		"subcategory" : "Index",
		"currency" : "USD",
		"pointvalue" : 50.0,
		"ticksize" : 0.1,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 17,
		"ticker" : "RS1",
		"description" : "E-mini Russell 1000",
		"category" : "FUT",
		"subcategory" : "Index",
		"currency" : "USD",
		"pointvalue" : 50.0,
		"ticksize" : 0.1,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 18,
		"ticker" : "DX",
		"description" : "US Dollar Index Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 1000.0,
		"ticksize" : 0.005,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 19,
		"ticker" : "E7",
		"description" : "E-mini Euro FX Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 62500.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 20,
		"ticker" : "GE",
		"description" : "Eurodollar Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 2500.0,
		"ticksize" : 0.0025,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 21,
		"ticker" : "6A",
		"description" : "Australian Dollar Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 100000.0,
		"ticksize" : 5.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 22,
		"ticker" : "6B",
		"description" : "British Pound Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 62500.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 23,
		"ticker" : "6C",
		"description" : "Canadian Dollar Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 100000.0,
		"ticksize" : 5.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 24,
		"ticker" : "6E",
		"description" : "Euro FX Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 125000.0,
		"ticksize" : 5.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 25,
		"ticker" : "6J",
		"description" : "Japanese Yen Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 1.25E7,
		"ticksize" : 5.0E-7,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 26,
		"ticker" : "6L",
		"description" : "Brazilian Real Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 100000.0,
		"ticksize" : 5.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 27,
		"ticker" : "6M",
		"description" : "Mexican Peso Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 500000.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 28,
		"ticker" : "6N",
		"description" : "New Zealand Dollar Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 100000.0,
		"ticksize" : 5.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 29,
		"ticker" : "6R",
		"description" : "Russian Ruble Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 2500000.0,
		"ticksize" : 5.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 30,
		"ticker" : "6S",
		"description" : "Swiss Franc Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 125000.0,
		"ticksize" : 5.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 31,
		"ticker" : "6Z",
		"description" : "South African Rand Futures",
		"category" : "FUT",
		"subcategory" : "Currency",
		"currency" : "USD",
		"pointvalue" : 500000.0,
		"ticksize" : 2.5E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 32,
		"ticker" : "HE",
		"description" : "Lean Hog Futures",
		"category" : "FUT",
		"subcategory" : "Agricultural",
		"currency" : "USD",
		"pointvalue" : 400.0,
		"ticksize" : 0.025,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 33,
		"ticker" : "LE",
		"description" : "Live Cattle Futures",
		"category" : "FUT",
		"subcategory" : "Agricultural",
		"currency" : "USD",
		"pointvalue" : 400.0,
		"ticksize" : 0.025,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 34,
		"ticker" : "GF",
		"description" : "Feeder Cattle Futures",
		"category" : "FUT",
		"subcategory" : "Agricultural",
		"currency" : "USD",
		"pointvalue" : 500.0,
		"ticksize" : 0.025,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 35,
		"ticker" : "ZC",
		"description" : "Corn Futures",
		"category" : "FUT",
		"subcategory" : "Agricultural",
		"currency" : "USD",
		"pointvalue" : 50.0,
		"ticksize" : 0.25,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 36,
		"ticker" : "ZW",
		"description" : "Wheat Futures",
		"category" : "FUT",
		"subcategory" : "Agricultural",
		"currency" : "USD",
		"pointvalue" : 50.0,
		"ticksize" : 0.25,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 37,
		"ticker" : "ZL",
		"description" : "Soybean Oil Futures",
		"category" : "FUT",
		"subcategory" : "Agricultural",
		"currency" : "USD",
		"pointvalue" : 600.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 38,
		"ticker" : "ZM",
		"description" : "Soybean Meal Futures",
		"category" : "FUT",
		"subcategory" : "Agricultural",
		"currency" : "USD",
		"pointvalue" : 100.0,
		"ticksize" : 0.1,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 39,
		"ticker" : "ZS",
		"description" : "Soybean Futures",
		"category" : "FUT",
		"subcategory" : "Agricultural",
		"currency" : "USD",
		"pointvalue" : 50.0,
		"ticksize" : 0.25,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 40,
		"ticker" : "ZO",
		"description" : "Oats Futures",
		"category" : "FUT",
		"subcategory" : "Agricultural",
		"currency" : "USD",
		"pointvalue" : 50.0,
		"ticksize" : 0.25,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 41,
		"ticker" : "CL",
		"description" : "Crude Oil Futures",
		"category" : "FUT",
		"subcategory" : "Energy",
		"currency" : "USD",
		"pointvalue" : 1000.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 42,
		"ticker" : "QM",
		"description" : "E-mini Crude Oil Futures",
		"category" : "FUT",
		"subcategory" : "Energy",
		"currency" : "USD",
		"pointvalue" : 500.0,
		"ticksize" : 0.025,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 43,
		"ticker" : "MCL",
		"description" : "Micro Crude Oil Futures",
		"category" : "FUT",
		"subcategory" : "Energy",
		"currency" : "USD",
		"pointvalue" : 100.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 44,
		"ticker" : "NG",
		"description" : "Natural Gas Futures",
		"category" : "FUT",
		"subcategory" : "Energy",
		"currency" : "USD",
		"pointvalue" : 10000.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 45,
		"ticker" : "QG",
		"description" : "E-mini Natural Gas Futures",
		"category" : "FUT",
		"subcategory" : "Energy",
		"currency" : "USD",
		"pointvalue" : 2500.0,
		"ticksize" : 0.005,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 46,
		"ticker" : "HO",
		"description" : "Heating Oil Futures",
		"category" : "FUT",
		"subcategory" : "Energy",
		"currency" : "USD",
		"pointvalue" : 42000.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 47,
		"ticker" : "RB",
		"description" : "NY Harbor Gasoline Futures",
		"category" : "FUT",
		"subcategory" : "Energy",
		"currency" : "USD",
		"pointvalue" : 42000.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 48,
		"ticker" : "QU",
		"description" : "E-mini Gasoline Futures",
		"category" : "FUT",
		"subcategory" : "Energy",
		"currency" : "USD",
		"pointvalue" : 21000.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 49,
		"ticker" : "QH",
		"description" : "E-mini NY Harbor ULSD Futures",
		"category" : "FUT",
		"subcategory" : "Energy",
		"currency" : "USD",
		"pointvalue" : 21000.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 50,
		"ticker" : "GC",
		"description" : "Gold Futures",
		"category" : "FUT",
		"subcategory" : "Metals",
		"currency" : "USD",
		"pointvalue" : 100.0,
		"ticksize" : 0.1,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 51,
		"ticker" : "ZG",
		"description" : "100 oz Gold Future",
		"category" : "FUT",
		"subcategory" : "Metals",
		"currency" : "USD",
		"pointvalue" : 100.0,
		"ticksize" : 0.1,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 52,
		"ticker" : "ZI",
		"description" : "5000 oz Silver Future",
		"category" : "FUT",
		"subcategory" : "Metals",
		"currency" : "USD",
		"pointvalue" : 5000.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 53,
		"ticker" : "SI",
		"description" : "Silver Futures",
		"category" : "FUT",
		"subcategory" : "Metals",
		"currency" : "USD",
		"pointvalue" : 5000.0,
		"ticksize" : 0.005,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 54,
		"ticker" : "HG",
		"description" : "Copper Futures",
		"category" : "FUT",
		"subcategory" : "Metals",
		"currency" : "USD",
		"pointvalue" : 25000.0,
		"ticksize" : 5.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 55,
		"ticker" : "PL",
		"description" : "Platinum Futures",
		"category" : "FUT",
		"subcategory" : "Metals",
		"currency" : "USD",
		"pointvalue" : 50.0,
		"ticksize" : 0.1,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 56,
		"ticker" : "PA",
		"description" : "Palladium Futures",
		"category" : "FUT",
		"subcategory" : "Metals",
		"currency" : "USD",
		"pointvalue" : 100.0,
		"ticksize" : 0.5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 57,
		"ticker" : "QI",
		"description" : "E-mini Silver Futures",
		"category" : "FUT",
		"subcategory" : "Metals",
		"currency" : "USD",
		"pointvalue" : 2500.0,
		"ticksize" : 0.0125,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 58,
		"ticker" : "QO",
		"description" : "E-mini Gold Futures",
		"category" : "FUT",
		"subcategory" : "Metals",
		"currency" : "USD",
		"pointvalue" : 50.0,
		"ticksize" : 0.25,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 59,
		"ticker" : "QC",
		"description" : "E-mini Copper Futures",
		"category" : "FUT",
		"subcategory" : "Metals",
		"currency" : "USD",
		"pointvalue" : 12500.0,
		"ticksize" : 0.002,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 60,
		"ticker" : "MES",
		"description" : "Micro E-mini S&P 500 Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 5.0,
		"ticksize" : 0.25,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 61,
		"ticker" : "MYM",
		"description" : "Micro E-mini Dow Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 0.5,
		"ticksize" : 1.0,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 62,
		"ticker" : "MNQ",
		"description" : "Micro E-mini Nasdaq-100 Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 2.0,
		"ticksize" : 0.25,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 63,
		"ticker" : "M2K",
		"description" : "Micro E-mini Russell 2000 Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 5.0,
		"ticksize" : 0.1,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 64,
		"ticker" : "MGC",
		"description" : "E-micro Gold Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 10.0,
		"ticksize" : 0.1,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 65,
		"ticker" : "M6A",
		"description" : "E-micro AUD/USD Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 10000.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 66,
		"ticker" : "M6B",
		"description" : "E-micro GBP/USD Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 6250.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 67,
		"ticker" : "M6C",
		"description" : "E-micro USD/CAD Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 10000.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 68,
		"ticker" : "M6E",
		"description" : "E-micro EUR/USD Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 12500.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 69,
		"ticker" : "M6J",
		"description" : "E-micro USD/JPY Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 10000.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 70,
		"ticker" : "M6S",
		"description" : "E-micro USD/CHF Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 10000.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 71,
		"ticker" : "MICD",
		"description" : "E-micro CAD/USD Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 10000.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 72,
		"ticker" : "MIJY",
		"description" : "E-micro JPY/USD Futures",
		"category" : "FUT",
		"subcategory" : "Micro",
		"currency" : "USD",
		"pointvalue" : 1250000.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 73,
		"ticker" : "ETH",
		"description" : "CME Ether Futures",
		"category" : "FUT",
		"subcategory" : "Crypto",
		"currency" : "USD",
		"pointvalue" : 50.0,
		"ticksize" : 0.5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 74,
		"ticker" : "BTC",
		"description" : "CME Bitcoin Futures",
		"category" : "FUT",
		"subcategory" : "Crypto",
		"currency" : "USD",
		"pointvalue" : 5.0,
		"ticksize" : 5.0,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 75,
		"ticker" : "MBT",
		"description" : "CME Micro Bitcoin Futures",
		"category" : "FUT",
		"subcategory" : "Crypto",
		"currency" : "USD",
		"pointvalue" : 0.1,
		"ticksize" : 5.0,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 76,
		"ticker" : "MET",
		"description" : "CME Micro Ether Futures",
		"category" : "FUT",
		"subcategory" : "Crypto",
		"currency" : "USD",
		"pointvalue" : 0.1,
		"ticksize" : 0.5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 77,
		"ticker" : "AUDCAD",
		"description" : "AUD/CAD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CAD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 78,
		"ticker" : "AUDCHF",
		"description" : "AUD/CHF",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CHF",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 79,
		"ticker" : "AUDJPY",
		"description" : "AUD/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 80,
		"ticker" : "AUDNZD",
		"description" : "AUD/NZD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "NZD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 81,
		"ticker" : "AUDUSD",
		"description" : "AUD/USD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 82,
		"ticker" : "CADCHF",
		"description" : "CAD/CHF",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CHF",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 83,
		"ticker" : "CADJPY",
		"description" : "CAD/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 84,
		"ticker" : "CHFJPY",
		"description" : "CHF/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 85,
		"ticker" : "CHFNOK",
		"description" : "CHF/NOK",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "NOK",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 86,
		"ticker" : "CHFSEK",
		"description" : "CHF/SEK",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "SEK",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 87,
		"ticker" : "EURAUD",
		"description" : "EUR/AUD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "AUD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 88,
		"ticker" : "EURCAD",
		"description" : "EUR/CAD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CAD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 89,
		"ticker" : "EURCHF",
		"description" : "EUR/CHF",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CHF",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 90,
		"ticker" : "EURCZK",
		"description" : "EUR/CZK",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CZK",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 91,
		"ticker" : "EURDKK",
		"description" : "EUR/DKK",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "DKK",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 92,
		"ticker" : "EURGBP",
		"description" : "EUR/GBP",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "GBP",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 1,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 93,
		"ticker" : "EURHUF",
		"description" : "EUR/HUF",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "HUF",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 94,
		"ticker" : "EURJPY",
		"description" : "EUR/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 95,
		"ticker" : "EURNOK",
		"description" : "EUR/NOK",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "NOK",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 96,
		"ticker" : "EURNZD",
		"description" : "EUR/NZD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "NZD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 97,
		"ticker" : "EURPLN",
		"description" : "EUR/PLN",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "PLN",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 98,
		"ticker" : "EURSEK",
		"description" : "EUR/SEK",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "SEK",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 99,
		"ticker" : "EURTRY",
		"description" : "EUR/TRY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "TRY",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 100,
		"ticker" : "EURUSD",
		"description" : "EUR/USD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 8,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 101,
		"ticker" : "GBPAUD",
		"description" : "GBP/AUD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "AUD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 102,
		"ticker" : "GBPCAD",
		"description" : "GBP/CAD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CAD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 103,
		"ticker" : "GBPCHF",
		"description" : "GBP/CHF",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CHF",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 104,
		"ticker" : "GBPJPY",
		"description" : "GBP/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 105,
		"ticker" : "GBPNZD",
		"description" : "GBP/NZD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "NZD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 106,
		"ticker" : "GBPSEK",
		"description" : "GBP/SEK",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "SEK",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 107,
		"ticker" : "GBPUSD",
		"description" : "GBP/USD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 108,
		"ticker" : "HKDJPY",
		"description" : "HKD/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 109,
		"ticker" : "NOKJPY",
		"description" : "NOK/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 110,
		"ticker" : "NZDCAD",
		"description" : "NZD/CAD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CAD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 111,
		"ticker" : "NZDCHF",
		"description" : "NZD/CHF",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CHF",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 112,
		"ticker" : "NZDJPY",
		"description" : "NZD/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 113,
		"ticker" : "NZDUSD",
		"description" : "NZD/USD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 114,
		"ticker" : "SEKJPY",
		"description" : "SEK/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 115,
		"ticker" : "SGDJPY",
		"description" : "SGD/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 116,
		"ticker" : "TRYJPY",
		"description" : "TRY/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 117,
		"ticker" : "USDCAD",
		"description" : "USD/CAD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CAD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 118,
		"ticker" : "USDCHF",
		"description" : "USD/CHF",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CHF",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 119,
		"ticker" : "USDCNH",
		"description" : "USD/CNH",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CNH",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 120,
		"ticker" : "USDCZK",
		"description" : "USD/CZK",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CZK",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 121,
		"ticker" : "USDDKK",
		"description" : "USD/DKK",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "DKK",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 122,
		"ticker" : "USDHKD",
		"description" : "USD/HKD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "HKD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 123,
		"ticker" : "USDHUF",
		"description" : "USD/HUF",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "HUF",
		"pointvalue" : 1.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 124,
		"ticker" : "USDILS",
		"description" : "USD/ILS",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "ILS",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 125,
		"ticker" : "USDJPY",
		"description" : "USD/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 126,
		"ticker" : "USDMXN",
		"description" : "USD/MXN",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "MXN",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 127,
		"ticker" : "USDNOK",
		"description" : "USD/NOK",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "NOK",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 128,
		"ticker" : "USDPLN",
		"description" : "USD/PLN",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "PLN",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 129,
		"ticker" : "USDRUB",
		"description" : "USD/RUB",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "RUB",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 130,
		"ticker" : "USDSEK",
		"description" : "USD/SEK",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "SEK",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 131,
		"ticker" : "USDSGD",
		"description" : "USD/SGD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "SGD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 132,
		"ticker" : "USDTRY",
		"description" : "USD/TRY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "TRY",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 133,
		"ticker" : "USDZAR",
		"description" : "USD/ZAR",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "ZAR",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 134,
		"ticker" : "XAGUSD",
		"description" : "XAG/USD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 135,
		"ticker" : "XAUAUD",
		"description" : "XAU/AUD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "AUD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 136,
		"ticker" : "XAUCHF",
		"description" : "XAU/CHF",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "CHF",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 137,
		"ticker" : "XAUEUR",
		"description" : "XAU/EUR",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "EUR",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 138,
		"ticker" : "XAUGBP",
		"description" : "XAU/GBP",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "GBP",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 139,
		"ticker" : "XAUUSD",
		"description" : "XAU/USD",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-4,
		"relevance" : 1,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 140,
		"ticker" : "ZARJPY",
		"description" : "ZAR/JPY",
		"category" : "FOREX",
		"subcategory" : "Pair",
		"currency" : "JPY",
		"pointvalue" : 0.01,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 141,
		"ticker" : "ADABTC",
		"description" : "ADA/BTC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-8,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 142,
		"ticker" : "ADAETH",
		"description" : "ADA/ETH",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 143,
		"ticker" : "ADAEUR",
		"description" : "ADA/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 144,
		"ticker" : "ADAGBP",
		"description" : "ADA/GBP",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 145,
		"ticker" : "ADAUSC",
		"description" : "ADA/USDC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 146,
		"ticker" : "ADAUSD",
		"description" : "ADA/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 147,
		"ticker" : "BATUSC",
		"description" : "BAT/USDC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 148,
		"ticker" : "BCHBTC",
		"description" : "BCH/BTC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 149,
		"ticker" : "BCHEUR",
		"description" : "BCH/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "EUR",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 150,
		"ticker" : "BCHGBP",
		"description" : "BCH/GBP",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 151,
		"ticker" : "BCHUSD",
		"description" : "BCH/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 152,
		"ticker" : "BTCEUR",
		"description" : "BTC/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "EUR",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 153,
		"ticker" : "BTCGBP",
		"description" : "BTC/GBP",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "GBP",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 154,
		"ticker" : "BTCUSC",
		"description" : "BTC/USDC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 155,
		"ticker" : "BTCUSD",
		"description" : "BTC/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 156,
		"ticker" : "CVCUSC",
		"description" : "CVC/USDC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 157,
		"ticker" : "DAIUSC",
		"description" : "DAI/USDC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 158,
		"ticker" : "DNTUSC",
		"description" : "DNT/USDC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 159,
		"ticker" : "DOGBTC",
		"description" : "DOGE/BTC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-7,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 160,
		"ticker" : "DOGEUR",
		"description" : "DOGE/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 161,
		"ticker" : "DOGGBP",
		"description" : "DOGE/GBP",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 162,
		"ticker" : "DOGUSD",
		"description" : "DOGE/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 163,
		"ticker" : "EOSBTC",
		"description" : "EOS/BTC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 164,
		"ticker" : "EOSEUR",
		"description" : "EOS/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "EUR",
		"pointvalue" : 1.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 165,
		"ticker" : "EOSUSD",
		"description" : "EOS/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 166,
		"ticker" : "ETCBTC",
		"description" : "ETC/BTC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 167,
		"ticker" : "ETCEUR",
		"description" : "ETC/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 168,
		"ticker" : "ETCGBP",
		"description" : "ETC/GBP",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 169,
		"ticker" : "ETCUSD",
		"description" : "ETC/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.001,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 170,
		"ticker" : "ETHBTC",
		"description" : "ETH/BTC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 171,
		"ticker" : "ETHDAI",
		"description" : "ETH/DAI",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 172,
		"ticker" : "ETHEUR",
		"description" : "ETH/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "EUR",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 173,
		"ticker" : "ETHGBP",
		"description" : "ETH/GBP",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 174,
		"ticker" : "ETHUSC",
		"description" : "ETH/USDC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 175,
		"ticker" : "ETHUSD",
		"description" : "ETH/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 176,
		"ticker" : "GNTUSC",
		"description" : "GNT/USDC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 177,
		"ticker" : "LNKETH",
		"description" : "LINK/ETH",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 178,
		"ticker" : "LNKUSD",
		"description" : "LINK/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 179,
		"ticker" : "LOMUSC",
		"description" : "LOOM/USDC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 180,
		"ticker" : "LTCBTC",
		"description" : "LTC/BTC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 181,
		"ticker" : "LTCEUR",
		"description" : "LTC/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "EUR",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 182,
		"ticker" : "LTCGBP",
		"description" : "LTC/GBP",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 183,
		"ticker" : "LTCUSD",
		"description" : "LTC/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 184,
		"ticker" : "MANUSC",
		"description" : "MANA/USDC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 185,
		"ticker" : "SOLBTC",
		"description" : "SOL/BTC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-7,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 186,
		"ticker" : "SOLETH",
		"description" : "SOL/ETH",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-5,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 187,
		"ticker" : "SOLEUR",
		"description" : "SOL/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 188,
		"ticker" : "SOLGBP",
		"description" : "SOL/GBP",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 189,
		"ticker" : "SOLUSD",
		"description" : "SOL/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 190,
		"ticker" : "SOLUST",
		"description" : "SOL/USDT",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 191,
		"ticker" : "XLMBTC",
		"description" : "XLM/BTC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-8,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 192,
		"ticker" : "XLMEUR",
		"description" : "XLM/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "EUR",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 193,
		"ticker" : "XLMUSD",
		"description" : "XLM/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 194,
		"ticker" : "XRPBTC",
		"description" : "XRP/BTC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-8,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 195,
		"ticker" : "XRPEUR",
		"description" : "XRP/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "EUR",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 196,
		"ticker" : "XRPUSD",
		"description" : "XRP/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 197,
		"ticker" : "XTZBTC",
		"description" : "XTZ/BTC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.0,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 198,
		"ticker" : "XTZUSD",
		"description" : "XTZ/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-4,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 199,
		"ticker" : "ZECUSC",
		"description" : "ZEC/USDC",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 0.01,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 200,
		"ticker" : "ZRXEUR",
		"description" : "ZRX/EUR",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	},
	{
		"id" : 201,
		"ticker" : "ZRXUSD",
		"description" : "ZRX/USD",
		"category" : "CRYPTO",
		"subcategory" : "Pair",
		"currency" : "USD",
		"pointvalue" : 1.0,
		"ticksize" : 1.0E-6,
		"relevance" : 0,
		"commission" : "[0, 0, 0, 0]",
		"defaultstop" : 0,
		"timezone" : "America/New_York"
	}
];
