import { Button, Flex, Icon, Select, useToast } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import { MdCheck } from 'react-icons/md';
import api from 'services/api';
import { db } from 'services/db';
import TemplateTitleEdit from 'views/trader/components/inputs/TemplateTitleEdit';
import TemplateTitleInput from 'views/trader/components/inputs/TemplateTitleInput';
import { NotesTemplate } from '../../variables/types';
import useNewNotesTemplate from '../../hooks/useNewNotesTemplate';
import { DeleteConfirm } from 'views/trader/components/confirm/DeleteConfirm';
import { useAuth } from 'services/auth/AuthProvider';
import { useData } from 'services/data/DataProvider';

const NotesTemplates = (props: { type: string, size: string, notes: string, setnotes: any, reset: string, mb: string }) => {

    const { type, notes, setnotes, reset, mb, size } = props;

    const {muuid} = useAuth();
    const {usertemplates} = useData();

    const [templates, setTemplates] = useState<NotesTemplate[]>([]);
    const [template, setTemplate] = useState(useNewNotesTemplate());

    const [saveload, setsaveload] = useState(false);
    const [deleteload, setDeleteload] = useState(false);
    const newtemplate = useNewNotesTemplate();

    const toast = useToast();

    useEffect(()=>{
        setTemplates(usertemplates);
    }, [usertemplates])


    useEffect(() => {
        setTemplate(newtemplate);
    }, [reset])

    const changeTemplate = (e: ChangeEvent<HTMLSelectElement>) => {
        let t = templates.filter((t) => t.id === Number(e.target.value));
        if (t.length) {
            setnotes(t[0].text);
            setTemplate(t[0]);
        }
        else {
            setTemplate(newtemplate);
            setnotes("");

        }
    }
    const saveTemplateTitle = (title: string) => {
        if (title.trim().length) {
            template.name = title;
            saveTemplate(false);
        }
    }

    const saveTemplate = async (updated: boolean) => {

        if (!notes.trim().length) {
            toast({ title: 'Enter some text ...', description: "", status: 'warning', position: 'top', duration: 2000, isClosable: false });
            setnotes("");
        }
        else if (notes.trim().length > 1000) {
            toast({ title: 'Too many characters ...', description: " Use 1000 characters or less for templates", status: 'warning', position: 'top', duration: 2000, isClosable: false });
        }
        else {
            setsaveload(true)

            template.type = type;
            template.text = notes;
            template.last_uuid = muuid;
            delete template.sync_due;

            await api.post('/trade/savetemplate', { template: template }).then(async (res) => {
                if (res.status === 200) {
                    if (res.data) {
                        let temp = res.data;
                        delete temp.last_uuid;
                        await db.templates.put(temp, temp.id).then(() => {
                            setTemplate(temp);
                        }).catch(() => { })
                        toast({ title: 'Template ' + (updated ? 'updated' : 'saved') + ' ...', description: "", status: 'success', position: 'top', duration: 2000, isClosable: false });
                    }
                    else {
                        toast({ title: 'Error saving template ...', description: "", status: 'error', position: 'top', duration: 3000, isClosable: true });
                    }
                } else {
                    if (template.id > 0) {
                        delete template.last_uuid;
                        template.sync_due = true;
                        await db.templates.put(template, template.id);
                    }
                }
                setsaveload(false)
            }).catch(async (e) => {
                toast({ title: 'Error saving template ...', description: "Are you connected to the internet?", status: 'error', position: 'top', duration: 3000, isClosable: false });
                if (template.id > 0) {
                    delete template.last_uuid;
                    template.sync_due = true;
                    await db.templates.put(template, template.id);
                }
            })

        }
    }
    const deleteTemplate = async () => {

        template.type = type;
        template.text = notes;
        template.last_uuid = muuid;
        delete template.sync_due;



        setDeleteload(true);

        await api.post('/trade/deletetemplate', { template: template, last_uuid: muuid }).then(async (res) => {
            if (res.status === 200) {
                if (res.data !== false) {
                    let temp = res.data;
                    delete temp.last_uuid;
                    await db.templates.delete(template.id).then(() => {
                        setTemplate(newtemplate);
                    }).catch((e) => { })
                    toast({ title: 'Template deleted ...', description: "", status: 'success', position: 'top', duration: 2000, isClosable: false });
                }
                else {
                    toast({ title: 'Error deleting template ...', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false });
                }
                setDeleteload(false);
            }
        })


    }

    return (
        <Flex>
            <Select size={size} placeholder={'Insert template'} p={'0px'} mb={mb} value={template.id} onChange={changeTemplate}>
                {templates && templates.map(t => (
                    <option key={t.id} value={t.id}>
                        {t.name}
                    </option>
                ))}
            </Select>
            <TemplateTitleInput hidden={template.id !== 0} updateTitle={saveTemplateTitle} notes={notes} size={size} />
            <DeleteConfirm hidden={template.id === 0} id={template.id} size={'sm'} me={'0px'} ms={'5px'} title={'Delete template'} message={''} callbackfunction={deleteTemplate} loading={deleteload}></DeleteConfirm>
            {/*<Button variant='outline' colorScheme={'red'} size={size} borderRadius={'5px'} p='0px' ms={'3px'} borderWidth='1px' onClick={deleteTemplate} hidden={template.id === 0} isLoading={deleteload}>
                <Icon as={MdClose} color={'red.200'} boxSize={5} />
            </Button>*/}
            <TemplateTitleEdit hidden={template.id === 0} updateTitle={saveTemplateTitle} notes={notes} title={template.name} size={size} />
            <Button variant='outline' colorScheme={'green'} size={size} borderRadius={'5px'} p='0px' ms={'3px'} borderWidth='1px' onClick={() => saveTemplate(true)} hidden={template.id === 0} isLoading={saveload}>
                <Icon as={MdCheck} color={'green.200'} boxSize={5} />
            </Button>
        </Flex>
    )
}

export default NotesTemplates