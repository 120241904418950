import { Button, Flex, FormLabel, Icon, Text, useColorModeValue, Link as ChakraLink } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { Mentee } from 'views/trader/trades/variables/types'
import DeleteMentorConfirm from 'views/trader/components/confirm/DeleteMentorConfirm';
import { useState } from 'react';
import dayjs from 'dayjs';
import AcceptMentorConfirm from 'views/trader/components/confirm/MentorAcceptConfirm';
import { RiMailSendLine } from "react-icons/ri";
import { useData } from 'services/data/DataProvider';

const MenteeBar = (props: { mentee: Mentee }) => {

  const { mentee } = props;
  const {dateformat} = useData();

  const bgcolor = useColorModeValue('white', 'navy.900');
  const dtcolor = useColorModeValue('gray.500', 'gray.400');
  const mailstr = "mailto:" + mentee.email;
  const [mailload, setmailload] = useState(false);


  const sendmail = () => {
    setmailload(true);
    window.location.href = ('mailto:' + mentee.email)
    setTimeout(() => {
      setmailload(false);
    }, 1000);
  }

  return (
    <>
      <Card borderWidth={'1px'}>
        <Flex justify={'start'}>
          <FormLabel fontWeight='bold' fontSize='sm' mb='8px' color={dtcolor} bg={bgcolor} px='5px' py='2px' borderRadius={'5px'} borderWidth={'1px'} mt='-30px'>{dayjs(mentee.started).format(dateformat)}</FormLabel>
        </Flex>
        <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'} align={{ base: 'center', md: 'end' }}>
          <Flex direction={'row'} justify={'space-between'} align={'end'}>
            <Flex direction={'column'}>
              <FormLabel fontWeight='bold' fontSize='sm' mb='8px' color={'gray.400'}>Trader</FormLabel>
              <Text fontWeight={'700'} me='30px'>{mentee.menteename}</Text>
            </Flex>
            <Flex direction={'column'}>
              <FormLabel fontWeight='bold' fontSize='sm' mb='8px' color={'gray.400'}>Status</FormLabel>
              <Text fontWeight={'700'} me='30px' color={'blue.500'}>{mentee.status === "pending" ? 'Invited' : 'Requested'}</Text>
            </Flex>
          </Flex>
          <Flex direction={'row'} mt={{ base: '20px', md: '0px' }}>
          {mentee.status === 'requested' &&
              <AcceptMentorConfirm mentorshipid={mentee.id} label={'Accept'} owner='mentor' />
            }
            <DeleteMentorConfirm mentorshipid={mentee.id} label={mentee.status === 'pending' ? 'Cancel invite' : 'Decline'} btnsize='sm' />
            <Button size={'sm'} variant={'outline'} borderRadius={'5px'} ms='10px' onClick={sendmail} isLoading={mailload}><Icon as={RiMailSendLine} boxSize={5}></Icon></Button>
          </Flex>
        </Flex>

      </Card>


    </>
  )
}

export default MenteeBar