import dayjs from 'dayjs';
import { Account, AccountGroup, drawdownChartData, equityChartData, profitWinChartData, Trade } from 'views/trader/trades/variables/types'

export async function equityChartValues(trades: Trade[], accounts: Account[], account: Account, group: AccountGroup, currency: string): Promise<any> {
    return new Promise(async (resolve, reject) => {

        let d = [];
        let c = [];
        let equity = 0;
        let initbal = 0;

        for (let a of accounts) {
            initbal += a.init_balance;
        }
        equity = initbal;

        let arr: { date: string, arr: Trade[] }[] = [];

        for (let t of trades) {
            let day = dayjs(t.datetime).startOf('day').format("YYYY-MM-DD");
            let d = arr.filter((d) => d.date === day);

            if (d.length) {
                d[0].arr.push(t);
            }
            else {
                arr.push({ date: day, arr: [t] });
            }
        }


        if (arr.length) {
            d.push(initbal);
            c.push(dayjs(arr[0].date).format("YYYY-MM-DD"))
        }

        for (let a of arr) {
            let eq = equity;
            for (let t of a.arr) {
                let ex = (t.currency !== currency ? t.exchange : 1);
                let nett = (t.nett / ex);
                eq += nett;
                equity += nett;

            }
            d.push(eq);
            c.push(dayjs(a.date).format("YYYY-MM-DD"))
        }


        /*for (let t of trades) {
            let ex = 1;
            if (account.accountid > 0) {
                if (t.currency !== account.currency) {
                    ex = t.exchange;
                }
            }
            else {
                if (t.currency !== group.currency) {
                    ex = t.exchange;
                }
            }
        }*/

        let chg = equity - initbal;
        let color = (chg > 0 ? '#01B574' : '#EE5D50');
        let s = [{
            name: 'Equity',
            type: 'area',
            data: d,
            color: color
        }]

        const data: equityChartData = {
            color: color,
            initBalance: initbal,
            equityChange: chg,
            series: s,
            categories: c
        }

        resolve(data);
    });
}

export async function profitFactorWinValues(trades: Trade[], currency: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        let categories = [];

        let winpercht = [];
        let pfactorcht = [];
        let wpfaxis = [];

        let wincnt = 0;
        let losscnt = 0;
        let wintotal = 0;
        let losstotal = 0;

        for (let t of trades) {

            let ex = (t.currency !== currency ? t.exchange : 1);
            let nett = (t.nett / ex);

            if (nett > 0) {
                wincnt++;
                wintotal += nett;
            }
            else {
                losscnt++;
                losstotal += nett;
            }
            let cnt = wincnt + losscnt;
            winpercht.push(Number(cnt === 0 ? 0 : (wincnt / (wincnt + losscnt)) * 100).toFixed(2));
            pfactorcht.push(Number(losstotal === 0 ? 0 : Math.abs(wintotal / losstotal)).toFixed(2));
            wpfaxis.push(dayjs(t.datetime).format("YYYY-MM-DD"));

            categories.push(dayjs(t.datetime).format("YYYY-MM-DD"))
        }

        for (var i = 1; i < wpfaxis.length; i++) {
            if (wpfaxis[i] === wpfaxis[i - 1]) {
                wpfaxis.splice(i - 1, 1);
                winpercht.splice(i - 1, 1);
                pfactorcht.splice(i - 1, 1);
                i--;
            }
        }

        const s = [{
            name: 'WIN %',
            type: 'column',
            data: winpercht,
            color: '#82DED6'
        }, {
            name: 'Profit Factor (R)',
            type: 'area',
            data: pfactorcht,
            color: '#3965FF'
        }]

        const val: profitWinChartData = {
            labels: wpfaxis,
            categories: categories,
            series: s
        }

        resolve(val);
    })

}

///issue here with calculation
export async function drawdownValues(trades: Trade[], accounts: Account[], currency: string): Promise<any> {

    return new Promise(async (resolve, reject) => {

        let axis = [];
        let data1 = [];
        let data2 = [];

        let openbalance = 0;

        for (let a of accounts) {
            openbalance += a.init_balance;
        }

        let equity = openbalance;
        let highest = openbalance;

        let arr: { date: string, arr: Trade[] }[] = [];

        for (let t of trades) {
            let day = dayjs(t.datetime).startOf('day').format("YYYY-MM-DD");
            let d = arr.filter((d) => d.date === day);

            if (d.length) {
                d[0].arr.push(t);
            }
            else {
                arr.push({ date: day, arr: [t] });
            }
        }

        for (let a of arr) {

            let eq = (equity > highest ? equity : highest);

            for (let t of a.arr) {
                let ex = (t.currency !== currency ? t.exchange : 1);
                let nett = (t.nett / ex);
                eq += nett;
                equity += nett;
                highest += nett;
            }
            let dd = (eq - openbalance);
            let p = (dd / openbalance) * 100;

            axis.push(dayjs(a.date).format("YYYY-MM-DD"));
            data1.push(dd < 0 ? dd : 0);
            data2.push(p < 0 ? p : 0);

            highest = (highest > equity ? highest : equity);
        }

        //alert(JSON.stringify(data1));
        //alert(JSON.stringify(data2));

        let s = [{
            name: 'Drawdown',
            type: 'column',
            data: data1,
            color: '#FFC46B'
        }, {
            name: 'Change',
            type: 'area',
            data: data2,
            color: '#EE5D50'
        }]

        const val: drawdownChartData = {
            axis: axis,
            series: s
        }
        resolve(val);
    })

}


