// chakra imports
import {
  Box,
  Flex,
  Stack
} from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import SidebarCard from 'components/sidebar/components/SidebarCard';
import UploadManualDrawer from 'views/trader/trades/components/modals/UploadManualDrawer';

// FUNCTIONS


function SidebarContent(props: {
  routes: RoutesType[];
  hovered?: boolean;
  mini?: boolean;
}) {
  const { routes, mini, hovered } = props;

  // SIDEBAR
  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
      <Brand mini={mini} hovered={hovered} />
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps={mini === false ? '20px' : mini === true && hovered === true ? '20px' : '16px'} pe={{ md: '16px', '2xl': '1px' }} ms={mini && hovered === false ? '-16px' : 'unset'} >
          <Links mini={mini} hovered={hovered} routes={routes} />
          <Flex mt={'30px'} me='15px'>
            <UploadManualDrawer />
          </Flex>
        </Box>
      </Stack>
      <Box ps="16px" pe={{ md: '16px', '2xl': '0px' }} mt="60px" mb="60px" borderRadius="30px">
        <SidebarCard mini={mini} hovered={hovered} />
      </Box>
    </Flex>
  );
}

export default SidebarContent;
