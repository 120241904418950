import { Button, Flex, FormLabel, useColorModeValue, useToast, Input, FormControl, Select, FormErrorMessage } from '@chakra-ui/react'
import Card from 'components/card/Card'

import { Account, AccountAdjustment, AccountGroup } from '../trades/variables/types';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { db } from 'services/db';
import AccountInputModal from '../trades/components/modals/AccountInputModal';
import api from 'services/api';
import AdjustmentModal from '../trades/components/modals/AdjustmentModal';
import useNewAccount from '../trades/hooks/useNewAccount';
import { useAuth } from 'services/auth/AuthProvider';
import { useData } from 'services/data/DataProvider';

const AccountEdit = (props: { account: Account, updateEdit: any, updateGroup: any }) => {

    const { muuid } = useAuth();//useContext(AuthContext);
    const { accounts, accountgroups, defaultaccount } = useData();


    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const [processing, setprocessing] = useState(false);

    const [account, setAccount] = useState(useNewAccount());
    //const [accounts, setAccounts] = useState([]);
    //const [groups, setGroups] = useState([]);


    const [groupid, setGroupid] = useState(0);
    const [initbalance, setInitbalance] = useState("0");
    const [accname, SetAccname] = useState("");

    const [balerror, setbalerror] = useState("");
    const [nameError, setNameError] = useState("");

    const balanceInputRef = useRef(null);

    const toast = useToast();

    const balances = [
        { label: 'Select ...', value: '0' },
        { label: 'Custom amount ...', value: 'other' },
        { label: '300,000', value: '300000' },
        { label: '250,000', value: '250000' },
        { label: '150,000', value: '150000' },
        { label: '100,000', value: '100000' },
        { label: '75,000', value: '75000' },
        { label: '50,000', value: '50000' },
        { label: '25,000', value: '25000' },
        { label: '15,000', value: '15000' },
        { label: '10,000', value: '10000' },
        { label: '5,000', value: '5000' },
    ];
    const [balarray, setBalanceArray] = useState(balances);

    /*useEffect(() => {
        if (props.account.accountid > 0) {

            let b = balances.filter((b) => Number(b.value) === props.account.init_balance);
            if (!b.length) {
                balarray.push({ label: props.account.init_balance.toLocaleString(), value: String(props.account.init_balance) });
                setBalanceArray(balarray);
            }
            setAccount(props.account);
            setGroupid(props.account.groupid);
            setInitbalance(String(props.account.init_balance));
            SetAccname(props.account.accname);
        }
    }, [props.account])*/

    useEffect(() => {
        if (defaultaccount) {
            setAccount({ ...defaultaccount });
            let b = balances.filter((b) => Number(b.value) === defaultaccount.init_balance);
            if (!b.length) {
                balarray.push({ label: defaultaccount.init_balance.toLocaleString(), value: String(defaultaccount.init_balance) });
                setBalanceArray(balarray);
            }
            //setAccount(props.account);
            setGroupid(defaultaccount.groupid);
            setInitbalance(String(defaultaccount.init_balance));
            SetAccname(defaultaccount.accname);
        }
    }, [defaultaccount]);


    const changeGroupid = (e: ChangeEvent<HTMLSelectElement>) => {
        account.groupid = Number(e.target.value);
        setGroupid(Number(e.target.value));
    }
    const changeOpen = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === '0') {
            setInitbalance("0");
            setbalerror("Required")
        }
        else if (e.target.value === 'other') {
            balanceInputRef.current.openInput();
            setbalerror("")
        }
        else {
            account.init_balance = Number(e.target.value);
            setInitbalance(String(account.init_balance));
            setbalerror("")
        }
    }
    const updateOpenBalance = (bal: number) => {

        if (bal) {
            account.init_balance = Number(bal.toFixed(2));
            setBalanceArray([...balarray, { label: account.init_balance.toLocaleString(), value: bal.toFixed(2) }]);
            setInitbalance(bal.toFixed(2));
        }
        else {
            setInitbalance("0");
            setbalerror("Required")
        }
    }

    const updateName = (e: ChangeEvent<HTMLInputElement>) => {
        account.accname = e.target.value;
        let err = (accounts.filter((a: Account) => a.accname.toLowerCase().trim() === account.accname.toLowerCase().trim()).length > 0 && account.accname !== accname);
        setNameError(err === true ? "Name already used" : "");
    }

    const setAdjustments = (adj: AccountAdjustment[]) => {
        account.adjustments.splice(0, account.adjustments.length);
        for (let a of adj) {
            account.adjustments.push(a)
        }
    }

    const updateTrades = async (acc: Account, sync_due: boolean) => {
        await db.accounts.put(acc, acc.accountid).then(async () => {
            //update groupid in existing trades for this account
            await db.trades.toCollection().modify((tr) => {
                if (tr.accountid === acc.accountid) {
                    tr.groupid = acc.groupid;
                    tr.sync_due = sync_due;
                    //tr.sync_due = acc.sync_due;
                    setprocessing(false);
                }
            })

        }).catch(error => {
            toast({ title: 'Oops, database error ...', description: "Account not saved", status: 'error', position: 'top', duration: 3000, isClosable: false })
            setprocessing(false);
        });

    }

    const updateAccount = async () => {
        if (balerror === "" && nameError === "") {

            setprocessing(true);

            delete account.sync_due;
            account.last_uuid = muuid;

            await api.post('/accounts/saveaccount', { acc: account })
                .then(async (res) => {
                    if (res.data === 0) {
                        toast({ title: 'Oops, error ...', description: "Unable to update account", status: 'error', position: 'top', duration: 3000, isClosable: false })
                        setprocessing(false);
                        props.updateEdit(false);
                    }
                    else {
                        account.accountid = res.data;
                        account.sync_due = false;
                        delete account.last_uuid;
                        updateTrades(account, false);
                        props.updateEdit(false);
                        props.updateGroup(account.groupid);
                        setprocessing(false);
                    }
                })
                .catch((error) => {
                    account.sync_due = true;
                    updateTrades(account, true);
                    props.updateEdit(false);
                    props.updateGroup(account.groupid);
                    setprocessing(false);
                })
        }
    }


    return (
        <Flex width={'100%'} mt={'20px'} direction={'column'}>

            <AccountInputModal ref={balanceInputRef} updateValue={updateOpenBalance}></AccountInputModal>

            <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Edit Account</FormLabel>
            <Card borderWidth={'1px'} borderColor={'gray.700'} mb='20px'>
                <Flex direction={'column'}>

                    <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Group</FormLabel>
                    <Select mb={'15px'} value={groupid} onChange={changeGroupid}>
                        {accountgroups.map((grp: AccountGroup) => (
                            <option key={grp.groupid} value={grp.groupid}>
                                {grp.groupname}
                            </option>
                        ))}
                    </Select>

                    <FormControl isInvalid={nameError !== ""}>
                        <Flex direction={'row'} justify={'space-between'}>
                            <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Name</FormLabel>
                            <FormErrorMessage mt='0px' mb='8px'>{nameError}</FormErrorMessage>
                        </Flex>
                        <Input mb={'15px'} defaultValue={accname} color={textColor} onChange={(e) => updateName(e)}></Input>
                    </FormControl>

                    <FormControl isInvalid={balerror !== ""}>
                        <Flex direction={'row'} justify={'space-between'}>
                            <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Opening balance</FormLabel>
                            <FormErrorMessage mt='0px' mb='8px'>{balerror}</FormErrorMessage>
                        </Flex>
                        <Select
                            value={initbalance}
                            onChange={changeOpen}
                            borderLeftRadius={'0px'} mb='15px'
                        >
                            {balarray.map(bal => (
                                <option key={bal.value} value={bal.value}>
                                    {bal.label}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    <AdjustmentModal adjust={account.adjustments} updateAdjustments={setAdjustments} />

                    <Flex justify='right'>
                        <Button mt='15px' mb='10px' variant={'outline'} colorScheme='red' me={1} onClick={() => props.updateEdit(false)}>
                            Cancel
                        </Button>
                        <Button mt='15px' mb='10px' variant={'outline'} colorScheme='blue' ms={3} onClick={updateAccount} isLoading={processing} loadingText='Saving'>
                            Save
                        </Button>
                    </Flex>
                </Flex>
            </Card>


        </Flex>
    )
}

export default AccountEdit