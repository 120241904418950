import { Checkbox, Flex, useColorModeValue } from '@chakra-ui/react'
import { StrategyRule } from '../../variables/types'

const StrategyRuleView = (props: { rule: StrategyRule }) => {

    const textColor = useColorModeValue('gray.700', 'white');

    return (
        <Flex direction={'row'}>
            <Checkbox size='md' colorScheme='blue' me='10px' mb='15px' mt='5px' color={textColor} isChecked={props.rule.used} isReadOnly={true}>
                {props.rule.text}
            </Checkbox>
        </Flex>
    )
}
export default StrategyRuleView