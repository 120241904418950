import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, AvatarBadge, Button, Flex, Icon, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import AvatarDropzone from './AvatarDropzone';
import { MdAdd } from 'react-icons/md';

const AvatarInput = (props: { image: string, saveimage: any }) => {

  const { image, saveimage } = props;

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [avatar, setAvatar] = useState(image);
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important',
  );

  const updateimage = async (file: string) => {
    setAvatar(file);
  }
  const closeModal = () => {
    saveimage(avatar);
    onClose();
  }

  return (
    <>
      <Avatar mx="auto" bg='gray.500' cursor={'pointer'} src={avatar} color={borderColor} h="87px" w="87px" mt="-43px" border="4px solid" borderColor={borderColor} onClick={onOpen}>
        <AvatarBadge boxSize='1.5em' bg='blue.500'><Icon as={MdAdd} boxSize={6} color={'white'} /></AvatarBadge>
      </Avatar>

      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={undefined} closeOnOverlayClick={false}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Upload avatar image
            </AlertDialogHeader>

            <AlertDialogBody>
              <Flex justify={'center'} align={'center'}>
                <AvatarDropzone imagefile={avatar} setimagefile={updateimage} />
              </Flex>

            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme='blue' variant={'outline'} onClick={closeModal} ml={3}>
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default AvatarInput