import { Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Icon, Flex, useColorModeValue, Button, Box, Show, useToast } from '@chakra-ui/react'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { MdInsights } from 'react-icons/md';
import MinimalStatBar from 'views/trader/components/display/MinimalStatBar';
import MinimalStatBarLarge from 'views/trader/components/display/MinimalStatBarLarge';
import TableCard from 'views/trader/trades/components/tables/TableCard';
import { MinimalStatProcess } from 'views/trader/trades/variables/MinimalStatProcessor';
import { Mentee, MinimalStatistics, newMinStats, PeriodDates, Trade } from 'views/trader/trades/variables/types';
import MentorTradesTable from './MentorTradesTable';
import api from 'services/api';
import { PeriodDateRange } from 'views/trader/trades/variables/PeriodDateRange';
import dayjs from 'dayjs';


const StudentTradesModal = forwardRef((props: {cancelLoading: any, updatepending: any}, ref) => {

    const drawerBg = useColorModeValue('white', 'navy.800');
    ///const { user } = useAuth();//useContext(AuthContext);

    const [title, setTitle] = useState('');
    const [trades, setTrades] = useState<Trade[]>([]);
    const [titleColor, setTitleColor] = useState(null);
    const [titleIcon, setTitleIcon] = useState(<Icon as={MdInsights} boxSize={8} ms='20px' me='20px' />);
    const [statload, setstatload] = useState(false);
    const [mentee, setMentee] = useState<Mentee>(null);
    const toast = useToast();

    const btnRef = useRef(null);

    const [stats, setStats] = useState<MinimalStatistics>(newMinStats());

    useImperativeHandle(ref, () => ({
        async openModal(mentee: Mentee, selection: string) {
            //onOpen();
            setTitle(mentee.menteename);
            setMentee(mentee);

            setstatload(true);

            let r: PeriodDates = PeriodDateRange(selection);
            let s = [dayjs(r.startthis).format('YYYY-MM-DD HH:mm:ss'), dayjs(r.endthis).format('YYYY-MM-DD HH:mm:ss')];

            await api.post('/mentor/studenttrades', { mentee: mentee, selection: selection, start: s[0], end: s[1] }).then(async (res) => {
                if (!res.data.length) {
                  switch (selection) {
                    case 'review':
                      toast({ title: 'No trades found for review ...', description: '', status: 'info', position: 'top', duration: 3000, isClosable: false })
                      break;
                    case 'all':
                      toast({ title: 'No trades found for this trader ...', description: '', status: 'info', position: 'top', duration: 3000, isClosable: false })
                      break;
                    default:
                      toast({ title: 'No trades found for this period ...', description: '', status: 'info', position: 'top', duration: 3000, isClosable: false })
                      break;
                  }
                  setTrades(res.data);
                }
                else {
                  setTrades(res.data);
                  await MinimalStatProcess(res.data, "USD").then((s) => {
                    setStats(s);
                  })
                  onOpen();
                  
                }
              }).catch((e) => {
                toast({ title: 'Error fetching trades ...', description: 'Check your network connection or try again in a few minutes', status: 'error', position: 'top', duration: 4000, isClosable: false })
              })

              setstatload(false);
              props.cancelLoading();
        }
    }));

    const closemodal = async () => {
        onClose();
        setTrades([]);
        setTitle('');
        setMentee(null);
    }

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Modal onClose={closemodal} isOpen={isOpen} size={'full'} autoFocus={false} returnFocusOnClose={false}>
                <ModalOverlay />
                <ModalContent bg={drawerBg} >
                    <ModalCloseButton onClick={closemodal} />
                    <ModalHeader>
                        <Button variant={'outline'} size={'lg'} mt='20px' colorScheme={titleColor} borderWidth={3} >
                            {titleIcon}
                            <Text me='20px'>{title}</Text>
                        </Button>
                    </ModalHeader>
                    <ModalBody>
                        <Flex direction={'column'}>
                            <Box w="100%" mt="10px">
                                <Show breakpoint='(min-width: 1800px)'>
                                    <MinimalStatBarLarge loading={statload} stats={stats} />
                                </Show>
                                <Show breakpoint='(max-width: 1799px)'>
                                    <MinimalStatBar loading={statload} stats={stats} />
                                </Show>
                            </Box>
                            {trades.length > 0 && <Flex>
                                <TableCard title='Tagged trades' table={<MentorTradesTable trades={trades} mentee={mentee ? mentee : null} mentor={null} updatePending={props.updatepending}/>} showTitleBar={true} showBg={true} showMt={true} showPadding={true} loading={false} minh={'auto'} />
                            </Flex>}
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
})

export default StudentTradesModal