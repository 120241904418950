import { Box, Flex, ScaleFade, Text, useColorModeValue } from '@chakra-ui/react';
import { ApexOptions } from 'apexcharts';
import Card from 'components/card/Card';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { profitWinChartData } from 'views/trader/trades/variables/types';
import reseticon from 'assets/icons/reseticon.png';
import downicon from 'assets/icons/downicon.png';

const ProfitFactorWinChart = (props: { profitWinData: profitWinChartData, hidden: boolean, showtoolbar: boolean, height: string }) => {

    const [nochartdata, setnochartdata] = useState(true);
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');

    useEffect(() => {
        updateChartData();
    }, [props.profitWinData])

    const updateChartData = () => {
        if (props.profitWinData.series[0].data.length) {
            setnochartdata(false);
            options.labels = props.profitWinData.labels;
            options.xaxis.categories = props.profitWinData.categories;
            setVariables({ options: options, series: props.profitWinData.series });
        }
        else {
            setnochartdata(true);
        }
    }

    const options: ApexOptions = {
        chart: {
            toolbar: {
                show: props.showtoolbar,
                offsetY: -20,
                tools: {
                    download: false, //'<img src=' + downicon + ' width="40">',
                    selection: false,
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: '<img src=' + reseticon + ' width="40">',
                    customIcons: [{
                        icon: '<img src=' + downicon + ' width="40">',
                        index: 0,
                        title: 'download',
                        click: function (chart, options, e) {
                            chart.dataURI().then((imgURI: any) => {
                                const a = document.createElement('a')
                                a.download = 'pfactorwin_'+dayjs().format("YYYY-MM-DD")+'.png'
                                a.href = imgURI.imgURI;
                                a.click()
                            })
                        }
                    }]
                }
            }
        },
        annotations: {

            yaxis: [{
                y: 50,
                strokeDashArray: 4,
                borderColor: '#848287',
                width: '100%',
                yAxisIndex: 0,
            }]
        },
        tooltip: {
            theme: 'dark',
        },
        plotOptions: {
            bar: {
                borderRadius: 1
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 0.5,
                opacityFrom: 0.3,
                opacityTo: 0.9,
                colorStops: [[{ offset: 0, color: '#82DED6', opacity: 0.25 }, { offset: 100, color: '#82DED6', opacity: 1 }], [{ offset: 0, color: '#3311DB', opacity: 1 }, { offset: 100, color: '#3311DB', opacity: 0.5 }]]
            }
        },
        stroke: {
            curve: 'smooth',
            width: [2, 5]
        },
        dataLabels: {
            enabled: false,
            enabledOnSeries: [1]
        },
        legend: {
            show: true,
            fontWeight: 700,
            fontSize: '11px',
            labels: {
                colors: ['#848287', '#848287']
            },
            onItemClick: {
                toggleDataSeries: false
            },
        },
        xaxis: {
            type: 'category',
            title: {
                text: undefined,
                style: {
                    color: '#848287',
                    fontSize: '11px',
                    fontWeight: 700,
                }
            },
            labels: {
                show: false,
                formatter: (value) => {
                    return dayjs(value).format('DD MMM YYYY');
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            }
        },
        yaxis: [{
            title: {
                text: 'WIN %',
                style: {
                    color: '#848287',
                    fontSize: '11px',
                    fontWeight: 700,
                }
            },
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: ['#848287'],
                    fontSize: '11px',
                    fontWeight: 700,
                },
                offsetX: 0,
                offsetY: 0,
                rotate: 0,
                formatter: (value) => {
                    return value.toFixed(0) + ' %';
                }
            }
        }, {
            opposite: true,
            title: {
                text: '(R) Profit factor',
                style: {
                    color: '#848287',
                    fontSize: '11px',
                    fontWeight: 700,
                }
            },
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: ['#848287'],
                    fontSize: '11px',
                    fontWeight: 700,
                },
                offsetX: 0,
                offsetY: 0,
                rotate: 0,
                formatter: (value) => {
                    return value.toFixed(2);
                }
            }
        }],
        grid: {
            show: false,
        }
    };

    const [variables, setVariables] = useState({ options: options, series: [{ name: "", data: [], color: "", type: "" }] });

    return (
        <>
            <Box mt='20px' h='100%' w='100%' p='0px' hidden={props.hidden}>
                <Flex hidden={!nochartdata} justify={'center'} align={'center'} >
                    <ScaleFade initialScale={0.5} in={true} transition={{ exit: { delay: 0.5 }, enter: { duration: 1 } }}>
                        <Box p='10px' color={textColorSecondary} >
                            <Text color={textColorSecondary} fontSize='md' mt='20px' mb='20px' ms='20px'>No data to display</Text>
                        </Box>
                    </ScaleFade>
                </Flex>
                <Chart
                    hidden={nochartdata}
                    options={variables.options}
                    width="100%"
                    height={props.height}
                    series={variables.series}
                />
            </Box>
        </>
    );
};


export default ProfitFactorWinChart;