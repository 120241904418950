
import {
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    InputGroup,
    InputLeftAddon,
    Select,
    Input,
    Text,
    Stack,
    useColorModeValue,
    useToast,
    FormControl,
    Flex,
    ModalOverlay,
    FormErrorMessage
} from "@chakra-ui/react";

import { Formik, Field } from "formik";
import * as Yup from "yup";
import { create as createAccount } from "react-modal-promise";

import { useRef, useState, useEffect } from "react";
import { useAuth } from "services/auth/AuthProvider";

import AccountInputModal from "./AccountInputModal";
import uuid from 'react-uuid'
import api from "services/api";
import { db } from "services/db";

import AccountGroupModal from "./AccountGroupModal";
import { getAccountGroup } from "services/dbservice";
import { useData } from "services/data/DataProvider";

const AccountModalDialog = ({ isOpen, onResolve, onReject }) => {

    const { user, muuid } = useAuth();//useContext(AuthContext);
    const { accounts, accountgroups } = useData();

    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const bgColor = useColorModeValue('gray.500', 'blackAlpha.500');
    const [processing, setprocessing] = useState(false);
    const toast = useToast();

    //const [accounts, setAccounts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [account, setAccount] = useState(JSON.parse(localStorage.getItem("newaccount")));
    const [groupid, setGroupid] = useState(0);
    const [initbalance, setInitbalance] = useState("0");
    const [bcurrency, setBCurrency] = useState("select");

    const balanceInputRef = useRef(null);

    useEffect(() => {
        setGroups(accountgroups);
    }, [accountgroups])

    const balances = [
        { label: 'Select ...', value: '0' },
        { label: 'Custom amount ...', value: 'other' },
        { label: '300,000', value: '300000' },
        { label: '250,000', value: '250000' },
        { label: '150,000', value: '150000' },
        { label: '100,000', value: '100000' },
        { label: '75,000', value: '75000' },
        { label: '50,000', value: '50000' },
        { label: '25,000', value: '25000' },
        { label: '15,000', value: '15000' },
        { label: '10,000', value: '10000' },
        { label: '5,000', value: '5000' },
    ];

    const [balarray, setBalanceArray] = useState(balances);

    const changeCurrency = (e) => {
        account.currency = e.target.value;
        setBCurrency(e.target.value)
    }
    const changeBroker = (e) => {
        account.platform = e.target.value;
        if (e.target.value === 'tview' || e.target.value === 'paper') {
            account.brokerid = uuid();
        }
    }

    const changeOpen = (e) => {
        if (e.target.value === 'other') {
            balanceInputRef.current.openInput();
        }
        else {
            account.init_balance = Number(e.target.value);
            setInitbalance(String(account.init_balance));
        }
    }
    const updateOpenBalance = (bal) => {
        let s = document.getElementById('init_balance');
        if (bal > 0) {
            account.init_balance = Number(bal.toFixed(2));
            setBalanceArray([...balarray, { label: account.init_balance.toLocaleString(), value: bal.toFixed(2) }]);
            setInitbalance(bal.toFixed(2));
        }
        else {
            s.value = "0";
        }
    }

    useEffect(() => { /// this updates select value when other amount entered
        try {
            let s = document.getElementById('init_balance');
            s.value = balarray[balarray.length - 1].value;
        }
        catch (err) { }
    }, [balarray])

    const reject = () => onReject(account.brokerid);


    const cancelacc = () => {
        onReject(account.brokerid)
    }

    const changeGroup = (e) => {
        setGroupid(Number(e.target.value));
        getAccountGroup(Number(e.target.value)).then((g) => {
            if (g) {
                account.currency = String(g.currency);
                setBCurrency(String(g.currency));
            }
        })
    }

    const updateGroup = (group) => {
        if (group.groupname.trim().length) {
            setGroups([...groups, group]);
            setGroupid(group.groupid);
            let s = document.getElementById('groupid');
            s.value = group.groupid > 0 ? group.groupid : "";
            account.currency = group.currency;
            setBCurrency(group.currency);
        }
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={reject} size="lg" closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add trading account</ModalHeader>
                    <ModalBody>

                        <AccountInputModal ref={balanceInputRef} updateValue={updateOpenBalance}></AccountInputModal>

                        <Formik
                            initialValues={{
                                accname: "",
                                init_balance: "0",
                                currency: "select",
                                broker: account.platform,
                                brokerid: account.brokerid,
                                groupid: 0
                            }}
                            onSubmit={(values) => {

                                setprocessing(true);
                                setTimeout(async () => {

                                    account.accname = values.accname;
                                    account.groupid = groupid;
                                    account.last_uuid = muuid;
                                    delete account.sync_due;

                                    await api.post('/accounts/saveaccount', { acc: account })
                                        .then(async (res) => {
                                            if (res.data === 0) {
                                                toast({ title: 'Stop the bus!', description: <Text whiteSpace="pre-line">{"\nAccount name or number already exists in your profile. \nOpen the account drawer and click 'Re-Sync Data' to refetch data ..."}</Text>, status: 'error', position: 'top', duration: 10000, isClosable: true })
                                                setprocessing(false);
                                            }
                                            else {
                                                account.accountid = res.data;
                                                delete account.last_uuid;

                                                await db.accounts.put(account, account.accountid).then(async () => {
                                                    // set defaultacc to false for existing accounts, set new account as default
                                                    await db.accountgroups.where({ userid: user.id }).toArray().then(async (grps) => {

                                                        let grp = grps.filter((g) => g.defaultgrp);

                                                        await db.accounts.toCollection().modify(acct => {
                                                            if (acct.userid === user.id && acct.groupid === grp[0].groupid) {
                                                                acct.defaultacc = (acct.accountid !== account.accountid ? false : true);
                                                            }
                                                        }).catch(() => { });
                                                    })
                                                    setprocessing(false);
                                                    localStorage.removeItem('newaccount')
                                                    onResolve(account);

                                                }).catch(error => {
                                                    toast({ title: 'Oops, error ...', description: "Account not saved", status: 'error', position: 'top', duration: 3000, isClosable: false })
                                                    setprocessing(false);
                                                    onReject(account.brokerid);
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            toast({ title: 'Oops, error ...', description: "Cannot save account, try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false })
                                            setprocessing(false);
                                        })
                                }, 500);
                            }}

                            validationSchema={Yup.object().shape({
                                groupid: Yup.string().required('Required ...').test("no group", "Group required", (item) => {
                                    return groupid > 0;
                                }),
                                accname: Yup.string().required('Name required ...').min(3, 'Minimum 3 characters').max(20, 'Maximum 20 characters').test("accname duplicate", "Account name already used", (item) => {
                                    return accounts.filter((a) => a.accname.toLowerCase().trim() === item.toLowerCase().trim()).length < 1;
                                }),
                                broker: Yup.string().required('Required ...'),
                                init_balance: Yup.string().required('Required ...').test("no balance", "Initial balance needed", (item) => {
                                    return String(account.init_balance) !== "0"
                                }),
                                currency: Yup.string().required('Required ...').test("no currency", "Base currency needed", (item) => {
                                    return account.currency !== "select" && account.currency !== "";
                                })
                            })}
                        >
                            {({ handleChange, handleSubmit, errors, touched }) => (
                                <form onSubmit={handleSubmit} onChange={handleChange}>
                                    <Stack spacing={4} mt='25px'>
                                        <FormControl isInvalid={!!errors.groupid && touched.groupid}>
                                            <InputGroup variant='outline' size='md'>
                                                <InputLeftAddon children="Account Group" bg={bgColor} color={'white'} w='150px' />
                                                <Select id="groupid" name="groupid" borderRadius={'0px'}
                                                    onChange={changeGroup} value={groupid}
                                                >
                                                    <option value="">Select group ...</option>
                                                    {groups.map(grp => (
                                                        <option key={grp.groupid} value={grp.groupid}>
                                                            {grp.groupname}
                                                        </option>
                                                    ))}
                                                </Select>
                                                <AccountGroupModal updateGroup={updateGroup}></AccountGroupModal>

                                            </InputGroup>
                                            <Flex justify="right">
                                                <FormErrorMessage>{errors.groupid}</FormErrorMessage>
                                            </Flex>
                                        </FormControl>
                                        <FormControl isInvalid={!!errors.accname && touched.accname}>
                                            <InputGroup size='md'>
                                                <InputLeftAddon bg={bgColor} color={'white'} w='150px'>
                                                    Account name
                                                </InputLeftAddon>
                                                <Field
                                                    as={Input}
                                                    id="accname"
                                                    name="accname"
                                                    color={textColor} placeholder='Enter local account name ...'
                                                    borderLeftRadius={'0px'}
                                                >
                                                </Field>
                                            </InputGroup>
                                            <Flex justify="right">
                                                <FormErrorMessage>{errors.accname}</FormErrorMessage>
                                            </Flex>

                                        </FormControl>
                                        <FormControl isInvalid={!!errors.brokerid && touched.brokerid} hidden={true}>
                                            <InputGroup size='md'>
                                                <InputLeftAddon bg={bgColor} color={'white'} w='150px'>
                                                    Account number
                                                </InputLeftAddon>
                                                <Field
                                                    as={Input}
                                                    id="brokerid"
                                                    name="brokerid"
                                                    color={textColor}
                                                    value={account.brokerid}
                                                    placeholder='Account number'
                                                    disabled={true}
                                                    borderLeftRadius={'0px'}
                                                >
                                                </Field>
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl isInvalid={!!errors.broker && touched.broker}>
                                            <InputGroup variant='outline' size='md'>
                                                <InputLeftAddon children="Broker platform" bg={bgColor} color={'white'} w='150px' />
                                                <Field
                                                    as={Select}
                                                    id="broker"
                                                    name="broker"
                                                    value={account.platform}
                                                    disabled={account.platform !== ""}
                                                    onChange={changeBroker}
                                                    borderLeftRadius={'0px'}
                                                >
                                                    <option value=''>Select</option>
                                                    <optgroup label="Manual entry">
                                                        <option value='paper'>Paper trading</option>
                                                    </optgroup>
                                                    <optgroup label="Statement upload">
                                                        <option value='ninja'>Ninjatrader</option>
                                                        <option value='tvate'>Tradovate</option>
                                                        <option value='tview'>Tradingview</option>
                                                    </optgroup>
                                                </Field>
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl isInvalid={!!errors.init_balance && touched.init_balance}>
                                            <InputGroup variant='outline' size='md'>
                                                <InputLeftAddon children="Opening balance" bg={bgColor} color={'white'} w='150px' />
                                                <Select

                                                    value={initbalance}
                                                    id="init_balance"
                                                    name="init_balance"
                                                    onChange={changeOpen}
                                                    borderLeftRadius={'0px'}
                                                >
                                                    {balarray.map(bal => (
                                                        <option key={bal.value} value={bal.value}>
                                                            {bal.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl isInvalid={!!errors.currency && touched.currency}>
                                            <InputGroup variant='outline' size='md'>
                                                <InputLeftAddon children="Base currency" bg={bgColor} color={'white'} w='150px' />
                                                <Field
                                                    as={Select}
                                                    value={bcurrency}
                                                    id="currency"
                                                    name="currency"
                                                    onChange={changeCurrency}
                                                    borderLeftRadius={'0px'}
                                                >
                                                    <option value="select">Select currency ...</option>
                                                    <option value="USD">USD - US Dollar</option>
                                                    <option value="EUR">EUR - Euro</option>
                                                    <option value="GBP">GBP - British Pound</option>
                                                    <option value="CAD">CAD - Canadian Dollar</option>
                                                    <option value="AUD">AUD - Australian Dollar</option>
                                                    <option value="CHF">CHF - Swiss Franc</option>
                                                    <option value="HKD">HKD - Hong Kong Dollar</option>
                                                    <option value="JPY">JPY - Japanese Yen</option>
                                                    <option value="ZAR">ZAR - South African Rand</option>
                                                </Field>
                                            </InputGroup>
                                        </FormControl>
                                    </Stack>
                                    <Flex justify='right'>
                                        <Button mt='25px' mb='15px' colorScheme='red' me={3} onClick={cancelacc}>
                                            Cancel
                                        </Button>
                                        <Button mt='25px' mb='15px' type='submit' colorScheme='blue' ms={3} isLoading={processing} loadingText='Processing'>
                                            Save
                                        </Button>
                                    </Flex>
                                </form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export const NewAccountModal = createAccount(AccountModalDialog)