
import dayjs from "dayjs";
import { StatInstrument, StatInstruments, StatLose, StatSide, StatSummary, StatWin, Trade } from "views/trader/trades/variables/types";

export async function DashStatsData(trades: Trade[], currency: string): Promise<any> {

    return new Promise(async (resolve, reject) => {

        let wincount: number = 0;
        let losscount: number = 0;

        let maxwin: number = 0;
        let maxloss: number = 0;

        let winnett: number = 0;
        let lossnett: number = 0;

        let windays: number = 0;
        let lossdays: number = 0;

        let winstreak: number = 0;
        let losstreak: number = 0;

        let totalwinduration: number = 0;
        let totallossduration: number = 0;

        let totalrulescore: number = 0;
        let rulescorecount = 0;

        let strategies: { id: number, name: string, count: number }[] = [];
        let mistakes: { id: number, name: string, count: number }[] = [];

        let instruments: StatInstrument[] = [];

        let daycount: number = 0;
        let rvaluestotal: number = 0;
        let rvaluetrades = 0;

        let target_rvaluestotal: number = 0;
        let target_rvaluetrades = 0;

        let longcount: number = 0;
        let shortcount: number = 0;

        let lastresult = "";
        let currentstreak: number = 0;

        let dayrunning = 0;
        let daynetts: number[] = [];

        let day = (trades.length ? dayjs(trades[0].datetime) : dayjs());

        for (let index = 0; index < trades.length; index++) {

            let tr = trades[index];

            let ex = (tr.currency !== currency ? tr.exchange : 1);
            let nett = (tr.nett / ex);


            /////////////////// rulescore only calculated if strategies with rules used
            //let score = false;
            if (tr.strategies.length) {
                for (let s of tr.strategies) {
                    if (s.rules.length) {
                        totalrulescore += tr.rulescore;
                        rulescorecount++;
                    }
                }
            }
            //totalrulescore += tr.rulescore;
            ///////////////////

            longcount += (tr.side.toLowerCase() === "buy" ? 1 : 0);
            shortcount += (tr.side.toLowerCase() === "sell" ? 1 : 0);

            // NOTE: below ignores trades with no stop, ie R values only calculated for trades where stop used
            /*if (tr.stop_price > 0) {
                rvaluestotal += ((tr.points / tr.size) / Math.abs(tr.entry_avg - tr.stop_price));
                rvaluetrades++;
            }*/
            if (tr.rvalue !== 0) { // replaces above
                rvaluestotal += tr.rvalue;
                rvaluetrades++;
            }

            if (tr.target_rvalue !== 0) {
                target_rvaluestotal += tr.target_rvalue;
                target_rvaluetrades++;
            }

            if (dayjs(tr.datetime).isSame(day, 'day')) {
                dayrunning += nett;
                //if (index === trades.length - 1) {
                   // daynetts.push(dayrunning);
                //}
            }
            else {
                daynetts.push(dayrunning);
                windays += (dayrunning > 0 ? 1 : 0);
                lossdays += (dayrunning < 0 ? 1 : 0);
                dayrunning = nett;
                daycount++;
                day = dayjs(tr.datetime);
            }

            if (index === (trades.length - 1)) {
                daynetts.push(dayrunning);
                windays += (dayrunning > 0 ? 1 : 0);
                lossdays += (dayrunning < 0 ? 1 : 0);
            }

            for (let s of tr.strategies) {
                let i = strategies.findIndex((str) => str.id === s.id);
                if (i > -1) {
                    strategies[i].count++;
                    //strategies[i].nett += nett;
                }
                else {
                    strategies.push({ id: s.id, name: s.name, count: 1 }) //, nett: nett })
                }
            }
            for (let m of tr.mistakes) {
                let i = mistakes.findIndex((mi) => mi.id === m.id);
                if (i > -1) {
                    mistakes[i].count++;
                    //mistakes[i].nett += nett;
                }
                else {
                    mistakes.push({ id: m.id, name: m.name, count: 1 }) //, nett: nett })
                }
            }

            let i = instruments.findIndex((i) => i.ticker === tr.ticker);
            if (i > -1) {
                instruments[i].count++;
                instruments[i].nett += nett;
            }
            else {
                instruments.push({ ticker: tr.ticker, count: 1, nett: nett })
            }


            if (nett > 0) {
                wincount++;
                maxwin = (nett > maxwin ? nett : maxwin);
                totalwinduration += tr.duration;
                winnett += nett;
                currentstreak = (lastresult === "win" ? currentstreak + 1 : 1)
                lastresult = "win";
                winstreak = (currentstreak > winstreak ? currentstreak : winstreak);
            }
            else if (nett < 0) { // ie ignore scratch trades

                losscount++;
                maxloss = (nett < maxloss ? nett : maxloss);
                totallossduration += tr.duration;
                lossnett += nett;
                currentstreak = (lastresult === "loss" ? currentstreak + 1 : 1)
                lastresult = "loss";
                losstreak = (currentstreak > losstreak ? currentstreak : losstreak);
            }

        }


        strategies.sort((a, b) => b.count - a.count);
        mistakes.sort((a, b) => b.count - a.count);

        let tradestotal = wincount + losscount;

        let profit_factor = winnett / (lossnett === 0 ? 1 : Math.abs(lossnett));

        let avgw = (wincount > 0 ? winnett / wincount : 0);
        let avgl = (losscount > 0 ? lossnett / losscount : 0);
        let plratio = (avgl < 0 ? (avgw > 0 ? avgw : 1) / Math.abs(avgl) : avgw);

        let dn = daynetts.sort((a, b) => b - a);
        let bestday = (dn.length ? (dn[0] > 0 ? dn[0] : 0) : 0);
        let worstday = (dn.length ? (dn[dn.length - 1] < 0 ? dn[dn.length - 1] : 0) : 0);

        const summary: StatSummary = {
            //plratio: plratio,
            profit_factor: profit_factor,
            total_R: rvaluestotal,
            avg_R: (rvaluetrades > 0 ? (rvaluestotal / rvaluetrades) : 0),
            avg_R_target: (target_rvaluetrades > 0 ? (target_rvaluestotal / target_rvaluetrades) : 0),
            avg_Rulescore: (rulescorecount > 0 ? totalrulescore / rulescorecount : 0),
            avg_trades: (tradestotal > 0 ? tradestotal / daycount : 0),
            max_strategy: (strategies.length ? strategies[0].name : '-'),
            max_mistake: (mistakes.length ? mistakes[0].name : '-'),
        }
        const win: StatWin = {
            wintotal: wincount,
            winperc: (tradestotal > 0 ? (wincount / tradestotal) * 100 : 0),
            windays: windays,
            maxwin: maxwin,
            avgwin: avgw,
            winstreak: winstreak,
            avg_duration: (wincount > 0 ? totalwinduration / wincount : 0),
            bestday: bestday
        }
        const lose: StatLose = {
            losstotal: losscount,
            lossperc: (tradestotal > 0 ? (losscount / tradestotal) * 100 : 0),
            lossdays: lossdays,
            maxloss: maxloss,
            avgloss: avgl,
            losstreak: losstreak,
            avg_duration: (losscount > 0 ? totallossduration / losscount : 0),
            worstday: worstday
        }
        const sides: StatSide = {
            long: longcount,
            short: shortcount
        }
        let labels = [];
        for (let i of instruments) {
            labels.push(i.ticker);
        }
        const inst: StatInstruments = {
            labels: labels,
            data: instruments
        }

        resolve({ summary: summary, winners: win, losers: lose, sides: sides, instruments: inst })
    })
}


