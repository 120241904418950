// Chakra imports
import { Flex, Box, FormLabel, Tag, TagLabel, useColorModeValue, Heading } from '@chakra-ui/react';
// Custom components
import { useEffect, useState } from 'react';
import { TradeStrategy } from 'views/trader/trades/variables/types';


function MentorStrategyTags(props: { label?: string, id?: string, [x: string]: any, usedstrategies: TradeStrategy[] }) {

	const { label, id, usedstrategies, ...rest } = props;

	const [usedTags, setUsedTags] = useState(props.usedstrategies);

	useEffect(() => {
		setUsedTags(props.usedstrategies)
	}, [props.usedstrategies, props.unusedstrategies])

	let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
	const headcol = useColorModeValue('gray.400', 'gray.500');

	let bgs = useColorModeValue('blue.500', 'blue.500');


	return (
		<Box overflowY='auto' mb='20px'>
			<FormLabel htmlFor={id} fontWeight='bold' fontSize='sm' mb='8px'>
				{label}
			</FormLabel>
			<Flex
				direction='row'
				p='10px'
				wrap='wrap'
				bg='transparent'
				border='1px solid'
				borderColor={borderColor}
				borderRadius='16px'
				_focus={{ borderColor: 'teal.300' }}
				minH='40px'
				h='auto'
				cursor='text'
				{...rest}>
				{!usedTags.length &&
					<Heading size={'xs'} color={headcol}>No strategies tagged</Heading>
				}
				{usedTags.map((tag: any, index: any) => {
					return (
						<Tag
							fontSize='sm'
							fontWeight={'700'}
							h='25px'
							mb='6px'
							me='6px'
							borderRadius='5px'
							variant='solid'
							bg={bgs}
							key={index}>
							<TagLabel w='100%'>{tag.name}</TagLabel>
						</Tag>
					);
				})}
			</Flex>
		</Box>
	);
}

export default MentorStrategyTags;
