import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Text, useDisclosure } from "@chakra-ui/react"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"

export const UserEnableConfirm = forwardRef((props: { confirmaction: any }, ref) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const [enabled, setEnabled] = useState(false);

    useImperativeHandle(ref, () => ({
        openInput(initenabled: boolean) {
            setEnabled(initenabled);
            onOpen();
        }
    }));

    const confirmAction = () => {
        onClose();
        props.confirmaction(enabled);
    }

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Change user access
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text color={enabled ? "cyan" : "red.500"} fontWeight={'700'}>
                                {enabled ? "Do you want ENABLE this user?" : "DISABLE user and sign them out?"}
                            </Text>
                            <Text fontWeight={'700'} mt='20px'>
                                Are you sure?
                            </Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose} variant={'ghost'}>
                                Cancel
                            </Button>
                            <Button colorScheme={enabled ? "blue" : "red"} variant='outline' onClick={confirmAction} ml={3}>
                                YES, {enabled ? "ENABLE" : "DISABLE"}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
})