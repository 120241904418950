import { Box, Button, Flex, FormLabel, Icon, Select, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { ChangeEvent, useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md';
import api from 'services/api';
import { db } from 'services/db';
import { Instrument } from '../../variables/types';
import { DeleteConfirm } from 'views/trader/components/confirm/DeleteConfirm';
import { useData } from 'services/data/DataProvider';

const TickerInput = (props: { ticker: string, instrument: Instrument, update: any, remove: any }) => {

    const [market, setMarket] = useState('');
    const [tickers, setTickers] = useState([]);
    const [ticker, setTicker] = useState('');
    const [orig_ticker, setorig_ticker] = useState(props.ticker);
    const [searching, setsearching] = useState(false);
    const [queryRes, setQueryRes] = useState([]);
    const toast = useToast();
    const textColor = useColorModeValue('gray.700', 'gray.200');
    const [instrument, setInstrument] = useState<Instrument>(props.instrument);

    const {instruments} = useData();

    useEffect(()=>{
        if(market!==''){
            updateMarketTickers(market);
        }
    },[instruments])

    const changeMarket = async (e: ChangeEvent<HTMLSelectElement>) => {
        updateMarketTickers(e.target.value);
    }

    const updateMarketTickers = async(market: string) =>{
        setMarket(market);
        await db.instruments.where({ category: market }).toArray().then((inst) => {
            let ins = inst.filter((i) => orig_ticker.indexOf(i.ticker) > -1);
            ins.sort((a, b) => a.ticker.toLowerCase().localeCompare(b.ticker.toLowerCase()));
            setTickers(ins);
            setTicker('');
        })
    }

    const changeTicker = async (e: ChangeEvent<HTMLSelectElement>) => {
        setTicker(e.target.value);
    }

    const findstock = async () => {
        setsearching(true);
        await api.post('/stocks/findstocksymbol', { query: orig_ticker }).then((res) => {
            //alert(JSON.stringify(res.data))
            setQueryRes(res.data);
            if (!res.data.length) {
                toast({ title: 'No matcing stocks found ...', description: "", status: 'info', position: 'top', duration: 3000, isClosable: false })
            }
        }).catch((e) => {
            toast({ title: 'Unable to search for stocks', description: "Check your internet connection", status: 'error', position: 'top', duration: 3000, isClosable: false })
        })
        setsearching(false);
    }

    const addStock = async (stock: any) => {
        let newInst: Instrument = {
            id: stock.id,
            userid: 0,
            ticker: stock.symbol,
            description: stock.name,
            category: "STOCK",
            subcategory: stock.sector,
            currency: "USD",
            pointvalue: 1,
            ticksize: 0.01,
            relevance: 0,
            defaultstop: 0,
            commission: [],
            sync_due: false
        };

        await db.instruments.put(newInst, newInst.id).then(() => {
            setTickers([...tickers, newInst]);
            setTicker(newInst.ticker);
            setQueryRes([]);
            toast({ title: newInst.ticker + ' added...', description: stock.name, status: 'success', position: 'top', duration: 3000, isClosable: false })
        }).catch(() => { })
    }

    const updateticker = async () => {
        await db.instruments.get({ ticker: ticker }).then((inst) => {
            setInstrument(inst);
            props.update(orig_ticker, inst);
            setQueryRes([]);
        })
    }

    const removeTickerExecutions = () => {
        props.remove(instrument !== null ? ticker : orig_ticker)
    }


    return (
        <>
            <Card borderWidth={'1px'} mt='20px'>
                <Flex direction={'column'} >
                    <Flex direction={'row'} justify={'space-between'}>
                        <Text fontWeight={'700'} mb='10px' color={'red.500'}>{orig_ticker}</Text>
                        {/*<Button size={'sm'} borderRadius={'5px'}><Icon as={MdClose} color={'red.500'}></Icon></Button>*/}
                        <DeleteConfirm hidden={!!instrument} id={1} size={'sm'} me={'0px'} title={'Delete ticker: ' + orig_ticker} message={'Remove all executions for ' + orig_ticker + ' from this import?'} callbackfunction={removeTickerExecutions}></DeleteConfirm>
                    </Flex>

                    {instrument && <>
                        <Flex direction={'row'} >
                            <Flex direction={'column'} me='20px'>
                                <FormLabel fontSize='sm' mb='8px' color="secondaryGray.600">Ticker</FormLabel>
                                <Text fontWeight={'700'} mb='10px' color={textColor}>{instrument.ticker}</Text>
                            </Flex>
                            <Flex direction={'column'}>
                                <FormLabel fontSize='sm' mb='8px' color="secondaryGray.600">Desciption</FormLabel>
                                <Text fontWeight={'700'} mb='10px' color={textColor}>{instrument.description}</Text>
                            </Flex>
                        </Flex>
                    </>}

                    <Flex direction={'row'} gap={5} hidden={instrument !== null}>
                        <Flex direction={'column'}>
                            <FormLabel fontSize='sm' mb='8px' color="secondaryGray.600">Market</FormLabel>
                            <Select onChange={changeMarket} value={market}>
                                <option value=''>Select ...</option>
                                <option value='FUT'>Futures</option>
                                <option value='FOREX'>Forex</option>
                                <option value='CRYPTO'>Crypto</option>
                                <option value='STOCK'>Stock</option>
                            </Select>
                        </Flex>
                        <Flex direction={'column'}>
                            <FormLabel fontSize='sm' mb='8px' color="secondaryGray.600">Ticker</FormLabel>
                            <Select onChange={changeTicker} value={ticker}>
                                <option value=''>Select ...</option>
                                {tickers.map((tick, index) => (
                                    <option value={tick.ticker}>{tick.ticker}</option>
                                ))}
                            </Select>
                        </Flex>
                        <Flex hidden={market !== 'STOCK' || ticker !== ''}>
                            <Button variant={'outline'} size={'sm'} colorScheme={'blue'} mt={'33px'} borderRadius={'10px'} ms='5px' onClick={findstock} isLoading={searching}>Lookup</Button>
                        </Flex>
                        <Flex justify={'end'} hidden={ticker === ''}>
                            <Button size={'sm'} colorScheme={'blue'} mt={'33px'} onClick={updateticker}>Accept</Button>
                        </Flex>
                    </Flex>
                    <Flex direction={'column'} hidden={market !== 'STOCK'}>
                        {queryRes.map((stock, index) => (
                            <Flex direction={'row'} key={index} justify={'space-between'} mt="20px">
                                <Box w='15%'>
                                    <Text color={textColor} fontSize='sm' fontWeight='700' me='20px'>
                                        {stock.symbol}
                                    </Text>
                                </Box>
                                <Box w='70%' me='10px'>
                                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                                        {stock.name}
                                    </Text>
                                    <Text color='secondaryGray.500' fontSize='sm' fontWeight='600'>
                                        {stock.sector}
                                    </Text>
                                </Box>
                                <Box w='15%'>
                                    <Button size={'sm'} variant={'outline'} colorScheme={'red'} onClick={() => { addStock(stock) }}><Icon as={MdAdd} />Add</Button>
                                </Box>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            </Card>
        </>
    )
}

export default TickerInput