import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure, useToast, Text } from '@chakra-ui/react'
import React, { useState } from 'react'

const MentorStrategyCopy = (props: { hidden: boolean, adding: boolean, copystrat: any }) => {

    const { hidden, adding, copystrat } = props;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    const copystrategy =()=>{
        onClose();
        copystrat();
    }

    return (
        <>
            <Button hidden={hidden} variant={'outline'} size={'md'} colorScheme={'red'} borderRadius='5px' ms='3px' me='3px' px='20px' isLoading={adding} onClick={onOpen}>
                + Copy
            </Button>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Copy mentor strategy?
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Text mb='20px'>This will add the strategy to your profile.</Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button  onClick={onClose}>
                                Cancel
                            </Button>
                            <Button ref={cancelRef} colorScheme='red' ml={3}  onClick={copystrategy}>
                                + COPY
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default MentorStrategyCopy

