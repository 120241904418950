import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Icon, useDisclosure, useToast, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdClose } from 'react-icons/md';
import api from 'services/api';
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';

const DeleteMentorConfirm = (props: { mentorshipid: number, label: string, btnsize: string }) => {

    const {muuid} = useAuth();
    const { mentorshipid, label, btnsize } = props;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const godelete = async () => {
        setLoading(true);
        await api.post('/mentor/cancel', { id: mentorshipid, last_uuid: muuid }).then(async (res) => {
            if (res.data === mentorshipid) {
                setLoading(false);
                onClose();
                await db.mentees.delete(mentorshipid).catch(() => { })
                await db.mentors.delete(mentorshipid).catch(() => { })
                await db.mentormessages.where({ mentorshipid: mentorshipid }).delete().catch((e) => { })
                toast({ title: 'Mentorship deleted ...', description: '', status: 'success', position: 'top', duration: 2000, isClosable: false })
            }
            else {
                toast({ title: 'Unable to accept ...', description: 'Please try again in a few minutes ...', status: 'error', position: 'top', duration: 3000, isClosable: false })
                setLoading(false);
                onClose();
            }
        }).catch((e) => {
            toast({ title: 'Unable to delete ...', description: 'Please try again in a few minutes ...', status: 'error', position: 'top', duration: 3000, isClosable: false })
            setLoading(false);
            onClose();
        });
    }

    return (
        <>
            <Button colorScheme='red' variant={'outline'} onClick={onOpen} size={btnsize} borderRadius={'5px'} >
                {label} <Icon as={MdClose} size={5} ms='5px'></Icon>
            </Button>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            CANCEL Mentorship
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Text mb='20px'>Any messages for this mentorship will be deleted.</Text>
                            <Text mb='20px'>A new invite or request will be needed to restart the mentorship.</Text>
                            <Text fontWeight={'700'} mb='20px'>Are you sure?</Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Go back
                            </Button>
                            <Button colorScheme='red' onClick={godelete} ml={3} isLoading={loading} loadingText='Deleting'>
                                YES, Cancel
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default DeleteMentorConfirm

