import {
  Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, Flex, Image,
  FormLabel, Text, Icon, Input, InputGroup, InputLeftAddon, useColorModeValue, useDisclosure, useToast, InputRightAddon
} from '@chakra-ui/react'

import Card from 'components/card/Card';
import dayjs from 'dayjs';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { MdCalendarMonth, MdEditDocument, MdInsights, MdOutlineImageNotSupported, MdOutlineTipsAndUpdates } from 'react-icons/md';
import { useData } from 'services/data/DataProvider';
import { db } from 'services/db';
import JornoQuillReadOnly from 'views/trader/components/inputs/JornoQuillReadOnly';
import ImageDrawer from 'views/trader/trades/components/modals/ImageDrawer';
import useNewJournal from 'views/trader/trades/hooks/useNewJournal';
import { JournalEntry } from 'views/trader/trades/variables/types';

const JournalDrawerMentor = forwardRef((props: {}, ref) => {

  const imageRef = useRef(null);

  const {dateformat} = useData();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bdColor = useColorModeValue('gray.200', 'gray.700');
  const drawerBg = useColorModeValue('white', 'navy.800');
  const imageColor = useColorModeValue('gray.300', 'gray.500');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [mentor, setMentor] = useState('');
  const [journaltitle, setjournaltitle] = useState("");
  const [journalcontent, setjournalcontent] = useState("");
  const [journalimage, setJournalimage] = useState<string>("");
  const [journalcategory, setjournalcategory] = useState("");
  const [journaldate, setjournaldate] = useState('');
  //const [dateformat, setDateFormat] = useState(localStorage.getItem("dateformat") ? localStorage.getItem("dateformat") : "YYYY-MM-DD")

  const [dateformatlocal, setDateFormatLocal] = useState("YYYY-MM-DD")

  const newjournal = useNewJournal();
  const toast = useToast();

  useMemo(() => {
    let df = dateformat.replace("HH:mm", '').replace("ss", '').replace(":", '');
    setDateFormatLocal(df);
  }, [dateformat])

  useImperativeHandle(ref, () => ({
    async openModal(j: JournalEntry) {
      await db.mentors.where({ id: j.mentorship_id }).toArray().then((m) => {
        setMentor(m.length ? m[0].mentorname.toUpperCase() : '');
      })

      setjournaldate(dayjs(j.date).format(dateformatlocal))
      setjournaltitle(j.title);
      setjournalcategory(j.category);
      setjournalcontent(j.text);
      setJournalimage(j.base64Image);
      onOpen();

    }
  }));

  function eventColor(category: string) {
    switch (category) {
      case 'general': return "#01B574";
      case 'education': return "#3965FF";
      case 'outlook': return "#ffa63f";
      case 'review': return "#EA4848";
    }
  }
  function renderIcon(category: string) {
    switch (category) {
      case 'general': return <Icon as={MdEditDocument} boxSize={5} />;
      case 'education': return <Icon as={MdOutlineTipsAndUpdates} boxSize={5} />;
      case 'outlook': return <Icon as={MdCalendarMonth} boxSize={5} />;
      case 'review': return <Icon as={MdInsights} boxSize={5} />;
      default: return null;
    }
  }

  const closeModal = () => {
    setjournaltitle("");
    setjournalcategory("");
    setjournalcontent("");
    setJournalimage("");
    onClose();
  }

  const imageclick = () => {
    imageRef.current.openImage();
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement='top'
        onClose={closeModal}
        size={'full'}
      >
        {/*<DrawerOverlay/>*/}
        <DrawerContent bg={drawerBg}>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex direction={{ base: 'column', md: 'row' }} ms='20px' mt='7px'>
              <Text color={textColor} ms="20px">{mentor} - Journal entry</Text>
              <Text color='gray.500' ms="20px" mt={{ base: '10px', md: '0px' }}>{journaldate}</Text>
            </Flex>
          </DrawerHeader>

          <DrawerBody>

            <Flex direction={{ base: 'column', lg: 'row' }}>
              <Card gridArea='content' borderWidth={'1px'} h='100%'>
                <Flex direction={{ base: 'column', md: 'row' }} mb={'20px'} justify={'space-between'} flexWrap={'wrap'}>
                  <Flex direction={{ base: 'column', md: 'row' }}>
                    <InputGroup mb={{ base: '20px', md: '0px' }} >
                      <InputLeftAddon fontWeight={'700'}>Title</InputLeftAddon>
                      <Input value={journaltitle} isReadOnly={true} color={textColor} type='text' placeholder='No journal title provided ...' maxLength={50} />
                    </InputGroup>
                    <InputGroup mb={{ base: '20px', md: '0px' }} ms={{ base: '0px', md: '10px' }} >
                      <InputLeftAddon fontWeight={'700'}>Category</InputLeftAddon>
                      <Input value={journalcategory} isReadOnly={true} color={textColor} type='text' placeholder='No journal title provided ...' maxLength={50} />
                      <InputRightAddon hidden={journalcategory === ""} bgColor={eventColor(journalcategory)}>{renderIcon(journalcategory)}</InputRightAddon>
                    </InputGroup>
                  </Flex>
                </Flex>
                <FormLabel color={textColor} fontWeight='bold' fontSize='sm' mb={3} mt={3}>Content</FormLabel>
                <Box>
                  <JornoQuillReadOnly content={journalcontent} placeHolder={'No content entered by mentor ...'} />
                </Box>
              </Card>
              <Card gridArea='image' borderColor={bdColor} borderWidth='0px' >
                {journalimage.length ? (
                  <>
                    <Flex w="100%" h="100%" justify="center" >
                      <Flex onClick={() => imageclick()}>
                        <Image src={journalimage} w="100%" maxH={'700px'} cursor="pointer" />
                      </Flex>
                    </Flex>
                    <ImageDrawer ref={imageRef} imageurl={journalimage}></ImageDrawer>
                  </>
                ) : (
                  <Flex w="100%" h="100%" direction={'column'} justify="center" align={'center'}>
                    <Icon as={MdOutlineImageNotSupported} w='80px' h='80px' mb='20px' color={imageColor} />
                    <Text
                      mb='12px'
                      fontSize='md'
                      fontWeight='700'
                      color={imageColor}>
                      No image saved by mentor
                    </Text>
                  </Flex>
                )}
              </Card>
            </Flex>

            {/*} <Grid h={'100%'} templateRows='repeat(1, 1fr)' templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }}
              templateAreas={{
                base: `'content' 'image'`,
                lg: `'content image'`,
              }}
              gap={5}
            >
            <Card gridArea='content' borderWidth={'1px'} h='100%'>
                <Flex direction={{ base: 'column', md: 'row' }} mb={'20px'} justify={'space-between'} flexWrap={'wrap'}>
                  <Flex direction={{ base: 'column', md: 'row' }}>
                    <InputGroup mb={{ base: '20px', md: '0px' }} >
                      <InputLeftAddon fontWeight={'700'}>Title</InputLeftAddon>
                      <Input value={journaltitle} isReadOnly={true} color={textColor} type='text' placeholder='No journal title provided ...' maxLength={50} />
                    </InputGroup>
                    <InputGroup mb={{ base: '20px', md: '0px' }} ms={{ base: '0px', md: '10px' }} >
                      <InputLeftAddon fontWeight={'700'}>Category</InputLeftAddon>
                      <Input value={journalcategory} isReadOnly={true} color={textColor} type='text' placeholder='No journal title provided ...' maxLength={50} />
                      <InputRightAddon hidden={journalcategory === ""} bgColor={eventColor(journalcategory)}>{renderIcon(journalcategory)}</InputRightAddon>
                    </InputGroup>
                  </Flex>
                </Flex>
                <FormLabel color={textColor} fontWeight='bold' fontSize='sm' mb={3} mt={3}>Content</FormLabel>
                <Box>
                  <JornoQuillReadOnly content={journalcontent} placeHolder={'No content entered by mentor ...'} />
                </Box>
              </Card>
              <Card gridArea='image' borderColor={bdColor} borderWidth='0px' >
                {journalimage.length ? (
                  <>
                    <Flex w="100%" h="100%" justify="center" >
                      <Flex onClick={() => imageclick()}>
                        <Image src={journalimage} w="100%" maxH={'700px'} cursor="pointer" />
                      </Flex>
                    </Flex>
                    <ImageDrawer ref={imageRef} imageurl={journalimage}></ImageDrawer>
                  </>
                ) : (
                  <Flex w="100%" h="100%" direction={'column'} justify="center" align={'center'}>
                    <Icon as={MdOutlineImageNotSupported} w='80px' h='80px' mb='20px' color={imageColor} />
                    <Text
                      mb='12px'
                      fontSize='md'
                      fontWeight='700'
                      color={imageColor}>
                      No image saved by mentor
                    </Text>
                  </Flex>
                )}
              </Card>
            </Grid>*/}
          </DrawerBody>
          <DrawerFooter>
            <Button variant={'outline'} colorScheme='blue' ms={'10px'} mt={'20px'} onClick={closeModal}>Close</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
})

export default JournalDrawerMentor