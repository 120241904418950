// Chakra imports
import { Flex, Box, FormLabel, Tag, TagCloseButton, TagLabel, useColorModeValue, Select, InputGroup } from '@chakra-ui/react';
// Custom components
import { ChangeEvent, useEffect, useState } from 'react';
import { db } from 'services/db';
import { Strategy, StrategyRule, TradeStrategy } from '../../variables/types';
import StrategiesModal from '../modals/StrategyModal';

function StrategyTags(props: { label?: string, id?: string, [x: string]: any, usedStrategies?: TradeStrategy[], unusedstrategies?: TradeStrategy[], updatestrategytags: any, notifychanges: any }) {

	const { label, id, usedstrategies, unusedstrategies, updatestrategytags, notifychanges, ...rest } = props;

	const [usedTags, setUsedTags] = useState(props.usedstrategies);
	const [unusedTags, setUnusedTags] = useState(props.unusedstrategies);

	useEffect(() => {
		setUnusedTags(props.unusedstrategies)
		setUsedTags(props.usedstrategies)
	}, [props.usedstrategies, props.unusedstrategies])

	let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

	let bgs = useColorModeValue('blue.500', 'blue.500');

	const addTag = (e: ChangeEvent<HTMLSelectElement>) => {
		try {
			let s = unusedTags.find((t) => t.id === Number(e.target.value));

			let unused = unusedTags.filter((t) => t.id !== s.id);
			let used = [...usedTags, s];
			setUnusedTags(unused);
			setUsedTags(used);
			props.updatestrategytags(used, unused);
			//alert("USED: " + JSON.stringify(used) + " UNUSED: " + JSON.stringify(unused));
		} catch { }
	}

	const removeTag = async (s: { id: number, name: string, rules: StrategyRule[] }) => {
		try {

			await db.strategies.get(s.id).then((st) => {
				if (st === undefined) {
					let used = [...usedTags.filter((element: any) => element.id !== s.id)]
					props.updatestrategytags(used, unusedTags);
				}
				else {
					let unused = [...unusedTags, s];
					let used = [...usedTags.filter((element: any) => element.id !== s.id)]

					setUsedTags(used);
					setUnusedTags(unused);//.sort((a, b) => a.name.localeCompare(b.name)));
					props.updatestrategytags(used, unused);
				}
			})
			//alert("USED: " + JSON.stringify(used) + " UNUSED: " + JSON.stringify(unused));
		} catch { }
	}

	const updateStrategyChange = (s: Strategy) => {


		let s2: TradeStrategy = { id: s.id, name: s.name, rules: s.rules }

		let u: TradeStrategy[] = usedTags.slice(); // copies array
		let un: TradeStrategy[] = unusedTags.slice();

		const u_index = u.findIndex((t) => t.id === s.id);
		const un_index = un.findIndex((t) => t.id === s.id);

		if (u_index > -1) {
			u = [...u.slice(0, u_index), s2, ...u.slice(u_index + 1)];
			setUsedTags(u);
		}
		else {
			if (un_index > -1) {
				un = [...un.slice(0, un_index), s2, ...un.slice(un_index + 1)];
			}
			else {
				un = [...un, s];
			}
			setUnusedTags(un);
		}
		props.updatestrategytags(u, un);
		//alert("USED: " + JSON.stringify(u) + " UNUSED: " + JSON.stringify(un));
	}

	const notifyStrategyChanges = (s: Strategy) => {
		let s2: TradeStrategy = { id: s.id, name: s.name, rules: s.rules }
		props.notifychanges(s2);
	}
	const deleteStrategy = (id: number) => {
		let un = unusedTags.filter((st) => st.id !== id);
		props.updatestrategytags(usedTags, un);
	}

	return (
		<Box overflowY='auto' mb='8px'>
			<FormLabel htmlFor={id} fontWeight='bold' fontSize='sm' mb='8px'>
				{label}
			</FormLabel>
			<Flex
				direction='row'
				p='10px'
				wrap='wrap'
				bg='transparent'
				//border='1px solid'
				borderColor={borderColor}
				borderRadius='16px'
				_focus={{ borderColor: 'teal.300' }}
				minH='40px'
				h='auto'
				cursor='text'
				{...rest}>
				<InputGroup size='sm'>
					<Select size='sm' borderLeftRadius={'5px'} onChange={addTag} value={""} mb='15px'>
						<option value="">Select tag ...</option>
						{unusedTags.map((tag, index) => (
							<option key={index} value={tag.id}>
								{tag.name}
							</option>
						))}
					</Select>
					<StrategiesModal updateStrategy={updateStrategyChange} notifyChanges={notifyStrategyChanges} deleteStrategy={deleteStrategy} />

				</InputGroup>
				{usedTags.map((tag: any, index: any) => {
					return (
						<Tag
							fontSize='sm'
							fontWeight={'700'}
							h='25px'
							mb='6px'
							me='6px'
							borderRadius='5px'
							variant='solid'
							bg={bgs}
							key={index}>
							<TagLabel w='100%'>{tag.name}</TagLabel>
							<TagCloseButton
								justifySelf='flex-end'
								color='white'
								onClick={() => removeTag(tag)}
							/>
						</Tag>
					);
				})}
			</Flex>
		</Box>
	);
}

export default StrategyTags;
