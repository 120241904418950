// Chakra imports
import { Box, Button, Flex, Icon, Text, useColorModeValue, useToast } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

// Assets
import { MdClose, MdDownloading, MdFileOpen } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export default function FileDropZone(props: { [x: string]: any, broker: string, setdropfile: (arg0: string) => void }) {

	const { setdropfile, broker, ...rest } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const imageColor = useColorModeValue('gray.300', 'gray.500');
	const bg = useColorModeValue('gray.100', 'navy.700');
	const borderColor = useColorModeValue('gray.300', 'navy.400');

	const buttonBg = useColorModeValue('white', 'navy.800');

	const toast = useToast();

	const brand = useColorModeValue('brand.500', 'brand.400');

	const [file, setFile] = useState(null);

	const [filetype, setFiletype] = useState({});
	const [filedesc, setFiledesc] = useState('');

	useEffect(() => {
		switch (broker) {
			case 'metaTrader':
				setFiletype({ 'text/html': [], 'text/htm': [] });
				setFiledesc(".HTML or .HTM ")
				break;
			case 'ninja':
				setFiletype({ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], 'application/vnd.ms-excel': [] });
				setFiledesc(".XLSX ")
				break;
			default:
				setFiletype({ 'text/csv': [] });
				setFiledesc(".CSV ");
		}
		setFile(null);
	}, [broker])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		maxFiles: 1,
		maxSize: 1000 * 1000,
		accept: filetype,
		onDrop: (acceptedFile: any, rejectedFile: any) => {
			if (acceptedFile.length === 1) {
				const file = acceptedFile.map((file: any) => Object.assign(file, {
					key: file.path,
					preview: URL.createObjectURL(file)
				}));
				updateFile(file[0])
			} else {
				toast({ title: 'Not accepted ...', description: "Drop ONE image file under 512kb size", status: 'error', position: 'top', duration: 3000, isClosable: false });
			}
		}
	});

	const updateFile = (file: any) => {
		setFile(file)
		setdropfile(file)
	}

	return (
		<Card h={'100%'} p='0px' mt='20px'>
			{file !== null ? (
				<>
					<Box w="100%" h="100%">
						<Flex justify={'space-between'} align={'center'}>{/*align="center"*/}
							<Text p='20px'>{file.name}</Text>
							<Button me='20px' variant={'outline'} colorScheme={'red'} onClick={() => updateFile(null)} borderRadius={'5px'}>
								<Icon as={MdClose} boxSize={5} />
							</Button>
						</Flex>
					</Box>
				</>
			) : (
				<Flex
					align='center'
					justify='center'
					//bg={bg}
					border='1px dashed'
					borderColor={borderColor}
					borderRadius='16px'
					w='100%'
					h='100%'
					cursor='pointer'
					{...getRootProps({ className: 'dropzone' })}
					{...rest}
				>
					<input {...getInputProps()} />
					<Button h={'200px'} w={'100%'} bg={buttonBg}>
						{isDragActive ? (
							<Flex align="center" position="absolute" mt="0" mb="0" mr="0" ml="0" >
								<Icon as={MdDownloading} me='20px' boxSize={7} />
								<Text fontSize="md">DROP it ...</Text>
							</Flex>
						) : (
							<Flex justify={'center'}>
								<Box >
									<Icon as={MdFileOpen} w='80px' h='80px' mb='20px' color={imageColor} />
									<Flex direction={{ base: 'column', md: 'row' }}>
										<Text
											mb='12px'
											fontSize='md'
											w='100%'
											maxW='100%'
											fontWeight='700'
											color={textColor}
											whiteSpace='pre-wrap'>
											Drop your file here, or{' '}
										</Text>
										<Text as='span' fontSize='md' fontWeight='700' color={brand}>
											click to browse
										</Text>
									</Flex>
									<Flex direction={{ base: 'column', md: 'row' }} justify={'center'}>
										<Text
											mt='20px'
											me='5px'
											fontSize='sm'
											fontWeight='500'
											color='secondaryGray.500'
											white-space='pre-wrap !important'>
											{filedesc} files only.
										</Text>
									</Flex>
								</Box>
							</Flex>
						)}
					</Button>
				</Flex>
			)}
		</Card>
	);
}
