import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react'

import { useEffect, useState } from 'react'
import StatisticBox from 'views/trader/components/display/AccountStatistics';
import IconBox from 'components/icons/IconBox';

// Assets
import { MdArrowUpward, MdOutlineBarChart, MdInsights, MdArrowDownward, MdHorizontalRule } from 'react-icons/md';
import { RiDashboard3Line } from "react-icons/ri";
import { FaPercent } from "react-icons/fa6";
import { MinimalStatistics } from '../../trades/variables/types';
import { currencyformat } from '../../trades/variables/FormatData';

const MinimalStatBarLarge = (props: { loading: boolean, stats: MinimalStatistics }) => {

    //const textColor = useColorModeValue('navy.700', 'white');
    const iconBg = useColorModeValue('secondaryGray.300', 'navy.700');
    const iconColor = useColorModeValue('brand.500', 'white');
    //const [stats, setStats] = useState<StudentStatistics>(props.stats);

    const [pfactorcolor, setPFactorColor] = useState('');
    const [nettcolor, setNettcolor] = useState('');
    const [avgcolor, setAvgcolor] = useState('');
    const [plcolor, setPlcolor] = useState('');
    const [wlcolor, setWlcolor] = useState('');
    const [rvaluecolor, setRvaluecolor] = useState('');

    useEffect(() => {
        setPFactorColor((props.stats.profit_factor > 1 ? 'green.500' : (props.stats.profit_factor > 0.5 ? 'orange.500' : 'red.500')));//: 'secondaryGray.600')
        setNettcolor(props.stats.nett_gainloss > 0 ? 'green.500' : 'red.500');
        setAvgcolor(props.stats.avg_pl > 0 ? 'green.500' : 'red.500');
        setPlcolor(props.stats.pl_ratio >= 1 ? 'green.500' : (props.stats.pl_ratio >= 0.5 ? 'orange.500' : 'red.500'));
        setWlcolor(props.stats.wl_ratio >= 1 ? 'green.500' : (props.stats.wl_ratio >= 0.5 ? 'orange.500' : 'red.500'));
        setRvaluecolor(props.stats.avg_rvalue >= 1 ? 'green.500' : (props.stats.avg_rvalue > 0 ? 'orange.500' : 'red.500'));
    }, [props.stats])

    return (
        <>

            <Flex direction='row'>
                <StatisticBox title={'Trades total'} value={props.stats.trade_count.toFixed()} loading={props.loading} me='20px'
                    detail={
                        <></>
                    }
                    illustration={<IconBox w="50px" h="50px" bg={iconBg}
                        icon={
                            <Icon color={iconColor} as={MdInsights} w="28px" h="28px" />
                        }
                    />}
                />
                <StatisticBox title={'Nett gain/loss'} value={currencyformat(props.stats.nett_gainloss, props.stats.currency)} statcolor={nettcolor} loading={props.loading} me='20px'
                    detail={
                        <></>
                    }
                    illustration={
                        <IconBox w="50px" h="50px" bg={iconBg}
                            icon={
                                <Icon color={nettcolor} as={props.stats.nett_gainloss !== 0 ? (props.stats.nett_gainloss > 0 ? MdArrowUpward : MdArrowDownward) : MdHorizontalRule} w="30px" h="30px" />
                            }
                        />
                    }
                />
                <StatisticBox title={'Average P/L'} value={currencyformat(props.stats.avg_pl, props.stats.currency)} statcolor={avgcolor} loading={props.loading} me='20px'
                    detail={
                        <></>
                    }
                    illustration={
                        <IconBox w="50px" h="50px" bg={iconBg}
                            icon={
                                <Icon color={avgcolor} as={MdOutlineBarChart} w="30px" h="30px" />
                            }
                        />
                    }
                />

                <StatisticBox title={'Average R-Value'} value={props.stats.avg_rvalue.toFixed(2)} loading={props.loading} statcolor={rvaluecolor} me='20px'
                    detail={
                        <></>
                    }
                    illustration={
                        <IconBox w="50px" h="50px" bg={iconBg}
                            icon={
                                <Icon color={avgcolor} as={RiDashboard3Line} w="30px" h="30px" />
                            }
                        />
                    }
                />

                <StatisticBox title={'P/L Ratio'} value={props.stats.pl_ratio.toFixed(2)} statcolor={plcolor} loading={props.loading} me='20px'
                    detail={
                        <></>
                    }
                    illustration={<IconBox w="50px" h="50px" bg={iconBg}
                        icon={
                            <Icon color={plcolor} as={FaPercent} w="25px" h="25px" />
                        }
                    />}
                />
                <StatisticBox title={'W/L Ratio'} value={props.stats.wl_ratio.toFixed(2)} statcolor={wlcolor} loading={props.loading} me='20px'
                    detail={
                        <></>
                    }
                    illustration={<IconBox w="50px" h="50px" bg={iconBg}
                        icon={
                            <Icon color={wlcolor} as={FaPercent} w="25px" h="25px" />
                        }
                    />}
                />
                {/*<StatisticBox title={'Profit Factor'} value={props.stats.profit_factor.toFixed(2)} loading={props.loading} statcolor={pfactorcolor}
                    detail={
                        <></>
                    }
                    illustration={
                        <IconBox w="50px" h="50px" bg={iconBg}
                            icon={
                                <Icon color={pfactorcolor} as={RiDashboard3Line} w="30px" h="30px" />
                            }
                        />
                    }
                />*/}

            </Flex>
        </>

    )
}

export default MinimalStatBarLarge