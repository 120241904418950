import { useDisclosure, Button, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Text, DrawerFooter, Icon, useColorModeValue, Flex, useToast } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { MdOutlineNotificationAdd } from 'react-icons/md';
import { RiMegaphoneLine } from "react-icons/ri";
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';
import MentorMessages from './MentorMessages';
import MenteeMessages from './MenteeMessages';
import { useData } from 'services/data/DataProvider';


const MessageDrawer = () => {

    const { user } = useAuth();
    const {mentormessages} = useData();

    const { isOpen, onOpen, onClose } = useDisclosure()
    const navbarIcon = useColorModeValue('gray.400', 'white');
    const [newmessages, setnewmessages] = useState(false);
    const btnRef = useRef()
    const toast = useToast();

    useEffect(()=>{
        setnewmessages(false);
        for (let m of mentormessages) {
            if (m.seen === false) {
                setnewmessages(true);
                break;
            }
        }
    }, [mentormessages])

    useEffect(() => {
        if (newmessages && user.role !== 'MENTOR') {
            toast({ title: 'You have new mentor notifications ...', description: '', icon: <Icon as={MdOutlineNotificationAdd} boxSize={6} me='20px' />, status: 'info', position: 'top', duration: 3000, isClosable: false })
        }
    }, [newmessages])


    const closeMessages = async () => {
        setnewmessages(false)
        //setselectedmentorship(0);
        onClose();
        await db.mentormessages.toCollection().modify(mess => {
            mess.seen = true;
        })
    }



    return (
        <>
            <Button variant="no-hover" bg="transparent" p="0px" minW="unset" minH="unset" h="25px" w="max-content" onClick={onOpen}>
                <Icon me="10px" h="25px" w="25px" color={newmessages&& user.role !== 'MENTOR' ? 'red.500' : navbarIcon} as={newmessages && user.role !== 'MENTOR' ? MdOutlineNotificationAdd : RiMegaphoneLine} />
            </Button>
            <Drawer isOpen={isOpen} placement='right' onClose={closeMessages} size={'lg'} finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        <Flex direction={'row'} align={'center'}>
                            <Icon boxSize={7} color={navbarIcon} as={RiMegaphoneLine} me='15px' />
                            <Text>Mentor notifications</Text>
                            <Text></Text>
                        </Flex>
                    </DrawerHeader>
                    <DrawerBody>
                        <Flex direction="column" w="100%" maxW={'100%'} boxSizing="border-box">
                            <Flex direction="column" w="100%">
                                {user.role === 'MENTOR' ? (
                                    <>
                                        <MentorMessages />
                                    </>
                                ) : (
                                    <>
                                        <MenteeMessages />
                                    </>
                                )
                                }
                            </Flex>
                        </Flex>
                    </DrawerBody>

                    <DrawerFooter>

                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default MessageDrawer