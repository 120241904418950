// Chakra imports
import { Flex, Box, FormLabel, Tag, TagCloseButton, TagLabel, useColorModeValue, Select, InputGroup, Heading } from '@chakra-ui/react';
// Custom components
import { ChangeEvent, useEffect, useState } from 'react';
import { Mistake } from 'views/trader/trades/variables/types';

function MentorMistakeTags(props: { label?: string, id?: string, [x: string]: any, usedMistakeTags: { name: string; id: number }[], }) {

	const { label, id, usedMistakeTags, ...rest } = props;


	const [usedTags, setUsedTags] = useState(props.usedMistakeTags);

	let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
	const headcol = useColorModeValue('gray.400', 'gray.500');

	let bgm = useColorModeValue('red.500', 'red.700');

	return (
		<Box overflowY='auto' mb='8px'>
			<FormLabel htmlFor={id} fontWeight='bold' fontSize='sm' mb='8px'>
				{label}
			</FormLabel>
			<Flex
				direction='row'
				p='10px'
				wrap='wrap'
				bg='transparent'
				border='1px solid'
				borderColor={borderColor}
				borderRadius='16px'
				_focus={{ borderColor: 'teal.300' }}
				minH='40px'
				h='auto'
				cursor='text'
				{...rest}>
				{!usedTags.length &&
					<Heading size={'xs'} color={headcol}>No mistakes tagged</Heading>
				}
				{usedTags.map((tag, index) => {
					return (
						<Tag
							fontSize='sm'
							fontWeight={'700'}
							h='25px'
							mb='6px'
							me='6px'
							borderRadius='5px'
							variant='solid'
							bg={bgm}
							key={index}>
							<TagLabel w='100%'>{tag.name}</TagLabel>
						</Tag>
					);
				})}
			</Flex>
		</Box>
	);
}

export default MentorMistakeTags;
