import { Flex, FormLabel, Select, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { ChangeEvent, useEffect, useState } from 'react'
import { db } from 'services/db'
import { Account, AccountGroup, drawdownChartData, equityChartData, profitWinChartData, Trade } from 'views/trader/trades/variables/types'
import { drawdownValues, equityChartValues, profitFactorWinValues } from '../../variables/DashChartData'
import DrawdownChart from '../charts/DrawdownChart'
import EquityChart from '../charts/EquityChart'
import ProfitFactorWinChart from '../charts/ProfitFactorWinChart'
import TimeStats from './TimeStats'
import EquityModal from '../modals/EquityModal'
import ProfitFactorWinModal from '../modals/ProfitFactorWinModal'
import DrawdownModal from '../modals/DrawdownModal'
import { useData } from 'services/data/DataProvider'

const DashCharts = () => {

    const { periodtrades, defaultaccount, defaultgroup, groupAccounts, defaultcurrency } = useData();
    
    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const [chartType, setChartType] = useState(localStorage.getItem("chart") ? localStorage.getItem("chart") : "time");

    const [equityData, setEquityData] = useState<equityChartData>({ color: "", initBalance: 0, equityChange: 0, series: [{ name: "", data: [], color: "", type: "" }], categories: [] });
    const [profitWinData, setProfitWinData] = useState<profitWinChartData>({ labels: [], categories: [], series: [{ name: '', data: [], color: '', type: '' }] });
    const [drawdownData, setDrawdownData] = useState<drawdownChartData>({ axis: [], series: [{ name: '', data: [], color: '', type: '' }] });


    const changeChart = (e: ChangeEvent<HTMLSelectElement>) => {
        setChartType(e.target.value);
        localStorage.setItem("chart", e.target.value);
    }

    useEffect(() => {
        periodtrades.sort((a: Trade, b: Trade) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()); // sort first to last here
        updateVariables();
    }, [periodtrades])

    const updateVariables = async () => {
        if(defaultaccount){
            calculateChartData(periodtrades, [defaultaccount]);
        }
        else{
            calculateChartData(periodtrades, groupAccounts);
        }
        
        /*if (props.defaultAccount.accountid > 0) {
            calculateChartData(props.trades, [props.defaultAccount]);
        }
        else {
            await db.accounts.where({ groupid: props.defaultGroup.groupid }).toArray((accs) => {
                calculateChartData(props.trades, accs);
            })
        }*/
    }

    const calculateChartData = (trades: Trade[], accounts: Account[]) => {
        equityChartValues(trades, accounts, defaultaccount, defaultgroup, defaultcurrency).then((d) => {
            setEquityData(d);
        });
        profitFactorWinValues(trades, defaultcurrency).then((d) => {
            setProfitWinData(d);
        })
        drawdownValues(trades, accounts, defaultcurrency).then((d) => {
            setDrawdownData(d);
        })
    }

    return (
        <>
            {defaultgroup &&
                <Card borderColor={bdColor} borderWidth='1px' h='100%' minH={'500px'} bg='transparent'>
                    <Flex direction={'row'} justify={'space-between'} mb='20px'>
                        <FormLabel fontWeight='bold' fontSize='md' mb='8px'>Charts</FormLabel>
                        <Flex direction={'row'}>
                            <Select size={'sm'} w='150px' borderRadius={'5px'} defaultValue={chartType} onChange={changeChart}>
                                <option value='time'>Time Analytics</option>
                                <option value='equity'>Equity Curve</option>
                                <option value='pfwin'>Profit factor Win%</option>
                                <option value='ddown'>Drawdown</option>
                            </Select>
                            <EquityModal equityChartData={equityData} hidden={chartType !== "equity"} />
                            <ProfitFactorWinModal profitWinData={profitWinData} hidden={chartType !== "pfwin"} />
                            <DrawdownModal drawdownData={drawdownData} hidden={chartType !== "ddown"} />
                        </Flex>
                    </Flex>

                    <Card h='100%' w='100%' p='0px' hidden={chartType !== "equity"} bg='transparent'>
                        <EquityChart equityChartData={equityData} hidden={chartType !== "equity"} showtoolbar={true} height={'95%'} />
                    </Card>
                    <Card h='100%' p='0px' hidden={chartType !== "pfwin"} bg='transparent'>
                        <ProfitFactorWinChart profitWinData={profitWinData} hidden={chartType !== "pfwin"} showtoolbar={true} height={'95%'} />
                    </Card>
                    <Card h='100%' p='0px' hidden={chartType !== "ddown"} bg='transparent'>
                        <DrawdownChart drawdownData={drawdownData} hidden={chartType !== "ddown"} showtoolbar={true} height={'95%'} />
                    </Card>
                    <TimeStats hidden={chartType !== "time"} />
                </Card>
            }
        </>
    )
}

export default DashCharts