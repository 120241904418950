/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Badge,
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import PricingLayout from 'layouts/auth/variants/Pricing';
import PackPricing from './components/PackPricing';
import FeaturesTableData from './components/FeaturesTableData';
import FeaturesTable from './components/FeaturesTable';
import { useState } from 'react';
import FadeInSection from 'layouts/landing/sections/FadeInSection';

function Pricing() {
  const [activeButton, setActiveButton] = useState({
    monthly: true,
    yearly: false,
  });

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <PricingLayout contentTop={{ base: '60px', md: '8vh' }} contentBottom={{ base: '50px', lg: 'auto' }}>
      <Flex direction="column" alignSelf="center" justifySelf="center" overflow="hidden">
        <Flex direction="column" textAlign="center" justifyContent="center" align="center" mb="38px">
          <Text zIndex="1" fontSize="35px" color="white" fontWeight="700" lineHeight="52px">
            Flexible pricing for your trading journey
          </Text>
          <Text zIndex="1" fontSize="md" color="white" fontWeight="normal" mt="10px" mb="26px" maxW="400px">
            See our pricing plans and features table below
          </Text>
          <Flex mb={{ base: '20px', '2xl': '40px' }} zIndex="2" bg="brand.900" borderRadius="60px" p="6px">
            <Button variant="no-hover" w="135px" h="40px" fontSize="xs" color={activeButton.monthly ? 'brand.500' : 'white'} bg={activeButton.monthly ? 'gray.200' : 'transparent'} onClick={() => setActiveButton({ monthly: true, yearly: false })} borderRadius="60px">
              MONTHLY
            </Button>
            <Button variant="no-hover" w="135px" h="40px" fontSize="xs" color={activeButton.yearly ? 'brand.500' : 'white'} bg={activeButton.yearly ? 'gray.200' : 'transparent'} onClick={() => setActiveButton({ monthly: false, yearly: true })} borderRadius="60px">
              YEARLY
            </Button>
          </Flex>
          <Stack direction={{ sm: 'column', lg: 'row' }} alignItems="flex-end" spacing="20px" mt="40px" mb="100px">
            <FadeInSection>
              <PackPricing title="FREE" desc="Let's start journaling" button="Sign Up FREE"
                price={
                  <Text textAlign="start" w="100%" color={textColor} fontSize="35px" fontWeight="bold">
                    {activeButton.monthly ? '$0' : '$0'}
                    <Text as="span" color="secondaryGray.600" fontSize="35px" fontWeight="bold">
                      {activeButton.monthly ? '/mo' : '/yr'}
                    </Text>
                  </Text>
                }
                details="(Yes, that's ZERO!)"
                monthly ={activeButton.monthly}
                benefits={[
                  '1 trading account',
                  '1 trading group',
                  '1 journal entry per week',
                  '25 trades per week',
                  'No Mentoring access',
                  'See other features below ...',

                ]}
              />
              <></>
            </FadeInSection>
            <FadeInSection>
              <PackPricing title="Novice trader" desc="You're getting better" button="Sign Up FREE"
                price={
                  <Text textAlign="start" w="max-content" color={textColor} fontSize="35px" fontWeight="bold">
                    {activeButton.monthly ? '$12.99' : '$139.99'}
                    <Text as="span" color="secondaryGray.600" fontSize="35px" fontWeight="bold">
                      {activeButton.monthly ? '/mo' : '/yr'}
                    </Text>
                  </Text>
                }
                details={activeButton.monthly ? '(Per trader)' : '(1 month is FREE)'}
                monthly ={activeButton.monthly}
                benefits={[
                  '5 trading accounts',
                  '2 trading groups',
                  '2 journal entries per week',
                  '250 trades per week',
                  'Mentoring access',
                  'See other features below ...',
                ]}
              />
              <></>
            </FadeInSection>
            <FadeInSection>
              <PackPricing title="PRO trader" desc="Now you're cruising" button="Sign Up FREE" highlighted={true}
                price={
                  <Text textAlign="start" w="max-content" color={textColor} fontSize="35px" fontWeight="bold">
                    {activeButton.monthly ? '$24.99' : '$269.99'}
                    <Text as="span" color="secondaryGray.600" fontSize="35px" fontWeight="bold">
                      {activeButton.monthly ? '/mo' : '/yr'}
                    </Text>
                  </Text>
                }
                details={activeButton.monthly ? '(Per trader)' : '(1 month is FREE)'}
                monthly ={activeButton.monthly}
                benefits={[
                  '20 trading accounts',
                  '5 trading groups',
                  '7 journal entries per week',
                  '1500 trades per week',
                  'Mentoring access',
                  'See other features below ...',
                ]}
              />
              <></>
            </FadeInSection>
            <FadeInSection>
              <PackPricing title="Mentor" desc="Helping others and paying it forward" button="Sign Up FREE"
                price={
                  <Text textAlign="start" w="max-content" color={textColor} fontSize="35px" fontWeight="bold">
                    {activeButton.monthly ? '$39.99' : '$438.99'}
                    <Text as="span" color="secondaryGray.600" fontSize="35px" fontWeight="bold">
                      {activeButton.monthly ? '/mo' : '/yr'}
                    </Text>
                  </Text>
                }
                details={activeButton.monthly ? '(Per trader)' : '(1 month is FREE)'}
                monthly ={activeButton.monthly}
                benefits={[
                  'Unlimited trading accounts',
                  'Unlimited trading groups',
                  'Unlimited journal entries per week',
                  'Unlimited trades per week',
                  'Mentor other traders',
                  'See other features below ...',
                ]}
              /><></>
            </FadeInSection>
          </Stack>
          <FadeInSection>
            <Flex direction="column" mb="100px" justify="center" align="center">
              <FeaturesTable tableData={FeaturesTableData} />
            </Flex>
            <></>
          </FadeInSection>
        </Flex>
      </Flex>
    </PricingLayout>
  );
}

export default Pricing;
