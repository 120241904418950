import { db } from "services/db";

export async function SubscriptionLevelCheck(user_id: number, user_role: string): Promise<string> {

    return new Promise(async (resolve, reject) => {

        await db.mentors.where({ userid: user_id }).count().then(async (mentors) => {

            await db.accounts.where({ userid: user_id }).count().then(async (accs) => {
                await db.accountgroups.where({ userid: user_id }).count().then((grps) => {
                    switch (user_role) {
                        case 'FREE':
                            if (accs > 1 || grps > 1) {
                                let ret = toastwarning(user_role, accs, grps, 0)
                                resolve(ret);
                            }
                            else {
                                resolve("");
                            }
                            break;
                        case 'NOVICE':
                            if (accs > 5 || grps > 2 || mentors > 1) {
                                let ret = toastwarning(user_role, accs, grps, mentors)
                                resolve(ret);
                            }
                            else {
                                resolve("");
                            }
                            break;
                        case 'PRO':
                            if (accs > 20 || grps > 5 || mentors > 10) {
                                let ret = toastwarning(user_role, accs, grps, mentors)
                                resolve(ret);
                            }
                            else {
                                resolve("");
                            }
                            break;
                        case 'ADMIN':
                        case 'MENTOR':
                            resolve("");
                            break;
                    }
                })
            })
        })
    });


}

function toastwarning(user_role: string, accs: number, grps: number, ments: number) {
    let title = "";
    let a_to_delete = "";
    let g_to_delete = "";
    let m_to_delete = "";
    let endtxt = "";
    switch (user_role) {
        case 'FREE':
            title = "\n" + user_role + " subscription limits reached.\n\nTo continue uploading new trades, please:\n";
            //m_to_delete = (ments > 0 ? "\nDELETE - " + ments + " mentorship(s)" : "");
            a_to_delete = (accs > 1 ? "\nDELETE - " + (accs - 1) + " trading account(s)" : "");
            g_to_delete = (grps > 1 ? "\nDELETE - " + (grps - 1) + " trading group(s)" : "");
            endtxt = grps > 1 ? "\n\nNOTE: you can move accounts between groups" : "";
            break;
        case 'NOVICE':
            title = "\n" + user_role + " subscription limits reached.\n\nTo continue uploading new trades, please:\n";
            m_to_delete = (ments > 1 ? "\nDELETE - " + (ments - 1) + " mentorship(s)" : "");
            a_to_delete = (accs > 5 ? "\nDELETE - " + (accs - 5) + " trading account(s)" : "");
            g_to_delete = (grps > 2 ? "\nDELETE - " + (grps - 2) + " trading group(s)" : "");
            endtxt = grps > 2 ? "\n\nNOTE: you can move accounts between groups" : "";
            break;
        case 'PRO':
            title = "\n" + user_role + " subscription limits reached.\n\nTo continue uploading new trades, please:\n";
            m_to_delete = (ments > 10 ? "\nDELETE - " + (ments - 10) + " mentorship(s)" : "");
            a_to_delete = (accs > 20 ? "\nDELETE - " + (accs - 20) + " trading account(s)" : "");
            g_to_delete = (grps > 5 ? "\nDELETE - " + (grps - 5) + " trading group(s)" : "");
            endtxt = grps - 5 > 0 ? "\n\nNOTE: you can move accounts between groups" : "";
            break;
    }

    return title + m_to_delete + a_to_delete + g_to_delete + endtxt;
}