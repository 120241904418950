import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import TraderLayout from './layouts/trader';
import { AuthProvider } from 'services/auth/AuthProvider';

import {
  ChakraProvider
} from '@chakra-ui/react';
import initialTheme from './theme/theme';
import { useEffect, useState } from 'react';
import { AuthInterceptor } from 'services/api';
import SplashScreen from 'components/splash/SplashScreen';
import PrivateRoutes from 'services/auth/PrivateRoutes';
import { useScript } from '@uidotdev/usehooks';
import { LoadingProvider } from 'services/loading/LoadingProvider';
import { DataProvider } from 'services/data/DataProvider';

// Chakra imports

const App = () => {

  useScript('https://app.lemonsqueezy.com/js/lemon.js');

  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000);
  }, []);

  return (
    <>
      <SplashScreen loading={loading} />
      <AuthProvider>
        <AuthInterceptor>
          <LoadingProvider>
            <DataProvider>
              <ChakraProvider theme={currentTheme}>
                <Routes>
                  <Route path="home/*" element={<AuthLayout />} />
                  <Route path="auth/*" element={<AuthLayout />} />
                  <Route path="trader/*" element={<PrivateRoutes><TraderLayout theme={currentTheme} setTheme={setCurrentTheme} /></PrivateRoutes>} />
                  <Route path="/*" element={<Navigate to="/home" replace />} />
                </Routes>
              </ChakraProvider>
            </DataProvider>
          </LoadingProvider>
        </AuthInterceptor>
      </AuthProvider>
    </>
  );
}

export default App;
