// Chakra imports
import { Box, Flex, Text, Badge, Icon, DarkMode } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsArrowsAngleExpand } from 'react-icons/bs';
import SideBarChart from 'views/trader/components/charts/sidebar/SideBarChart';
// Custom components

import { currencyformat } from 'views/trader/trades/variables/FormatData';
import { Account, AccountGroup, Trade } from 'views/trader/trades/variables/types';
import { useData } from 'services/data/DataProvider';

const SidebarCard = (props: { mini: boolean; hovered: boolean }) => {

  const { mini, hovered } = props;

  const { periodtrades, defaultgroup, defaultaccount, groupAccounts } = useData();

  const [accname, setAccname] = useState('NO ACCOUNTS');
  const [grpname, setGrpname] = useState('NO GROUPS');
  const [balanceStr, setBalanceStr] = useState('0.00');
  const [equityStr, setEquityStr] = useState('0.00');
  const [equityChg, setEquityChg] = useState(0);
  const [equityChgStr, setEquityChgStr] = useState('0%');
  const [balcolor, setBalcolor] = useState('white');

  const [chartdata, setChartdata] = useState<{ trades: Trade[], accounts: Account[] }>({ trades: [], accounts: [] });


  useEffect(() => {

    periodtrades.sort((a: Trade, b: Trade) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()); // sort from most recent first

    setGrpname(defaultgroup ? defaultgroup.groupname : "NO GROUP");
    setAccname(defaultaccount ? defaultaccount.accname : (defaultgroup ? 'ALL ACCOUNTS' : 'NO ACCOUNTS'));
    if (defaultaccount) {
      updateAccountStats(periodtrades, defaultaccount);
      setChartdata({ trades: periodtrades, accounts: [defaultaccount] });
    }
    else if (defaultgroup) {
      updateGroupStats(periodtrades, groupAccounts, defaultgroup);
      setChartdata({ trades: periodtrades, accounts: groupAccounts });
    }

  }, [periodtrades, defaultgroup, groupAccounts])

  const updateAccountStats = (trades: Trade[], acc: Account) => {

    let equity: number = acc.init_balance;

    //add/minus adjustments here
    for (let a of acc.adjustments) {
      equity += a.amount;
    }

    for (let tr of trades) {
      if (tr.accountid === acc.accountid) {
        let ex = (tr.currency !== acc.currency ? tr.exchange : 1);
        equity += (tr.nett / ex);
      }
    }
    setBalanceStr(currencyformat(equity, acc.currency));
    let chg = ((equity - acc.init_balance) / acc.init_balance) * 100
    setEquityChg(chg);
    setEquityStr(currencyformat((equity - acc.init_balance), acc.currency));
    setBalcolor((equity - acc.init_balance) > 0 ? 'green.500' : 'red.500')
    let chgstr = equityChangeSigned(chg);
    setEquityChgStr(chgstr);
  }

  const updateGroupStats = (trades: Trade[], accounts: Account[], group: AccountGroup) => {

    if (accounts.length) {
      let equity: number = 0;
      let init_balance: number = 0;

      for (let ac of accounts) {
        equity += ac.init_balance;
        init_balance += ac.init_balance;
        //add/minus adjustments here
        for (let a of ac.adjustments) {
          equity += a.amount;
        }
      }

      for (let tr of trades) {
        let ex = (tr.currency !== group.currency ? tr.exchange : 1);
        equity += (tr.nett / ex);
      }

      //setEquityAmt(equity);
      setBalanceStr(currencyformat(equity, group.currency));
      let chg = ((equity - init_balance) / init_balance) * 100
      setEquityChg(chg);
      setEquityStr(currencyformat((equity - init_balance), group.currency));
      setBalcolor((equity - init_balance) > 0 ? 'green.500' : 'red.500')
      let chgstr = equityChangeSigned(chg);
      setEquityChgStr(chgstr);
    }
    else {
      setBalanceStr("0.00");
      setAccname("NO ACCOUNTS");
      setEquityChg(0);
      setEquityChgStr("0.00%")
      setEquityStr('0.00')
      setBalcolor('white')
    }

  }

  const equityChangeSigned = (equitychg: number) => {
    if (equitychg > 0) {
      return "+" + equitychg.toFixed(2) + "%";
    }
    else {
      return equitychg.toFixed(2) + "%";
    }
  }

  return (
    <Flex justify="center" direction="column" align="center" bgGradient="linear(to-b, brand.400, brand.600)" borderRadius="20px" me="20px" position="relative">
      <Icon display={mini === true && hovered === false ? 'block' : 'none'} h="26px" w="26px" my="100px" mx="20px" color="white" as={BsArrowsAngleExpand} />
      <Flex direction="column" mb="12px" align="center" justify="center" px="15px" pt="30px"
        display={
          mini === false
            ? 'block'
            : mini === true && hovered === true
              ? 'block'
              : 'none'
        }
      >

        <DarkMode >
          <Badge colorScheme="gray" color="white" size="lg" maxW="max-content" mx="auto" borderRadius="58px" mb='20px' hidden={grpname === ''}
            display={
              mini === false
                ? 'block'
                : mini === true && hovered === true
                  ? 'block'
                  : 'none'
            }
          >
            {grpname}
          </Badge>
        </DarkMode>
        <DarkMode>
          <Badge colorScheme="gray" color="white" size="lg" maxW="max-content" mx="auto" borderRadius="58px" mb='20px'
            display={
              mini === false
                ? 'block'
                : mini === true && hovered === true
                  ? 'block'
                  : 'none'
            }
          >
            {accname}
          </Badge>
        </DarkMode>
        <Text fontSize="sm" color="white" px="10px" mb="5px" textAlign="center" fontWeight={'700'}
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
                ? 'block'
                : 'none'
          }
        >
          Balance
        </Text>
        <Text fontSize={{ base: 'lg', xl: '2xl' }} color={'white'} fontWeight="bold" lineHeight="150%" textAlign="center" px="10px" mb='15px'
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
                ? 'block'
                : 'none'
          }
        >
          {balanceStr}
        </Text>

        <Text fontSize="sm" color="white" px="10px" mb="5px" textAlign="center" fontWeight={'700'}
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
                ? 'block'
                : 'none'
          }
        >
          Equity
        </Text>
        <Text fontSize={{ base: 'lg', xl: '2xl' }} color={balcolor} fontWeight="bold" lineHeight="150%" textAlign="center" px="10px" mb='20px'
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
                ? 'block'
                : 'none'
          }
        >
          {equityStr}
        </Text>

        <Badge colorScheme="green" color="white" size="lg" maxW="max-content" mx="auto" borderRadius="58px" bg={equityChg > 0 ? 'green.500' : equityChg === 0 ? '' : 'red.500'}
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
                ? 'block'
                : 'none'
          }
        >
          {equityChgStr}
        </Badge>

        <Box
          h="160px"
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
                ? 'block'
                : 'none'
          }
        >
          <SideBarChart data={chartdata} equitychange={equityChg} />
        </Box>
      </Flex>
    </Flex>
  );
}


export default SidebarCard
