import { Button, Flex, FormLabel, Icon, Input, NumberInput, NumberInputField, Textarea, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/card/Card'
import dayjs from 'dayjs'
import { ChangeEvent, useState } from 'react'
import { MdCheck, MdClose, MdEdit } from 'react-icons/md'
import { AccountAdjustment } from '../../variables/types'

const AdjustmentInput = (props: { adjust: AccountAdjustment, removemadjust: any, updateadjust: any }) => {

    const { adjust, removemadjust, updateadjust } = props;

    const textColor = useColorModeValue('gray.700', 'white');

    const [text, setText] = useState(adjust.reason);
    const [amount, setAmount] = useState(adjust.amount);
    const [date, setDate] = useState(adjust.datetime);

    const [editing, setEditing] = useState((adjust.reason.trim().length < 1));
    const modalColor = useColorModeValue('white', 'gray.700');

    const [amtInValid, setAmtInValid] = useState(adjust.amount === 0);

    const setAdjusttext = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    }
    const editAdjustment = (edit: boolean) => {
        if (amtInValid === false) {
            if (!edit) {
                adjust.reason = text;
                adjust.amount = amount;
                adjust.datetime = date;
                updateadjust(adjust);
            }
            setEditing(edit);
        }
    }
    const updateAmount = (amt: number) => {
        setAmtInValid(amt === 0);
        setAmount(amt);
    }
    const updatedate = (e: ChangeEvent<HTMLInputElement>) => {
        setDate(dayjs(e.target.value).format('YYYY-MM-DD HH:mm'));
    }
    function ishidden() {
        return text.trim() === "" || !amount || !editing;
    }

    return (
        <>
            <Card w='100%' bg={modalColor} borderWidth={'1px'} mb={'10px'}>
                <Flex mb='10px'>
                    <FormLabel fontWeight='bold' fontSize='sm' mb='8px' hidden={editing}>{date}</FormLabel>
                    <Input placeholder='Select Date and Time' size='sm' type='datetime-local' color={textColor} hidden={!editing} defaultValue={date} onChange={(d) => updatedate(d)} />
                </Flex>
                <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'}>
                    <Flex>
                        <Textarea isDisabled={!editing} autoFocus={true} minW={'300px'} size={'md'} placeholder="Enter reason ..." color={textColor} onChange={setAdjusttext} value={text} onKeyDown={(e) => (e.key === "Enter" ? (text.toString().trim().length > 0 ? editAdjustment(false) : null) : null)}></Textarea>
                    </Flex>
                    <Flex mt={{ base: '10px', md: '0px' }} justify={'center'} direction={'column'}>

                        <NumberInput isDisabled={!editing} defaultValue={adjust.amount} allowMouseWheel={true} onChange={(valueString) => updateAmount(valueString ? Number(valueString) : 0)} step={0.01} isInvalid={amtInValid} >
                            <NumberInputField color={textColor} readOnly={!editing} />
                        </NumberInput>

                        <Flex direction={'row'} justify={'end'} mt='10px' align={'end'}>
                            
                            <Button variant={'outline'} size={'sm'} onClick={() => { editAdjustment(true) }} borderRadius='5px' me='5px' hidden={editing}>
                                <Icon as={MdEdit} boxSize={5} me='10px' />Edit
                            </Button>
                            <Button variant={'outline'} colorScheme={'blue'} size={'sm'} onClick={() => { editAdjustment(false) }} borderRadius='5px' ms='5px' hidden={ishidden()} disabled={text.toString().trim().length < 1 || amtInValid}>
                                <Icon as={MdCheck} boxSize={5} me='10px' />Save
                            </Button>
                            <Button variant={'outline'} colorScheme={'red'} size={'sm'} onClick={() => { removemadjust(adjust.id) }} borderRadius='5px' ms='5px'>
                                <Icon as={MdClose} boxSize={5} me='10px' />Delete
                            </Button>

                        </Flex>
                    </Flex>
                </Flex>



                {/*<Grid templateRows={{ base: 'repeat(2, 1fr)', md: 'repeat(1, 1fr)' }} templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={4}>
                    <GridItem colSpan={{ base: 1, md: 2 }} >
                        <Text hidden={editing}>{text}</Text>
                        <Textarea autoFocus={true} size={'md'} placeholder="Enter reason ..." color={textColor} onChange={setAdjusttext} value={text} hidden={!editing} onKeyDown={(e) => (e.key === "Enter" ? (text.toString().trim().length > 0 ? editAdjustment(false) : null) : null)}></Textarea>
                    </GridItem>
                    <GridItem >
                        <NumberInput defaultValue={adjust.amount} allowMouseWheel={true} onChange={(valueString) => updateAmount(valueString ? Number(valueString) : 0)} step={0.01} isInvalid={amtInValid}>
                            <NumberInputField color={textColor} readOnly={!editing} />
                        </NumberInput>
                    </GridItem>
                </Grid>*/}

            </Card>

        </>



    )
}
export default AdjustmentInput