// Chakra imports
import { Box, Button, Flex, Icon, Text, useColorModeValue, Image, useToast } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';


// Assets
import { MdDownloading, MdScreenshotMonitor } from 'react-icons/md';
import { useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import ImageDrawer from '../modals/ImageDrawer';

export default function ImageDropzone(props: { [x: string]: any, imagefile: string, setimagefile: (arg0: string) => void }) {

	const { setimagefile, ...rest } = props;
	const imageRef = useRef(null);

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const imageColor = useColorModeValue('gray.300', 'gray.500');
	const bg = useColorModeValue('gray.100', 'navy.700');
	const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');

	const buttonBg = useColorModeValue('white', 'navy.800');

	const toast = useToast();

	const brand = useColorModeValue('brand.500', 'brand.400');

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		maxFiles: 1,
		maxSize: 1024 * 1000,
		accept: {
			'image/jpeg': [],
			'image/png': []
		},
		onDrop: (acceptedFile: any, rejectedFile: any) => {
			if (acceptedFile.length === 1) {
				const file = acceptedFile.map((file: any) => Object.assign(file, {
					key: file.path,
					preview: URL.createObjectURL(file)
				}));
				imageToBase64(file[0])
			} else {
				toast({ title: 'Not accepted ...', description: "Drop ONE image file under 1024kb (1MB) size", status: 'error', position: 'top', duration: 3000, isClosable: false });
			}
		}
	});

	const imageToBase64 = (imageData: File) => {
		const reader = new FileReader();
			reader.onloadend = () => {
				setimagefile(reader.result.toString());
			};
			reader.readAsDataURL(imageData);
	}

	const imageclick = () => {
		imageRef.current.openImage();
	}

	return (
		<Card h={'100%'}  p='0px' mt='20px'>
			{props.imagefile.length ? (
				<>
					<Flex w="100%" h="100%" justify="center" >{/*align="center"*/}
						<Flex onClick={() => imageclick()}>
							<Image src={props.imagefile} w="100%" maxH={'700px'} cursor="pointer" />
						</Flex>
					</Flex>
					<ImageDrawer ref={imageRef} imageurl={props.imagefile}></ImageDrawer>
				</>
			) : (
				<Flex
					align='center'
					justify='center'
					bg={bg}
					border='1px dashed'
					borderColor={borderColor}
					borderRadius='16px'
					w='100%'
					h='100%'
					cursor='pointer'
					{...getRootProps({ className: 'dropzone' })}
					{...rest}
				>
					<input {...getInputProps()} />
					<Button h={'100%'} w={'100%'} bg={buttonBg}>
						{isDragActive ? (
							<Flex align="center" position="absolute" mt="0" mb="0" mr="0" ml="0" >
								<Icon as={MdDownloading} me='20px' boxSize={7} />
								<Text fontSize="md">DROP it ...</Text>
							</Flex>
						) : (
							<Flex justify={'center'}>
								<Box >
									<Icon as={MdScreenshotMonitor} w='80px' h='80px' mb='20px' color={imageColor} mt='20px'/>
									<Flex direction={{ base: 'column', md: 'row' }}>
										<Text
											mb='12px'
											fontSize='md'
											w='100%'
											maxW='100%'
											fontWeight='700'
											color={textColor}
											whiteSpace='pre-wrap'>
											Drop your screenshot here, or{' '}
										</Text>
										<Text as='span' fontSize='md' fontWeight='700' color={brand}>
											click to browse
										</Text>
									</Flex>
									<Flex direction={{ base: 'column', md: 'row' }} justify={'center'} mb='20px'>
										<Text
											mt='20px'
											me='5px'
											fontSize='sm'
											fontWeight='500'
											color='secondaryGray.500'
											white-space='pre-wrap !important'>
											Image file only.
										</Text>
										<Text
											mt={{ base: '10px', md: '20px' }}
											fontSize='sm'
											fontWeight='500'
											color='secondaryGray.500'
											white-space='pre-wrap !important'>
											Max 1mb.
										</Text>
									</Flex>
								</Box>
							</Flex>
						)}
					</Button>
				</Flex>
			)}
		</Card>
	);
}
