import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react'
import { Trade } from 'views/trader/trades/variables/types';
import { currencyformat } from 'views/trader/trades/variables/FormatData';
import { DayChartValues } from '../../variables/DashModalChartData';
import { Box, Flex, Spinner, useColorModeValue } from '@chakra-ui/react';

const DashCalendarModalChart = (props: { trades: Trade[], currency: string, opentrade: any }) => {

  const { opentrade, ...rest } = props;

  const [hidden, sethidden] = useState(true);
  const spinnerColor = useColorModeValue('gray.300', 'brand.300');

  useEffect(() => {
    if (props.trades.length) {
      updateVariables();
    }
  }, [props.trades])

  const updateVariables = () => {
    try {
      DayChartValues(props.trades, props.currency).then((res) => {
        options.xaxis.categories = res.axis;
        setVariables({ options: options, series: [{ data: res.data }] });
      })
      setTimeout(() => {
        sethidden(false)
      }, 100);
    } catch (e) { }
  }

  const options: ApexOptions = {
    chart: {
      toolbar: { show: false },
      events: {
        click: function (event, chartContext, config) {
          let tr = props.trades[config.dataPointIndex];
          if(tr){
            opentrade(tr.tradeid);
          }
        }
      }
    },
    annotations: {
      yaxis: [{
        y: 0,
        strokeDashArray: 4,
        borderColor: '#848287',
        fillColor: '#848287',
        opacity: 0.3,
        offsetX: 0,
        offsetY: 0
      }]
    },
    plotOptions: {
      bar: {
        barHeight: '80%',
        distributed: false,
        horizontal: false,
        borderRadius: 4,
        dataLabels: {
          position: 'bottom'
        },
        colors: { ranges: [{ from: 0, to: 1000000, color: '#01B574' }, { from: -1000000, to: 0, color: '#EE5D50' }] }
      }
    },
    colors: ['#dc748b', '#d8637c', '#d4526e', '#be4963'],
    grid: { show: false },
    legend: { show: false },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.15,
        opacityFrom: 0.9,
        opacityTo: 1
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          colors: ['#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287'],
          fontSize: '12px',
          fontFamily: 'DM Sans',
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      }
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: ['#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287'],
          fontSize: '12px',
          fontWeight: 400,
        },
        formatter: (val) => { return val.toFixed(2) },
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      }
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: true
      },
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          return currencyformat(value, props.currency)
        },
        title: {
          formatter: () => "Nett: ",
        },
      }
    }
  }

  const [variables, setVariables] = useState({ options: options, series: [] });

  return (
    <>
      <Box h='100%'>
        <Flex hidden={!hidden} justify={'center'} align={'center'} >
          <Spinner color={spinnerColor} size='sm'></Spinner>
        </Flex>
        <Chart
          hidden={hidden}
          type='bar'
          options={variables.options}
          width="100%"
          height="300px"
          series={variables.series}
        />
      </Box>

    </>
  )
}

export default DashCalendarModalChart