import { Flex, Button, Card, FormControl, FormLabel, FormErrorMessage, Input, Icon, useColorModeValue, Box, IconButton, useToast, Badge } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { MdAdd, MdClose } from 'react-icons/md';
import api from 'services/api';
import { useAuth } from 'services/auth/AuthProvider';
import { useData } from 'services/data/DataProvider';
import { db } from 'services/db';
import { updateStratsAndMistakes } from 'services/dbservice';
import JornoQuill from 'views/trader/components/inputs/JornoQuill';
import ImageDropzone from 'views/trader/trades/components/fields/ImageDropzone';
import NotesTemplates from 'views/trader/trades/components/fields/NotesTemplates';
import StrategyRuleInput from 'views/trader/trades/components/fields/StrategyRuleInput';
import StudentShare from 'views/trader/trades/components/fields/StudentShare';
import { newStrategyRule, Strategy, StrategyRule } from 'views/trader/trades/variables/types';

const StrategyView = (props: { strategy: Strategy, selectStrategy: any, setStrategy: any, tempreset: string }) => {

    const { strategy, selectStrategy, setStrategy, tempreset } = props;

    const { user, muuid } = useAuth();
    const {strategies} = useData();

    const textColor = useColorModeValue('gray.700', 'white');

    const [stratname, setstratname] = useState(".");
    const [stratdesc, setstratdesc] = useState("");
    const [rules, setRules] = useState([]);
    const [image, setImage] = useState("");

    const [edited, setEdited] = useState(false);
    const [stratshared, setStratshared] = useState(false);
    const [processingstrat, setProcessingStrat] = useState(false);
    const [addDisable, setAddDisable] = useState(false);
    const [namerror, setnamerror] = useState('');
    //const [tempreset, setTempreset] = useState('');

    const toast = useToast();

    useEffect(() => {
        if (strategy) {
            setnamerror(strategy.id === 0 ? "Name required" : "")
            setstratname(strategy.name);
            setstratdesc(strategy.description);
            setRules(strategy.rules);
            setImage(strategy.base64Image);
            setStratshared(strategy.mentorshare !== 0);
            setEdited(false);
        }
    }, [strategy])

    function validateName(value: string) {
        let error = '';
        let index = strategies.findIndex((s: Strategy) => s.name.trim().toUpperCase() === value.trim().toUpperCase());

        if (index > -1 && strategy.id === 0) {
            error = 'Name already used'
        }
        else if (index > -1 && strategy.id !== strategies[index].id) {
            error = 'Name already used'
        }
        else if (!value.trim().length) {
            error = 'Name required'
        }
        else if (value.length < 3) {
            error = "Minimum 3 characters"
        }
        setnamerror(error);
    }
    function nameinvalid() {
        return namerror !== '';//stratname.trim().length < 1;
    }

    const updateStratname = (e: ChangeEvent<HTMLInputElement>) => {
        if (strategy) {
            validateName(e.target.value);
            setstratname(e.target.value);
            strategy.name = e.target.value.trim();
            setEdited(true);
        }
        else {
            clearStrategy();
        }
    }
    const updateStratDescription = (content: any) => {
        if (strategy) {
            setstratdesc(content.trim());
            strategy.description = content.trim();
            setEdited(true);
        }
        else {
            clearStrategy();
        }
    }
    const clearStrategy = () => {
        setRules([]);
        setstratname("");
        setstratdesc("");
        setImage('');
        setEdited(false);
        //validateName();
    }

    const addRule = async () => {
        let s = newStrategyRule();
        s.id = rules.length ? rules[rules.length - 1].id + 1 : 1;
        s.text = '';
        s.sid = strategy.id;
        strategy.rules = [...rules, s];
        setRules(strategy.rules);
        setEdited(true);
        setAddDisable(true);
    }
    const removeRule = (id: number) => {
        strategy.rules = rules.filter((r) => r.id !== id);
        setRules(strategy.rules);
        setEdited(true);
        setAddDisable(false);
    }
    const setRuleText = (rule: StrategyRule) => {
        const update = rules.map(oldrule => oldrule.id === rule.id ? rule : oldrule)
        setRules(update);
        strategy.rules = update;
        setEdited(true);
        setAddDisable(false);
    }
    const updateimage = async (file: any) => {
        setImage(file);
        strategy.base64Image = file;
        setEdited(true);
    }

    const updateShare = (share: boolean) => {
        setStratshared(share);
        strategy.mentorshare = (share ? strategy.id : 0);
        setEdited(true);
    }

    const removeBlankRules = () => {
        const update = rules.filter((r) => r.text.trim().length > 0)
        setRules(update);
        strategy.rules = update;
    }

    const saveStrategy = () => {

        removeBlankRules();

        if (nameinvalid()) {
            toast({ title: namerror, description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false })
            return;
        }

        setProcessingStrat(true);

        let str = strategy.description.replace(/(<([^>]+)>)/ig, '').trim();
        if (!str.length) {
            strategy.description = "";
        }

        strategy.last_uuid = muuid;
        //strategy.mentorshare = stratshared;
        delete strategy.sync_due;

        let url = (strategy.id > 0 ? 'updatestrategy' : 'savestrategy');

        setTimeout(async () => {
            await api.post('/strategies/' + url, strategy)
                .then(async (res) => {
                    if (res.status === 200) {
                        if (res.data === 0) {
                            toast({ title: 'Oops, error ...', description: "Strategy not saved", status: 'error', position: 'top', duration: 3000, isClosable: false })
                        }
                        else {
                            //res.data.sync_due = false;
                            let init_id = strategy.id;
                            strategy.sync_due = false;
                            strategy.id = res.data;

                            if (user.role === "MENTOR") { // when adding mentor start need to update share after creation to update mentorshare value
                                if (url === 'savestrategy' && stratshared) {
                                    api.post('/strategies/setshared', { id: res.data, share: true }).then((res) => {
                                        if (res.data === true) {
                                            strategy.mentorshare = strategy.id;
                                        }
                                    }).catch((e) => { })
                                }
                            }

                            await db.strategies.put(strategy, strategy.id).then(async () => {
                                toast({ title: 'Strategy saved ...', description: "", status: 'success', position: 'top', duration: 2000, isClosable: false })
                                setEdited(false);

                                if (init_id < 1) {
                                    selectStrategy(strategy.id);
                                    setStrategy(strategy);
                                }
                                else {
                                    await updateStratsAndMistakes([strategy], []);
                                }
                            });
                        }
                    }
                    setProcessingStrat(false);
                }).catch(async function (error) {
                    if (strategy.id > 0) {
                        strategy.sync_due = true;
                        await db.strategies.put(strategy, strategy.id).then(async () => {
                            await updateStratsAndMistakes([strategy], []);
                            toast({ title: 'Strategy saved ...', description: "", status: 'success', position: 'top', duration: 2000, isClosable: false })
                            setEdited(false);
                        });
                    }
                    else {
                        toast({ title: 'Error saving strategy ...', description: "Are you connected to the internet?", status: 'error', position: 'top', duration: 3000, isClosable: false });
                    }
                    setProcessingStrat(false);
                });
        }, 250);
    }

    return (
        <>
            <Flex direction={'column'} w={'100%'}>
                <Flex direction={'column'} >
                    <FormControl isInvalid={nameinvalid()} mb={'20px'}>
                        <Flex justify={'space-between'}>
                            <FormLabel fontWeight='bold' fontSize='sm' >Name</FormLabel>
                            <FormErrorMessage mt='0px' mb='8px'>{namerror}</FormErrorMessage>
                            {(strategy.mentorshare !== 0 && user.role !== "MENTOR") &&
                                <Flex align={'center'} mt='-10px'>
                                    <Badge size={'sm'} variant='outline' borderRadius={'5px'} colorScheme='blue' fontSize={'sm'}>
                                        Copied Strategy
                                    </Badge>
                                </Flex>
                            }
                        </Flex>
                        <Flex>
                            <Input placeholder='Enter name ...' color={textColor} onChange={updateStratname} value={stratname} />
                        </Flex>
                    </FormControl>
                    <FormControl>
                        <Flex justify={'space-between'}>
                            <Flex direction={'row'}>
                                <FormLabel fontWeight='bold' fontSize='sm' mt='10px'>Description </FormLabel>
                                <FormLabel fontWeight='bold' fontSize='sm' mt='10px' color={'gray.400'}>(optional)</FormLabel>
                            </Flex>
                            <NotesTemplates type={'strategy'} notes={stratdesc} setnotes={updateStratDescription} reset={tempreset} mb={'8px'} size={'sm'} />
                            <FormErrorMessage>Description required</FormErrorMessage>
                        </Flex>
                        <JornoQuill content={stratdesc} updatecontent={updateStratDescription} allowimages={false} placeHolder={'Describe your strategy ...'} />
                    </FormControl>
                </Flex>

                <Flex direction={'column'} mt={'20px'}>
                    <Flex direction={'row'} justify={'start'} align={'center'}>
                    <Flex direction={'row'}>
                                <FormLabel fontWeight='bold' fontSize='sm' mt='10px'>Rules </FormLabel>
                                <FormLabel fontWeight='bold' fontSize='sm' mt='10px' color={'gray.400'}>(optional)</FormLabel>
                            </Flex>
                        <Button isDisabled={addDisable} ms='10px' variant={'outline'} size={'sm'} onClick={() => addRule()} borderRadius='5px'>
                            <Icon as={MdAdd} boxSize={5} me={'5px'} />Add Rule
                        </Button>
                    </Flex>
                    <Flex direction={'column'} mt='20px'>
                        {rules.map((srule) => (
                            <StrategyRuleInput key={Math.floor(Math.random() * 10000)} rule={srule} removerule={removeRule} updatetext={setRuleText}></StrategyRuleInput>
                        ))}
                    </Flex>
                </Flex>

                <Box mt={'20px'} mb={'50px'} bg={'transparent'} >
                    <Flex direction={'row'} justify={'space-between'}>
                        <FormLabel fontWeight='bold' fontSize='sm'>Example image {'('}optional{')'}</FormLabel>
                        <IconButton hidden={!image.length} aria-label={'closebtn'} size={'sm'} borderRadius={'5px'} icon={<MdClose />} variant={'outline'} colorScheme={'red'} onClick={() => updateimage('')}></IconButton>
                    </Flex>
                    <ImageDropzone imagefile={image} setimagefile={updateimage} />
                </Box>

                <Flex justify={'end'} mt='20px'>
                    {user.role === 'MENTOR' &&
                        <Flex hidden={!strategy} >
                            <StudentShare checked={stratshared} updateshare={updateShare} label='Share with students' />
                        </Flex>
                    }
                    <Button colorScheme="red" variant={'outline'} isLoading={processingstrat} loadingText="Saving ..." hidden={!edited} borderRadius={'5px'} minW='140px' onClick={saveStrategy}>
                        {strategy && strategy.id > 0 ? 'Save changes' : 'Save'}
                    </Button>
                </Flex>

            </Flex>
        </>
    )
}

export default StrategyView