import dayjs from "dayjs";
import { Execution, Trade } from "./types";
import { db } from "services/db";

export async function ProcessTrade(props: { trade: Trade }) {

    if (props.trade.executions.length) {

        try {
            const tickerDetails = await db.instruments.where("ticker").equalsIgnoreCase(props.trade.executions[0].ticker.trim()).toArray();//InstrumentsList.find((inst) => inst.ticker === props.trade.executions[0].ticker);

            let side = props.trade.executions[0].side.toLowerCase().trim();
            props.trade.side = side;

            //based on first in first out
            const entryexec: any[] = [];
            const exitexec: any[] = [];

            //separate exections into arrays
            let opencount: number = 0;
            let closecount: number = 0;
            let fees: number = 0;

            props.trade.executions.forEach((e: Execution, index: number) => {
                fees += Number(e.fee);
                let csize = Number(e.size);
                let el = { price: Number(e.price), size: csize, index: index };
                e.side === side ? entryexec.push(el) : exitexec.push(el);
                opencount += e.side === side ? csize : 0;
                closecount += e.side !== side ? csize : 0;

            });

            if (closecount > opencount) { // this stops user from closing with greater than remaining size. see below
                closecount = opencount;
            }

            let el: number = props.trade.executions.length;
            let opentime: Date = new Date(props.trade.executions[0].datetime);
            let closetime: Date = new Date(props.trade.executions[el - 1].datetime);

            let point_total: number = 0;
            let profit_total: number = 0;
            let remaining: number = 0;
            let closeprice: number = 0;
            let adjusted = [];

            for (let i = 0; i < exitexec.length; i++) {

                let e = exitexec[i];

                let csize = e.size;
                let cprice = e.price;

                let oprice = entryexec[0].price;
                let osize = entryexec[0].size;

                remaining = osize - csize;

                if (remaining > 0) {
                    entryexec[0].size = remaining;
                }
                else if (remaining < 0) {
                    if (entryexec.length === 1) { // this stops user from closing with greater than remaining size, and reverses the position

                        let fee = props.trade.executions[e.index].fee;
                        fees -= Math.abs(remaining) * fee;
                        fee = (fee > 0 ? fee / csize : 0);

                        csize = entryexec[0].size;
                        props.trade.executions[e.index].size = csize;
                        props.trade.executions[e.index].fee = csize * fee;

                        let newexec: Execution = { // open new reversed trade with remaining size
                            datetime: props.trade.executions[e.index].datetime,
                            instrument: props.trade.executions[e.index].instrument,
                            ticker: props.trade.executions[e.index].ticker,
                            side: props.trade.executions[e.index].side, // reversing position
                            size: Math.abs(remaining),
                            price: props.trade.executions[e.index].price,
                            fee: Math.abs(remaining) * fee,
                            stop: 0,
                            currency: props.trade.executions[e.index].currency,
                            editInstument: false,
                            orderid: ''
                        };
                        adjusted.push(newexec);
                    }
                    else {
                        entryexec.splice(0, 1);
                        exitexec[i].size = Math.abs(remaining);
                        i--;
                    }
                }
                else {
                    entryexec.splice(0, 1);
                }

                point_total += (side === 'buy' ? ((cprice - oprice) * (csize > osize ? osize : csize)) : ((oprice - cprice) * (csize > osize ? osize : csize)));
                closeprice = cprice;
            }

            let closed = (opencount - closecount === 0);


            let ts = tickerDetails[0].ticksize.toString().split('.')
            let dec = ts[1] ? ts[1].length : 0
            point_total = Number(point_total.toFixed(dec))
            profit_total = point_total * tickerDetails[0].pointvalue;

            props.trade.datetime = props.trade.executions[0].datetime;
            props.trade.duration = (closetime.getTime() - opentime.getTime());
            props.trade.ticker = props.trade.executions[0].ticker;
            props.trade.side = props.trade.executions[0].side;
            props.trade.size = opencount;//.toString();
            props.trade.entry_avg = props.trade.executions[0].price;
            props.trade.exit_avg = closeprice;//.toString();
            props.trade.points = point_total;//.toString();
            props.trade.currency = tickerDetails[0].currency;
            props.trade.profitloss = profit_total;
            props.trade.fees = fees;
            props.trade.nett = (profit_total - fees);
            props.trade.closed = closed;

            return adjusted;
        }
        catch (e) { }


    }
    else {
        //props.trade.date = ['Enter executions below', 0];
        props.trade.datetime = dayjs().format('YYYY-MM-DD HH:mm:ss');
        props.trade.duration = 0;
        props.trade.ticker = '?';
        props.trade.currency = 'USD';
        props.trade.side = '?';
        props.trade.size = 0;
        props.trade.entry_avg = 0;
        props.trade.exit_avg = 0;
        props.trade.points = 0;
        props.trade.profitloss = 0;
        props.trade.fees = 0;
        props.trade.nett = 0;
        props.trade.closed = false;
        return false;
    }
}

