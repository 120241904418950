import { MentorProfile } from "../variables/types";
import { useAuth } from "services/auth/AuthProvider";

const useNewMentorProfile = () => {
	const { user } = useAuth();//useContext(AuthContext);
	const newmen: MentorProfile = {
		id: 0,
		userid: (user === null ? 0 : user.id),
		name: "",
		username: user.username,
		description: "",
		avatar: "",
		x_handle: "",
		yt_handle: "",
		ig_handle: "",
		weburl: "",
		s_show: true,
		t_show: true,
		j_show: true,
		listed: false,
		strategies: 0,
		trades: 0,
		students: 0,
		journals: 0,
	}
	return newmen;
};

export default useNewMentorProfile;