import { IconButton } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { RiFlag2Line } from "react-icons/ri";

const Favourite = (props: { selected: boolean, updatefavourite: any }) => {

    const { selected, updatefavourite } = props;
    const [favourite, setFavourite] = useState(selected);

    useEffect(()=>{
        setFavourite(selected);
    }, [selected])

    const updateFavourite = () => {
        setFavourite(!favourite);
        updatefavourite(!favourite);
    }

    return (
        <IconButton
            isRound={true}
            variant={favourite ? 'solid' : 'outline'}
            colorScheme={favourite ? 'cyan' : null}
            aria-label='Done'
            fontSize='20px'
            icon={<RiFlag2Line />}
            onClick={updateFavourite}
        />
    )
}

export default Favourite