
import {
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    InputGroup,
    Input,
    Icon,
    FormControl,
    Flex,
    ModalOverlay,
    FormErrorMessage,
    InputRightElement,
    FormLabel,
    useToast
} from "@chakra-ui/react";

import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

import { Formik, Field } from "formik";
import * as Yup from "yup";

import { useState } from "react";

import { create } from "react-modal-promise";
import { useAuth } from "services/auth/AuthProvider";
import api from "services/api";

const PasswordUpdateDialog = ({ isOpen, onResolve, onReject }) => {

    const textColorSecondary = 'gray.400';
    const [processing, setprocessing] = useState(false);
    const { signout } = useAuth();//useContext(AuthContext);

    const [show, setShow] = useState(false);

    const toast = useToast();

    const handleShowClick = () => setShow(!show);

    const cancelchange = () => {
        onReject("cancel");
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={cancelchange} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Change your password</ModalHeader>
                    <ModalBody>
                        <Formik
                            initialValues={{
                                passwordcurrent: '',
                                passwordnew: ''
                            }}
                            onSubmit={async (values) => {
                                setprocessing(true);
                                setTimeout(async() => {
                                    await api.post('/user/updatepassword', values).then((resp) => {
                                        if (resp.data.message === "true") {                                          
                                            onResolve('');
                                            signout(false);
                                            toast({ title: 'Password changed ...', description: "Sign in with your new password", status: 'success', position: 'top', duration: 3000, isClosable: false })
                                        }
                                        else {
                                            toast({ title: 'Authentication failed', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false })
                                        }
                                    }).catch((err) => {
                                        toast({ title: 'Password update failed', description: "Verify your network connection and try again ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                                        onReject(0)
                                    })
                                    setprocessing(false);
                                }, 1000);
                            }}

                            validationSchema={Yup.object().shape({
                                passwordcurrent: Yup.string().required('Current required').min(10, 'Min 10 characters').max(40, 'Max 40 characters'),
                                passwordnew: Yup.string().required('New required').min(10, 'Min 10 characters').max(40, 'Max 40 characters').test("pw duplicate", "Cannot be matching", (item, testContext) => {
                                    return item.trim() !== testContext.parent.passwordcurrent;
                                }),
                            })}
                        >
                            {({ handleSubmit, errors, touched },) => (
                                <form onSubmit={handleSubmit}>

                                    <FormControl isInvalid={!!errors.passwordcurrent && touched.passwordcurrent}>
                                        <Flex direction={'row'} justify={'space-between'}>
                                            <FormLabel ms='10px' fontWeight='bold' fontSize='sm' mb='8px'>Current password</FormLabel>
                                            <FormErrorMessage mt='0px' mb='7px'>{errors.passwordcurrent}</FormErrorMessage>
                                        </Flex>

                                        <InputGroup size="md">
                                            <Field as={Input} id="passwordcurrent" name="passwordcurrent" type={show ? 'text' : 'password'} fontSize="sm" ms={{ base: '0px', md: '4px' }} placeholder="Min. 10 characters" size="lg" variant="auth" />
                                            <InputRightElement display="flex" alignItems="center" mt="4px">
                                                <Icon color={textColorSecondary} _hover={{ cursor: 'pointer' }} as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={handleShowClick} />
                                            </InputRightElement>
                                        </InputGroup>

                                    </FormControl>
                                    <FormControl isInvalid={!!errors.passwordnew && touched.passwordnew} mt='20px'>
                                        <Flex direction={'row'} justify={'space-between'}>
                                            <FormLabel ms='10px' fontWeight='bold' fontSize='sm' mb='8px'>New password</FormLabel>
                                            <FormErrorMessage mt='0px' mb='7px'>{errors.passwordnew}</FormErrorMessage>
                                        </Flex>

                                        <InputGroup size="md">
                                            <Field as={Input} id="passwordnew" name="passwordnew" type={show ? 'text' : 'password'} fontSize="sm" ms={{ base: '0px', md: '4px' }} placeholder="Min. 10 characters" size="lg" variant="auth" />
                                            <InputRightElement display="flex" alignItems="center" mt="4px">
                                                <Icon color={textColorSecondary} _hover={{ cursor: 'pointer' }} as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={handleShowClick} />
                                            </InputRightElement>
                                        </InputGroup>

                                    </FormControl>
                                    <Flex justify='right'>
                                        <Button mt='25px' mb='15px' colorScheme='red' me={3} onClick={cancelchange}>
                                            Cancel
                                        </Button>
                                        <Button mt='25px' mb='15px' type='submit' colorScheme='blue' ms={3} isLoading={processing} loadingText='Verifying'>
                                            Update
                                        </Button>
                                    </Flex>
                                </form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export const PasswordUpdate = create(PasswordUpdateDialog)