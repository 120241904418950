import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Text, useColorModeValue, Image, Icon, Button } from '@chakra-ui/react'

import reqlight from 'assets/img/jorno/help/request_light.png';
import reqdark from 'assets/img/jorno/help/request_dark.png';
import copylight from 'assets/img/jorno/help/copy_light.png';
import copydark from 'assets/img/jorno/help/copy_dark.png';
import journallight from 'assets/img/jorno/help/journal_light.png';
import journaldark from 'assets/img/jorno/help/journal_dark.png';

import { RiMegaphoneLine } from 'react-icons/ri';
import { MdOutlineNotificationAdd } from 'react-icons/md';

const MenteeHelp = () => {

    const bgcolor = useColorModeValue('white', 'navy.900');
    const accol = useColorModeValue('gray.400', 'gray.500');
    const copyimg = useColorModeValue(copylight, copydark);
    const journalimg = useColorModeValue(journallight, journaldark);
    const reqimg = useColorModeValue(reqlight, reqdark);


    return (

        <Flex direction={'column'}>
            <Text mb='30px' mt='10px'>Here's some guidance on how to use your mentorship:</Text>
            <Accordion allowToggle>

                <AccordionItem>
                    <AccordionButton>
                        <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>Request trade reviews</Text></Flex></Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Text mb='20px'>Mentors cannot see all your trades.</Text>
                        <Text mb='20px'>Only trades that you have chosen to share will be visible. You will need to toggle on 'Request mentor review' when you have completed journaling your trade.</Text>
                        <Text mb='20px'>This can be found next to the save button on the trade record.</Text>
                        <Image src={reqimg} />
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionButton>
                        <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>Mentor feedback</Text></Flex></Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Text mb='20px'>Once reviewed, your mentors comments will be availble on the trade record under your own notes.</Text>
                        <Text mb='20px'>The trade will be marked as <Button variant='outline' colorScheme={'green'} size={'sm'} borderRadius='10px' ms='10px' me='10px'> Reviewed +</Button> wherever listed.</Text>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionButton>
                        <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>Copy mentor strategies</Text></Flex></Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Text mb='20px'>Any strategies your mentors have chosen to share will be made available on your strategies page.</Text>
                        <Text mb='20px'>If you want use a strategy, copy it to your profile and modify if desired (this only modifies your copy of the strategy).</Text>
                        
                        <Image src={copyimg} mb='20px'/>
                        <Text mb='20px'>Once copied you can use it to tags trades in your journal.</Text>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionButton>
                        <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>View mentor journal entries</Text></Flex></Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Text mb='20px'>Mentors can choose to share categorized journal entires with students.</Text>
                        <Text mb='20px'>Any shared journals will appear on your calendar as outline buttons as show below.</Text>
                        <Text mb='20px'>Just click on the calendar journal to view it.</Text>
                        <Image src={journalimg} />
                    </AccordionPanel>
                </AccordionItem>



                <AccordionItem>
                    <AccordionButton>
                        <Box as='span' flex='1' textAlign='left'><Flex align={'center'}><Text fontWeight={'700'} minH={'30px'} color={accol} mt='10px'>Mentor notifications</Text></Flex></Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Text mb='20px'>Once in a mentorship, messages are accesible on the top right icon bar via the <Icon boxSize={5} ms='5px' me='5px' mb='-5px' as={RiMegaphoneLine}></Icon> icon.</Text>
                        <Text mb='20px'>When new messages are available the icon changes to  <Icon boxSize={5} ms='5px' me='5px' mb='-5px' as={MdOutlineNotificationAdd} color={'red.500'}></Icon></Text>
                        <Text mb='20px'>Messages can only be sent by mentors to students (uni-directional).</Text>
                    </AccordionPanel>
                </AccordionItem>


            </Accordion>
        </Flex>

    )
}

export default MenteeHelp