/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Flex } from '@chakra-ui/react';
import { useAuth } from 'services/auth/AuthProvider';
import Mentorpage from './pages/Mentorpage';
import Menteepage from './pages/Menteepage';
import { useEffect } from 'react';
import { SyncAPI } from '../components/syncapi/SyncApi';
import { db } from 'services/db';
import { useLoading } from 'services/loading/LoadingProvider';

export default function Default() {

  const { user, setUser } = useAuth();
  const { loading, setLoading} = useLoading();

  useEffect(() => {
    setLoading(true);
    SyncAPI(user.id).then(async()=>{
      setLoading(false);
      await db.users.get(user.id).then((u) => {
				setUser(u);
			  })
    })
  }, [])

  return (
    <>
      <Flex direction={{ base: 'column', xl: 'row' }} pt={{ base: '130px', md: '80px', xl: '80px' }}>
        {user.role === 'MENTOR' ? (<Mentorpage />) : (<Menteepage />)}
      </Flex>
    </>
  );
}


