import { Strategy } from "../variables/types";
import { useAuth } from "services/auth/AuthProvider";


const useNewStrategy = () => {
    
    const { user, muuid } = useAuth();//useContext(AuthContext);

    const newstrat: Strategy = {
		name: "",
		id: 0,
		userid: (user === null ? 0 : user.id),
		rules: [],
		sync_due: false,
		description: "",
		base64Image: '',
		last_uuid: muuid,
		mentorshare: 0
	}
	return newstrat;
};

export default useNewStrategy;