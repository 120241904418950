import { Button, Flex, Icon, Input, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { MdCheck, MdEdit } from 'react-icons/md'
import api from 'services/api'
import { db } from 'services/db'
import { DeleteConfirm } from 'views/trader/components/confirm/DeleteConfirm'
import { Mistake } from '../../variables/types'
import { useAuth } from 'services/auth/AuthProvider'

const MistakeInput = (props: { userMistakes: Mistake[], mistake: Mistake, updatemistake: any, deletemistake: any }) => {

    const { updatemistake, deletemistake } = props;

    const {muuid} = useAuth();

    const textColor = useColorModeValue('gray.700', 'white');

    const [text, setText] = useState(props.mistake.name);
    const [editing, setEditing] = useState((props.mistake.name.trim().length < 1));
    const [saving, setSaving] = useState(false);

    const toast = useToast()

    const setMistaketext = (e: ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
    }
    const editMistake = (edit: boolean) => {
        if (!edit) {
            setSaving(true);
            props.mistake.name = text;
            updatemistake(props.mistake).then(() => {
                setSaving(false);
            });
        }
        setEditing(edit);
    }

    const deleteMistake = async (id: number) => {
        if (id === props.mistake.id) {
            delete props.mistake.sync_due;
            props.mistake.last_uuid = muuid;

            await api.post('/strategies/deletemistake', props.mistake).then(async (res) => {
                if (res.data === false) {
                    toast({ title: 'Oops, error ...', description: "Mistake not deleted ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
                }
                else {
                    await db.mistakes.delete(id);
                    deletemistake(id);
                }
            }).catch((e) => {
                toast({ title: 'Oops, error ...', description: "Mistake not deleted ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
            })
        }
    }
    
    return (
        <Flex direction={'row'} justify={'space-between'}>
            <Flex>
                <Button variant={'outline'} p='0px' size={'sm'} onClick={() => { editMistake(true) }} borderRadius='5px' me='5px' hidden={editing && !saving}>
                    <Icon as={MdEdit} boxSize={5} />
                </Button>
                <Text ms='20px' mb='15px' mt='5px' hidden={editing}>{text}</Text>
                <Input autoFocus={true} w='250px' size={'sm'} mb='15px' placeholder="Enter rule ..." color={textColor} onChange={setMistaketext} value={text} hidden={!editing} onKeyDown={(e) => (e.key === "Enter" ? (text.toString().trim().length > 0 ? editMistake(false) : null) : null)}></Input>
            </Flex>
            <Flex>
                <Button isDisabled={!text.trim().length} variant={'outline'} p='0px' size={'sm'} onClick={() => { editMistake(false) }} borderRadius='5px' ms='20px' hidden={!editing && !saving} disabled={text.toString().trim().length < 1} isLoading={saving}>
                    <Icon as={MdCheck} boxSize={5} color={'blue.500'} />
                </Button>
                <DeleteConfirm hidden={editing && !saving} id={props.mistake.id} size={'sm'} me={'0px'} title={'Delete mistake'} message={'Mistake will remain on trades where used until removed manually, but will no longer be available for tagging a trade or for mistake analysis.'} callbackfunction={deleteMistake}></DeleteConfirm>
            </Flex>

        </Flex>
    )
}
export default MistakeInput