/*eslint-disable*/

import {
  Flex,
  Link as ChakraLink,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { Link as ReactRouterLink } from 'react-router-dom';

export default function Footer() {
  //const textColor = useColorModeValue('gray.400', 'white');
  const textColorSecondary = useColorModeValue('gray.400', 'gray.600');
  return (
    <Flex w={{ base: '100%', xl: '1170px' }} maxW={{ base: '90%', xl: '1170px' }} zIndex="1.5" flexDirection={{ base: 'column', lg: 'row' }}
      alignItems={{ base: 'center', xl: 'start' }}
      justifyContent="space-between"
      px={{ base: '0px', xl: '0px' }}
      pb="30px"
      mx="auto"
    >
      <Text color={textColorSecondary} textAlign={{ base: 'center', xl: 'start' }} mb={{ base: '20px', xl: '0px' }} mt={{ base: '20px', md: '0px' }}>{' '} &copy; {new Date().getFullYear()}
        <Text as="span" fontWeight="500" ms="4px">TradeJorno. All Rights Reserved.</Text>
      </Text>
      <List display="flex">
        <ListItem me={{ base: '20px', md: '44px' }}>
          <ChakraLink as={ReactRouterLink} fontWeight="500" to='/home/terms' color={textColorSecondary}>Terms of Use</ChakraLink>
        </ListItem>
        <ListItem me={{ base: '20px', md: '44px' }}>
          <ChakraLink as={ReactRouterLink} fontWeight="500" to='/home/privacy' color={textColorSecondary}>Privacy</ChakraLink>
        </ListItem>
        <ListItem>
          <ChakraLink fontWeight="500" color={textColorSecondary} href="mailto:support@tradejorno.zohodesk.com.au">Support</ChakraLink>
        </ListItem>
      </List>
    </Flex>
  );
}
