export const Timezones = [
    {
        "timezone": 'Africa/Johannesburg',
        "description" : 'Johannesburg, South Africa, SAST',
    },
    {
        "timezone": 'America/New_York',
        "description" : 'New York (Eastern Time US & Canada)',
    },
    {
        "timezone": 'America/Sao_Paulo',
        "description" : 'São Paulo, Brazil, BRT',
    },
    {
        "timezone": 'America/Toronto',
        "description" : 'Toronto, Canada',
    },
    {
        "timezone": 'Asia/ Shanghai',
        "description" : 'Beijing Time, CST',
    },
    {
        "timezone": 'Asia/ Tokyo',
        "description" : 'Tokyo, Japan, JST',
    },
    {
        "timezone": 'Asia/Hong_Kong',
        "description" : 'Hong Kong, HKT',
    },
    {
        "timezone": 'Asia/Kolkota',
        "description" : 'India Standard Time, IST',
    },
    {
        "timezone": 'Asia/Riyahd',
        "description" : 'Riyadh, Saudi Arabia, AST',
    },
    {
        "timezone": 'Asia/Seoul',
        "description" : 'Seoul, South Korea, KST',
    },
    {
        "timezone": 'Asia/Taipei',
        "description" : 'Taipei, Taiwan, CST',
    },
    {
        "timezone": 'Australia/Sydney',
        "description" : 'Sydney, Australia, AEDT',
    },
    {
        "timezone": 'Europe/Amsterdam',
        "description" : 'Amsterdam, Netherlands, CEST',
    },
    {
        "timezone": 'Europe/Berlin',
        "description" : 'Frankfurt, Germany, CEST',
    },
    {
        "timezone": 'Europe/London',
        "description" : 'London, United Kingdom, GMT',
    },
    {
        "timezone": 'Europe/Stockholm',
        "description" : 'Stockholm, Sweden, CEST',
    },
    {
        "timezone": 'Europe/Zurich',
        "description" : 'Zurich, Switzerland, CEST',
    },
    

];