import {  NotesTemplate } from "../variables/types";
import { useAuth } from "services/auth/AuthProvider";


const useNewNotesTemplate = () => {

    const { user, muuid } = useAuth();//useContext(AuthContext);

    const newtemp: NotesTemplate = {
		id: 0,
		userid: (user === null ? 0 : user.id),
		name: '',
		type: '',
		text: '',
		last_uuid: muuid,
		sync_due: false
	}
	return newtemp;
};

export default useNewNotesTemplate;