import {
  Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex,
  FormControl, FormErrorMessage, FormLabel, Grid, Text, Icon, Input, InputGroup, InputLeftAddon, Select, useColorModeValue, useDisclosure, useToast, InputRightAddon
} from '@chakra-ui/react'

import Card from 'components/card/Card';
import dayjs from 'dayjs';
import { ChangeEvent, forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { MdCalendarMonth, MdClose, MdEditDocument, MdInsights, MdOutlineTipsAndUpdates } from 'react-icons/md';
import api from 'services/api';
import { useAuth } from 'services/auth/AuthProvider';
import { useData } from 'services/data/DataProvider';
import { db } from 'services/db';
import { DeleteJournalConfirm } from 'views/trader/components/confirm/DeleteJournalConfirm';
import Favourite from 'views/trader/components/inputs/Favourite';
import JornoQuill from 'views/trader/components/inputs/JornoQuill';
import ImageDropzone from 'views/trader/trades/components/fields/ImageDropzone';
import NotesTemplates from 'views/trader/trades/components/fields/NotesTemplates';
import StudentShare from 'views/trader/trades/components/fields/StudentShare';
import useNewJournal from 'views/trader/trades/hooks/useNewJournal';
import { JournalEntry } from 'views/trader/trades/variables/types';

const JournalDrawer = forwardRef((props: {}, ref) => {

  const { user, muuid } = useAuth();
  const { dateformat } = useData();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bdColor = useColorModeValue('gray.200', 'gray.700');
  const drawerBg = useColorModeValue('white', 'navy.800');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [journalid, setjournalid] = useState(0);
  const [journaltitle, setjournaltitle] = useState("");
  const [journalcontent, setjournalcontent] = useState("");
  const [journalentry, setJournalentry] = useState<JournalEntry>(useNewJournal());
  const [journalimage, setJournalimage] = useState<string>("");
  const [journalcategory, setjournalcategory] = useState("");
  const [journaldate, setjournaldate] = useState('');
  const [journalshared, setjournalshared] = useState(false);
  const [journalflag, setjournalflag] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dateformatlocal, setDateFormatLocal] = useState("YYYY-MM-DD")

  
  const newjournal = useNewJournal();
  const toast = useToast();

  useMemo(() => {
    let df = dateformat.replace("HH:mm", '').replace("ss", '').replace(":", '');
    setDateFormatLocal(df);
  }, [dateformat])

  useImperativeHandle(ref, () => ({
    openModal(jid: number, date: string) {
      setjournalid(jid);
      if (jid === 0) {
        let nj = newjournal;
        nj.date = date;
        setJournalentry(nj);
        setjournaldate(dayjs(date).format(dateformatlocal))
        setjournalflag(false);
      }
      else {
        db.journals.get(jid).then((j) => {
          setjournaldate(dayjs(j.date).format(dateformatlocal))
          setJournalentry(j);
          setjournaltitle(j.title);
          setjournalcategory(j.category);
          setjournalcontent(j.text);
          setJournalimage(j.base64Image);
          setjournalflag(j.flagged);
          setjournalshared(user.role === "MENTOR" ? j.published : false);
        })
      }
      onOpen();
    }
  }));

  const updatejournaltitle = (title: string) => {
    setjournaltitle(title);
    if (journalentry) {
      journalentry.title = title;
    }

  }
  const updatejournalcontent = (content: any) => {
    setjournalcontent(content);
    if (journalentry) {
      journalentry.text = content;
    }
  }
  const updateimage = async (file: string) => {
    setJournalimage(file);
    if (journalentry) {
      journalentry.base64Image = file;
    }
  }
  const updatecategory = (e: ChangeEvent<HTMLSelectElement>) => {
    setjournalcategory(e.target.value);
    if (journalentry) {
      journalentry.category = e.target.value;
    }
  }
  function invalidtitle() {
    return !journaltitle.trim().length
  }
  function invalidcontent() {
    let str = journalcontent.replace(/(<([^>]+)>)/ig, '').trim();
    return !journalcontent.trim().length || !str.length;
  }
  function invalidcategory() {
    return !journalcategory.trim().length
  }

  function eventColor(category: string) {
    switch (category) {
      case 'general': return "#01B574";
      case 'education': return "#3965FF";
      case 'outlook': return "#ffa63f";
      case 'review': return "#EA4848";
    }
  }
  function renderIcon(category: string) {
    switch (category) {
      case 'general': return <Icon as={MdEditDocument} boxSize={5} />;
      case 'education': return <Icon as={MdOutlineTipsAndUpdates} boxSize={5} />;
      case 'outlook': return <Icon as={MdCalendarMonth} boxSize={5} />;
      case 'review': return <Icon as={MdInsights} boxSize={5} />;
      default: return null;
    }
  }

  const savejournal = async () => {
    if (invalidtitle()) {
      toast({ title: 'Please enter title ...', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false });
      return;
    }
    if (invalidcontent()) {
      toast({ title: 'Please enter content ...', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false });
      return;
    }
    if (invalidcategory()) {
      toast({ title: 'Please select category ...', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false });
      return;
    }

    setSaving(true);

    delete journalentry.sync_due;
    journalentry.last_uuid = muuid;
    journalentry.published = journalshared;

    await api.post('/journals/savejournal', journalentry).then(async (res) => {
      if (res.data > 0) {
        journalentry.id = res.data;
        journalentry.sync_due = false;
        delete journalentry.last_uuid;
        await db.journals.put(journalentry, journalentry.id).catch((r) => { })
        toast({ title: 'Journal entry saved ...', description: "", status: 'success', position: 'top', duration: 2000, isClosable: false });
      }
      else {
        toast({ title: 'Unable to save journal entry ...', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false });
      }
    }).catch(async (e) => {
      if (journalentry.id > 0) {
        journalentry.sync_due = true;
        await db.journals.put(journalentry, journalentry.id).catch((r) => { })
        toast({ title: 'Journal entry saved ...', description: "", status: 'success', position: 'top', duration: 2000, isClosable: false });
      }
      else {
        toast({ title: 'Error saving journal entry ...', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false });
      }
    })
    closeModal();
  }

  const deletejournal = async () => {

    delete journalentry.sync_due;
    journalentry.last_uuid = muuid;

    await api.post('/journals/deletejournal', journalentry).then(async (res) => {
      if (res.data > 0) {
        await db.journals.delete(res.data).then((r) => {
          closeModal();
          toast({ title: 'Journal entry deleted ...', description: "", status: 'success', position: 'top', duration: 2000, isClosable: false });
        }).catch((e) => { })
      }
    }).catch((e) => {
      toast({ title: 'Error deleting journal entry ...', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false });
    })
  }

  const updatefavourite = (fav: boolean) => {
    journalentry.flagged = fav;
    setjournalflag(fav);
  }

  const closeModal = () => {
    setSaving(false);
    setjournalid(0);
    setJournalentry(null);
    setjournaltitle("");
    setjournalcategory("");
    setjournalcontent("");
    setJournalimage("");
    onClose();
  }

  const updateShare = (share: boolean) => {
    setjournalshared(user.role === "MENTOR" ? share : false);
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement='top'
        onClose={closeModal}
        size={'full'}
      >
        {/*<DrawerOverlay bg='blackAlpha.300'/>*/}
        <DrawerContent bg={drawerBg}>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex direction={'row'}>
              <Flex>
                <Favourite selected={journalflag} updatefavourite={updatefavourite} />
              </Flex>
              <Flex ms='20px' mt='7px'>
                Journal entry <Text color='gray.500' ms="20px">{journaldate}</Text>
              </Flex>
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <Grid h={'100%'} templateRows='repeat(1, 1fr)' templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }}
              templateAreas={{
                base: `'content' 'image'`,
                lg: `'content image'`,
              }}
              gap={5}
            >
              <Card gridArea='content' borderWidth={'1px'} h='100%'>

                <Flex direction={{ base: 'column', md: 'row' }} mb={'20px'} justify={'space-between'} flexWrap={'wrap'}>
                  <Flex direction={{ base: 'column', md: 'row' }}>
                    <InputGroup mb={{ base: '20px', md: '0px' }} >
                      <InputLeftAddon fontWeight={'700'}>Title</InputLeftAddon>
                      <Input isInvalid={invalidtitle()} value={journaltitle} onChange={(e) => updatejournaltitle(e.target.value)} color={textColor} minW={'285px'} type='text' placeholder='Enter journal title ...' maxLength={50} />
                    </InputGroup>
                    <InputGroup mb={{ base: '20px', md: '0px' }} ms={{ base: '0px', md: '10px' }} >
                      <InputLeftAddon fontWeight={'700'}>Category</InputLeftAddon>
                      <Select value={journalcategory} onChange={updatecategory} isInvalid={invalidcategory()} borderLeftRadius={'0px'} borderRightRadius={journalcategory === "" ? '5px' : '0px'}>
                        <option value="">Select category ...</option>
                        <option value="general">General</option>
                        <option value="education">Education</option>
                        <option value="outlook">Outlook</option>
                        <option value="review">Review</option>
                      </Select>
                      <InputRightAddon hidden={journalcategory === ""} bgColor={eventColor(journalcategory)}>{renderIcon(journalcategory)}</InputRightAddon>
                    </InputGroup>
                  </Flex>

                </Flex>

                <Flex direction={'row'} justify={'space-between'}>
                  <FormControl isInvalid={!invalidcontent()} hidden={invalidcontent()}>
                    <FormErrorMessage color={textColor} fontWeight={'700'} mb={3}>Content</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={invalidcontent()}>
                    <FormErrorMessage fontWeight={'700'} mb={3}>Enter content</FormErrorMessage>
                  </FormControl>
                  <Flex justify={'end'} minW='250px'>
                    <NotesTemplates type={'journal'} notes={journalcontent} setnotes={updatejournalcontent} reset={''} mb={'0px'} size={'sm'} />
                  </Flex>
                </Flex>
                <Box>
                  <JornoQuill content={journalcontent} updatecontent={updatejournalcontent} allowimages={true} placeHolder={'Create a journal entry with formatted text and small images'} />
                </Box>

              </Card>
              <Card gridArea='image' borderColor={bdColor} borderWidth='0px' >
                <Flex direction={'row'} justify={'space-between'}>
                  <Flex hidden={!journalimage.length} w="30px" h="30px" justify="center" align="center" bg={'red.500'} borderColor={'red.500'} borderWidth={'2px'} borderRadius="10px" zIndex={1000} position="absolute" top="30px" right="15px" transition="0.3s" cursor="pointer" onClick={() => updateimage('')} _hover={{ background: 'rgba(0, 0, 0, 0.25);' }}>
                    <Icon as={MdClose} color="#fff" fontSize="20px" />
                  </Flex>
                </Flex>
                <FormLabel fontWeight='bold' fontSize='md' color={textColor}>Larger image (optional)</FormLabel>
                <ImageDropzone imagefile={journalimage} setimagefile={updateimage} />
              </Card>
            </Grid>
          </DrawerBody>
          <DrawerFooter>
            <Flex direction={{ base: 'column', md: 'row' }}>
              {user.role === 'MENTOR' &&
                <Flex mt='20px'>
                  <StudentShare checked={journalshared} updateshare={updateShare} label='Share with students' />
                </Flex>
              }
              <Flex direction={'row'} justify={'end'} mt='5px'>
                <DeleteJournalConfirm deletejournal={deletejournal} hidden={journalid < 1} />
                <Button variant={'outline'} colorScheme='blue' ms={'10px'} mt={'20px'} onClick={savejournal} isLoading={saving} loadingText="Saving">Save entry</Button>
              </Flex>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
})

export default JournalDrawer