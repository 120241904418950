/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports

import { Box, Button, Flex, FormLabel, Grid, InputGroup, InputLeftAddon, Select, useToast, VStack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { db } from 'services/db';
import { MentorStartegySelect, StatStrat, Strategy, Trade, TradeStrategy } from '../trades/variables/types';
import { DeleteConfirm } from '../components/confirm/DeleteConfirm';
import api from 'services/api';
import StatWinner from './components/StatWinners';
import StatLoser from './components/StatLosers';
import WinGuage from './charts/WinGuage';
import { StartegyStats } from './variables/StratStatData';
import StrategiesChart from './charts/StrategiesChart';
import uuid from 'react-uuid';
import { updateStratsAndMistakes } from 'services/dbservice';
import MistakeChart from './charts/MistakeChart';
import StrategyTradesModal from './components/StrategyTradesModal';
import useNewStrategy from '../trades/hooks/useNewStrategy';
import { useAuth } from 'services/auth/AuthProvider';
import MistakeModal from '../trades/components/modals/MistakeModal';
import StrategyView from './components/StrategyView';
import StrategyViewMentor from './components/StrategyViewMentor';
import MentorStrategyCopy from '../components/confirm/MentorStrategyCopy';
import { SyncAPI } from '../components/syncapi/SyncApi';
import { useLoading } from '../../../services/loading/LoadingProvider';
import { useData } from 'services/data/DataProvider';
// Custom components

export default function Strategies() {

  interface smdata { id: number, name: string, trades: Trade[], nett: number }

  const { user, setUser, muuid, IsMentored } = useAuth();
  const { usertrades, strategies, mistakes, defaultcurrency } = useData();
  const [strategy, setStrategy] = useState<Strategy>(null);
  const [mentorstrategy, setMentorStrategy] = useState<Strategy>(null);
  const [mentors, setMentors] = useState([]);
  const [mentorstrats, setMentorstrats] = useState<MentorStartegySelect[]>([]);
  const [stratData, setStratData] = useState<smdata[]>([]);
  const [mistData, setMistData] = useState<smdata[]>([]);
  const [modaltrades, setmodaltrades] = useState<{ id: number, name: string, trades: Trade[], nett: number }>({ id: 0, name: '', trades: [], nett: 0 });
  const [stratstats, setStratStats] = useState({ wcount: 0, wperc: 0, wavg: 0, wmax: 0, wavgscore: 0, lcount: 0, lperc: 0, lavg: 0, lmax: 0, lavgscore: 0 });
  const { loading, setLoading } = useLoading();//useState(false);
  const [adding, setAdding] = useState(false);
  const [tempreset, setTempreset] = useState('');
  const tradesModalRef = useRef(null);
  const newstrat = useNewStrategy();
  const toast = useToast();

  useEffect(() => {
    setLoading(true);
    SyncAPI(user.id).then(async (res) => {
      setLoading(false);
      await db.users.get(user.id).then((u) => {
        setUser(u);
      })
      setTimeout(() => {

      }, 250);
    });
  }, [])

  useEffect(() => {
    getMentorStrats();
  }, [IsMentored, strategies]);//, chartupdate]) // using chart update as used in livequery below

  useEffect(() => {

    let sdata: smdata[] = [];
    for (let s of strategies) {
      sdata.push({ id: s.id, name: s.name, trades: [], nett: 0 });
    }

    let mdata: smdata[] = [];
    for (let m of mistakes) {
      mdata.push({ id: m.id, name: m.name, trades: [], nett: 0 });
    }

    updateStrategyData(usertrades, sdata);
    updateMistakeData(usertrades, mdata);


  }, [strategies, mistakes, usertrades])


  const updateStrategyData = (trades: Trade[], stratdata: smdata[]) => {

    for (let tr of trades) {
      let ex = (tr.currency !== defaultcurrency ? tr.exchange : 1);

      tr.strategies.forEach((s: TradeStrategy) => {
        let i = stratdata.findIndex((strat) => strat.id === s.id)
        if (i > -1) {
          let tri = stratdata[i].trades.findIndex((t) => t.tradeid === tr.tradeid);
          if (tri < 0) {
            stratdata[i].trades.push(tr);
            stratdata[i].nett += (tr.nett / ex);
          }
        }
        else {
          stratdata.push({ id: s.id, name: s.name, trades: [tr], nett: (tr.nett / ex) })
        }
      })
    }

    setStratData(stratdata);
  }

  const updateMistakeData = async(trades: Trade[], mistdata: smdata[]) => {


    for (let tr of trades) {
      let ex = (tr.currency !== defaultcurrency ? tr.exchange : 1);

      for (let m of tr.mistakes) {

        let nett = 0;
        
        if (tr.profitloss < 0) {
          nett += (tr.nett / ex);
        }
        else {

          if (tr.target_price > 0) {

            let diff = Math.abs((tr.target_price - tr.exit_avg));

            await db.instruments.where({ ticker: tr.ticker }).toArray().then((ins) => {
              if (ins.length) {
                if (tr.side.toLowerCase() === 'sell') {
                  if (tr.target_price < tr.exit_avg) {
                    nett -= (diff * tr.size * ins[0].pointvalue) / ex;
                  }
                }
                else {
                  if (tr.target_price > tr.exit_avg) {
                    nett -= (diff * tr.size * ins[0].pointvalue) / ex;
                  }
                }
              }
            }).catch(() => { })
          }
        }

        let mindex = mistdata.findIndex((mi) => mi.id === m.id);

        if (mindex > -1) {
          let trindex = mistdata[mindex].trades.findIndex((t) => t.tradeid === tr.tradeid);
          if (trindex < 0) {
            mistdata[mindex].trades.push(tr);
            mistdata[mindex].nett += nett;
          }
        }
        else {
          mistdata.push({ id: m.id, name: m.name, trades: [tr], nett: nett })
        }
      }
    }

    setMistData(mistdata);
  }

  const getMentorStrats = async () => {
    if (IsMentored) {
      setAdding(true);
      await api.post('/mentor/strategies').then(async (res) => {
        if (res.data !== false) {
          let men: string[] = [];
          let mens: MentorStartegySelect[] = [];
          for (let m of res.data) {
            let i = strategies.findIndex((s: Strategy) => s.mentorshare === m.id);
            if (i < 0) { //first check if strategy already copied by trader
              if (men.indexOf(m.mentorname) < 0) {
                men.push(m.mentorname);
              }
              if (mens.findIndex((me) => me.id === m.id) < 0) {
                mens.push(m);
              }
            }
          }
          setMentors(men);
          setMentorstrats(mens);

        }
        else {
          setMentors([]);
          setMentorstrats([]);
        }
        setAdding(false);
      }).catch(() => {
        setAdding(false);
      })
    }
  }

  const loadStrategies = async (newid: number) => {
    if (newid > 0) {
      let s = (document.getElementById('selectedstrat') as HTMLInputElement);
      s.value = newid.toString();
    }
  }

  const loadUserStrategy = (e: ChangeEvent<HTMLSelectElement>) => {

    clearStrategy();
    clearMentorStrategy();

    let id = Number(e.target.value);

    if (id > 0) {
      let str = strategies.filter((s: Strategy) => s.id === id);
      let strat_deep_copy = JSON.parse(JSON.stringify(str[0]));
      setStrategy(strat_deep_copy);
      loadStrategyStats(strat_deep_copy);
    }
  }

  const reloadUserStrategy = () => {
    if (strategy) {
      loadStrategyStats(strategy);
    }
  }

  const loadMentorStrategy = async (e: ChangeEvent<HTMLSelectElement>) => {
    let s = (document.getElementById('selectedstrat') as HTMLInputElement);
    s.value = '0';
    clearStrategy();
    //alert("MENTOR Strategy: " + e.target.value)
    if (e.target.value !== '0') {
      setAdding(true);
      await api.post('/mentor/getstrategy', { id: e.target.value }).then(async (res) => {
        if (res.data !== false) {
          delete res.data.hibernateLazyInitializer;
          let s: Strategy = res.data;
          setMentorStrategy(s);
        }
        else {
          toast({ title: 'Oops, error ...', description: "Unable to retrieve mentor strategy", status: 'error', position: 'top', duration: 3000, isClosable: false })
          clearMentorStrategy();
        }
        setAdding(false);
      }).catch((e) => {
        toast({ title: 'Oops, error ...', description: "Unable to retrieve mentor strategy", status: 'error', position: 'top', duration: 3000, isClosable: false })
        clearMentorStrategy();
        setAdding(false);
      })
    }
    else {
      clearMentorStrategy();
    }
  }

  const loadStrategyStats = (str: Strategy) => {
    let s = stratData.filter((s) => s.id === str.id);
    if (s.length) {
      StartegyStats(str.id, s[0].trades, defaultcurrency).then((res: StatStrat) => {
        setStratStats(res);
      })
    }
  }

  const clearStrategy = () => {
    setStrategy(null);
  }
  const clearMentorStrategy = () => {
    setMentorStrategy(null);
    let s = (document.getElementById('selectedmentorstrat') as HTMLInputElement);
    s.value = '0';
  }

  const addNewStrategy = () => {
    clearStrategy();
    clearMentorStrategy();
    setStrategy(newstrat);
    setStratStats({ wcount: 0, wperc: 0, wavg: 0, wmax: 0, wavgscore: 0, lcount: 0, lperc: 0, lavg: 0, lmax: 0, lavgscore: 0 });
    let s = document.getElementById('selectedstrat') as HTMLSelectElement;
    s.value = '0';
    setTempreset(uuid());
  }


  const deleteStrategy = async (id: number) => {

    delete strategy.sync_due; // variable not used on API
    strategy.last_uuid = muuid;

    await api.post('/strategies/deletestrategy', strategy).then(async (res) => {
      if (res.data === true) {
        await db.strategies.delete(id).then(() => {
          loadStrategies(0);
          clearStrategy();
        })
      }
      else {
        toast({ title: 'Oops, error ...', description: "Strategy not deleted ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
      }
    }).catch((err) => {
      toast({ title: 'Oops, error ...', description: "Strategy not deleted ...", status: 'error', position: 'top', duration: 3000, isClosable: false })
    })

  }

  const addMentorStrategy = () => {

    setAdding(true);

    mentorstrategy.id = 0; //reset id for saving as new strat
    mentorstrategy.userid = user.id;
    mentorstrategy.last_uuid = muuid;

    delete mentorstrategy.sync_due;

    setTimeout(async () => {
      await api.post('/strategies/savestrategy', mentorstrategy)
        .then(async (res) => {
          if (res.status === 200) {
            if (res.data === 0) {
              toast({ title: 'Oops, error ...', description: "Strategy not saved", status: 'error', position: 'top', duration: 3000, isClosable: false })
            }
            else {
              mentorstrategy.sync_due = false;
              mentorstrategy.id = res.data;
              await db.strategies.put(mentorstrategy, mentorstrategy.id).then(async () => {
                toast({ title: 'Strategy copied ...', description: "You can select it in your strategy dropdown", status: 'success', position: 'top', duration: 2000, isClosable: false })
                removeMentorStrat(mentorstrategy.mentorshare);
                setStrategy(mentorstrategy);
                let s = (document.getElementById('selectedstrat') as HTMLInputElement);
                s.value = String(mentorstrategy.id);
                reloadUserStrategy();
              });
            }
          }
          setAdding(false);
        }).catch(async function (error) {
          toast({ title: 'Error saving strategy ...', description: "Are you connected to the internet?", status: 'error', position: 'top', duration: 3000, isClosable: false });
          setAdding(false);
        });
    }, 250);
  }

  const removeMentorStrat = (id: number) => {
    setMentorStrategy(null);
    let s = (document.getElementById('selectedmentorstrat') as HTMLInputElement);
    s.value = '0';
    let r = mentorstrats.findIndex((m) => m.id === id);
    if (r > -1) {
      mentorstrats.splice(r, 1);
      setMentorstrats(mentorstrats)
    }
  }


  const showtrades = (type: string, index: number) => {
    switch (type) {
      case 'strategy':
        setmodaltrades(stratData[index]);
        tradesModalRef.current.openModal(type, stratData[index]);
        break;
      case 'mistake':
        setmodaltrades(mistData[index]);
        tradesModalRef.current.openModal(type, mistData[index]);
        break;
    }
  }
  const placeholderfunc = (s: any) => { }
  const updatemistake = async (m: { name: string, id: number }) => {
    await updateStratsAndMistakes([], [m]);
  }

  return (
    <Flex direction={{ base: 'column', xl: 'row' }} pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Box w='100%'>
        <Grid templateRows='repeat(1, 1fr)' templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
          templateAreas={{
            base: `'perf' 'charts'`,
            md: `'perf charts'`,
            lg: `'perf perf charts'`,
          }}
          gap={5}
        >
          <Card gridArea={'charts'} h='100%' w='100%' borderWidth={'1px'} bg='transparent'>
            <Flex direction={'column'}>
              <FormLabel fontWeight='bold' fontSize='md' mb='20px'>Charts</FormLabel>
              <Flex mt='10px'>
                <StrategiesChart strats={stratData} currency={defaultcurrency} showtrades={showtrades} />
              </Flex>
              <Flex mt='20px'>
                <MistakeChart mistakes={mistData} currency={defaultcurrency} showtrades={showtrades} />
              </Flex>
              <Flex direction={'row'} mt='20px' align={'center'} justify={{ base: 'center' }}>
                <MistakeModal updateMistakes={updatemistake} notifyChanges={placeholderfunc} addnewsavedtag={placeholderfunc} deletemistake={placeholderfunc} largebtn={true} />
              </Flex>
            </Flex>
          </Card>
          <Card gridArea={'perf'} h='100%' w='100%' borderWidth={'1px'} bg='transparent'>
            <Grid templateRows='repeat(1, 1fr)' templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
              templateAreas={{
                base: `'strat' 'winners'`,
                lg: `'strat strat winners'`,
              }}
              gap={5}
            >
              <Box gridArea={'strat'} w='100%' p='0px' bg='transparent'>
                <Flex mb='5px' direction={{ base: 'column', lg: 'row' }} >
                  <Flex me={{ base: '0px', lg: '20px' }}>
                    <InputGroup size='md'>
                      <InputLeftAddon fontWeight={'700'} >
                        Strategy
                      </InputLeftAddon>
                      <Select id='selectedstrat' onChange={loadUserStrategy} borderLeftRadius={'0px'} isDisabled={loading} >
                        <option value='0'>Select to view/edit</option>
                        {strategies.length > 0 &&
                          strategies.map((strat: Strategy) => (
                            <option key={strat.id} value={strat.id}>
                              {strat.name}
                            </option>
                          ))
                        }
                      </Select>
                      <Button variant={'outline'} size={'md'} onClick={addNewStrategy} borderRadius='5px' ms='3px' me='3px' isLoading={loading}>
                        New
                      </Button>
                      {(strategy && strategy.id > 0) && <DeleteConfirm hidden={false} id={strategy.id} size={'md'} me={'0px'} title={'Delete profile strategy'} message={'Strategy tags will remain on trades where used until removed manually, but will no longer be available for tagging a new trade.'} callbackfunction={deleteStrategy}></DeleteConfirm>}
                    </InputGroup>
                  </Flex>

                  <Flex mt={{ base: '20px', lg: '0px' }} hidden={!IsMentored || !mentorstrats.length}>
                    <InputGroup size='md' >
                      <InputLeftAddon fontWeight={'700'}>
                        Mentor
                      </InputLeftAddon>
                      <Select id='selectedmentorstrat' onChange={loadMentorStrategy} borderLeftRadius={'0px'} isDisabled={adding} >
                        <option value='0'>Select to view/edit</option>
                        {mentors.map((mentor, key) => {
                          return <optgroup key={key} label={mentor}>
                            {mentorstrats.map((men, key) => {
                              return mentor === men.mentorname && <option key={men.id} value={men.id}>{men.strategyname}</option>
                            })}
                          </optgroup>;
                        })}
                      </Select>
                      <MentorStrategyCopy hidden={!mentorstrategy && !adding} adding={adding} copystrat={addMentorStrategy} />
                    </InputGroup>
                  </Flex>
                </Flex>
                {strategy &&
                  <Flex mt='40px'>
                    <StrategyView strategy={strategy} selectStrategy={loadStrategies} setStrategy={setStrategy} tempreset={tempreset} />
                  </Flex>
                }
                {mentorstrategy &&
                  <Flex mt='40px'>
                    <StrategyViewMentor strategy={mentorstrategy} />
                  </Flex>
                }
              </Box>
              <Box gridArea={'winners'} hidden={!strategy || strategy.id === 0}>
                <VStack gap={4}>
                  <Card borderWidth='1px' bg='transparent'>
                    <WinGuage stats={stratstats} hidden={false} />
                  </Card>
                  <StatWinner stats={stratstats} currency={defaultcurrency} loading={false} />
                  <StatLoser stats={stratstats} currency={defaultcurrency} loading={false} />
                </VStack>
              </Box>
            </Grid>
          </Card>
        </Grid>
      </Box>
      <StrategyTradesModal ref={tradesModalRef} data={modaltrades} onclosemodal={reloadUserStrategy} />
    </Flex>

  );
}
