
import api from "services/api";
import { db } from "services/db";
import { Account, AccountGroup, DayNote, Instrument, JournalEntry, Mentee, Mentor, Mistake, NotesTemplate, Strategy, Trade } from "../../trades/variables/types";
import useJornoUuid from "views/trader/trades/hooks/useJornoUuid";

export const ReSyncAPI = ((user_id: number) => {

    const uuid = useJornoUuid();

    return new Promise(async (resolve, reject) => {

        await api.post('/sync/resyncapi', { uuid: uuid })
            .then(async (res) => {
                if (res.status === 200) {
                    localStorage.setItem('resync', 'true');
                    await db.trades.clear().then(async () => {
                        for (let trade of res.data.trades) {
                            trade.sync_due = false;
                            await db.trades.put(trade, trade.tradeid).catch(error => { })
                        }
                        /*res.data.trades.forEach(async (trade: Trade) => {
                            trade.sync_due = false;
                            await db.trades.put(trade, trade.tradeid).catch(error => { })
                        })*/
                    });
                    await db.strategies.clear().then(async () => {
                        res.data.strategies.forEach(async (strat: Strategy) => {
                            strat.sync_due = false;
                            await db.strategies.put(strat, strat.id).catch(error => { });
                        })
                    });
                    await db.accounts.clear().then(async () => {
                        res.data.accounts.forEach(async (acc: Account) => {
                            acc.sync_due = false;
                            await db.accounts.put(acc, acc.accountid).catch(error => { });
                        })
                    });
                    await db.accountgroups.clear().then(async () => {
                        res.data.groups.forEach(async (grp: AccountGroup) => {
                            grp.sync_due = false;
                            delete grp.last_uuid;
                            await db.accountgroups.put(grp, grp.groupid).catch(error => { });
                        })
                    });
                    await db.templates.clear().then(async () => {
                        res.data.templates.forEach(async (tmp: NotesTemplate) => {
                            tmp.sync_due = false;
                            delete tmp.last_uuid;
                            await db.templates.put(tmp, tmp.id).catch(error => { });
                        })
                    })
                    await db.instruments.where('id').above(9999).delete().then(async () => {
                        res.data.instruments.forEach(async (tmp: Instrument) => {
                            tmp.sync_due = false;
                            delete tmp.last_uuid;
                            await db.instruments.put(tmp, tmp.id).catch(error => { });
                        })
                    })
                    await db.daynotes.clear().then(async () => {
                        res.data.daynotes.forEach(async (dn: DayNote) => {
                            dn.sync_due = false;
                            delete dn.last_uuid;
                            await db.daynotes.put(dn, dn.id).catch(error => { });
                        })
                    })
                    await db.mistakes.clear().then(async () => {
                        res.data.mistakes.forEach(async (dn: Mistake) => {
                            dn.sync_due = false;
                            delete dn.last_uuid;
                            await db.mistakes.put(dn, dn.id).catch(error => { });
                        })
                    })
                    await db.journals.clear().then(async () => {
                        res.data.journals.forEach(async (jn: JournalEntry) => {
                            jn.sync_due = false;
                            delete jn.last_uuid;
                            await db.journals.put(jn, jn.id).catch(error => { });
                        })
                    })
                    await db.mentees.clear().then(async () => {
                        res.data.mentees.forEach(async (mr: Mentee) => {
                            mr.sync_due = false;
                            delete mr.last_uuid;
                            await db.mentees.put(mr, mr.id).catch(error => { });
                        })
                    })
                    await db.mentors.clear().then(async () => {
                        res.data.mentors.forEach(async (mr: Mentor) => {
                            mr.sync_due = false;
                            delete mr.last_uuid;
                            await db.mentors.put(mr, mr.id).catch(error => { });
                        })
                    })
                    await db.mentormessages.clear().then(async () => {
                        for (let mess of res.data.mentormessages) {
                            mess.seen = false;
                            await db.mentormessages.put(mess, mess.id).catch(error => { });
                        }
                        await db.mentormessages.orderBy('id').last().then((m) => {
                            if (m !== undefined) {
                                localStorage.setItem("lastmessage", m.id.toFixed())
                            }
                            else {
                                localStorage.setItem("lastmessage", '0')
                            }
                        }).catch((e) => { localStorage.setItem("lastmessage", '0') })
                    })

                    //await db.users.update(user_id, { subscription: res.data.subscription }).catch(() => { })

                    await db.users.put(res.data.subscription, res.data.subscription.id).then(async () => {
                        await db.users.toCollection().modify(user => {
                            user.current_user = (user.id === res.data.subscription.id ? 1 : 0);
                        }).catch((err) => { });
                    }).catch((e) => { })
                    delete res.data.subscription.subscription; // so it's not saved in localstorage
                    localStorage.setItem('juser', JSON.stringify(res.data.subscription));
                    //window.location.reload(); - do this in calling component rather

                }
                localStorage.setItem('resync', 'false');
            }).catch(async (err) => { reject('api') });

        resolve('');
    });

});
