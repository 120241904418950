import dayjs, { Dayjs } from "dayjs";

export interface JUser {
	id: number,
	username: string,
	role: string,
	current_user?: number,
	subscription: Subscription
}
export interface JAUser {
	id: number,
	username: string,
	email: string,
	role: string,
	lastlogin: string,
	subscription: Subscription,
	verified: boolean,
	enabled: boolean,
	ismfa: boolean, 
	ipaddress: string,
	devices: number[]

}
export interface JUserStats {
	trades: number,
	accounts: number,
	groups: number,
	strategies: number,
	journals: number,
	platforms: string[]
}
export const JUserSchema = 'id, username, role, current_user, subscription'

export interface Trade {
	tradeid: number;
	accountid: number;
	groupid: number;
	brokerid?: string;
	userid: number;
	datetime: string;
	duration: number
	ticker: string;
	currency: string,
	side: string;
	size: number;
	entry_avg: number;
	exit_avg: number;
	stop_price?: number;
	target_price?: number;
	points: number;
	profitloss: number;
	rvalue: number;
	target_rvalue?: number;
	fees: number;
	nett: number;
	rulescore?: number;
	executions?: Execution[];
	exchange: number;
	closed: boolean;
	mistakes: any[];
	strategies: TradeStrategy[];
	mentornotes?: string;
	notes?: string;
	shared?: boolean;
	req_review: boolean;
	reviewed?: boolean;
	sync_due?: boolean;
	last_uuid?: string;
	base64Image?: string;
	flagged: boolean;
};
export const TradeSchema = 'tradeid, accountid, groupid, userid, datetime, duration, ticker, currency, side, size, entry_avg, exit_avg, stop_price, target_price, points, profitloss, rvalue, target_rvalue, fees, nett, rulescore, executions, exchange, closed, mistakes, strategies, mentornotes, notes, shared, req_review, reviewed, sync_due, base64Image, flagged';

export interface Execution {
	datetime: string;
	instrument: string,
	ticker: string;
	contract?: string;
	side: string;
	size: number;
	price: number;
	stop?: number;
	fee: number;
	currency: string;
	editInstument: boolean;
	orderid: string;
};

export interface Instrument {
	id: number;
	userid: number;
	ticker: string;
	description: string;
	category: string;
	subcategory: string;
	currency: string;
	pointvalue: number;
	ticksize: number;
	timezone?: string;
	relevance: number;
	defaultstop: number;
	commission: number[]; //[0:ninja, 1:tvate, 2:tview, 3:paper]
	sync_due?: boolean;
	last_uuid?: string;

}
export const IntrumentSchema = 'id, userid, ticker, description, category, subcategory, currency, pointvalue, ticksize, timezone, relevance, defaultstop, commission, sync_due';


export interface MinimalStatistics {
	currency: string;
	trade_count: number;
	pl_ratio: number;
	wl_ratio: number;
	avg_pl: number;
	nett_gainloss: number;
	profit_factor: number;
	avg_rvalue: number;
}

export interface Statistics {
	id: number;
	period: string;
	periodstr: string;
	currency: string;
	trade_count: [current: number, last: number];
	pl_ratio: [current: number, last: number];
	wl_ratio: [current: number, last: number];
	avg_pl: [current: number, last: number];
	nett_gainloss: [current: number, last: number];
	profit_factor: [current: number, last: number];
	avg_rvalue: [current: number, last: number];
}
export const StatisticsSchema = '++id, period, periodstr, currency, trade_count, pl_ratio, wl_ratio, avg_pl, nett_gainloss, profit_factor, avg_rvalue';

export interface PeriodDates {
	period: string;
	startthis: Dayjs;
	endthis: Dayjs;
	startlast: Dayjs;
	endlast: Dayjs;
}

export interface PeriodTrades {
	current: Trade[],
	previous: Trade[],
}

export interface AccountGroup {
	groupid: number,
	userid: number,
	groupname: string,
	currency: string,
	defaultgrp: boolean,
	sync_due?: boolean,
	last_uuid?: string
}
export const AccountGroupSchema = 'groupid, userid, groupname, currency, defaultgrp, sync_due';

export interface Account {
	accountid: number,
	groupid: number,
	userid: number,
	accname: string,
	adjustments: AccountAdjustment[],
	init_balance: number,
	currency: string,
	platform: string,
	brokerid: string,
	defaultacc: boolean,
	sync_due?: boolean,
	last_uuid?: string
}

export const AccountSchema = 'accountid, groupid, userid, accname, adjustments, init_balance, currency, platform, brokerid, defaultacc, sync_due';

export interface AccountExecutions {
	account: string;
	executions: Execution[];
}
export interface AccountAdjustment {
	id?: number,
	datetime: string,
	reason: string,
	amount: number
}

export interface Strategy {
	name: string,
	id: number,
	userid: number,
	rules: StrategyRule[],
	description: string,
	last_uuid?: string,
	sync_due?: boolean,
	base64Image?: string;
	mentorshare: number; //ie mentor's strategy ID, 0 if not shared
}
export const StategySchema = 'id, userid, name, rules, description, sync_due, base64Image, mentorshare';

export interface StrategyRule {
	id: number,
	sid: number,
	text: string,
	used: boolean
}
export interface TradeStrategy {
	id: number,
	name: string,
	rules: StrategyRule[]
}

export interface Mistake {
	name: string,
	id: number,
	userid: number,
	sync_due: boolean,
	last_uuid?: string
}

export const MistakeSchema = 'id, userid, name, sync_due';

export interface DashCalEvent {
	title: string,
	borderColor: string,
	start: string,
	end: string,
	backgroundColor: string, // "#EE5D50" is red.500, #01B574 is green.500 - use HEX
	extendedProps: { trades: number[], groupid: number, avgR: number }
}

export interface equityChartData {
	color: string,
	initBalance: number,
	equityChange: number,
	series: { name: string, data: any[], color: string, type: string }[],
	categories: string[]
}
export interface profitWinChartData {
	labels: any[],
	categories: string[],
	series: { name: string, data: any[], color: string, type: string }[]
}
export interface drawdownChartData {
	axis: any[],
	series: { name: string, data: any[], color: string, type: string }[]
}
export interface dayModalChartData {
	axis: any[],
	data: any[]
}

export interface userProfile {
	email: string,
	id: number,
	login: string,
	roles: any[],
	username: string
}

export interface NotesTemplate {
	id: number,
	userid: number,
	name: string,
	type: string,
	text: string,
	last_uuid?: string,
	sync_due?: boolean
}
export const NotesTemplateSchema = 'id, userid, name, type, text';

export interface StatSummary {
	profit_factor: number,
	total_R: number,
	avg_R: number,
	avg_R_target: number,
	avg_Rulescore: number,
	avg_trades: number,
	max_strategy: string,
	max_mistake: string
}
export interface StatWin {
	wintotal: number,
	winperc: number,
	windays: number,
	maxwin: number,
	avgwin: number,
	winstreak: number,
	avg_duration: number,
	bestday: number
}
export interface StatLose {
	losstotal: number,
	lossperc: number,
	lossdays: number,
	maxloss: number,
	avgloss: number,
	losstreak: number,
	avg_duration: number,
	worstday: number
}
export interface StatSide {
	long: number,
	short: number
}
export interface StatInstruments {
	labels: any[],
	data: StatInstrument[]
}
export interface StatInstrument {
	ticker: string,
	count: number,
	nett: number
}
export interface StatIntraday {
	hour: number,
	ticker: StatTicker[]
}

export interface StatWeekday {
	day: number,
	ticker: StatTicker[]
}
export interface StatMonthly {
	month: number,
	ticker: StatTicker[]
}

export interface StatTicker {
	symbol: string,
	nett: number
}
export interface StatStrat {
	wcount: number,
	wperc: number,
	wavg: number,
	wmax: number,
	wavgscore: number,
	lcount: number,
	lperc: number,
	lavg: number,
	lmax: number,
	lavgscore: number,
}

export interface DayNote { 
	id: number,
	date: string,
	userid: number,
	groupid: number,
	text: string,
	bias: string,
	trades: number[],
	base64Image: string,
	last_uuid?: string,
	sync_due?: boolean
}
export const DayNotesSchema = 'id, date, userid, groupid, text, bias, trades, base64Image, sync_due';

export interface JournalEntry {
	id: number,
	userid: number,
	date: string,
	title: string,
	category: string,
	text: string,
	base64Image?: string;
	sync_due?: boolean,
	last_uuid?: string,
	mentorship_id?: string,
	flagged: boolean,
	published: boolean
}
export const JournalSchema = 'id, userid, date, title, category, text, base64Image, sync_due, flagged, published';

export interface MentorProfile {
	id: number,
	userid: number,
	name: string,
	username?: string,
	description: string,
	avatar: string,
	x_handle: string,
	yt_handle: string,
	ig_handle: string,
	weburl: string,
	s_show: boolean,
	t_show: boolean,
	j_show: boolean,
	listed: boolean,
	strategies?: number,
	trades?: number,
	journals?: number,
	students?: number,
	status?: string
}

export interface Mentor {
	id: number, // mentorship ID
	userid: number, // local trader id
	mentorid?: number,
	status: string,
	mentorname: string,
	started: string,
	sync_due?: boolean,
	last_uuid?: string,
	avatar: string
}
export interface MentorTag {
	mentorid: number,
	mentorname: string,
	avatar: string,
	newcount: number
}
export interface MentorStartegySelect {
	id: number,
	strategyname: string,
	mentorname: string
}
export interface MentorJournalSelect {
	id: number,
	title: string,
	date: string
}
export interface Mentee {
	id: number, // mentorship ID
	userid: number, // local mentor id
	menteeid?: number,
	status: string,
	menteename: string,
	email: string,
	started: string,
	pending?: number,
	sync_due?: boolean,
	last_uuid?: string
}

export interface MentorMessage {
	id: number,
	mentorid: number,
	mentorshipid: number,
	sent: string,
	content: string,
	seen?: boolean,
}
export interface MentorReview {
	id: number,
	tradeid: number,
	accountid: number,
	mentorid: number,
	traderid: number,
	mentornotes: string,
	reviewed: boolean,
	seen: string
}
export interface Subscription {
	subscription_id: number,
	product_id: number,
	variant_id: number,
	status: string,
	renews_at: string,
	ends_at: string,
	period: string
}
export interface SubscriptionProduct {
	product_id: number,
	variant_id: number,
	store: string,
	period: string,
	price: number,
	role: string
}
/*
private long product_id;
	private long variant_id;
	private String store;
	private String period;
	private double price;
	private String role;*/


export const MentorSchema = 'id, userid, mentorid, started, status, mentorname, sync_due, avatar'
export const MenteeSchema = 'id, userid, menteeid, started, status, menteename, email, sync_due'
export const MentorMessageSchema = 'id, mentorid, mentorshipid, date, content, seen'

export interface JournalEvent {
	title: string,
	start: string,
	end: string,
	backgroundColor: string, // "#EE5D50" is red.500, #01B574 is green.500 - use HEX
	borderColor: string,
	extendedProps: { journalid: number, category: string, published: boolean }
}
///// FUNCTIONS/INSTANCES

export const newTrade = () => { // this still needed for processing trades
	const id = Math.floor(Math.random() * 10000);
	let newRow: Trade = {
		tradeid: id,
		accountid: 0,
		groupid: 0,
		userid: 0,
		//date: ["Enter executions below ...", 0],
		datetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
		duration: 0,
		ticker: '',
		currency: 'USD',
		side: '?',
		size: 0,
		entry_avg: 0,
		exit_avg: 0,
		stop_price: 0,
		target_price: 0,
		points: 0,
		profitloss: 0,
		rvalue: 0,
		target_rvalue: 0,
		fees: 0,
		nett: 0,
		rulescore: 0,
		executions: [],
		exchange: 1,
		closed: false,
		mistakes: [],
		strategies: [],
		mentornotes: '',
		notes: '',
		shared: false,
		req_review: false,
		reviewed: false,
		sync_due: false,
		last_uuid: localStorage.getItem('jornomid'),
		base64Image: '',
		flagged: false
	};
	return newRow;
};

export function newExecution() {
	const newRow: Execution = {
		datetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
		instrument: localStorage.getItem("instrument"),
		ticker: localStorage.getItem("ticker"),
		contract: '',
		side: '',
		size: null,
		price: null,
		fee: 0,
		stop: 0,
		currency: 'USD',
		editInstument: true,
		orderid: ''
	};
	return newRow;
}

export function newInstrument(inst: any) {
	const newInst: Instrument = {
		id: inst.id,
		userid: 0,
		ticker: inst.ticker,
		description: inst.description,
		category: inst.category,
		subcategory: inst.subcategory,
		currency: inst.currency,
		pointvalue: inst.pointvalue,
		ticksize: inst.ticksize,
		timezone: inst.timezone,
		relevance: inst.relevance,
		defaultstop: 0,
		commission: [],//[0, 0, 0, 0]
		sync_due: false
	};
	return newInst;
}

export function newStats() {
	const newRow: Statistics = {
		id: 1,
		period: 'all',
		periodstr: 'all',
		currency: 'USD',
		trade_count: [0, 0],
		pl_ratio: [0, 0],
		wl_ratio: [0, 0],
		//winners: [0, 0],
		//win_percantage: [0, 0],
		//losers: [0, 0],
		//loss_percantage: [0, 0],
		avg_pl: [0, 0],
		nett_gainloss: [0, 0],
		profit_factor: [0, 0],
		avg_rvalue: [0, 0],
	};
	return newRow;
}
export function newMinStats() {
	const newstat: MinimalStatistics = {
		currency: "USD",
		trade_count: 0,
		pl_ratio: 0,
		wl_ratio: 0,
		avg_pl: 0,
		nett_gainloss: 0,
		profit_factor: 0,
		avg_rvalue: 0
	}
	return newstat;
}

export function newPeriodDates() {
	const newRange: PeriodDates = {
		period: 'all',
		startthis: dayjs(),
		endthis: dayjs(),
		startlast: dayjs(),
		endlast: dayjs()
	}
	return newRange;
}

export function newPeriodTrades() {
	const newTradeRange: PeriodTrades = {
		current: [],
		previous: []
	}
	return newTradeRange;
}

export function newStrategyRule() {
	const newrule: StrategyRule = {
		id: 0,
		sid: 0,
		text: '',
		used: false
	}
	return newrule;
}

export function newDashCalEvent() {
	const newcalevt: DashCalEvent = {
		title: "",
		borderColor: "transparent",
		start: "",
		end: "",
		backgroundColor: "",
		extendedProps: { trades: [], groupid: 0, avgR: 0 }
	}
	return newcalevt;
}

export function newAdjustment() {
	const tempid = Math.floor(Math.random() * 10000);
	const adjust: AccountAdjustment = {
		id: tempid,
		datetime: dayjs().format('YYYY-MM-DD HH:mm'),
		reason: '',
		amount: 0
	}
	return adjust;
}

export function newJournalEvent() {
	const newevt: JournalEvent = {
		title: "",
		start: dayjs().format("YYYY-MM-DD"),
		end: dayjs().format("YYYY-MM-DD"),
		backgroundColor: "",
		borderColor: "",
		extendedProps: { journalid: 0, category: "", published: false }
	}
	return newevt;
}

export function newSubscription() {
	const newsub: Subscription = {
		subscription_id: 0,
		product_id: 0,
		variant_id: 0,
		status: '',
		renews_at: '',
		ends_at: '',
		period: ''
	}
	return newsub;
}

export function newAccountGroup() {
	const newaccgroup: AccountGroup = {
		groupid: 0,
		userid: 0,
		groupname: "",
		currency: "",
		defaultgrp: true,
		sync_due: false,
		last_uuid: ''
	}
	return newaccgroup;
}
