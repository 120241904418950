// Chakra imports
import { Flex, Box, FormLabel, Tag, TagCloseButton, TagLabel, useColorModeValue, Select, InputGroup } from '@chakra-ui/react';
// Custom components
import { ChangeEvent, useEffect, useState } from 'react';
import { Mistake } from '../../variables/types';
import MistakeModal from '../modals/MistakeModal';

function MistakeTags(props: { label?: string, id?: string, [x: string]: any, usedMistakeTags: { name: string; id: number }[], unusedMistakeTags: { name: string; id: number }[], updatemistaketags: any, notifychanges: any, tagtype: string, addnewsavedmistake: any }) {

	const { label, id, usedMistakeTags, unusedMistakeTags, updatemistaketags, notifychanges, addnewsavedmistake, ...rest } = props;

	useEffect(() => {

	}, [usedMistakeTags, unusedMistakeTags])

	const [usedTags, setUsedTags] = useState(props.usedMistakeTags);
	const [unusedTags, setUnusedTags] = useState(props.unusedMistakeTags);

	let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

	let cs = (props.tagtype === 'strategies' ? true : false);

	let bgs = useColorModeValue('gray.500', 'gray.700');
	let bgm = useColorModeValue('red.500', 'red.700');

	const addTag = (e: ChangeEvent<HTMLSelectElement>) => {
		try {
			let tag = unusedTags.filter((t) => t.id === Number(e.target.value));
			let unused = unusedTags.filter((t) => t.id !== tag[0].id);
			let used = [...usedTags, tag[0]];
			setUnusedTags(unused);
			setUsedTags(used);
			updatemistaketags(used, unused);
			//let s = document.getElementById('tagselect') as HTMLSelectElement;
			//s.value = "";
		} catch { }


		//alert("USED: " + JSON.stringify(used) + " UNUSED: " + JSON.stringify(unused));
	}
	const removeTag = (tag: any) => {
		try {
			let unused = [...unusedTags, tag];
			let used = [...usedTags.filter((element) => element.id !== tag.id)]

			setUsedTags(used);
			setUnusedTags(unused.sort((a, b) => a.name.localeCompare(b.name)));
			updatemistaketags(used, unused);
			//let s = document.getElementById('tagselect') as HTMLSelectElement;
			//s.value = "";
		} catch { }

		//alert("USED: " + JSON.stringify(used) + " UNUSED: " + JSON.stringify(unused));
	}
	const updateTagChange = (s: { name: string; id: number }) => {

		//alert(JSON.stringify(s));
		let tag: { name: string; id: number } = { name: s.name, id: s.id };

		let u: { name: string; id: number }[] = usedTags.slice(); // copies array
		let un: { name: string; id: number }[] = unusedTags.slice();

		const u_index = u.findIndex((t) => t.id === tag.id);
		const un_index = un.findIndex((t) => t.id === tag.id);

		if (u_index > -1) {
			u = [...u.slice(0, u_index), tag, ...u.slice(u_index + 1)];
			setUsedTags(u);
		}
		else {
			if (un_index > -1) {
				un = [...un.slice(0, un_index), tag, ...un.slice(un_index + 1)];
			}
			else {
				un = [...un, tag];
			}
			setUnusedTags(un);
		}
		updatemistaketags(u, un);

		//alert("USED: " + JSON.stringify(u) + " UNUSED: " + JSON.stringify(un));
	}

	const notifyTagChanges = (s: { name: string; id: number }) => {
		notifychanges(s);
	}

	const addNewSavedtag = (m: Mistake) => {
		let mi = { name: m.name, id: m.id };
		setUnusedTags([...unusedTags, mi])
		addnewsavedmistake(mi);
		/*
		let mi = { name: m.name, id: m.id };
		let unused = [...unusedTags, mi];
		let used = [...unusedTags];
		setUnusedTags(unused);
		updatemistaketags(used, unused);
		 */
	}
	const deleteTag = (id: number) => {
		let un = unusedTags.filter((t) => t.id !== id);
		setUnusedTags(un);
		//updatemistaketags(usedTags, un);
	}




	return (
		<Box overflowY='auto' mb='8px'>
			<FormLabel htmlFor={id} fontWeight='bold' fontSize='sm' mb='8px'>
				{label}
			</FormLabel>
			<Flex
				direction='row'
				p='10px'
				wrap='wrap'
				bg='transparent'
				//border='1px solid'
				borderColor={borderColor}
				borderRadius='16px'
				_focus={{ borderColor: 'teal.300' }}
				minH='40px'
				h='auto'
				cursor='text'
				{...rest}>
				<InputGroup size='sm'>
					<Select size='sm' borderLeftRadius={'5px'} onChange={addTag} value={""} mb='15px'>
						<option value="">Select tag ...</option>
						{unusedTags.map((tag, index) => (
							<option key={index} value={tag.id}>
								{tag.name}
							</option>
						))}
					</Select>
					{/*props.tagtype === "strategies" && <StrategiesModal updateStrategy={updateTagChange} notifyChanges={notifyTagChanges}/>*/}
					{props.tagtype === "mistakes" && <MistakeModal updateMistakes={updateTagChange} notifyChanges={notifyTagChanges} addnewsavedtag={addNewSavedtag} deletemistake={deleteTag} largebtn={false}/>}
				</InputGroup>
				{usedTags.map((tag, index) => {
					return (
						<Tag
							fontSize='sm'
							fontWeight={'700'}
							h='25px'
							mb='6px'
							me='6px'
							borderRadius='5px'
							variant='solid'
							bg={(cs ? bgs : bgm)}
							key={index}>
							<TagLabel w='100%'>{tag.name}</TagLabel>
							<TagCloseButton
								justifySelf='flex-end'
								color='white'
								onClick={() => removeTag(tag)}
							/>
						</Tag>
					);
				})}
			</Flex>
		</Box>
	);
}

export default MistakeTags;
