import { Button, Flex, Icon, Input, Text, useColorModeValue } from '@chakra-ui/react'
import React, { ChangeEvent, useState } from 'react'
import { MdCheck, MdClose, MdEdit } from 'react-icons/md'
import { StrategyRule } from '../../variables/types'

const StrategyRuleInput = (props: { rule: StrategyRule, removerule: any, updatetext: any }) => {

    const textColor = useColorModeValue('gray.700', 'white');

    const [text, setText] = useState(props.rule.text);
    const [editing, setEditing] = useState((props.rule.text.trim().length < 1));

    const setruletext = (e: ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
    }
    const editrule = (edit: boolean) => {
        if (!edit) {
            props.rule.text = text;
            props.updatetext(props.rule);
        }
        setEditing(edit);
    }


    return (
        <Flex direction={'row'} justify={'space-between'}>
            <Flex>
                <Button variant={'outline'} p='0px' size={'sm'} onClick={() => { editrule(true) }} borderRadius='5px' me='5px' hidden={editing}>
                    <Icon as={MdEdit} boxSize={5} />
                </Button>
                <Text ms='20px' mb='15px' mt='5px' hidden={editing}>{text}</Text>
                <Input autoFocus={true} w='250px' size={'sm'} mb='15px' placeholder="Enter rule ..." color={textColor} onChange={setruletext} value={text} hidden={!editing} onKeyDown={(e) => (e.key === "Enter" ? (text.toString().trim().length > 0 ? editrule(false) : null) : null)}></Input>
            </Flex>
            <Flex>
                <Button isDisabled={!text.trim().length} variant={'outline'} p='0px' size={'sm'} onClick={() => { editrule(false) }} borderRadius='5px' ms='20px' hidden={!editing} disabled={text.toString().trim().length < 1}>
                    <Icon as={MdCheck} boxSize={5} color={'blue.500'} />
                </Button>
                <Button variant={'outline'} p='0px' size={'sm'} onClick={() => { props.removerule(props.rule.id) }} borderRadius='5px' ms='5px'>
                    <Icon as={MdClose} boxSize={5} color={'red.500'} />
                </Button>
            </Flex>

        </Flex>
    )
}
export default StrategyRuleInput

/*
<Flex direction={'row'} justify={'space-between'}>
            <Flex>
                <Button variant={'outline'} p='0px' size={'sm'} onClick={() => { editMistake(true) }} borderRadius='5px' me='5px' hidden={editing && !saving}>
                    <Icon as={MdEdit} boxSize={5} />
                </Button>
                <Text ms='20px' mb='15px' mt='5px' hidden={editing}>{text}</Text>
                <Input autoFocus={true} w='250px' size={'sm'} mb='15px' placeholder="Enter rule ..." color={textColor} onChange={setMistaketext} value={text} hidden={!editing} onKeyDown={(e) => (e.key === "Enter" ? (text.toString().trim().length > 0 ? editMistake(false) : null) : null)}></Input>
            </Flex>
            <Flex>
                <Button isDisabled={!text.trim().length} variant={'outline'} p='0px' size={'sm'} onClick={() => { editMistake(false) }} borderRadius='5px' ms='20px' hidden={!editing && !saving} disabled={text.toString().trim().length < 1} isLoading={saving}>
                    <Icon as={MdCheck} boxSize={5} color={'blue.500'} />
                </Button>
                <Button variant={'outline'} p='0px' size={'sm'} onClick={() => { props.removerule(props.rule.id) }} borderRadius='5px' me='5px'>
                <Icon as={MdClose} boxSize={5} color={'red.500'} />
            </Button>
            </Flex>

        </Flex>



        <Flex direction={'row'}>
            <Button variant={'outline'} p='0px' size={'sm'} onClick={() => { props.removerule(props.rule.id) }} borderRadius='5px' me='5px'>
                <Icon as={MdClose} boxSize={5} color={'red.500'} />
            </Button>
            <Button variant={'outline'} p='0px' size={'sm'} onClick={() => { editrule(true) }} borderRadius='5px' me='5px' hidden={editing}>
                <Icon as={MdEdit} boxSize={5} />
            </Button>
            <Text ms='20px' mb='15px' mt='5px' hidden={editing}>{text}</Text>
            <Input autoFocus={true} size={'sm'} mb='15px' placeholder="Enter rule ..." color={textColor} onChange={setruletext} value={text} hidden={!editing} onKeyDown={(e) => (e.key === "Enter" ? (text.toString().trim().length > 0 ? editrule(false) : null) : null)}></Input>
            <Button variant={'outline'} p='0px' size={'sm'} onClick={() => { editrule(false) }} borderRadius='5px' ms='20px' hidden={!editing} disabled={text.toString().trim().length < 1}>
                <Icon as={MdCheck} boxSize={5} color={'blue.500'} />
            </Button>
        </Flex>
*/