import { Flex, Select, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { ChangeEvent, useEffect, useState } from 'react'
import { useData } from 'services/data/DataProvider'
import IntradayChart from 'views/trader/dashboard/components/charts/IntradayChart'
import MonthlyChart from 'views/trader/dashboard/components/charts/MonthlyChart'
import WeekdayChart from 'views/trader/dashboard/components/charts/WeekdayChart'
import { TimeSeriesData } from 'views/trader/dashboard/variables/TimeSeriesData'
import { StatIntraday, StatMonthly, StatWeekday } from 'views/trader/trades/variables/types'


const TimeStats = (props: { hidden?:boolean}) => {

    const { periodtrades, defaultcurrency } = useData();

    const bdColor = useColorModeValue('gray.200', 'gray.700');

    const [intraday, setIntraday] = useState<StatIntraday[]>([])
    const [weekdays, setWeekdays] = useState<StatWeekday[]>([])
    const [monthly, setMonthly] = useState<StatMonthly[]>([])
    const [ticker, setTicker] = useState("");
    const [tickers, setTickers] = useState([]);
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        updateVariables();
    }, [periodtrades])

    const updateVariables = async () => {
        setHidden(!periodtrades.length);
        if (periodtrades.length) {
            await TimeSeriesData(periodtrades, defaultcurrency).then((res) => {
                setTickers(res.tickers);
                setIntraday(res.day);
                setWeekdays(res.week);
                setMonthly(res.month);
                setHidden(false);
            })
        }
    }

    const changeChart = (e: ChangeEvent<HTMLSelectElement>) => {
        setTicker(e.target.value);
        //localStorage.setItem("chart", e.target.value);
    }

    return (
        <Card borderColor={bdColor} borderWidth='1px' h='100%' hidden={props.hidden} bg='transparent'>

            <Flex direction={'row'} justify={'space-between'} >
                <Flex direction={'row'}>
                    <Select size={'sm'} w='150px' borderRadius={'5px'} placeholder={'All tickers'} onChange={changeChart}>
                        {tickers.map(t => (
                            <option key={t} value={t} >
                                {t}
                            </option>
                        ))}
                    </Select>
                </Flex>
            </Flex>
            <Tabs isFitted variant='enclosed' mt="10px" align='center' defaultIndex={1}>
                <TabList>
                    <Tab>Intraday</Tab>
                    <Tab>Weekdays</Tab>
                    <Tab>Monthly</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <IntradayChart stats={intraday} currency={defaultcurrency} ticker={ticker} hidden={hidden} />
                    </TabPanel>
                    <TabPanel>
                        <WeekdayChart stats={weekdays} currency={defaultcurrency} ticker={ticker} hidden={hidden} />
                    </TabPanel>
                    <TabPanel>
                        <MonthlyChart stats={monthly} currency={defaultcurrency} ticker={ticker} hidden={hidden} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Card>
    )
}

export default TimeStats